import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import './CardLIstingMenu.scss';
import Modal from 'new/components/Modal/Modal';
import Cards from '../Cards/Cards';
import ModalAddNewCard from 'new/containers/checkout/components/ModalAddNewCard/ModalAddNewCard';

import { deletePaymentCard } from 'helpers/apiHelper';
import { notification } from 'helpers/util';
import { domainColorStyle } from 'config';
import { getListingCards } from 'helpers/apiHelper';
import { getAllPaymentTypes } from 'helpers/apis/marketInfo';

export default function CardLIstingMenu({ removeSection }) {
  const [showModalCardData, setShowModalCardData] = useState(false)
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null)

  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const marketId = useSelector(({ markets }) => markets.id) || localStorage.getItem("market_id")
  const customerId = useSelector(({ userInfo }) => userInfo.id)

  const validateBrandWithPagarme = async (marketID) => {
    try {
      const { data } = await getAllPaymentTypes(marketID);
      const paymentTypes = data.payment_types;
      const isValidPaymentStoneCredit = paymentTypes?.some(
        (payment) =>
          payment.kind === "online" && payment.codename === "stone_credit"
      );
      if (!isValidPaymentStoneCredit) {
        removeSection("Meus Cartões");
      }
    } catch (error) {
      removeSection("Meus Cartões");
      throw error
    }
  };

  const listCards = async () => {
    const { cards } = await getListingCards(marketId, customerId, deliveryMethod.id)
    cards ? setCardList(cards) : setCardList([])
  }

  const deleteCardFromList = async (cardId) => {
    const response = await deletePaymentCard(cardId, customerId, marketId)
    if (!response.data) {
      setCardList((prevCardList) => prevCardList.filter((card) => card.id !== cardId))
      notification(`${response.message}`, "success")
    } else {
      notification("Erro interno, contate o suporte", "danger")
    }
  }

  useEffect(() => {
    if (marketId) validateBrandWithPagarme(marketId)
    listCards();
  }, [])


  const handleCardClick = (cardId) => {
    setSelectedCardId(cardId)
  }


  return (
    <>
      <Box style={{ margin: 10 }}>
        <Grid container>
          <Box className='header-card-listing'>
            <Typography variant="h6" className='title-card-listing-menu' style={domainColorStyle("color")}>
              Meus cartões
            </Typography>
            <IconButton
              className='button-new-card-menu'
              style={domainColorStyle("color")}
              onClick={() => setShowModalCardData(true)}>
              <Add />
              <span>Novo</span>
            </IconButton>
          </Box>
          <Grid container spacing={3} className='body-card-listing-menu'>
            {cardList.map((card) => (
              <Grid item xs={12} sm={12} md={6} key={card?.id}>
                <Cards
                  lastFourDigits={card?.last_four_digits}
                  brandImg={card?.brand}
                  cardId={card?.id}
                  onDelete={deleteCardFromList}
                  onClick={handleCardClick}
                  selectedCardId={selectedCardId}
                  allCards={cardList}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Modal
        isOpen={showModalCardData}
        className="order-client-modal">
        <ModalAddNewCard
          setShowModal={setShowModalCardData}
          getNewCardList={listCards}/>
      </Modal>
    </>
  );
} 