import React from "react";
import Product from "components/NewProductCard/Product";
import './OffersContainer.scss';
import { groupBySection } from "helpers/util";
import { useSelector } from "react-redux";
import { groupProductsByMixId } from "helpers/mixesHelper";
import { removeRepeatedMixes } from "helpers/mixesHelper";




function OffersContainer({ products, currentCategory, brand }) {
  const categories = useSelector(({categories}) => categories.categories)
  const groupedProducts = groupBySection(products, categories)

  return groupedProducts.map((category) => {
    const categoryProducts = category.products
    return (
      <div className="px-1 px-lg-4 container-offers-products">
        {categoryProducts &&
          removeRepeatedMixes(categoryProducts).map((product, productKey) => (
            <Product
              currentCategory={currentCategory}
              products={products}
              product={product}
              key={productKey}
              brand={brand}
              index={productKey}
              mix={groupProductsByMixId(product, categoryProducts)}
            />
          ))}
      </div >
    )
  });
}

export default OffersContainer;
