import React from "react";
import { productsArrayFromMix } from "helpers/mixesHelper";
import { connect, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "actions/cartActions";
import { openDeliveryMethod } from "actions/configsActions";
import { useHistory } from "react-router-dom";
import ProductCard from "../components/NewProductCard/ProductCard";

function MapProducts({ currentMarket, product, cart, origin }) {
  const history = useHistory();
  const products = productsArrayFromMix(product);
  const brand = useSelector(({brand}) => brand.brand)

  const getCartAmount = (product) => {
    return (cart[product.id] || {}).amount || 0;
  };

  return products.map((product, key) => (
    <ProductCard
      key={key}
      origin={origin}
      product={product}
      brand={brand}
      currentMarket={currentMarket}
      amount={getCartAmount(product)}
      history={history}
    />
  ));
}
function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentMarket: state.markets
  };
}

export default connect(mapStateToProps, {
  addToCart,
  removeFromCart,
  openDeliveryMethod,
})(MapProducts);
