import React from "react"
import { useSelector } from "react-redux";
import { Box, Card } from "@material-ui/core";
import { getMixesFromCart, getProductsTotal, getCartTotal } from "helpers/cartHelper";
import numberToCurrency from "helpers/currency";
import { domainColorStyle } from "config";

import "./OrderDetails.scss"


export function OrderDetails({ discounts = [], total_discounts = 0, request_custom_bags, correioDeliveryTax }) {
  const market = useSelector(({ markets }) => markets)
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const total_products = getProductsTotal(cart);
  const total_value = getCartTotal(
    cart,
    market,
    deliveryMethod.mode === "drivethru",
    total_discounts,
    parseFloat(request_custom_bags.custom_bag_value || 0),
    deliveryMethod,
    correioDeliveryTax
  )

  function deliveryTax() {
    const tax = deliveryMethod.combinedTax(market)
    const isValidCorreio = deliveryMethod.tax_calculation_method === 'correios'
    if (!deliveryMethod) {
      return "Taxa: R$ 0,00";
    } else if (tax === 0) {
      return "Grátis";
    } else if(isValidCorreio){
      return !correioDeliveryTax?.price ? 'à calcular': correioDeliveryTax.price
    } 
    else {
      return numberToCurrency(tax);
    }
  }
  const verifyDeliveryMethod = () => deliveryMethod && deliveryMethod.serviceTax(market) > 0;

  return (
    <Card className='order-details'>
      <Box display="flex" flexDirection="column">
        <Box px={3} pt={2} className="title-details">
          <h5>Resumo do pedido</h5>
        </Box>
        <Box display="flex" flexDirection="column" px={3} pt={1} className='values-details'>
          <span>Valor no carrinho:
            <label>{numberToCurrency(total_products)}</label>
          </span>
          <span>Taxa de {deliveryMethod.mode === "drivethru" ? "retirada" : "entrega"}:
            <label>{deliveryTax()}</label>
          </span>
          {request_custom_bags.custom_bag_value &&
            <span key={request_custom_bags.custom_bag_id}>
              {request_custom_bags.name}:
              <label>{numberToCurrency(request_custom_bags.custom_bag_value)}</label>
            </span>
          }
          {verifyDeliveryMethod() &&
            <span>Taxa de serviço:
              <label>{numberToCurrency(market.service_tax)}</label>
            </span>
          }
          {discounts.map((discount) => (
            <span key={discount.title} className="discount">
              {discount.title}:
              <label>-{numberToCurrency(discount.absolute_value)}</label>
            </span>
          ))}


          <Box mt="5px">
            <p style={{ ...domainColorStyle("color") }}>Total: <label>{numberToCurrency(total_value)}</label></p>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default React.memo(OrderDetails);