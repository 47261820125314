import { ORDENATION_CHANGED } from "ActionTypes";
import { SEARCH_TEXT_CHANGED, SEARCH_ACTIVE_CHANGED } from "ActionTypes";

const initialState = {
  searchText: "",
  searchActive: false,
  ordenation: "1",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TEXT_CHANGED:
      return Object.assign({}, state, {
        searchText: action.payload,
      });
    case SEARCH_ACTIVE_CHANGED:
      return Object.assign({}, state, {
        searchActive: action.payload,
      });
    case ORDENATION_CHANGED:
      return Object.assign({}, state, {
        ordenation: action.payload,
      });
    default:
      return state;
  }
}
