import React, { cloneElement } from "react";
import { Divider, ListItem, ListItemText } from "@material-ui/core";
import { domainColorStyle } from "config";

function ItemList({
  title,
  icon,
  isSelected,
  handleClick
}) {

  const selectedStyle = {
    item: isSelected ? { ...domainColorStyle("backgroundColor") } : { backgroundColor: "transparent" },
    icon: isSelected ? { color: "#fff", marginRight: 10 } : { ...domainColorStyle("color"), marginRight: 10 },
    text: isSelected ? { color: "#fff" } : { ...domainColorStyle("color") },
    divider: isSelected ? { ...domainColorStyle("backgroundColor"), height: 2, width: "100%" } : { width: "90%" }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ListItem
        button
        onClick={handleClick}
        style={selectedStyle.item}
      >
         {cloneElement(icon, { style: selectedStyle.icon })}
        <ListItemText
          style={selectedStyle.text}
          primary={title}
        />
      </ListItem>
      <Divider
        style={selectedStyle.divider}
      />
    </div>
  );
}

export default ItemList;
