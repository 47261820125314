import React from "react";
import numberToCurrency from "helpers/currency";
import "./sidebar.scss";
import { useSelector } from "react-redux";

function CartValues({ productsTotal }) {
  const currentMarket = useSelector(({ markets }) => markets)
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)

  const validateFunctionAndExecute = (func, param) => {
    return typeof func === "function" && func(param)
  }

  function serviceTax() {
    if (!deliveryMethod || !currentMarket) return null;
    if (validateFunctionAndExecute(deliveryMethod.serviceTax, currentMarket) > 0) {
      return (
        <p>
          Taxa de serviço:
          <span>{numberToCurrency(currentMarket.service_tax)}</span>
        </p>
      );
    }
    return null;
  }

  function taxLabel() {
    if (!deliveryMethod || !currentMarket) {
      return "Taxa: R$ 0,00";
    }
    const combinedTax = validateFunctionAndExecute(deliveryMethod.combinedTax, currentMarket) || 0;
    const taxValue = deliveryMethod?.tax === 0 ? "Grátis" : numberToCurrency(combinedTax);
    let taxLabel = "Taxa de entrega:";
    const isValidCorreio = deliveryMethod.tax_calculation_method === 'correios'
    if (deliveryMethod.mode === "drivethru") {
      taxLabel = `Taxa de retirada: `;
    }

    return (
      <p>
        {taxLabel}
        <span>{!isValidCorreio ? taxValue : 'à calcular'}</span>
      </p>
    );
  }

  return (
    <>
      <p>
        Método de entrega: <span>{(deliveryMethod || {}).display_name}</span>
      </p>
      {(deliveryMethod || {}).max_items_amount && (
        <p>
          Limite máximo de items:
          <span>{(deliveryMethod || {}).max_items_amount}</span>
        </p>
      )}
      <p>
        Valor mínimo de compra:
        {(deliveryMethod || {}).min_order_price === 0 ? (
          <span> Sem Valor mínimo </span>
        ) : (
          <span>
            {numberToCurrency((deliveryMethod || {}).min_order_price)}
          </span>
        )}
      </p>

      {taxLabel()}
      {serviceTax()}
      <p className="total-cart">
        Total
        <span>{numberToCurrency(productsTotal)}</span>
      </p>
    </>
  );
}

export default CartValues;
