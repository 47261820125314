import React, { useEffect, useState } from "react";
import { Box, Card, Grid } from "@material-ui/core";
import { maskPhone, formatCpf, maskCNPJ } from "helpers/util";
import { checkAge } from "helpers/util";
import moment from "moment";
import "./OrderClientInfos.scss";
import BirthdayModal from "components/StorePage/BirthdayModal/BirthdayModal";
import { getMixesForAdults } from "helpers/cartHelper";
import { useDispatch, useSelector } from "react-redux";
import { userInfoChanged } from "actions/userInfoActions";

export function OrderClientInfos({ cart }) {
  const [birthdateModal, setBirthdateModal] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const birthDate = userInfo.cpf.length > 11 ? userInfo.responsible_birthdate : userInfo.birth_date
  const age = checkAge(new Date(userInfo.birth_date));

  useEffect(() => {
    const mixesForAdults = getMixesForAdults(cart);
    if ((age < 18 && mixesForAdults.length >= 1) || age < 15) {
      setBirthdateModal(true);
    }
  }, [userInfo, age, cart])


  return (
    <>
      <Card className="mt-3 order-client-infos">
        <Box display="flex" flexDirection="column">
          <Box
            pl={3}
            pr={2}
            pt={1}
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="title-details"
          >
            <h5>Dados do Cliente</h5>
          </Box>
          <Box px={3} pb={2} className="client-infos" display="flex" flexWrap="wrap">
                <Grid item xs={12} md={6} lg={6}>
                  <span>Nome Completo</span>
                  <p>{userInfo.name}</p>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <span>{userInfo.cpf.length > 11 ? "CNPJ" : "CPF"}</span>
                  <p>{userInfo.cpf.length > 11 ?
                   maskCNPJ(userInfo.cpf) : formatCpf(userInfo.cpf)}</p>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                  <span>Telefone</span>
                  <p>{maskPhone(userInfo.phone_number)}</p>
                </Grid >
                <Grid item xs={6} md={3} lg={3}>
                  <span>Telefone secundário</span>
                  <p>{maskPhone(userInfo.second_phone_number)}</p>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <span>Data de nascimento</span>
                  <p>{moment(birthDate, "DD/MM/YYYY").format("DD/MM/YYYY")}</p>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <span>Email</span>
                  <p>{userInfo.email}</p>
                </Grid>
              </Box >
        </Box >
      </Card >

      {
        birthdateModal && age < 18 && (
          <BirthdayModal
            isCheckout={true}
            showModal={true}
            showAgeInput={false}
            user={{ ...userInfo, age }}
            setState={(client) =>
              dispatch(userInfoChanged({ ...userInfo, age: client.age }))
            }
            toggleSubcategory={() => { }}
            toggleModal={() =>
              setBirthdateModal(false)
            }
          />
        )
      }
    </>
  );
}

export default React.memo(OrderClientInfos);
