import config from "config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function getFlipBooks(market) {
  try {
    const flipbooks = await oAuthRequest.get(`${config.mappURLV2}/markets/${market}/flipbooks`);
    return flipbooks.data;
  } catch (error) {
    console.log("Brand error");
  }
}
