import config from "config";
import { getMixesFromCart } from "helpers/cartHelper";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function createList(name, cart, market, customerId) {
  const products_to_add = getMixesFromCart(cart).map((product) => ({
    product_id: product.id,
    amount: product.amount,
  }));
  try {
    const response = await oAuthRequest.post(
      `${config.mappURLV2}/mercadapp_market_lists/save`,
      {
        id: null,
        name: name,
        market_id: market.id,
        customer_id: customerId,
        should_override_items: false,
        products_to_add: products_to_add,
        products_to_remove: [],
      },
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("List error", error.response);
    return error.response.data;
  }
}

export async function updateList(id, name, cart, market, to_remove, customerId) {
  const products_to_add = getMixesFromCart(cart).map((product) => ({
    product_id: product.id || product.product_id,
    amount: product.amount,
  }));
  const products_to_remove = to_remove
    ? getMixesFromCart(to_remove).map((product) => ({
      product_id: product.product_id,
    }))
    : [];
  try {
    const response = await oAuthRequest.post(
      `${config.mappURLV2}/mercadapp_market_lists/save`,
      {
        id: id,
        name: name,
        market_id: market.id,
        customer_id: customerId,
        should_override_items: false,
        products_to_add: products_to_add,
        products_to_remove: products_to_remove || [],
      },
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("List error", error.response);
    throw error.response.data;
  }
}

export async function renameList(list, name, customerId) {
  try {
    const response = await oAuthRequest.patch(
      `${config.mappURLV2}/mercadapp_market_lists/${list}`,
      {
        name: name,
        customer_id: customerId,
      },
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("List error", error.response);
    throw error.response.data;
  }
}

export async function getLists(marketId, customerId) {
  const url = `${config.mappURLV2}/mercadapp_market_lists`
  const params = {
    market_id: marketId,
    customer_id: customerId
  }

  try {
    const response = await oAuthRequest.get(url, { params: params });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getList(list) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/mercadapp_market_lists/${list}`,
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function leaveList(list, customerId) {

  try {
    const response = await oAuthRequest.post(
      `${config.mappURLV2}/mercadapp_market_lists/leave`,
      {
        id: list,
        customer_id: customerId,
      },
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteList(list, customerId) {
  try {
    const response = await oAuthRequest.delete(
      `${config.mappURLV2}/mercadapp_market_lists/${list}?customer_id=${customerId}`,
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function sendInvite(listId, phone) {
  try {
    const response = await oAuthRequest.post(
      `${config.mappURLV2}/mercadapp_market_lists/invite`,
      {
        id: listId,
        phone_number: phone,
      },
      {
        headers: {

          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("List error", error.response);
    return error.response.data;
  }
}
