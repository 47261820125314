import React from "react";
import { Input, FormGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ordenationChanged } from "actions/uiActions";
import "./ProductsSorter.scss";
import { getDomainMarketColor } from "config";

const ProductsSorter = () => {
  const domainColor = getDomainMarketColor();
  const dispatch = useDispatch();
  const ordenation = useSelector(({ ui }) => ui.ordenation);
  return (
    <FormGroup className="products-sorter">
      <Label for="exampleSelect">Ordenar</Label>
      <div>
        <Input
          type="select"
          name="selectMulti"
          id="exampleSelect"
          value={ordenation}
          style={{ color: domainColor }}
          onChange={({ target }) => dispatch(ordenationChanged(target.value))}
        >
          <option value={"1"}>A-Z</option>
          <option value={"2"}>Z-A</option>
          <option value={"3"}>Maior Preço</option>
          <option value={"4"}>Menor Preço</option>
        </Input>
      </div>
    </FormGroup>
  );
};

export default ProductsSorter;
