import React, {Component} from "react";
import {domainColorStyle} from "config";
import {Row} from "reactstrap";
class OfferLabel extends Component {
  render() {
    const {isOffer, useCrm} = this.props;
    if (isOffer && !useCrm) {
      return (
        <Row
          style={{...domainColorStyle("background"), position: "absolute", width: "50%", zIndex: 2, left: 20}}
          className={"text-center circle-corners mt-2 py-1 py-1"}
        >
          <strong className="text-white blink-me w-100">OFERTA!</strong>
        </Row>
      );
    }

    if (isOffer && useCrm) {
      return (
        <Row
          style={{backgroundColor: "#d62d20", position: "absolute", width: "50%", zIndex: 2, left: 20}}
          className={"text-center circle-corners mt-2 py-1 py-1"}
        >
          <strong className="text-white w-100">CLUBE!</strong>
        </Row>
      );
    }

    return (
      <></>
    );
  }
}
export default OfferLabel;
