import {SET_CRM, CLEAR_CRM} from "ActionTypes";

export function setCRM(response) {
  return {
    type: SET_CRM,
    crmData: response,
  };
}

export function clearCRM() {
  return {
    type: CLEAR_CRM,
  };
}