import React from "react";
import OrderStatus from "../OrderStatus/OrderStatus";
import { getDeliveryTime } from "helpers/orderhelper";
import "./OrderInfos.scss";
import { Box, Typography } from "@material-ui/core";

const OrderInfos = ({ order, minutes, seconds }) => {
  return (
    <Box className="order-infos">
      <OrderStatus
        status={order?.status.status}
        minutes={minutes}
        seconds={seconds}
      />
      <Typography variant="h5">Pedido Nº {order?.order_number}</Typography>
      <Box className="order-infos-box">
        <Box>
          <b>Entrega:</b>
          <span>{getDeliveryTime(order)}</span>
        </Box>
        <Box>
          {order?.address === ", drivethru" ? (
            <>
              <b>Forma de retirada: </b>
              <span>Drivethru</span>
            </>
          ) : (
            <>
              <b>Endereço:</b>
              <span>{order?.address}</span>
            </>
          )}
        </Box>
        <Box>
          <b>Forma de pagamento: </b>
          <span>{order?.payment_type_name}</span>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderInfos;
