import config from "config";

export const numberToCurrency = (x) => {
  return (parseFloat(x) || 0).toLocaleString(config.currentLocale, {
    style: "currency",
    currency: config.currentCurrency,
  });
};

export const numberToDecimal = (x) => {
  return (x || 0).toLocaleString(config.currentLocale);
};

export const ToDecimal = (x) => {
  return parseFloat(x || 0).toFixed(2).replace(".", ",");
}

export default numberToCurrency;
