import React, { useState } from "react";
import Modal from "new/components/Modal/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Button, IconButton, Typography, LinearProgress } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { domainsBrandIds } from "config";
import "./ChangeBrand.scss";
import { getListAllMarkets } from "helpers/apis/marketInfo";
import { debounce } from "lodash";

const ChangeLocalBrand = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasActiveMarkets, setHasActiveMarkets] = useState(true);
  const closeModal = () => setOpen(false);
  const hasLocalBrandID = Boolean(localStorage.getItem("localBrandID"));

  const isBrandValid = (brandValue) => domainsBrandIds.includes(brandValue);

  const getBrandMarkets = debounce(async (brandValue, setFieldValue) => {
    setIsLoading(true);
    try {
      const { data } = await getListAllMarkets(brandValue);
      setHasActiveMarkets(data.length > 0);
    } catch (error) {
      setHasActiveMarkets(false);
    } finally {
      setIsLoading(false);
      setFieldValue("brandId", brandValue);
    }
  }, 1000);

  const validationSchema = Yup.object().shape({
    brandId: Yup.number()
      .required("Campo obrigatório")
      .test("valid-brand", "Marca inválida", isBrandValid)
      .test("enabled-brand", "Marca sem lojas ativas", () => hasActiveMarkets),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      localStorage.clear();
      closeModal();
      localStorage.setItem("localBrandID", values.brandId);
      window.location.assign(window.location.origin);
    } catch (error) {
      console.log(`Error on submit: ${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  const navigateToTestHost = () => {
    closeModal();
    localStorage.clear();
    window.location.assign(window.location.origin);
  };

  return (
    <Modal isOpen={open} toggleModal={closeModal} size={"xs"}>
      <Box className="change-brand-modal-title">
        <Typography variant="h6">Alterar marca do site</Typography>
        <IconButton color="primary" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Formik
        initialValues={{ brandId: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div>
              <label htmlFor="brandId">ID da Marca</label>
              <Field
                type="number"
                id="brandId"
                name="brandId"
                className="form-control"
                style={{ fontWeight: "bold" }}
                placeholder="Insira um ID"
                onChange={({ target }) => {
                  setFieldValue("brandId", target.value);
                  getBrandMarkets(target.value, setFieldValue);
                }}
              />
              {isLoading ? (
                <LinearProgress style={{ marginTop: "1rem" }}/>
              ) : (
                <ErrorMessage
                  name="brandId"
                  component="div"
                  className="error"
                  style={{ fontSize: "smaller", margin: "0.5rem", color: "#f00" }}
                />
              )}
            </div>

            <div className="change-brand-modal-actions">
              {hasLocalBrandID ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={navigateToTestHost}
                  type="button"
                >
                  Voltar ao Site Teste
                </Button>
              ) : null}
              <Button type="submit" color="primary" variant="contained">
                Alterar Marca
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLocalBrand;
