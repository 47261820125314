import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { domainColorStyle } from "config";
import numberToCurrency from "helpers/currency";

import "./OrdersList.scss";
import FeedbackStars from "components/FeedbackStars/FeedbackStars";
import ChatOrder from "components/ChatOrder/Chat";
import ChatOrderFirebase from "components/ChatOrderFirebase/Chat";
import modally from "components/modally";
import OrderCancelationForm from "components/OrderCancelationForm";
import { timeZoneAdjustedMoment } from "helpers/util";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getOrders } from "helpers/apiHelper";
import ChangeOrderTime from "new/components/UserPage/ChangeOrderTime/ChangeOrderTime";
import Feedback from "../Feedback/Feedback";
import { getUseLegacyChat } from "../../../firebase";
const OrderCancelationModal = modally(OrderCancelationForm);
const ChangeOrderTimeModal = modally(ChangeOrderTime)

function OrdersList() {
  const history = useHistory();
  const [chat, setChat] = useState(null);
  const [orders, setOrders] = useState([])
  const [orderNumberSelected, setOrderNumberSelected] = useState();
  const [orderIdSelected, setOrderIdSelected] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false)
  const [showCancelationModal, setShowCancelationModal] = useState(false);
  const [showChangeTimeModal, setShowChangeTimeModal] = useState(false)
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
  const customer = useSelector(({ userInfo }) => userInfo)


  function toggle(orderNumber, orderId) {
    if (openDropdown) setOpenDropdown(false);
    else {
      setOrderNumberSelected(orderNumber);
      setOrderIdSelected(orderId)
      setOpenDropdown(true);
    }
  }

  const loadOrders = useCallback(async () => {
    const orders = await getOrders(customer.id);
    setOrders(orders.data)
  }, [customer.id])



  useEffect(() => {
    loadOrders()
  }, [customer.id])

  const handleFormSubmit = useCallback(() => {
    loadOrders();
  }, []);

  if (orders.length === 0) {
    return (
      <Card style={{ margin: 10 }}>
        <CardBody>
          <h6 className="font-weight-bold" style={domainColorStyle("color")}>
            Últimos Pedidos
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="w-100 text-dark description-font">
              Você ainda não realizou nenhuma compra
            </span>
          </div>
        </CardBody>
      </Card>
    );
  }

  const orderTotalPrice = (order) => {
    const { subtotal_with_additions, service_tax, delivery_tax, custom_bag_value, total_discounts } = order
    return Number(subtotal_with_additions) + Number(service_tax) + Number(delivery_tax) + Number(custom_bag_value) - Number(total_discounts)
  }
  return (
    <Card style={{ marginTop: 10, border: "none" }}>
      <CardBody style={{ padding: 0 }}>
        <h6 className="font-weight-bold" style={domainColorStyle("color")}>
          Últimos Pedidos
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {orders.map((order) => {
            return (
              <Col
                lg={5}
                md={12}
                className="order-card"
                key={order.order_number}
              >
                <div style={{ display: "flex" }}>
                  <Button
                    className="order-button"
                    onClick={() => {
                      history.push("/order?orderId=" + order.id);
                    }}
                  >
                    <div className="d-flex flex-wrap h-100">
                      <h6 className="order-date col-sm-12 col-md-2">
                        <span style={{ fontSize: "25px" }}>
                          {timeZoneAdjustedMoment(
                            order.deliver_end_time
                          ).format("DD")}
                        </span>
                        {timeZoneAdjustedMoment(order.deliver_end_time).format(
                          "MMM"
                        )}
                      </h6>
                      <div className="col-sm-12 col-md-10">
                        <h6 className="order-number">
                          Pedido N° {order.order_number}
                        </h6>
                        <h6
                          className={
                            order.drivethru
                              ? "text-details"
                              : "text-details-address"
                          }
                        >
                          {order.drivethru ? "Retirado na loja" : order.address}
                        </h6>

                        <h6 className="text-details">
                          {`${numberToCurrency(orderTotalPrice(order))}`}
                        </h6>
                        <h6
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                            color: order.status.color,
                          }}
                        >
                          {order.status.display_name}
                        </h6>
                      </div>
                    </div>
                  </Button>
                  <Dropdown
                    isOpen={
                      openDropdown && orderNumberSelected === order.order_number
                    }
                    toggle={() => toggle(order.order_number, order.id)}
                  >
                    <DropdownToggle className="order-button-dropdown">
                      <i class="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() => {
                          setShowResponseModal(true);
                        }}
                        disabled={
                          order.status.display_name !== "Recebido pela loja"
                        }
                        data-toggle="tooltip"
                        data-placement="top"
                        title={
                          order.status.display_name !== "Recebido pela loja"
                            ? "Pedido " +
                            order.status.display_name.toLowerCase() +
                            " não pode ser cancelado"
                            : "Seu pedido pode ser cancelado"
                        }
                      >
                        Cancelar pedido
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <OrderCancelationModal
                  showModal={
                    showCancelationModal &&
                    orderNumberSelected === order.order_number
                  }
                  toggleModal={() => {
                    setShowCancelationModal(false);
                  }}
                  orderIdSelect={order.id}
                  phoneNumber={order}
                  onFormSubmit={handleFormSubmit}
                />

                {
                  // currentMarket.has_order_chat &&
                  order.can_chat && (
                    <div
                      className="open-chat"
                      style={domainColorStyle("borderColor")}
                      onClick={() => setChat(order)}
                    >
                      <i
                        style={{ ...domainColorStyle("color"), fontSize: 25 }}
                        className=" far text-exclamation fa-comments mr-1"
                      />
                      <p style={domainColorStyle("color")}>Chat</p>
                    </div>
                  )
                }
                {order.can_rate && (
                  <div
                    className="send-feedback"
                    onClick={() => setOpenFeedbackModal(true)}
                  >
                    <p>Avalie seu pedido</p>
                    <Feedback isOpen={openFeedbackModal} order_number={order.order_number} toggleModal={() => setOpenFeedbackModal(!openFeedbackModal)}/>
                    <FeedbackStars />
                  </div>
                )}
              </Col>
            );
          })}
        </div>

        <Modal
          isOpen={showResponseModal}
          toggle={() => setShowResponseModal(false)}
          className="question-response-modal"
        >
          <ModalHeader className="title-response-modal-header">
            <h5>Algum problema com a sua compra? Que tal ao invés de cancelar, alterar o horário do seu pedido?</h5>
          </ModalHeader>
          <ModalFooter className="response-modal-footer-button">
            <Button
              color="primary"
              outline
              onClick={() => setShowCancelationModal(true)}
            >
              Cancelar Pedido
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => setShowChangeTimeModal(true)}
            >
              Alterar Horário
            </Button>
          </ModalFooter>
        </Modal>

        <ChangeOrderTimeModal
          showModal={showChangeTimeModal}
          onClose={() => {
            setShowChangeTimeModal(false)
            setShowResponseModal(false)
          }}
          toggleModal={() => setShowChangeTimeModal(false)}
          orderId={orderIdSelected}
        />

        {getUseLegacyChat() ? (
          <ChatOrder
            isOpen={!!chat}
            toggleModal={setChat}
            orderId={chat ? chat.id : null}
            order={chat}
          />
        ) : (
          < ChatOrderFirebase
            isOpen={!!chat}
            toggleModal={setChat}
            orderId={chat ? chat.id : null}
            order={chat}
            marketId={chat?.market_id}
          />
        )}
      </CardBody>
    </Card>
  );
}

export default OrdersList;
