export function applyDeliveryMethodFunctions(deliveryMethods) {
  return deliveryMethods.map((deliveryMethod) => {
    deliveryMethod.combinedTax = (market) => {
      if (market)
        return market.is_service_tax_on_delivery_tax
          ? deliveryMethod.tax + market.service_tax
          : deliveryMethod.tax;
    };

    deliveryMethod.serviceTax = (market) => {
      if (market)
        return market.is_service_tax_on_delivery_tax ? 0 : market.service_tax;
    };
    return deliveryMethod;
  });
}
