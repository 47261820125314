import React, { useState, useEffect, memo, useCallback } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { showNotification, unmask, parseQueryString } from "../../helpers/util";
import { setZoneForRequestedCep } from "actions/setZoneForRequestedCep";
import InputMask from "../InputMaskCustom";
import LoadingContent from "../LoadingContent";
import loadsContent from "../loadsContent";
import { domainColorStyle, getDomainLogo } from "config";
import { userInfoChanged } from "../../actions/userInfoActions";
import { getZoneForCep, getListMarkets } from "../../helpers/apis/marketInfo";
import petiLogo from '../../assets/img/logo/petiLogoPrincipal.png'
import Modal from "../Modal/modal";
import ListMarkets from "./ListMarkets";
import { useQuery } from "helpers/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { saveLocalCep, getLocalCep } from "helpers/localStorage";
import { localCepKey } from "constants.js";



function CEPForm(props) {
  const {
    isLoading,
    toggleLoading,
    setModalDeliveryMethods = () => { },
  } = props;
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const dispatch = useDispatch()
  const [hasDelivery, setHasDelivery] = useState(false);
  const [hasDriveThru, setHasDriveThru] = useState(false);

  const [deliveryMode, setDeliveryMode] = useState("delivery");

  const [showModal, setShowModal] = useState(false);

  const [cep, setCep] = useState(getLocalCep(localCepKey) ?? "");
  const [cpf] = useState("");
  const [markets, setMarkets] = useState("");

  const [thisZoneForCep, setZoneForCep] = useState(null);

  const [stores, setStores] = useState([]);
  const query = useQuery();
  const exclusiveMarketId = query.get("exclusive_market_id");
  const brand = useSelector(({brand}) => brand.brand)

  const getMctUrlParams = useCallback(() => {
    const readUrl = parseQueryString(window.location);
    dispatch(userInfoChanged({
      ...userInfo,
      name: readUrl.mc_name || userInfo.name,
      cpf: readUrl.mc_cpf || userInfo.cpf,
      email: readUrl.mc_email || userInfo.email,
      sex: readUrl.mc_sex || userInfo.sex,
      phone_number: readUrl.mc_phone_number || userInfo.phone_number,
      birthDate: readUrl.mc_birthdate || userInfo.birthDate || userInfo.birth_date,
    }))
  }, [userInfo, dispatch]);

  const getBrandStores = useCallback(async function () {
    const { data } = await getListMarkets(exclusiveMarketId);
    setStores(data);
  }, [exclusiveMarketId])

  useEffect(() => {
    brandData();
    localStorage.removeItem("USERCPF");
    getMcCpf();
    getMctUrlParams();
    getBrandStores();
  }, []);

  async function brandData() {
    setHasDelivery(brand.has_delivery);
    setHasDriveThru(brand.has_drivethru);
  }

  function getMcCpf() {
    const readUrl = parseQueryString(window.location);
    if (readUrl.mc_cpf) {
      localStorage.setItem("USERCPF", readUrl.mc_cpf);
    }
  }

  async function onSubmit() {
    if (cpf) {
      localStorage.setItem("USERCPF", unmask(cpf));
    }
    const unmaskedCep = unmask(cep);
    try {
      toggleLoading();
      const zoneForCep = await getZoneForCep(unmaskedCep, undefined);
      toggleLoading();
      if (zoneForCep.status >= 200 && zoneForCep.status < 300) {
        saveLocalCep(localCepKey, cep)
        if (zoneForCep.data.markets.length > 1) {
          setZoneForCep(zoneForCep);
          setMarkets(zoneForCep.data.markets);
          setShowModal(true);
        } else {
          if (zoneForCep.data.markets[0]) {
            localStorage.setItem("market_id", zoneForCep.data.markets[0].id);
          }
          dispatch(setZoneForRequestedCep(zoneForCep, deliveryMode));
          setModalDeliveryMethods(true);
        }
      } else {
        showNotification(
          "top",
          "center",
          "Não conseguimos encontrar seu cep, por favor tente novamente!",
          "warning"
        );
      }
    } catch (error) {
      console.log(error);
      showNotification(
        "top",
        "center",
        "Não conseguimos encontrar seu cep, por favor tente novamente!",
        "warning"
      );
    }
  }

  function saveCpf() {
    if (cpf) {
      localStorage.setItem("USERCPF", unmask(cpf));
    }
  }

  //criado para atender solici individual da marca Petí MAPP-10206
  const logoMarket = brand.id === 384 ? petiLogo :  getDomainLogo()

  return (
    <div>
      <Container fluid>
        {getDomainLogo() && (
          <Row className="text-center">
            <img
              className="cep-image img mx-auto"
              src={logoMarket}
              alt="Logo do mercado"
            />
          </Row>
        )}
        <Row>
          <Col md={12}>
            <div style={{ marginBottom: 10 }}>
              <InputMask
                required
                mask="99.999-999"
                disabled={isLoading}
                placeholder="CEP*"
                name="cep"
                onChange={(e) => setCep(e.target.value)}
                value={cep}
              />
            </div>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <LoadingContent isLoading={isLoading} />
          ) : (
            <div
              style={{
                display: "contents",
              }}
            >
              {hasDelivery ? (
                <Button
                  onClick={() => {
                    saveCpf();
                    setDeliveryMode("delivery");
                    onSubmit();
                  }}
                  className="btn-icon block btn-3"
                  outline
                  style={{
                    margin: 10,
                    ...domainColorStyle("color"),
                    ...domainColorStyle("borderColor"),
                  }}
                >
                  <span className="btn-inner--text">Receba em casa</span>
                </Button>
              ) : null}

              {hasDriveThru ? (
                <Button
                  onClick={() => {
                    saveCpf();
                    setDeliveryMode("drivethru");
                    setShowModal(true);
                  }}
                  className="btn-icon block btn-3"
                  outline
                  style={{
                    margin: 10,
                    ...domainColorStyle("color"),
                    ...domainColorStyle("borderColor"),
                  }}
                >
                  <span className="btn-inner--text">Clique e Retire</span>
                </Button>
              ) : null}
            </div>
          )}
        </Row>
      </Container>
      
      <Modal
        WrappedComponent={ListMarkets}
        zoneForCep={thisZoneForCep}
        title="Lista de mercados"
        size="sm"
        setModalDeliveryMethods={setModalDeliveryMethods}
        showModal={showModal}
        markets={markets}
        stores={stores}
        setMarkets={setMarkets}
        setShowModal={(status) => setShowModal(status)}
      />
    </div>
  );
}
export default loadsContent(memo(CEPForm));
