import { CART_CLEAR_ON_STORE_CHANGE } from "ActionTypes";
import {
  CART_ADD,
  CART_REMOVE,
  CART_REMOVE_PRODUCT,
  CART_ADD_LIST,
  CART_CLEAR,
  REPLACE_CART_PRODUCT,
  REMOVE_WHOLE_PRODUCT_CART,
  RECALCULATE_TOTAL_PRICE,
  OPEN_CART,
  CART_ADD_PREFERENCES,
  CART_REMOVE_PREFERENCES,
  SET_ABANDONED_CART,
  AWARE_HIGHER_VALUE_CAPTURE,
  HIGHER_VALUE_LIMIT_CHANGED
} from "ActionTypes";
import { sendCartEvent } from "helpers/apis/impulseEventsApiHelper";

export function addToCart(mix, origin, quantity) {
  return {
    type: CART_ADD,
    payload: mix,
    origin: origin,
    quantity: quantity,
  };
}

export function addPreferencesToCart(mix) {
  return {
    type: CART_ADD_PREFERENCES,
    payload: mix,
  };
}

export function removePreferencesToCart(mix) {
  return {
    type: CART_REMOVE_PREFERENCES,
    payload: mix,
  }
}
export function addToCartList(itens) {
  return { type: CART_ADD_LIST, payload: itens };
}

export function openCart(value) {
  if (value) {
    sendCartEvent();
  }
  return { type: OPEN_CART, payload: value };
}

export function setAbandonedCart(value) {
  return { type: SET_ABANDONED_CART, payload: value };
}

export function removeFromCart(product) {
  return {
    type: CART_REMOVE,
    payload: product,
  };
}

export function removeProductFromCart(product) {
  return {
    type: CART_REMOVE_PRODUCT,
    payload: product,
  };
}

export function clearCart() {
  return {
    type: CART_CLEAR,
  };
}

export function removeWholeProduct(productId) {
  return {
    type: REMOVE_WHOLE_PRODUCT_CART,
    payload: productId,
  };
}

export function replaceCartProduct(product) {
  return {
    type: REPLACE_CART_PRODUCT,
    payload: product,
  };
}

export function recalculateTotalPrice(product) {
  return {
    type: RECALCULATE_TOTAL_PRICE,
    payload: product,
  };
}

export function awareFromHigherValueCapture(isAware) {
  return {
    type: AWARE_HIGHER_VALUE_CAPTURE,
    payload: isAware
  }
}

export function higherValueLimitChanged(value) {
  return {
    type: HIGHER_VALUE_LIMIT_CHANGED,
    payload: value
  }
}

export function clearCartFromOtherMarket(value) {
  return {
    type: CART_CLEAR_ON_STORE_CHANGE,
    payload: value
  }
}
