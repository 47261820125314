import { setMarket } from "actions/setZoneForRequestedCep";
import "assets/animate.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/vendor/font-awesome/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import firebase from "firebase/app";
import LayoutWrapper from "components/ApplicationLayout";
import StorePageWrapper from "components/StorePageLayout";
import { getDomainFavIcon, getMenu } from "config";
import Flipbooks from "containers/Flipbooks/Flipbooks";
import React, { useCallback, useEffect, useState } from "react";
import { getListAllMarkets } from "helpers/apis/marketInfo";
import Favicon from "react-favicon";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";
// Containers
import HomePage from "../containers/HomePage";
import InfoStore from "../containers/InfoStore/InfoStore";
import StorePage from "../containers/StorePage";
import UserPage from "../containers/UserPage/UserPage";
import Order from "../containers/Order/Order";
import withClearsaleFingerPrint from "../withClearsaleFingerPrint";
import NewHomePage from "new/containers/Home/HomePage";
import OffersPage from "new/containers/Offers/OffersPage";
import HomeMenu from "new/containers/HomeMenu/HomeMenu"
// Modules
import withTracker from "../withTracker";
import Checkout from "../new/containers/checkout/Checkout";
import CRMPage from "new/containers/CRMPage/CRMPage";
import { clearUserInfo } from "actions/userInfoActions";
import { showNotification } from "helpers/util";
import { brandMarketsChanged } from "actions/brandActions";
import { changeDocumentTitle } from "helpers/util";
import PageNotFound from "containers/PageNotFound/PageNotFound";
import { clearCartFromOtherMarket } from "actions/cartActions";
import { getMixesFromCart } from "helpers/cartHelper";
import { showCommonCategoriesOnHome } from "config";

const WithMarketId = ({ component: Component, ...rest }) => {
  const [market, setThisMarket] = useState(null);
  const [loadingMarket, setIsLoadingMarket] = useState(true)
  const { market_id } = rest.computedMatch.params;
  localStorage.setItem("market_id", market_id)
  const dispatch = useDispatch()

  const fetchData = useCallback(async function () {
    const response = await checkMarketId(market_id);
    setThisMarket(response);
    setIsLoadingMarket(false)
  }, [market_id])

  React.useEffect(() => {
    fetchData();
    dispatch(clearCartFromOtherMarket(market_id))
  }, [market_id]);

  const checkMarketId = async (marketID) => {
    const { data } = await getListAllMarkets();
    dispatch(brandMarketsChanged(data))
    const market = data.find((market) => market.id === Number(marketID));
    return market || data[0];
  };

  if (!loadingMarket && market?.id !== Number(market_id)) {
    setIsLoadingMarket(true)
    return <Redirect
      to={{
        pathname: `/loja/${market?.id}`,
      }}
    />
  }
  return loadingMarket ? null : (
    <Route
      {...rest}
      render={(props) =>
        !market.id ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
};

const Home = ({ component: Component, props }) => (
  <Component {...props} />
)

const CustomRoute = ({ component: Component, ...rest }) => {

  useEffect(() => changeDocumentTitle(), [rest.path]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const ProtectedCheckoutRoute = ({ component: Component, ...rest }) => {
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart)) || []

  return (
    <Route
      {...rest}
      render={(props) =>
        cart.length === 0 ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Routes = ({ setMarket, brand }) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const userInfoId = useSelector(({ userInfo }) => userInfo.id)
  const isValidMenu = getMenu()
  const homeWithCommonCategories = showCommonCategoriesOnHome()

  firebase.auth().onAuthStateChanged(user => {
    if (!user && userInfoId) {
      showNotification("top",
      "center",
      "Sessão expirada, faça login novamente para continuar.",
      "danger")
      setTimeout(() => {
        localStorage.removeItem("USERNAME");
        localStorage.removeItem("USERPHONE");
        localStorage.removeItem("USER_ID");
        localStorage.removeItem("idToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("crmToken")
        localStorage.removeItem("crmUserCpf")
        localStorage.removeItem("crmUser")
        localStorage.removeItem("crmUserName")
        dispatch(clearUserInfo())
        firebase.auth().signOut();
        window.location.reload()
        history.push("/")
      }, 1000)
      
    }
  });
  return (
    <>
      <Favicon url={getDomainFavIcon()} />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={(props) => {
            return (
              brand?.main_market_id ? (
                <Redirect to={`/loja/${brand.main_market_id}`} />
              ) : (
                <Home props={props} component={withTracker(HomePage)} />
              )
            )
          }} />
          {/* <Route exact path="/" component={withTracker(HomePage)} /> */}
          <WithMarketId
            exact
            path="/loja/:market_id/ofertas"
            component={StorePageWrapper(withTracker(OffersPage))}
          />
          {isValidMenu || homeWithCommonCategories ? (
            <WithMarketId
            exact
            path="/loja/:market_id"
            component={StorePageWrapper(withTracker(HomeMenu))}
            />
          ):(
            <WithMarketId
            exact
            path="/loja/:market_id"
            component={StorePageWrapper(withTracker(NewHomePage))}
          />
          )}
          
          
          {!isValidMenu &&
            <CustomRoute
              exact
              path="/user/cupons"
              component={LayoutWrapper(withTracker(UserPage))}
            />
          }

          {!isValidMenu &&
            <CustomRoute
              exact
              path="/user"
              component={LayoutWrapper(withTracker(UserPage))}
            />
          }

          {!isValidMenu &&
            <CustomRoute
              exact
              path="/user/listas"
              component={LayoutWrapper(withTracker(UserPage))}
            />
          }

          {!isValidMenu &&
            <CustomRoute
              exact
              path="/order"
              component={LayoutWrapper(
                withTracker(withClearsaleFingerPrint(Order))
              )} />
          }

          {!isValidMenu &&
            <ProtectedCheckoutRoute
              exact
              path="/pagamento/:market_id"
              component={LayoutWrapper(withTracker(withClearsaleFingerPrint(Checkout)))}
            />
          }
          <WithMarketId
            exact
            path="/loja/:market_id/encartes"
            setMarket={setMarket}
            component={LayoutWrapper(withTracker(Flipbooks))}
          />
          <WithMarketId
            exact
            path="/loja/:market_id/encartes/:flipbook_id"
            setMarket={setMarket}
            component={LayoutWrapper(withTracker(Flipbooks))}
          />
          <CustomRoute exact path="/" component={withTracker(HomePage)} />
          <WithMarketId
            exact
            path="/loja/:market_id/categoria/:category"
            setMarket={setMarket}
            component={StorePageWrapper(withTracker(StorePage))}
          />
          <WithMarketId
            path="/loja/:market_id/categoria/:category/produto/:product_slug"
            setMarket={setMarket}
            component={StorePageWrapper(withTracker(StorePage))}
          />
          <WithMarketId
            path="/loja/:market_id/categoria/:category/produto/:product_id"
            setMarket={setMarket}
            component={StorePageWrapper(withTracker(StorePage))}
          />
          <WithMarketId
            exact
            path="/clube/:market_id"
            setMarket={setMarket}
            component={StorePageWrapper(withTracker(CRMPage))}
          />
          <WithMarketId
            exact
            path="/loja/:market_id/produto_cb/:product_cb"
            setMarket={setMarket}
            component={StorePageWrapper(withTracker(StorePage))}
          />

          <CustomRoute
            exact
            path="/informacoes"
            component={LayoutWrapper(withTracker(InfoStore))}
          />

          <CustomRoute
            exact
            path="/404"
            component={LayoutWrapper(withTracker(PageNotFound))}
          />

          <Route
            path="*"
            render={() => <Redirect to="/404" />}
          />

        </Switch>
      </BrowserRouter>
    </>
  )
};

export default connect(null, { setMarket })(Routes);
