import { getPixelId } from "config";
import ReactPixel from "react-facebook-pixel";

const pixelConfig = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export const initPixel = () => {
  if (getPixelId()) {
    ReactPixel.init(getPixelId(), {}, pixelConfig);
  }
};

export function pageView(event, data) {
  if (getPixelId()) {
    initPixel();
    ReactPixel.pageView(event, data);
  }
}

export function track(event, data) {
  if (getPixelId()) {
    initPixel();
    ReactPixel.track(event, data);
  }
}

export function trackCustom(event, data) {
  if (getPixelId()) {
    initPixel();
    ReactPixel.trackCustom(event, data);
  }
}
