import { applyProductFunctions } from "./models/product";
import { getReduxValue, notification } from "./util";

export function getSubcategorie(categories, section_id) {
  return categories.find((cat) => cat.id === section_id)
    ? categories.find((cat) => cat.id === section_id).display_name
    : "Extras";
}

export function checkIsNewCategory(products, product, key) {
  if (key > 0) {
    if (!product.is_highlight) {
      return (
        (product.section_id === products[key + 1].section_id &&
          product.section_id !== products[key - 1].section_id) ||
        (product.section_id !== products[key + 1].section_id &&
          product.section_id !== products[key - 1].section_id)
      );
    } else {
      return (
        product.is_highlight !== products[key - 1].is_highlight &&
        product.is_highlight === products[key + 1].is_highlight
      );
    }
  } else {
    return true;
  }
}

export function addSubcategoryAllFilter(subcategories) {
  const subCategoriesWithAllFilter = subcategories.map((subcat) => {
    if (subcat.subcategories) {
 
      const allFilter = {
        display_name: "Todos",
        id: null,
      };

      const filtersWithAllFilter = [allFilter, ...subcat.subcategories];

      return { ...subcat, subcategories: filtersWithAllFilter };
    }
    return subcat;
  });

  return subCategoriesWithAllFilter;
}

export function addForYouCategory(categories) {
  const forYouCategory = {
    id: "paravoce",
    display_name: "Para Você",
    color: "",
    bulk: false,
    filter: false,
    name: "Para Você",
  };

  return [forYouCategory, ...categories];
}

export function hideForYouCategory(category) {
  const userId = getReduxValue("userInfo", "id")
  if (category.id === "paravoce" && !userId) {
    notification(
      `Faça login ou seu primeiro pedido para acessar a categoria "Para Você"`
    );

    return true;
  }
  return false;
}

export function filterItemsByCategory(items, category) {
  const filteredItems = items.filter((item) => item.section_id === category.id);
  return applyProductFunctions(filteredItems);
}

export const isSpecialCategory = (category) => {
  const specialCategories = ["clube", "fretegratis", "paravoce"]
  return specialCategories.includes(category.id);
}