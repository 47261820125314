import React, { useEffect, useState } from "react";
import { Box, Button, Card, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Select, Switch } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { timeZoneAdjustedMoment } from "helpers/util";
import * as Yup from "yup";
import moment from "moment";

import Modal from "new/components/Modal/Modal";
import InputForm from "new/components/InputForm/InputForm";
import InputCurrency from "components/InputCurrency";

import HigherValueCaptureCheckbox from 'new/components/HigherValueCapture/AcceptanceBox/AcceptanceBox';
import "./OrderPaymentInfos.scss";
import { validate } from "cpf-check";
import { useSelector } from "react-redux";
import { getPaymentTypeGroups } from "helpers/apiHelper";
import CardListingCheckout from "new/components/UserPage/PaymentCardListing/CardListingCheckout/CardListingCheckout";
import creditCardType from "credit-card-type";
import { unmask } from "helpers/util";
import { getEncryptedValue } from "helpers/encryptionHelper";
import { domainColorStyle } from "config";


function formatDate(date) {
  return moment(`01/${date}`).isValid();
}

const card_infos_schema = Yup.object().shape({
  card_holder_name: Yup.string()
    .min(5)
    .required("Campo obrigatório"),
  card_number: Yup.string()
    .min(14, "O campo deve ter pelo menos 15 caracteres")
    .required("Campo obrigatório"),
  card_expiration_date: Yup.string()
    .required("Campo obrigatório")
    .test("card_expiration_date", "Data de expiração deve ser uma data válida.", formatDate),
  cvv: Yup.string()
    .required("Campo obrigatório"),
  card_holder_birth_date: Yup.date()
    .required("Campo obrigatório"),
  card_holder_cpf: Yup.string()
    .required("Campo obrigatório")
    .test("card_holder_cpf", "Cpf deve ser válido", validate),
  cep: Yup.string()
    .min(8, "Insira um cep válido"),
  street: Yup.string()
    .min(4, "O campo deve ter meno menos 4 caracteres"),
  number: Yup.string(),
  reference_spot: Yup.string()
    .min(4, "O campo deve ter meno menos 4 caracteres")
});

export function OrderPaymentInfos(
  { market,
    deliveryMethod,
    setSelectedPaymentType,
    payment_selected_type,
    cash,
    setPaymentCash,
    total = 0,
    setShowModal,
    showModal,
    setEncryptedInfos,
    formError,
    setNumberCreditInstallments
  }) {

  const [paymentTypesState, setPaymentTypesState] = useState({
    paymentKind: null,
    selectedPaymentTypeGroup: null,
    selectedPaymentType: null
  })
  const [presentialPaymentTypeGroups, setPresentialPaymentTypeGroups] = useState([])
  const [onlinePaymentTypeGroups, setOnlinePaymentTypeGroups] = useState([])
  const [checked, setChecked] = useState(true);
  const [cashError, setCashError] = useState("");
  const [noCash, setNoCash] = useState(false);
  const [confirmStoneCredit, setConfirmStoneCredit] = useState(false);
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const cardSelectedPayment = useSelector(({ payment }) => payment?.paymentCard)
  const [cvvValue, setCvvValue] = useState('')
  const [paymentInfos, setPaymentInfos] = useState({})


  const validInstallments = payment_selected_type?.installments?.filter((item) =>
    total / item.number_of_parcels >= +item.min_value
  )

  const handlerInstallments = (value) => {
    const installment = payment_selected_type.installments.find((installment) => installment.id === value)
    setNumberCreditInstallments(installment)
  }

  function updatePaymentInfos(cardInfo) {
    if (cardInfo.card_number) {
      setShowModal(false)
    };

    const pubKey = process.env.REACT_APP_MPAY_PUB_KEY;
    const cardType = creditCardType(cardInfo.card_number);
    var encryptedCardInfo = Object.keys(cardInfo)
      .map((infoKey) => {
        if (infoKey === "card_number") {
          const card_number = unmask(cardInfo[infoKey]);
          return {
            [infoKey]: getEncryptedValue(pubKey, card_number),
          };
        } else {
          return {
            [infoKey]: getEncryptedValue(pubKey, cardInfo[infoKey]),
          };
        }
      })
      .reduce((sum, item) => {
        return { ...sum, ...item };
      }, {});

    encryptedCardInfo.brand = getEncryptedValue(pubKey, cardType[0]?.type);
    encryptedCardInfo.value_to_authorize = getEncryptedValue(pubKey, total);
    encryptedCardInfo.client_name = getEncryptedValue(pubKey, userInfo.name);
    setPaymentInfos(cardInfo);
    setEncryptedInfos(encryptedCardInfo);
  }

  function changeType(value) {
    const paymentTypes = paymentTypesForSelectedPaymentTypeGroup(paymentTypesState.selectedPaymentTypeGroup)
    setPaymentTypesState(prevState => ({ ...prevState, selectedPaymentType: value }))
    const type = paymentTypes.find((item) => item.id === value);
    const codeName = type?.codename
    const notPaymentPix = codeName !== "stone_pix" && codeName !== "levoo_pix"
    setSelectedPaymentType(type)
    if(type && type.kind === "online" && 
      notPaymentPix && 
      codeName !== "stone_credit" && 
      codeName !== "paga_leve_pix")
    {
      setShowModal(true)
    }
    if (type && type.kind === "online" && codeName === "stone_credit") {
      setConfirmStoneCredit(true)
    } else if (type && codeName !== "stone_pix") {
      setConfirmStoneCredit(false)
    }
  }

  useEffect(() => {
    if (paymentTypesState.selectedPaymentType) {
      changeType(paymentTypesState.selectedPaymentType)
    }
  }, [paymentTypesState.selectedPaymentType])

  function setCash(value) {
    if (value < total && value !== 0) {
      setCashError("O valor em dinheiro não pode ser menor que o valor do pedido");
    } else {
      setCashError(undefined);
    }
    setPaymentCash(value)
  }

  function onChangeNoCash() {
    if (!noCash) {
      setCash(0)
    }
    setNoCash(!noCash)
  }

  useEffect(() => {
    async function apiPaymentTypes() {
      try {
        if (onlinePaymentTypeGroups.length <= 0 && presentialPaymentTypeGroups.length <= 0) {
          const { data } = await getPaymentTypeGroups(market.id, deliveryMethod.id);
          setPresentialPaymentTypeGroups(data.presential);
          setOnlinePaymentTypeGroups(data.online);
        }
      } catch (error) {
        console.log("erro", error);
      }
    }
    apiPaymentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedPaymentType(null)
    updatePaymentInfos({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTypesState.paymentKind]);


  useEffect(() => {
    updatePaymentInfos({
      card_id: cardSelectedPayment?.id,
      cvv: cvvValue
    })
  }, [cardSelectedPayment, cvvValue])

  const paymentTypeGroupsForSelectedPaymentKind = (selectedPaymentKind) => {
    return selectedPaymentKind === "online" ? onlinePaymentTypeGroups : presentialPaymentTypeGroups
  }

  const paymentTypesForSelectedPaymentTypeGroup = (selectedPaymentTypeGroup) => {
    const groupId = selectedPaymentTypeGroup || paymentTypesState.selectedPaymentTypeGroup
    const groups = paymentTypeGroupsForSelectedPaymentKind(paymentTypesState.paymentKind)
    const group = groups.find((group) => group.id === groupId)
    return group?.payment_types
  }

  const removeUselessPaymentTypeName = (name) => {
    return name.split("Pagamento na entrega - ").join("")
  }

  const paymentCardInfosStone = payment_selected_type.codename !== 'stone_credit'

  return (
    <>
      <Card className='mt-3 order-payment-infos' style={formError === 3 ? { border: "solid 1px red" } : {}}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center">
          <Box pl={3} pt={2} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title-details">
            <h5>Pagamento</h5>
            {(payment_selected_type && paymentInfos.card_number) &&
              <Button className='change-button' onClick={() => setShowModal(true)} color="primary">Editar</Button>
            }
          </Box>
          <Box display={"flex"} flexWrap={"wrap"} style={{ width: "100%" }} pl={3} mb={3}>
            <FormControl variant="outlined" style={{ width: "90%" }}>
              <label id="demo-simple-select-outlined-label">Selecione quando pagar</label>
              <Select
                style={{ height: 40 }}
                value={paymentTypesState.paymentKind}
                onChange={(e) => {
                  setPaymentTypesState({
                    selectedPaymentType: null,
                    selectedPaymentTypeGroup: null,
                    paymentKind: e.target.value
                  })
                }}
              >
                {onlinePaymentTypeGroups?.length > 0 &&
                  <MenuItem value="online">Pague pelo site</MenuItem>
                }
                {presentialPaymentTypeGroups?.length > 0 &&
                  <MenuItem value="presential">Pague na {deliveryMethod.mode === "drivethru" ? "Retirada" : "Entrega"}</MenuItem>
                }
              </Select>
            </FormControl>
            {paymentTypesState.paymentKind &&
              <FormControl variant="outlined" style={{ width: "90%", paddingTop: 10 }}>
                <label id="demo-simple-select-outlined-label">Selecione a forma de pagamento</label>
                <Select
                  style={{ height: 40 }}
                  value={paymentTypesState.selectedPaymentTypeGroup}
                  onChange={({ target }) => {
                    const paymentTypes = paymentTypesForSelectedPaymentTypeGroup(target.value)
                    setPaymentTypesState(prevState => ({
                      ...prevState,
                      selectedPaymentType: paymentTypes?.length === 1 ? paymentTypes[0].id : null,
                      selectedPaymentTypeGroup: target.value
                    }))
                  }}
                >
                  {
                    paymentTypeGroupsForSelectedPaymentKind(paymentTypesState.paymentKind).map((group) =>
                      <MenuItem value={group.id}>{group.display_name}</MenuItem>
                    )
                  }

                </Select>
              </FormControl>
            }
            {paymentTypesState.selectedPaymentTypeGroup &&
              <FormControl variant="outlined" style={{ width: "90%", paddingTop: 10 }}>
                <label id="demo-simple-select-outlined-label">Selecione o meio de pagamento</label>
                <Select
                  style={{ height: 40 }}
                  value={paymentTypesState.selectedPaymentType}
                  onChange={({ target }) => {
                    setPaymentTypesState(prevState => ({
                      ...prevState,
                      selectedPaymentType: target.value,
                    }))
                  }}
                >
                  {paymentTypesForSelectedPaymentTypeGroup().map((paymentType) => (
                    <MenuItem value={paymentType.id} key={paymentType.id}>
                      {removeUselessPaymentTypeName(paymentType.name)}
                    </MenuItem>
                  ))}
                </Select>
                {(payment_selected_type?.id && payment_selected_type.installments?.length) &&
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
                    <label id="demo-simple-select-outlined-label">Selecione o parcelamento</label>
                    <Select
                      style={{ height: 40 }}
                      onChange={({ target }) => { handlerInstallments(target.value) }}
                    >
                      {validInstallments.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.number_of_parcels}x
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                }
              </FormControl>
            }
            {payment_selected_type.id === 4 && paymentTypesState.selectedPaymentType &&
              <FormControl error={!!cashError} variant="outlined" style={{ width: "90%", paddingTop: 10 }}>
                <label id="demo-simple-select-outlined-label">Troco para?</label>
                {!noCash &&
                  <InputCurrency error={!!cashError} disabled={noCash} value={cash} onChange={({ target }) => setCash(target.value)} />
                }
                {cashError && <FormHelperText style={{ marginLeft: 0 }}>{cashError}</FormHelperText>}
                <FormControlLabel control={
                  <Switch
                    checked={noCash}
                    color="primary"
                    onChange={(e) => onChangeNoCash()}
                    name="noCash"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                } label="Não preciso de troco" />

              </FormControl>
            }
          </Box>
          {payment_selected_type.accepts_higher_value_capturing && (
            <HigherValueCaptureCheckbox
              percentage={payment_selected_type.order_percentage_total_limit}
              value={total}
              userAccepts={userInfo?.accepts_higher_value_capturing}
            />
          )}
          {payment_selected_type.name && paymentTypesState.selectedPaymentType && paymentCardInfosStone &&
            <Card className='payment-card-infos'>
              <Box px={3} mb={2}>
                <p className='title'>{paymentTypesState.paymentKind === "online" ? "Pagamento online" : "Pagamento na entrega"}</p>
                <Box className='payment-card-image'>
                  {payment_selected_type?.codename === "stone_pix" ? (
                    <Box mt={1}>
                      <p style={{ fontWeight: 500, fontSize: "0.75rem" }}>{payment_selected_type?.name}</p>
                      <p style={{ fontSize: "0.75rem" }}>Finalize seu pedido e depois pague pelo seu banco</p>
                    </Box>
                  ) : (
                    <p>{payment_selected_type.name}</p>
                  )}
                  <img alt="Imagem cartão selecionado" className='card-image' src={payment_selected_type.image} />
                </Box>
                {paymentInfos.card_number &&
                  <p>**** **** **** {paymentInfos.card_number.split(" ").pop()}</p>
                }
              </Box>
            </Card>
          }
          {payment_selected_type.name && !paymentCardInfosStone &&
            <>
              <Card className='payment-card-infos'>
                <div style={{ height: "1.7em", ...domainColorStyle("backgroundColor") }}>
                  <Button
                    className='button-change-card'
                    onClick={() => setConfirmStoneCredit(true)}>
                    Alterar</Button>
                </div>
                <Box px={3} mb={2} mt={2}>
                  <p className='title'>Pagamento online</p>
                  <Box className='payment-card-image'>
                    <p>{payment_selected_type.name}</p>
                    <img alt="Imagem cartão selecionado" className='card-image' src={payment_selected_type.image} />
                  </Box>
                  <p>**** **** **** {cardSelectedPayment?.last_four_digits}</p>
                </Box>
              </Card>
            </>
          }
        </Box>
      </Card >

      <Modal
        isOpen={showModal}
        className="order-client-modal"
        toggleModal={() => setShowModal(!showModal)}
      >
        <Box pl={3} pr={2} pt={1} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title">
          <h5>Dados do Cartão</h5>
          <Button className='change-button' color='primary' onClick={() => {
            setShowModal(false);
            changeType("")
          }}>Cancelar</Button>
        </Box>
        <Formik
          initialValues={{
            card_holder_name: paymentInfos.card_holder_name || "",
            card_number: paymentInfos.card_number || "",
            card_expiration_date: paymentInfos.card_expiration_date || "",
            cvv: paymentInfos.cvv || "",
            card_holder_cpf: userInfo.cpf || "",
            card_holder_birth_date: timeZoneAdjustedMoment(
              userInfo.birthDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD") || "",
            cep: userInfo.address_attributes?.cep || "",
            street: userInfo.address_attributes?.street || "",
            number: userInfo.address_attributes?.number || "",
            reference_spot: userInfo.address_attributes?.reference_spot || ""
          }}
          validationSchema={card_infos_schema}
          onSubmit={values => {
            updatePaymentInfos(values)
          }}
        >
          {() => (
            <Form noValidate style={{ width: "100%" }}>
              <Box px={3}>
                <Grid container className="order-payment-inputs-form">
                  <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                    <h5>Preencha os dados do seu cartão para o pagamento</h5>
                    <Grid item className='input-item' xs={12}>
                      <Field name="card_holder_name" label="Nome impresso no cartão *" type="text" total_width={true} required component={InputForm} />
                    </Grid>
                    <Grid item className='input-item' xs={12}>
                      <Field name="card_number" mask="9999 9999 9999 9999" label="Número do cartão" type="text" total_width={true} required component={InputForm} />
                    </Grid>
                    <Grid item className='input-item' xs={12}>
                      <Field name="card_expiration_date" label="Data de expiração (mm/aaaa)" mask='99/9999' total_width={true} required component={InputForm} />
                    </Grid>
                    <Grid item className='input-item' xs={12}>
                      <Field name="cvv" label="CVV" type="number" total_width={true} required component={InputForm} />
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={6} className="card-first-infos">
                    <h5>Informações da cobrança</h5>
                    <Grid item className='input-item' xs={12}>
                      <Field name="card_holder_cpf" label="CPF do titular do cartão" type="text" total_width={true} required component={InputForm} />
                    </Grid>
                    <Grid item className='input-item' xs={12}>
                      <Field name="card_holder_birth_date" label="Data de nascimento do titular do cartão" type="date" total_width={true} required component={InputForm} />
                    </Grid>
                    <Grid item className='input-item' xs={10}>
                      <FormControlLabel className='switch-label' control={<Switch checked={checked} onChange={() => setChecked(!checked)} color='primary' />} label="O endereço de cobrança é o mesmo do endereço de entrega?" labelPlacement="start" />
                    </Grid>
                  </Grid>
                  {!checked &&
                    <Box display={"flex"} flexWrap={"wrap"}>
                      <h5>Preencha os dados do seu cartão para o pagamento</h5>
                      <Grid item className='input-item' xs={12}>
                        <Field name="cep" label="CEP" total_width={true} required component={InputForm} />
                      </Grid>
                      <Grid item className='input-item' xs={12}>
                        <Field name="street" label="Rua" total_width={true} required component={InputForm} />
                      </Grid>
                      <Grid item className='input-item' xs={6}>
                        <Field name="number" label="Número" total_width={true} required component={InputForm} />
                      </Grid>
                      <Grid item className='input-item' xs={5}>
                        <Field name="complement" label="Complemento" total_width={true} component={InputForm} />
                      </Grid>
                      <Grid item className='input-item' xs={12}>
                        <Field name="reference_spot" label="Ponto de referencia" total_width={true} required component={InputForm} />
                      </Grid>
                    </Box>
                  }
                </Grid>
                <Box mt={3} className="modal-client-footer">
                  <Button
                    style={{ width: "100px", alignSelf: "flex-end" }}
                    variant='contained'
                    type='submit'
                    color='primary'>Confirmar</Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        isOpen={confirmStoneCredit}
        toggleModal={() => setConfirmStoneCredit(!confirmStoneCredit)}
        className='modal-card-listing'>
          <Box className='order-card-listing'>
            <CardListingCheckout
              cardPicker={true}
              isClose={() => setConfirmStoneCredit(false)}
              updatePaymentInfos={updatePaymentInfos}
              setCvvValue={setCvvValue}/>
          </Box>
      </Modal>

    </>

  )
}

export default React.memo(OrderPaymentInfos);