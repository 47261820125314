import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { ModalBody, Modal, ModalHeader } from "reactstrap";
import { withRouter } from "react-router-dom";
import Messages from "../Messages/Messages";

import { domainColorStyle } from "config";
import { notification } from "helpers/util";
import "../../assets/css/react-confirm-alert.css";
import "./Chat.scss";
import { sendChatNotification } from "helpers/apis/chatFirebaseApi";
function ChatOrderFirebase({
  isOpen = true,
  toggleModal,
  orderId,
  order = {},
  marketId,
}) {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [playerId, setPlayerId] = useState("");


  const getChat = useCallback(async () => {
    if (orderId && isOpen) {
      var messagesRef = firebase
        .database()
        .ref(`${marketId}/order_${orderId}`);
      messagesRef.on("value", (snapshot) => {
        const response = snapshot.val();
        if (response) {
          setChatMessages(response.messages);
          if (response.market_player_id) {
            setPlayerId(response.market_player_id);
          }
        } else {
          setChatMessages([]);
        }
      });
    }
  }, [orderId, marketId, isOpen]);


  useEffect(() => {
    getChat();
  }, [orderId, marketId, isOpen]);

  const sendMessage = async () => {
    try {
      if (newMessage === "") return;
      if (order) {
        firebase
          .database()
          .ref(`${marketId}/order_${orderId}`)
          .update({
            name: localStorage.getItem("USERNAME"),
            no_customer_message: false,
            no_market_message: true,
            order_number: order.order_number,
            updated_at: Date.now(),
          });
      }
      firebase.database().ref(`${marketId}/order_${orderId}/messages`).push({
        content: newMessage,
        created_at: Date.now(),
        is_from_market: false,
      });
      await sendChatNotification(
        `order_${orderId}`,
        newMessage,
        marketId,
        playerId
      );
      setNewMessage("");
    } catch {
      notification("Ocorreu um erro ao enviar sua mensagem.", "danger");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal(null)}
      className="modal-dialog-centered modal-order-chat"
    >
      <ModalHeader
        className="header-order-chat"
        style={domainColorStyle("background")}
        toggle={() => toggleModal(null)}
      >
        <span style={{ fontSize: 16, fontWeight: "bold" }}>
          {order ? `Pedido N° ${order.order_number}` : ""}
        </span>
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className="chat-order-messages chat-order-menu">
          <div
            className="text-center w-100"
            style={{ height: "calc(50vh - 3.1rem)" }}
          >
            <div style={{ overflow: "auto", height: "100%" }}>
              {chatMessages && <Messages chatMessages={chatMessages} />}
              <div className="input-order-chat" style={{ width: "100%" }}>
                <input
                  onKeyDown={({ key }) =>
                    key === "Enter" ? sendMessage() : null
                  }
                  value={newMessage}
                  onChange={({ target }) => setNewMessage(target.value)}
                />
                <button
                  style={{
                    ...domainColorStyle("backgroundColor"),
                    padding: 5,
                    borderRadius: 100,
                    marginLeft: 10,
                    maxWidth: 35,
                  }}
                  onClick={() => sendMessage()}
                >
                  <i
                    className=" far text-exclamation fa-paper-plane mr-1"
                    style={{ color: "#fff", fontSize: 18 }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentMarket: state.markets,
    deliveryMethod: state.deliveryMethod.deliveryMethod,
  };
}
export default withRouter(connect(mapStateToProps, {})(ChatOrderFirebase));
