import React from 'react';
import { Typography } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button } from "reactstrap";
import "./ButtonBackTop.scss";


function ButtonBackTop() {
    return (
        <Button
            color="primary"
            href="#"
            className="button-topo"
        >
            <ExpandLessIcon className="icon" color="primary" />
            <Typography className="text" color="primary">Voltar ao topo</Typography>
        </Button>
    );
}

export default ButtonBackTop;