import config from "config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function getCupons(market,cpf) {
  const marketId = market ? market : localStorage.getItem("market_id")
  try {
    const cupons = await oAuthRequest.get(
      `${config.mappURLV2}/discount_coupons?market_id=${marketId}&cpf=${cpf}`
    );
    return cupons.data;
  } catch (error) {
    console.log("Brand error");
    throw error;
  }
}

export async function friendCampaigns(market, userID) {
  const marketId = market ? market : localStorage.getItem("market_id")
  const params = {
    market_id: marketId,
    customer_id: userID
  }

  const url = `${config.mappURLV2}/friend_campaigns/credit`
  try {
    const cupons = await oAuthRequest.get(url, { params });
    return cupons.data;
  } catch (error) {
    console.log("Brand error");
    throw error;
  }
}
