import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { domainColorStyle } from "config";
import { ToDecimal } from "helpers/currency";
import { Grid } from "@material-ui/core";
import { addToCart, removeFromCart } from "actions/cartActions";
import { openDeliveryMethod } from "actions/configsActions";
import { productURL } from "helpers/util";
import appExclusive from "assets/img/icons/appExclusive.png";
import offerExplosion from "assets/img/icons/offerExplosion.png";

import "./ProductCard.scss";
import { getDomainLogo } from "config";

function ProductCard({ product = {}, brand = {} }) {
  const history = useHistory();
  const highestPriority = product.highestPriorityOffer();
  const offerBadge = brand
    ? brand.offerBadgeForKind(highestPriority.kind)
    : null;
  return (
    <Grid
      xs={6}
      sm={4}
      md={3}
      lg={2}
      className={`store-card-product px-1`}
    >
      <div className="card-product">
        <img
          alt=""
          src={product.image}
          onClick={() => {
            history.push(productURL(product));
          }}
        />
        <div
          className="product-description"
          onClick={() => {
            history.push(productURL(product));
          }}
        >
          <p className="offer-price">
            {product.original_price > product.currentPrice() && (
              <>
                <span>R$</span> <del>{ToDecimal(product.original_price)}</del>
              </>
            )}
          </p>
          <p className="current-price-product" style={domainColorStyle("color")}>
            <span>R$</span>
            {ToDecimal(product.currentPrice())}
          </p>
          <p className="kilo-price">
            {product.kilogram_price && (
              <>
                <span>R$</span> {ToDecimal(product.currentKilogramPrice())}
                /Quilo
              </>
            )}
          </p>
          <p>{product.description}</p>
        </div>
        <div className="promotion-flag">
          {product.promotion_label_array &&
            product.promotion_label_array.map((item, index) =>
              index % 2 === 0 ? (
                <span>{item}</span>
              ) : (
                <span className="number-or-qtd">{item}</span>
              )
            )}
        </div>
        {((product.offers.length > 0 && brand) || product.crmOffer()) && (
          <img
            alt=""
            className="offer"
            src={
              offerBadge
                ? offerBadge.image_url
                : highestPriority.kind === "app_exclusive"
                ? appExclusive
                : highestPriority.kind === "club_exclusive"
                ? getDomainLogo()
                : offerExplosion
            }
          />
        )}
        {product.has_free_delivery && (
          <span
            className="free-delivery"
            style={product.offers.length > 0 ? { top: "25%" } : { top: "5%" }}
          >
            Frete Grátis
          </span>
        )}
      </div>
    </Grid>
  );
}

export default connect(null, { addToCart, removeFromCart, openDeliveryMethod })(
  React.memo(ProductCard)
);
