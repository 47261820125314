import React from 'react';

import "./FeedbackStars.scss"
function FeedbackStars({ evaluation, changeEvaluation = () => { } }) {
  const array = [1, 2, 3, 4, 5]
  return (
    <div>
      {array.map((i) => (
        <i className="fas fa-star star" key={i} onClick={() => changeEvaluation(i)} style={{ color: evaluation >= i ? "#f8b00c" : "#ccc" }}></i>
      ))
      }
    </div>
  );
};

export default FeedbackStars;