import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getDomainMarketColor } from "config";
import "./PixStatus.scss";
import { Box } from "@material-ui/core";
import { domainColorLightStyle } from "config";

const PixStatus = ({ status, minutes, seconds }) => {
  const domainColor = getDomainMarketColor()
  const timeExpired = status === "waiting" && minutes === 0 && seconds === 0;
  const orderCanceled = status === "canceled" || status === "cancel_requested";

  if (timeExpired || orderCanceled) {
    return (
      <Box className="pix-status pix-canceled">
        <CancelIcon />
        <span>Pagamento via Pix não realizado</span>
      </Box>
    );
  }

  if (status === "received") {
    return (
      <Box className="pix-status pix-received">
        <CheckCircleIcon />
        <span>Pagamento via Pix confirmado</span>
      </Box>
    );
  }

  return (
    <Box
      className="pix-status"
      style={domainColorLightStyle("backgroundColor")}
    >
      <span
        class="material-icons"
        style={{ fontSize: "2rem", color: domainColor }}
      >
        pix
      </span>
      <div>
        <strong>Pedido aguardando pagamento</strong>
        <span>Pague via Pix para finalizar o pedido</span>
      </div>
    </Box>
  );
};

export default PixStatus;
