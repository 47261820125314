export const CART_ADD = "ADD_TO_CART";
export const CART_ADD_PREFERENCES = "CART_ADD_PREFERENCES";
export const CART_REMOVE_PREFERENCES = "CART_REMOVE_PREFERENCES"
export const CART_ADD_LIST = "CART_ADD_LIST";
export const CART_REMOVE = "REMOVE_FROM_CART";
export const CART_REMOVE_PRODUCT = "CART_REMOVE_PRODUCT";
export const CART_CLEAR = "CLEAR_CART";
export const OPEN_CART = "OPEN_CART";
export const REMOVE_WHOLE_PRODUCT_CART = "REMOVE_WHOLE_PRODUCT_CART";
export const USER_INFO_CHANGED = "USER_INFO_CHANGED";
export const REPLACE_CART_PRODUCT = "REPLACE_CART_PRODUCT";
export const RECALCULATE_TOTAL_PRICE = "RECALCULATE_TOTAL_PRICE";
export const BUSINESS_CHANGED = "BUSINESS_CHANGED";
export const MARKETS_CHANGED = "MARKETS_CHANGED";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const SET_ORDER_NUMBER = "SET_ORDER_NUMBER";
export const CART_CLEAR_ON_STORE_CHANGE = "CART_CLEAR_ON_STORE_CHANGE"

// Zones
export const SET_ZONE = "SET_ZONE";
export const SET_NEIGHBORHOOD = "SET_ZONE";
export const SET_ZONE_FOR_REQUESTED_CEP = "SET_ZONE_FOR_REQUESTED_CEP";

// Markets
export const SET_CURRENT_MARKET = "SET_CURRENT_MARKET";
export const CURRENT_MIXES_CHANGED = "CURRENT_MIXES_CHANGED";
export const CATEGORIES_CHANGED = "CATEGORIES_CHANGED";
export const SUBCATEGORIES_CHANGED = "SUBCATEGORIES_CHANGED";
export const BANNERS_CHANGED = "BANNERS_CHANGED";
export const SAVE_BRAND = "SAVE_BRAND";

//Categories
export const SELECTED_CATEGORY_CHANGED = "SELECTED_CATEGORY_CHANGED";
export const SELECTED_SUBCATEGORY_CHANGED = "SELECTED_SUBCATEGORY_CHANGED";
export const SELECTED_FILTER_CHANGED = "SELECTED_FILTER_CHANGED";

// CRM
export const SET_CRM = "SET_CRM";
export const CLEAR_CRM = "CLEAR_CRM";
export const GET_CRM_DATA = "GET_CRM_DATA";

//MARKETS
export const RESET_MARKET = "RESET_MARKET";
export const SET_MARKET = "SET_MARKET";

//DELIVERY METOD
export const SET_DELIVERY_METHOD = "SET_DELIVERY_METHOD";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_DELIVERY_METHOD = "OPEN_DELIVERY_METHOD";
export const SEARCH_TEXT_CHANGED = "SEARCH_TEXT_CHANGED";
export const SEARCH_ACTIVE_CHANGED = "SEARCH_ACTIVE_CHANGED";
export const ORDENATION_CHANGED = "ORDENATION_CHANGED";
export const BRAND_CHANGED = "BRAND_CHANGED";
export const BRAND_MARKETS_CHANGED = "BRAND_MARKETS_CHANGED";
export const HAS_AUTO_COUPONS = "HAS_AUTO_COUPONS";

export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

//SET PEDING ABANDONED CART
export const SET_ABANDONED_CART = "SET_ABANDONED_CART";

//HIGHER VALUE CAPTURE
export const AWARE_HIGHER_VALUE_CAPTURE = "AWARE_HIGHER_VALUE_CAPTURE";
export const HIGHER_VALUE_LIMIT_CHANGED = "HIGHER_VALUE_LIMIT_CHANGED";

//PRODUCTS
export const CURRENT_OFFERS_CHANGED = "CURRENT_OFFERS_CHANGED"
export const PUBLICITY_PRODUCTS_CHANGED = "PUBLICITY_PRODUCTS_CHANGED"
export const HIGHLIGHTED_PRODUCTS_CHANGED = "HIGHLIGHTED_PRODUCTS_CHANGED"
export const CLUB_OFFERS_CHANGED = "CLUB_OFFERS_CHANGED"
export const FORYOU_PRODUCTS_CHANGED = "FORYOU_PRODUCTS_CHANGED"
export const FREE_DELIVERY_PRODUCTS_CHANGED = "FREE_DELIVERY_PRODUCTS_CHANGED"
export const SUBCATEGORY_PRODUCTS_CHANGED = "SUBCATEGORY_PRODUCTS_CHANGED"
export const FILTER_PRODUCTS_CHANGED = "FILTER_PRODUCTS_CHANGED"
export const SEARCH_PRODUCTS_CHANGED = "SEARCH_PRODUCTS_CHANGED"
export const CURRENT_MENU_PRODUCTS_CHANGED = "CURRENT_MENU_PRODUCTS_CHANGED"

//PAYMENT CARDS PAGARME
export const SET_SELECTED_CARD = "SET_SELECTED_CARD"
