import {
  CURRENT_OFFERS_CHANGED,
  PUBLICITY_PRODUCTS_CHANGED,
  HIGHLIGHTED_PRODUCTS_CHANGED,
  CLUB_OFFERS_CHANGED,
  FORYOU_PRODUCTS_CHANGED,
  SUBCATEGORY_PRODUCTS_CHANGED,
  FILTER_PRODUCTS_CHANGED,
  FREE_DELIVERY_PRODUCTS_CHANGED,
  SEARCH_PRODUCTS_CHANGED,
  CURRENT_MENU_PRODUCTS_CHANGED 
} from "ActionTypes";
import { applyProductFunctions } from "helpers/models/product";

const INITIAL_STATE = {
  currentOffers: [],
  publicityProducts: [],
  highlightedProducts: [],
  clubOffers: [],
  forYouProducts: [],
  subcategoryProducts: [],
  filterProducts: [],
  freeDeliveryProducts: [],
  searchProducts: [],
  allProductsMenu: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CURRENT_OFFERS_CHANGED:
      return Object.assign({}, state, {
        currentOffers: applyProductFunctions(action.payload),
      });
    case PUBLICITY_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        publicityProducts: applyProductFunctions(action.payload),
      });
    case HIGHLIGHTED_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        highlightedProducts: applyProductFunctions(action.payload),
      });
    case CLUB_OFFERS_CHANGED:
      return Object.assign({}, state, {
        clubOffers: applyProductFunctions(action.payload),
      });
    case FORYOU_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        forYouProducts: applyProductFunctions(action.payload),
      });
    case SUBCATEGORY_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        subcategoryProducts: applyProductFunctions(action.payload),
      });
    case FILTER_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        filterProducts: applyProductFunctions(action.payload),
      });
    case FREE_DELIVERY_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        freeDeliveryProducts: applyProductFunctions(action.payload),
      });
    case SEARCH_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        searchProducts: applyProductFunctions(action.payload),
      });
    case CURRENT_MENU_PRODUCTS_CHANGED:
      return Object.assign({}, state, {
        allProductsMenu: applyProductFunctions(action.payload)
      })
    default:
      return state;
  }
}
