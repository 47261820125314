import React, { Component } from "react"

const loadsContent = (WrappedComponent) => {
    return class extends Component {
        constructor() {
            super()
            this.toggleLoading = this.toggleLoading.bind(this)
            this.setLoadingFalse = this.setLoadingFalse.bind(this)
            this.setLoadingTrue = this.setLoadingTrue.bind(this)
            this.state = {
                isLoading: false
            }
        }


        toggleLoading() {
            this.setState({
                isLoading: !this.state.isLoading
            })
        }

        setLoading = (loading) => {
            this.setState({
                isLoading: loading
            })
        }
        setLoadingFalse() {
            this.setState({
                isLoading: false
            })
        }

        setLoadingTrue() {
            this.setState({
                isLoading: true
            })
        }

        render() {
            const { isLoading } = this.state
            return <WrappedComponent
                setLoadingTrue={this.setLoadingTrue}
                toggleLoading={this.toggleLoading}
                setLoading={this.setLoading}
                setLoadingFalse={this.setLoadingFalse}
                isLoading={isLoading}
                {...this.props}
            />
        }
    }
}

export default loadsContent