import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { SwipeableDrawer, Button, Hidden, Box, Typography } from "@material-ui/core";
import { openCart } from "actions/cartActions";
import { useStylesSidebar } from "assets/useStyles";
import NewList from "components/StorePage/NewList/NewList";
import ItemList from "./ItemList";
import CartValues from "./CartValues";
import UserLoginModal from "components/UserLoginModal/UserLoginModal";
import numberToCurrency from "helpers/currency";
import { domainColorStyle } from "config";
import { clearCart } from "actions/cartActions";
import { confirmAlert } from "react-confirm-alert";
import { getMixesFromCart } from "helpers/cartHelper";
import { getProductsTotal } from "helpers/cartHelper";
import { countItensCart } from "helpers/util";
import { notification } from "helpers/util";
import { orderRequestFormattedItems } from "helpers/cartHelper";
import { checkForCartChanges } from "helpers/apiHelper";
import { useHistory } from "react-router-dom";
import { fixCartForErrors } from "helpers/util";
import { removeWholeProduct, replaceCartProduct } from "actions/cartActions";

import "./sidebar.scss";
import { WithholdRevenueWarning } from "new/components/WithholdRevenue";
import { WithholdRevenueDialog } from "new/components/WithholdRevenue";
import { isUserUnderAge, arrayHasContent } from "helpers/util";
import { removeProductFromCart } from "actions/cartActions";
import ModalDefault from "new/components/Modal/Modal";

function SideBar({
  clearCart,
  currentMarket,
  cart_open,
  openCart,
  removeWholeProduct,
  replaceCartProduct,
}) {
  const classes = useStylesSidebar();
  const history = useHistory();
  const cart = useSelector(({ cart }) => cart.cart)
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const mixesMapped = getMixesFromCart(cart);
  const productsTotal = getProductsTotal(mixesMapped);
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod?.deliveryMethod);
  const zone = useSelector(({ zone }) => zone)
  const [showModal, setShowModal] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false)
  const [openRevenueDialog, setOpenRevenueDialog] = useState(false)
  const hasItemsWithRevenue = mixesMapped.some(item => Boolean(item?.withhold_revenue))
  const adultRestrictedItems = mixesMapped.filter(({ is_adult_restricted }) => is_adult_restricted)
  const [openAdultRestrictedItemsDialog, setOpenAdultRestrictedItemsDialog] = useState(false)
  const dispatch = useDispatch()

  const orderValidationMessage = () => {
    if (productsTotal < deliveryMethod.min_order_price) {
      return `O valor mínimo da compra é de ${numberToCurrency(
        deliveryMethod.min_order_price
      )}`;
    }
    if (
      countItensCart(mixesMapped) > deliveryMethod.max_items_amount &&
      deliveryMethod.max_items_amount !== null
    ) {
      const amountToRemove =
        countItensCart(mixesMapped) - deliveryMethod.max_items_amount;
      return `Você ultrapassou a quantidade máxima de itens (${deliveryMethod.max_items_amount}). Retire ${amountToRemove} produtos do seu carrinho para continuar.`;
    }

    if (
      mixesMapped.length > deliveryMethod.max_distinct_items &&
      deliveryMethod.max_distinct_items !== null
    ) {
      const amountToRemove = mixesMapped.length - deliveryMethod.max_distinct_items;
      return `Você ultrapassou a quantidade máxima de itens distintos (${deliveryMethod.max_distinct_items}). Retire ${amountToRemove} produtos do seu carrinho para continuar.`;
    }
    return "";
  };
  const clearCartClick = () => {
    confirmAlert({
      title: "Todos os produtos serão removidos do carrinho, deseja continuar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            clearCart();
          },
        },
        { label: "Não" },
      ],
    });
  };

  const openModalList = () => {
    openCart(!cart_open);
    setShowModal(true);
  };

  const removeRestrictedItemsFromCart = () => {
    adultRestrictedItems.map(item => dispatch(removeProductFromCart(item)))
    setOpenAdultRestrictedItemsDialog(false)
  }

  const checkCart = (mixes) => {
    if (deliveryMethod?.mode === "delivery" && hasItemsWithRevenue) {
      setOpenRevenueDialog(true)
      return
    }

    if (arrayHasContent(adultRestrictedItems) && isUserUnderAge(userInfo)) {
      setOpenAdultRestrictedItemsDialog(true)
      return
    }

    const validationMessage = orderValidationMessage();
    if (validationMessage.length !== 0) {
      confirmAlert({
        title: validationMessage,
        buttons: [{ label: "Ok" }],
      });
      return;
    }
    if (
      currentMarket.deliveryMode === "drivethru" &&
      !currentMarket.complete_address
    ) {
      notification("Erro com o endereço do mercado", "danger");
      return;
    }

    const orderItems = orderRequestFormattedItems(mixes);
    checkForCartChanges(orderItems, deliveryMethod.id, currentMarket.id, (error, data) => {
      if (error) {
        if (error.response) {
          const cartErrors = error.response.data;
          fixCartForErrors(
            cartErrors,
            cart,
            currentMarket,
            removeWholeProduct,
            replaceCartProduct,
            history
          );
        } else {
          notification(
            "Ocorreram erros ao verificar produtos, por favor tente novamente",
            "danger"
          );
        }
      } else {
        if (!userInfo.id) {
          setShowModalLogin(true)
        } else {
          if (!zone.id && deliveryMethod.mode === "delivery") {
            return notification("Por gentileza, escolha um método de entrega.", "warning")
          }
          history.push("/pagamento/" + currentMarket.id)
        }
      }
    });
  };
  return (
    <>
      <nav aria-label="mailbox folders">
        <SwipeableDrawer
          anchor={"right"}
          open={cart_open}
          onClose={() => openCart(!cart_open)}
          onOpen={() => { }}
        >
          <div className={`${classes.drawerCart} sidebarCart`}>
            <div>
              <h3
                className="cart-title"
                style={{ ...domainColorStyle("background") }}
              >
                Meu carrinho
              </h3>
              <WithholdRevenueWarning show={hasItemsWithRevenue} sx={{ margin: "0 0.5rem" }} />
              <Hidden smUp implementation="css">
                <Button
                  className="close-button"
                  onClick={() => openCart(!cart_open)}
                >
                  Fechar
                </Button>
              </Hidden>
              <ItemList cart={mixesMapped} />
            </div>
            <div className="delivery-description">
              <CartValues productsTotal={productsTotal} />
              <div className="cart-buttons">
                <div style={{ display: "flex", width: "100%" }}>
                  <Button
                    onClick={() => clearCartClick()}
                    disabled={productsTotal === 0}
                    style={{ ...domainColorStyle("color") }}
                  >
                    Esvaziar carrinho
                  </Button>
                  <Button
                    onClick={() => openModalList(true)}
                    disabled={productsTotal === 0}
                    style={{ ...domainColorStyle("color") }}
                  >
                    Salvar lista
                  </Button>
                </div>
                <Button
                  style={{
                    ...domainColorStyle("background"),
                    color: "#fff",
                    marginBottom: 20,
                  }}
                  onClick={() => checkCart(mixesMapped)}
                  variant="contained"
                >
                  {(deliveryMethod || {}).mode === "delivery"
                    ? "Entregar no meu endereço"
                    : "Retirar na loja"}
                </Button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </nav>
      {showModal && (
        <NewList
          showModal={showModal}
          title={"Listas"}
          toggleModal={setShowModal}
          currentMarket={currentMarket}
        />
      )}
      <UserLoginModal 
        showModal={showModalLogin} 
        toggleModal={setShowModalLogin}/>
      <WithholdRevenueDialog
        isOpen={openRevenueDialog}
        setIsOpen={setOpenRevenueDialog}
        onProgress={() => checkCart(mixesMapped)}
      />
      <ModalDefault
        isOpen={openAdultRestrictedItemsDialog}
        toggleModal={removeRestrictedItemsFromCart}
        size="xs"
      >
        <Box className="restricted-products-box">
          <Typography variant="h5" color="primary">Atenção</Typography>
          <Typography variant="body">
            Seu carrinho contém {adultRestrictedItems.length > 1 ? "produtos" : "um produto"} com restrição de idade. {adultRestrictedItems.length > 1 ? "Os itens serão removidos" : "O item será removido"} para que você possa seguir com o pedido.
          </Typography>
          <Button variant="contained" color="primary" onClick={removeRestrictedItemsFromCart}>Confirmar</Button>
        </Box>
      </ModalDefault>
    </>
  );
}

function mapStateToProps({ marketStuff, markets, cart, deliveryMethod }) {
  return {
    categories: marketStuff.categories,
    currentMarket: markets,
    cart_open: cart.cartOpen,
  };
}

export default connect(mapStateToProps, {
  openCart,
  clearCart,
  removeWholeProduct,
  replaceCartProduct,
})(SideBar);
