// modules
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetMarket, setMarket, setZoneForRequestedCep } from "actions/setZoneForRequestedCep";
import { getMarketById } from "../../helpers/apis/marketInfo";
import { Button } from "reactstrap";
import { domainColorStyle } from "config";
import UserLoginModal from "components/UserLoginModal/UserLoginModal";

function ListMarkets(props) {
  const {
    setShowModal,
    setModalDeliveryMethods,
    markets,
    zoneForCep,
    stores,
    setMarkets = () => {},
  } = props;

  const [openModalLogin, setOpenModalLogin] = useState(false)
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const [closeCepLoginModal, setCloseCepLoginModal] = useState(false)
  const dispatch = useDispatch()
  const ListMarketData = useCallback(
    async function ListMarketData() {
      if (!markets) {
        setData(stores);
        if (stores.length === 1) {
          MarketById(stores[0]);
          setShowModal(false);
          return;
        }
      } else {
        setData(markets);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [markets]
  );

  useEffect(() => {
    ListMarketData();
    dispatch(resetMarket());
    setModalDeliveryMethods(false);
  }, []);

  const [data, setData] = useState([]);

  async function MarketById(item) {
    try {
      dispatch(setZoneForRequestedCep({ data: { cep: null } }));
      let deliveryMethod = "drivethru";
      localStorage.market_id = item.id;
      const response = await getMarketById(item.id);
      if (markets) {
        deliveryMethod = "delivery";
        dispatch(setZoneForRequestedCep(
          { ...zoneForCep, data: { ...zoneForCep.data, markets: [item] } },
          deliveryMethod
        ));
        if(item.restrict_delivery_methods_by_person_type && !userInfo.id){
          setOpenModalLogin(true)
          setCloseCepLoginModal(true)
          setModalDeliveryMethods(false);
        }else{
          setModalDeliveryMethods(true);
        }
      } else {
        dispatch(setMarket(response, deliveryMethod))
        setModalDeliveryMethods(true);
      }
    } catch (error) {
      console.log("erro", error);
    }
  }

  function resetMarketsList() {
    dispatch(resetMarket());
    setShowModal(false);
    setTimeout(() => setMarkets(null), 500);
    setModalDeliveryMethods(false)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {data.map((item, index) => {
        return (
          <Button
            onClick={() => MarketById(item)}
            key={index}
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              textTransform: "capitalize",
              whiteSpace: "pre-wrap",
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontWeight: "bold",
                ...domainColorStyle("color"),
              }}
            >
              {item.name}
            </span>

            <h6 style={{ fontSize: 12 }}>{item.address}</h6>
          </Button>
        );
      })}
      <Button
        onClick={() => {
          resetMarketsList();
        }}
        type="submit"
        className="btn-icon block btn-3"
        outline
        style={{
          margin: 10,
          ...domainColorStyle("color"),
          ...domainColorStyle("borderColor"),
        }}
      >
        <span className="btn-inner--text">Voltar</span>
      </Button>
      <UserLoginModal  
        showModal={openModalLogin} 
        toggleModal={setOpenModalLogin}
        closeModal={closeCepLoginModal}
        />
    </div>
  );
}

export default ListMarkets