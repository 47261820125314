import config from "config";
import axios from "axios";
import { getMixesFromCart } from "helpers/cartHelper";

const uninterceptedAxiosInstance = axios.create();

let defaultParameters = {};
let currentCartMixes = [];

export function registerLinxImpulseParametersListener(store) {
  store.subscribe(function () {
    const state = store.getState();
    const brand = state.brand.brand;

    if (!brand.impulseApiKey || !brand.impulseSecretKey) {
      return;
    }
    const currentMarket = state.markets || {};
    const user = state.userInfo || {};

    defaultParameters = {
      apiKey: brand.impulseApiKey,
      secretKey: brand.impulseSecretKey,
      deviceId: localStorage.deviceId,
      source: "desktop",
      salesChannel: currentMarket.id + "",
    };
    if (user.id) {
      defaultParameters.user = {
        id: user.id + "",
      };
    }
    currentCartMixes = getMixesFromCart(state.cart.cart);
  });
}
export async function sendSearchEvent(query, mixes) {
  if (!defaultParameters.apiKey) {
    return;
  }
  const url = `${config.impulseEventsUrl}/search`;
  const items = mixes.flatMap((mix) => {
    return mix.items.map((item) => {
      return { pid: item.product_id + "" };
    });
  });
  const parameters = { ...defaultParameters, query: query, items: items };

  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendEmptySearchEvent(query) {
  if (!defaultParameters.apiKey) {
    return;
  }
  const url = `${config.impulseEventsUrl}/emptysearch`;
  const parameters = { ...defaultParameters, query: query, items: [] };

  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendProductEvent(product) {
  if (!defaultParameters.apiKey) {
    return;
  }
  const url = `${config.impulseEventsUrl}/product`;
  const parameters = {
    ...defaultParameters,
    pid: product.product_id + "",
    price: product.currentPrice(),
  };
  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendCartEvent() {
  if (!defaultParameters.apiKey) {
    return;
  }
  const items = currentCartMixes.map((item) => {
    return { pid: item.product_id + "" };
  });
  const url = `${config.impulseEventsUrl}/cart`;
  const parameters = {
    ...defaultParameters,
    items: items,
  };
  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendTransactionEvent(orderId, totalPrice) {
  if (!defaultParameters.apiKey) {
    return;
  }
  const items = currentCartMixes.map((item) => {
    return { pid: item.product_id + "" };
  });
  const url = `${config.impulseEventsUrl}/transaction`;
  const parameters = {
    ...defaultParameters,
    items: items,
    id: orderId + "",
    total: parseFloat(totalPrice),
  };
  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendCategoryEvent(categories) {
  if (!defaultParameters.apiKey) {
    return;
  }
  const url = `${config.impulseEventsUrl}/category`;
  const categoriesFormatted = categories.map((categoriesArray) => {
    return categoriesArray
      .map((category) => category.display_name)
      .join("_")
      .split(" ")
      .join("_")
      .toLowerCase();
  });

  const parameters = {
    ...defaultParameters,
    categories: categoriesFormatted,
  };
  const response = await uninterceptedAxiosInstance.post(url, parameters);
  console.log(response);
}

export async function sendHomeEvent() {
  if (!defaultParameters.apiKey) {
    return;
  }
  const url = `${config.impulseEventsUrl}/home`;
  const response = await uninterceptedAxiosInstance.post(
    url,
    defaultParameters
  );
  console.log(response);
}
