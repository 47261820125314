import React, { useCallback } from "react";
import { domainColorStyle } from "config";
import "./NotificationCenter.scss";
import { Box, Card, CardContent, Checkbox, FormGroup, Typography } from "@material-ui/core";

import { getBrandNotificationAcceptances, updateBrandNotificationAcceptances } from "helpers/apiHelper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux"


function NotificationCenter() {
    const userId = useSelector(({ userInfo }) => userInfo.id)
    const [checked, setChecked] = useState(
        {
            push: true,
            email: true,
            sms: true,
            whatsapp: true
        }
    );

    const handleChange = (event, type) => {
        setChecked({ ...checked, [type]: event.target.checked })
        updateBrandNotificationAcceptances(userId, { ...checked, [type]: event.target.checked });
    };


    const fillNotificationInfo = useCallback(async () => {
        const notificationInfo = await getBrandNotificationAcceptances(userId)
        setChecked(notificationInfo);
    }, [userId])

    useEffect(() => {
        fillNotificationInfo();
    }, [])

    return (
        <Card style={{
            margin: 10,
            boxShadow: "none",
        }}>
            <CardContent>
                <h6 className="font-weight-bold" style={domainColorStyle("color")}>
                    Central de Notificações
                </h6>
                <Typography style={{ fontSize: 14 }}>
                    Escolha como você deseja receber as promoções, avisos e outras informações do Supermercado Tal.
                </Typography>
                <FormGroup column>
                    <Box className="checkbox-items">
                        <Typography className='checkbox-text'>
                            Notificações por aplicativo
                            <Checkbox
                                checked={checked.push}
                                onChange={(event) => handleChange(event, "push")}
                                style={domainColorStyle("color")}
                            />
                        </Typography>

                        <Typography className='checkbox-text'>
                            Receber SMS
                            <Checkbox
                                checked={checked.sms}
                                onChange={(event) => handleChange(event, "sms")}
                                style={domainColorStyle("color")}
                            />
                        </Typography>

                        <Typography className='checkbox-text'>
                            Receber E-mail
                            <Checkbox
                                checked={checked.email}
                                onChange={(event) => handleChange(event, "email")}
                                style={domainColorStyle("color")}
                            />
                        </Typography>

                        <Typography className='checkbox-text'>
                            Comunicação por WhatsApp
                            <Checkbox
                                checked={checked.whatsapp}
                                onChange={(event) => handleChange(event, "whatsapp")}
                                style={domainColorStyle("color")}
                            />
                        </Typography>

                        {/* <Typography className='checkbox-text'>
                            Acompanhar status do pedido
                            <Checkbox
                                checked={checked.}
                                onChange={(event) => handleChange(event, "")}
                                style={domainColorStyle("color")}
                            />
                        </Typography> */}

                    </Box>
                    <span className='checkbox-text-info' style={domainColorStyle("borderColor")}>
                        Ao desativar as notificações, você deixará de receber informações sobre promoções e novidades. As notificações sobre pedidos são essenciais para sua experiência.
                    </span>
                </FormGroup>
            </CardContent>
        </Card>
    );
}

export default NotificationCenter;