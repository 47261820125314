import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function ListSectionsFooter({webOrMobileFooterDisplay, sections, adjustableGridSize, handleWithInformationModals}) {
  return (
    <>
      {!webOrMobileFooterDisplay ? (
        sections
          .filter(section => section.kind === 'list')
          .map((sectionList, index) => (
            <Grid
              item
              key={index}
              xs={adjustableGridSize}
              className='support-footer-section-area'>
              <h6>{sectionList.title}</h6>
              <Box className='footer-information-button-modals'>
                {sectionList.content?.map((list, index) => (
                  <div key={index}>
                    <Button
                      onClick={() => handleWithInformationModals(list.action, list?.url, list?.body)}>
                      {list.message}
                    </Button>
                  </div>
                ))}
              </Box>
            </Grid>
          ))
      ) : (
        sections
          .filter(section => section.kind === 'list')
          .map((sectionList, index) => (
            <Accordion
              item
              key={index}
              xs={adjustableGridSize}
              elevation={0}
              className='support-footer-section-area'>
              <AccordionSummary className='support-footer-section-area' expandIcon={<ExpandMoreIcon />}>
                <h6 style={{ textTransform: 'uppercase' }}>{sectionList.title}</h6>
              </AccordionSummary>
              <AccordionDetails className='footer-information-button-modals'>
                {sectionList.content?.map((list, index) => (
                  <div key={index}>
                    <Button
                      onClick={() => handleWithInformationModals(list.action, list?.url, list?.body)}>
                      {list.message}
                    </Button>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          )))}
    </>
  )
}