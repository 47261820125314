// modules
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import CEPForm from "../components/Homepage/CEPForm";
import NeighborhoodsForm from "../components/Homepage/NeighborhoodsForm";
import {
  setZoneForRequestedCep,
  setNeighborhood,
  resetMarket,
} from "actions/setZoneForRequestedCep";
import { bindActionCreators } from "redux";
import Modal from "../components/Modal/modal";
import DeliveryMethods from "../components/Homepage/DeliveryMethods";
import LoadingContent from "components/LoadingContent";
import { HomePageLayout } from "components/HomePageLayout";
import Login from "components/Club/Login";
import { SUPPORTED_CRM_COMPANYS } from "config";
import { parseQueryString } from "helpers/util";

function HomePage(props) {
  const { zone, markets, resetMarket } = props;
  const coalescedZone = zone || {};
  const zoneEmpty = Object.keys(coalescedZone).length === 0;

  const brand = useSelector(({brand}) => brand.brand)

  useEffect(() => {
    if (markets.id) {
      delete localStorage.market_id;
      resetMarket();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldStartOnCRMScreen = () => {
    const readUrl = parseQueryString(window.location);
    if (readUrl.ignore_club_redirect) {
      return false;
    }
    if (brand != null) {
      const crmModule = brand.crm || {};
      const isCompanySupported = SUPPORTED_CRM_COMPANYS.includes(
        crmModule.company
      );
      return (
        crmModule.is_initial_screen &&
        !localStorage.crmUserCpf &&
        isCompanySupported
      );
    }

    return false;
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "-webkit-fill-available",
        backgroundPosition: "center",
        backgroundSize: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <HomePageLayout>
        <LoadingContent isLoading={brand == null}>
          {shouldStartOnCRMScreen() ? (
            <Login ignorePageLayout={true} crmModule={brand.crm} />
          ) : (
            <CEPForm brand={brand} />
          )}
        </LoadingContent>
      </HomePageLayout>
      <Modal
        WrappedComponent={NeighborhoodsForm}
        title="Selecione seu bairro!"
        showModal={!zoneEmpty && coalescedZone.neighborhoods.length > 0}
      />

      <Modal
        WrappedComponent={DeliveryMethods}
        title="Selecione sua forma de entrega"
        size="md"
        showModal={markets.id ? true : false}
      />
    </div>
  );
}

function mapStateToProps({ zone, markets }) {
  return { zone, markets };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setZoneForRequestedCep,
      setNeighborhood,
      resetMarket,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
