import config from "config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function sendChatNotification(
  chatId,
  message,
  marketId,
  playerIds
) {
  await oAuthRequest.post(
    `${config.mappURLV2}/chats/send_push_notification`,
    {
      chat_id: chatId,
      text: message,
      market_id: marketId,
      player_ids: playerIds,
    }
  );
}
