import React from "react";
import { domainColorStyle } from "config";
import { Modal } from "reactstrap";
import "./ModalNotificationPix.scss";
function ModalNotificationPix({ isOpen, toggleModal, notice }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered modalPixStatus"
    >
      <div
        className="modal-top"
        style={{
          ...domainColorStyle("backgroundColor"),
        }}
      />

      <div className="container">
        <span
          class="material-icons"
          style={{ fontSize: "50px", ...domainColorStyle("color") }}
        >
          pix
        </span>
        <div className="title">
          <strong>{notice.title}</strong>
        </div>

        <div style={{ color: "#1b1b1b ", lineHeight: "20px" }}>
          {notice.message}
        </div>

        <button
          style={{
            ...domainColorStyle("backgroundColor"),
          }}
          onClick={toggleModal}
        >
          OK
        </button>
      </div>
    </Modal>
  );
}

export default ModalNotificationPix;
