import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./AdditionalValueInput.scss";
import { useDispatch } from "react-redux";
import { higherValueLimitChanged } from "actions/cartActions";

function AdditionalValueInput({ defaultValue }) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const dispatch = useDispatch()

  function handleInputChange(e) {
    const value = e.target.value;
    setInputValue(value !== "" ? value : "0");
  }

  function clearValue() {
    setInputValue(0);
  }

  useEffect(() => {
    dispatch(higherValueLimitChanged(Number(inputValue)))
  }, [inputValue])

  function currencyRegex(e) {
    let value = e.target.value;
    value = value.replace(/\D/g, ""); // remove caracteres não numéricos
    value = value.replace(/^0+/, ""); // remove zeros à esquerda
    value = value.replace(/(\d)(\d{2})$/, "$1.$2"); // separa casas decimais
    value = value.replace(/(?=(\d{3})+(\D))\B/g, "."); // separa milhares
    e.target.value = value;
    return e;
  }

  return (
    <div className="input-container">
      <span>R$</span>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => handleInputChange(currencyRegex(e))}
      />
      <IconButton
        onClick={() => clearValue()}
        style={{ background: "#d9d9d9" }}
      >
        <CloseIcon fontSize="small" style={{ color: "#fff" }} />
      </IconButton>
    </div>
  );
}

export default AdditionalValueInput;