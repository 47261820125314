import {
  CART_ADD,
  CART_REMOVE,
  CART_REMOVE_PRODUCT,
  CART_CLEAR,
  CART_CLEAR_ON_STORE_CHANGE,
  REMOVE_WHOLE_PRODUCT_CART,
  REPLACE_CART_PRODUCT,
  CART_ADD_LIST,
  RECALCULATE_TOTAL_PRICE,
  OPEN_CART,
  CART_ADD_PREFERENCES,
  CART_REMOVE_PREFERENCES,
  SET_ABANDONED_CART,
  AWARE_HIGHER_VALUE_CAPTURE,
  HIGHER_VALUE_LIMIT_CHANGED
} from "../ActionTypes";
import { applyTotalPrice } from "helpers/cartHelper";
import { sendCartEvent } from "helpers/apis/impulseEventsApiHelper";

const INITIAL_STATE = {
  cart: {},
  cartOpen: false,
  pendingAbandonedCart: false,
  awareHigherValueCapture: undefined,
  higherValueLimit: undefined
};

export default function (state = INITIAL_STATE, action) {
  var cartItem = null;
  var totalPriceAppliedItens = null;
  const oldCart = {
    ...state.cart,
  };
  switch (action.type) {
    case OPEN_CART: {
      return { ...state, cartOpen: action.payload };
    }
    case SET_ABANDONED_CART: {
      return { ...state, pendingAbandonedCart: action.payload };
    }
    case CART_ADD:
      if (oldCart[action.payload.id]) {
        cartItem = oldCart[action.payload.id];
        if (action.quantity) {
          cartItem.amount = parseInt(action.quantity, 10);
        } else {
          cartItem.amount += 1;
        }
        if (action.payload.mix_item_preference) {
          cartItem.mix_item_preference = action.payload.mix_item_preference
        }
        if (action.payload.customer_preferences) {
          cartItem.customer_preferences = action.payload.customer_preferences
        }
      } else {
        cartItem = {
          amount: action.quantity ? action.quantity : 1,
          origin: action.origin,
          ...action.payload,
        };
      }
      totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
      totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
        oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
      });
      sendCartEvent(oldCart);
      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    case CART_ADD_PREFERENCES:
      if (oldCart[action.payload.id]) {
        cartItem = oldCart[action.payload.id];
        cartItem.item_preference = action.payload.item_preference;
        if (action.payload.mix_item_preference) {
          cartItem.mix_item_preference = action.payload.mix_item_preference
        }
        if (action.payload.customer_preferences) {
          cartItem.customer_preferences = action.payload.customer_preferences
        }
      } else {
        cartItem = {
          amount: 1,
          ...action.payload,
        };
      }
      totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
      totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
        oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
      });
      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    case CART_REMOVE_PREFERENCES:
      if (oldCart[action.payload.id]) {
        cartItem = oldCart[action.payload.id];
        cartItem.item_preference = action.payload.item_preference;
        if (!action.payload.customer_preferences) {
          cartItem.customer_preferences = ""
        }
      } else {
        cartItem = {
          amount: 1,
          ...action.payload,
        };
      }
      totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
      totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
        oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
      });
      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    case CART_ADD_LIST:
      action.payload.forEach((item) => {
        totalPriceAppliedItens = applyTotalPrice(oldCart, item);
        totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
          oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
        });
      });
      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });

    case CART_REMOVE: {



      cartItem = oldCart[action.payload.id] || {};
      const oldValue = cartItem.amount || 0;
      if (cartItem) {
        cartItem.amount = Math.max(0, oldValue - 1);
        totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
        totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
          oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
        });
        if (cartItem.amount <= 0) {
          delete oldCart[cartItem.id];
        }
      }

      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    }
    case CART_REMOVE_PRODUCT:
      cartItem = oldCart[action.payload.id] || {};
      if (cartItem) {
        delete oldCart[cartItem.id];
      }

      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    case REMOVE_WHOLE_PRODUCT_CART:
      console.log("Chamou")
      delete oldCart[action.payload];
      console.log(oldCart)
      return Object.assign({}, state, {
        cart: oldCart,
        pendingAbandonedCart: true,
      });
    case CART_CLEAR:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
        pendingAbandonedCart: true,
      });

    case CART_CLEAR_ON_STORE_CHANGE: {
      const marketId = action.payload;
      const hasOtherMarketProducts = Object.keys(oldCart).some((key) => {
        const cartItem = oldCart[key];
        return cartItem.market_id != marketId
      })
      if (hasOtherMarketProducts) {
        return Object.assign({}, state, {
          ...INITIAL_STATE,
          pendingAbandonedCart: true,
        });
      }
      return state
    }
    case REPLACE_CART_PRODUCT:
      cartItem = action.payload;
      totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
      totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
        oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
      });
      return Object.assign({}, state, {
        cart: {
          ...oldCart,
        },
        pendingAbandonedCart: true,
      });
    case RECALCULATE_TOTAL_PRICE:
      cartItem = oldCart[action.payload.id];
      totalPriceAppliedItens = applyTotalPrice(oldCart, cartItem);
      totalPriceAppliedItens.forEach((totalPriceAppliedItem) => {
        oldCart[totalPriceAppliedItem.id] = totalPriceAppliedItem;
      });
      return Object.assign({}, state, {
        cart: {
          ...oldCart,
        },
        pendingAbandonedCart: true,
      });
    case AWARE_HIGHER_VALUE_CAPTURE:
      return Object.assign({}, state, {
        awareHigherValueCapture: action.payload,
      })
    case HIGHER_VALUE_LIMIT_CHANGED:
      return Object.assign({}, state, {
        higherValueLimit: action.payload,
      })
    default:
      return state;

  }
}
