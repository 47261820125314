import React from "react";
import { formatValue } from "helpers/orderhelper";
import { getTotalPrice } from "helpers/orderhelper";
import PixStatus from "../PixStatus/PixStatus";
import PixInfo from "../PixInfo/PixInfo";
import "./OrderPreview.scss";
import { Box } from "@material-ui/core";

const OrderPreview = ({
  order,
  pix,
  pixStatus,
  minutes,
  seconds,
  totalTime,
}) => {
  const pendingPixPayment = pix && pixStatus === "waiting" && (minutes !== 0 || seconds !== 0);
  return (
    <Box className="order-preview">
      {pix ? (
        <PixStatus status={pixStatus} minutes={minutes} seconds={seconds} />
      ) : null}
      <Box className="order-values">
        <h6>Resumo</h6>
        <Box className="value">
          <span>Valor em Itens</span>
          <span>{formatValue(order?.subtotal_with_additions)}</span>
        </Box>
        <Box className="value">
          <span>Taxa de Entrega</span>
          <span>{formatValue(order?.delivery_tax)}</span>
        </Box>
        <Box className="value">
          <span>Descontos</span>
          <span>{formatValue(order?.total_discounts)}</span>
        </Box>
        <Box className="value">
          <span>Taxa de Serviço</span>
          <span>{formatValue(order?.service_tax)}</span>
        </Box>
        <Box className="value">
          <span>Embalagens</span>
          <span>{formatValue(order?.custom_bag_value)}</span>
        </Box>
        <Box className="total">
          <span>Valor total</span>
          <span>{formatValue(getTotalPrice(order))}</span>
        </Box>
      </Box>
      {pendingPixPayment ? (
        <PixInfo
          pix={pix}
          minutes={minutes}
          seconds={seconds}
          totalTime={totalTime}
        />
      ) : null}
    </Box>
  );
};

export default OrderPreview;
