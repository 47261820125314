import React from "react";
import Modal from "../../new/components/Modal/Modal";
import "./NotificationCenterWarningModal.scss";
import { domainColorStyle, getDomainLogo } from "config";
import { Grid, Button, Typography, Link } from "@material-ui/core";
import { updateBrandNotificationAcceptances } from "helpers/apiHelper";
import { useSelector } from "react-redux"

function NotificationCenterWarningModal({isOpen, toggleModal}) {
  const userId = useSelector(({ userInfo }) => userInfo.id)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      style={{ borderRadius: 15 }}
    >
      <Grid className="modal-info-notification">
        <Link className='logo-image-notification'>
          <img alt={"logo"} src={getDomainLogo()} />
        </Link>
        <Typography className="title-notification" style={{ ...domainColorStyle("color") }}>
          NOVIDADES PARA VOCÊ!
        </Typography>
        <Typography style={{ textAlign: "center", padding: "0px 45px" }}>
          Atualizamos nossa central de notificações!
          Caso não deseje receber ofertas, promoções e novidades, acesse:
        </Typography>
        <Link
          className="title-notification-link"
          underline="none"
          style={{ ...domainColorStyle("color") }}
          href="/user"
        >
          Central de notificações
        </Link>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => {
            toggleModal(false)
            updateBrandNotificationAcceptances(
              userId,
              {
                push: true,
                email: true,
                sms: true,
                whatsapp: true
              }
            )
          }}
          className="button-confirme"
        >Entendi</Button>
      </Grid>
    </Modal>
  );
}

export default NotificationCenterWarningModal;
