import axios from "axios";
import Axios from "axios";
import { oAuthHeader } from "config";
import { getAccessToken, refreshToken } from "./apis/oAuthApi";
import firebase from "firebase/app";

const oAuthRequest = axios.create({
  timeout: 20000,
  // baseURL: 'https://merconnect-staging.herokuapp.com',
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

oAuthRequest.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${oAuthHeader()}`;
  return config;
});


oAuthRequest.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      let newTokenData = {}
      try {
        const refreshTokenData = window.aes.decrypt(localStorage.getItem("refreshToken"), "mcSiteToken123").toString(
          window.CryptoJS.enc.Utf8
        )
        if (refreshTokenData !== "") {
          newTokenData = await refreshToken();
        } else {
          delete localStorage.refreshToken
          delete localStorage.accessToken

          newTokenData = await getAccessToken(localStorage.loggedCpf);
        }
      } catch (e) {
        delete localStorage.refreshToken
        delete localStorage.accessToken
        delete localStorage.loggedCpf
        try {
          firebase.auth().signOut();
        } catch (error) { }
        newTokenData = await getAccessToken(localStorage.loggedCpf);
      }
      console.log("Axios error, refreshing token")

      const newConfig = {
        ...error.config, headers: {
          ...error.config.headers,
          Authorization: `Bearer ${newTokenData.access_token}`
        }
      };
      return Axios.request(newConfig);
    } else {
      return Promise.reject(error);
    }
  }
);

export default oAuthRequest