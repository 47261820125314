import axios from "axios";

const oAuthDataCRM = {
  client_id: "abZTnWAIXk5p0xgSFjHNQviSf9J1v_7GNNAS4mRjuCM",
  client_secret: "0scJC_ukV2CvdogVyJQIY0dCc7qZ0U-QtsEI3yDxvtk",
  grant_type: "client_credentials"
}

async function getAccessTokenCRM() {
  try {
    const response = await axios.post(`https://mercacrm.merconnect.com.br/oauth/token`, oAuthDataCRM, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    });
    return response.data.access_token
  } catch (error) {
    return error.response;
  }
}


export async function autoRegisterCustomerOnCRM(brandId, customerId) {
  const url = `https://mercacrm.merconnect.com.br/brand_crms/register_customer`
  const tokenCRM = await getAccessTokenCRM()
  const params = {
    brand_id: brandId,
    id: customerId
  }

  try {
    const response = await axios.post(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenCRM}`
      }
    })
    return response
  } catch (error) {
    return error.response;
  }
}