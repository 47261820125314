// Zone

export function getZone() {
  return JSON.parse(localStorage.getItem("zone"))
}

export function saveZone(zone) {
  localStorage.setItem("zone", JSON.stringify(zone))
}

// Mixes

export function getCartMixesFromLocalStorage(marketId) {
  return JSON.parse(localStorage.getItem("cartMixesInMarket" + marketId)) || {}
}

export function saveCartMixesInLocalStorage(cartMixes, marketId) {
  localStorage.setItem("cartMixesInMarket" + marketId, JSON.stringify(cartMixes))
}

// Amounts

export function getProductAmountsFromLocalStorage(marketId) {
  return JSON.parse(localStorage.getItem("productAmounts" + marketId)) || {}
}

export function saveProductAmountsInLocalStorage(productAmounts, marketId) {
  localStorage.setItem("productAmounts" + marketId, JSON.stringify(productAmounts))
}

//Cep

export function saveLocalCep (localCepKey,localCep) {
  localStorage.setItem(localCepKey, localCep)
}

export function getLocalCep (localCepKey){
  return localStorage.getItem(localCepKey)
}