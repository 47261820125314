// modules
import { combineReducers } from "redux";
// reducers
import ZoneReducer from "./ZoneReducer";
import cartReducer from "./cartReducer";
import marketStuffReducer from "./marketStuffReducer";
import MarketsReducer from "./MarketsReducer";
import BusinessesReducer from "./BusinessesReducer";
import userInfoReducer from "./userInfoReducer";
import clearsaleWorkerReducer from "./clearsaleWorkerReducer";
import CRMReducer from "./CRMReducer";
import deliveryMethod from "./DeliveryMethodReducer";
import categoriesReducer from "./categoriesReducer";
import settingsReducer from "./settingsReducer";
import uiReducer from "./uiReducer";
import brandReducer from "./brandReducer";
import productsReducer from "./productsReducer";
import paymentReducer from "./paymentReducer";

const reducers = combineReducers({
  userInfo: userInfoReducer,
  cart: cartReducer,
  marketStuff: marketStuffReducer,
  products: productsReducer,
  payment: paymentReducer,
  // Request do cep
  ui: uiReducer,
  zone: ZoneReducer,
  markets: MarketsReducer,
  businesses: BusinessesReducer,
  clearsale: clearsaleWorkerReducer,
  crm: CRMReducer,
  settings: settingsReducer,
  categories: categoriesReducer,
  deliveryMethod: deliveryMethod,
  brand: brandReducer,
});

export default reducers;
