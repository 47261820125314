import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Container, Card, CardBody, Row, Col } from "reactstrap";
import FlipbookView from "components/FlipbookView/FlipbookView";
import loadsContent from "components/loadsContent";
import { useHistory } from "react-router-dom";
import { getFlipBooks } from "helpers/apis/flipbooksApi";
import { domainColorStyle } from "config";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import "./Flipbooks.scss";
import { Box } from "@material-ui/core";

function Flipbooks({ currentMarket }) {
  const [flipbooks, setFlipbooks] = useState([]);
  const [viewFlipbook, setViewFlipbook] = useState(null);
  const { flipbook_id, market_id } = useParams();
  useEffect(() => {
    async function getAllFlipbooks() {
      const market = currentMarket.id || market_id;
      const { flipbooks } = await getFlipBooks(market);
      setFlipbooks(flipbooks);
      if (flipbook_id) {
        const flipbook = flipbooks.find(
          (item) => item.id === Number(flipbook_id)
        );
        if (flipbook) {
          setViewFlipbook(flipbook);
        }
      }
    }
    getAllFlipbooks();
  }, [currentMarket, flipbook_id, market_id]);

  const history = useHistory();
  return (
    <section className="section user-page">
      <Container className="mt-5">
        <Row className="mt-2">
          <Col xs={12} md={1}>
            <IconButton
              style={domainColorStyle("color")}
              size="small"
              onClick={() => {
                history.goBack();
              }}
              color="primary"
            >
              <ArrowBack style={domainColorStyle("fill")} fontSize="inherit" />
              Voltar
            </IconButton>
          </Col>
        </Row>
        <h3 className="title-flipbooks">Encartes!</h3>
        <Row>
          {flipbooks.length > 0 ? (
            flipbooks.map((flipbook) => (
              <Col sm={4} xs={6} key={flipbook.id}>
                <Card
                  key={flipbook.id}
                  className="shadow flip-card"
                  onClick={() => setViewFlipbook(flipbook)}
                >
                  <CardBody>
                    <p style={domainColorStyle("color")}>{flipbook.name}</p>
                    <img
                      src={flipbook.images_urls[0]}
                      alt={flipbook.name}
                      style={{ width: "100%" }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <Box>Nenhum encarte cadastrado pela loja.</Box>
          )}
        </Row>
      </Container>
      <FlipbookView
        showModal={!!viewFlipbook}
        flipbook={viewFlipbook}
        toggleModal={setViewFlipbook}
      />
    </section>
  );
}

const mapStateToProps = ({ markets }) => ({
  currentMarket: markets,
});

export default loadsContent(connect(mapStateToProps, null)(Flipbooks));
