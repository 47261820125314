import React, { useState } from "react";
import Modal from "new/components/Modal/Modal";
import { Box, Button, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import firebase from "firebase/app";

import "./EmailAuthenticationLogin.scss";
import Input from "components/InputCustom";
import { getEmailAuthenticationMethod, createAccountWithEmailAndPassword } from "helpers/activeAuthMethods";
import { showNotification } from "helpers/util";

export default function EmailAuthenticationLogin({ showModal, toggleModal }) {
  const [showPassword, setShowPassword] = useState(false)
  const [problemsLoggin, setProblemsLoggin] = useState(false)
  const [isCreatingAccount, setIsCreatingAccount] = useState(false)
  const [credentials, setCredentials] = useState({ email: "", password: "", name: "" })

  const submitCredentials = async (e) => {
    e.preventDefault()
    const response = await getEmailAuthenticationMethod(credentials.email, credentials.password)
    if (response.message) {
      showNotification(
        "top",
        "center",
        "Credenciais inválidas",
        "warning")
      return
    } else {
      toggleModal(false)
    }
  }

  const createAccount = async () => {
    try {
      const response = await createAccountWithEmailAndPassword(credentials.email, credentials.password, credentials.name)
      if (response.code === "auth/weak-password" && response.message) {
        showNotification(
          "top",
          "center",
          "A senha deve ter pelo menos 6 caracteres",
          "warning")
        return
      } else if (response.code === "auth/email-already-in-use") {
        showNotification(
          "top",
          "center",
          "E-mail já está em uso",
          "warning")
      } else {        
        resetAuthentication()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendRecoveryInstructions = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(credentials.email)
      showNotification(
        "top",
        "center",
        `Siga as instruções enviadas para ${credentials.email}`,
        "success")
      resetAuthentication()
    } catch (error) {
      showNotification(
        "top",
        "center",
        `Tivemos um problema ao tentar enviar as instruções para ${credentials.email}. Tente novamente ou entre em contato com o suporte.`,
        "warning")
    }
  }

  const resetAuthentication = () => {
    setProblemsLoggin(false)
    setIsCreatingAccount(false)
    setCredentials({ email: "", password: "", name: "" })
    toggleModal(false)
  }

  const handleCredentialsChange = ({ target }) => {
    const { name, value } = target
    setCredentials({...credentials, [name]: value })
  }

  const validateCredentials = () => {
    const { email, password, name } = credentials
    const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return isCreatingAccount
      ? emailValidation.test(email) && password.length > 3 && name.length > 3
      : emailValidation.test(email) && password.length > 3
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        toggleModal={() => resetAuthentication()}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => resetAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Acessar</p>
          </Box>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span>Email</span>
                  <Input
                    value={credentials.email}
                    required
                    type="email"
                    placeholder="example123@gmail.com"
                    name="email"
                    showLabel={false}
                    onChange={handleCredentialsChange}
                  />
                </Grid>
              </Grid>
            </form>
            <form onSubmit={submitCredentials}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span>Senha</span>
                  <div className="email-authe-password">
                    <Input
                      required
                      type={showPassword ? "text" : "password"}
                      name="password"
                      showLabel={false}
                      value={credentials.password}
                      onChange={handleCredentialsChange}
                      placeholder="********"
                    />
                    <InputAdornment position="end" className="icon-visibility-password">
                      <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility /> }
                      </IconButton>
                    </InputAdornment>
                    <Box className="help-buttons-wrapper">
                    <Button
                      onClick={() => setIsCreatingAccount(true)}
                      color="primary"
                      style={{ textTransform: "none" }}
                    >
                      Novo usuário? Crie uma conta
                    </Button>
                    <Button
                      onClick={() => (
                        setProblemsLoggin(true),
                        toggleModal(false))}
                      className="button-problems-email-login">
                      Problemas para fazer login?
                    </Button>
                    </Box>
                  </div>
                </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    className="button-next-email-login"
                    type="submit"
                    disabled={!validateCredentials()}>
                    Acessar
                  </Button>
              </Grid>
            </form>
        </Box>
      </Modal>

      <Modal
        isOpen={problemsLoggin}
        toggleModal={() => (setProblemsLoggin(false), toggleModal(true))}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => resetAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Recuperar senha</p>
            <span>Receba instruções enviadas para este e-mail que explicam como redefinir sua senha</span>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                required
                type="email"
                name="email"
                showLabel={false}
                defaultValue={credentials.email}
                onChange={handleCredentialsChange}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className="button-next-email-login"
              onClick={() => sendRecoveryInstructions()}>Enviar
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal
        isOpen={isCreatingAccount}
        toggleModal={() => (setIsCreatingAccount(false), toggleModal(true))}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => resetAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Criar conta</p>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} className="email-authe-password">
              <span>Email*</span>
              <Input
                required
                type="email"
                name="email"
                showLabel={false}
                defaultValue={credentials.email}
                onChange={handleCredentialsChange}
              />
              <span>Nome*</span>
              <Input
                required
                type="name"
                name="name"
                showLabel={false}
                placeholder="Nome"
                defaultValue={credentials.name}
                onChange={handleCredentialsChange}
              />
              <span>Senha*</span>
              <Input
                required
                type={showPassword ? "text" : "password"}
                name="password"
                showLabel={false}
                placeholder="senha"
                defaultValue={credentials.password}
                onChange={handleCredentialsChange}
              />
              <InputAdornment position="end" className="icon-visibility-password">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className="button-next-email-login"
              onClick={() => createAccount()}
              disabled={!validateCredentials()}
            >
              Salvar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
