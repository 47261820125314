import firebase from "firebase";
import { isFirebaseBrand } from "../firebase";
import { getDomainName } from "config";
import config from "config";

const market = getDomainName();
let phone = null;

function updateClient() {
  phone = localStorage.getItem("USERPHONE");
}

/// Send after user enters product detail scene
export function sendViewProductEvent(product) {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("select_content", {
    currency: config.currentCurrency,
    content: product.description,
    content_type: "product",
    content_id: String(product.id),
    price: product.price,
    Mercado: market,
    Plataforma: "web",
  });
}

/// Send after user search for something
export function sendSearchEvent(searchString, success) {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("search", {
    search_term: searchString,
    success: success ? 1 : 0,
    Mercado: market,
    Plataforma: "web",
  });
}

/// Send after user add one product to cart
export function sendAddedToCartEvent(product) {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("add_to_cart", {
    currency: config.currentCurrency,
    content: product.description,
    content_type: product.section ? product.section : "sem_origem",
    content_id: String(product.id),
    value: product.price,
    Mercado: market,
    Plataforma: "web",
  });
}

/// Send after user enters payment info scene
export function sendPaymentInfoEvent() {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("add_payment_info", {
    Plataforma: "web",
    Mercado: market,
  });
}

/// Send after user enters customer info scene
export function sendCustomerInfoEvent() {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("select_content", {
    content: phone,
    content_type: "InformaçõesDoCliente",
    item_id: 0,
    Plataforma: "web",
    Mercado: market,
  });
}

/// Send after user saves customer info
export function sendCustomerInfoCompletedEvent() {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("sign_up", {
    sign_up_method: "telefone",
    Plataforma: "web",
    Mercado: market,
  });
}

/// Send after user enters operating times scene
export function sendOperatingTimesEvent() {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("set_checkout_option", {
    CheckoutStep: "horário",
    Plataforma: "web",
    Mercado: market,
  });
}

/// Send after user tries to finish the order (clicked continue on payment types scene)
export function sendInitiateCheckoutEvent(cart) {
  if (!isFirebaseBrand()) return;
  updateClient();
  let numItems = 0;
  const items = Object.keys(cart.items).map((key) => {
    const item = cart.items[key];
    numItems += item.amount;
    return { quantity: item.amount, id: item.id };
  });

  firebase.analytics().logEvent("begin_checkout", {
    currency: config.currentCurrency,
    items,
    quantity: numItems,
    price: cart.data.order.total_price,
    Plataforma: "web",
    Mercado: market,
  });
}

/// Send after user finish the order
export function sendPurchasedEvent(
  formPayment,
  antiFraudStatus,
  total_price,
  subtotal_with_additions,
  order_number
) {
  if (!isFirebaseBrand()) return;
  updateClient();
  firebase.analytics().logEvent("purchase", {
    currency: config.currentCurrency,
    value: total_price,
    revenue: subtotal_with_additions,
    Plataforma: "web",
    Mercado: market,
    FormaDePagamento: formPayment,
    Pedido: order_number,
    Antifraude: antiFraudStatus,
  });
}
