import React, { useState } from "react";
import clsx from "clsx";
// import Header from "./SuperComponents/StorePageHeader/index";
import SideBarCart from "./SuperComponents/SideBar/SideBar";
import SideBar from "./SuperComponents/SideBarCart/SideBar";
import { useStylesSidebar } from "assets/useStyles";
import Header from "new/containers/Header";
import MainFooter from "new/components/MainFooter/MainFooter";

const StorePageWrapper = (Children) =>
  function AnimatedWrapper(props) {
    const [sideBar, setSidebar] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const classes = useStylesSidebar();

    const setDrawerMobileOpen = (value) => {
      setSidebar(value);
      setMobileOpen(value);
    };
    return (
      <>
        <Header
          sidebar={sideBar}
          setSidebar={setSidebar}
          mobileOpen={mobileOpen}
          setDrawerMobileOpen={setDrawerMobileOpen}
        />
        <SideBar
          sideBar={sideBar}
          setSidebar={setSidebar}
          mobileOpen={mobileOpen}
          setMobileOpen={setDrawerMobileOpen}
        />
        <SideBarCart
          sideBar={sideBar}
          setSidebar={setSidebar}
          mobileOpen={mobileOpen}
          setMobileOpen={setDrawerMobileOpen}
        />
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: sideBar,
          })}
        >
          <Children {...props} />
        </div>
        <MainFooter />
      </>
    );
  };

export default StorePageWrapper;
