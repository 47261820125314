import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CircularProgress, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import { domainColorStyle } from 'config';
import { timeZoneAdjustedMoment, calculateBusinessDays } from 'helpers/util';
import { getDomainMarketColor } from 'config';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';

import moment from 'moment';
import './OrderDeliveryTime.scss'
import { arrayHasContent } from 'helpers/util';

export function OrderDeliveryTime({
  deliveryMethod = {},
  operatingTimes = [],
  formError,
  setDeliveryTime,
  postalMethodValues,
  serviceCodeCorreios,
  handleChangeValueCorreios,
  loading,
  isExpress,
 }) {
    const [selected_day, setDay] = useState({})
    const [selected_time, setTime] = useState({})
    
    const days = useRef(null)
    const times = useRef(null)

    let date = '';

    if (isExpress) {
      const currentMinute = moment().minutes();
      const calculatedMinute = currentMinute + deliveryMethod.preparation_time;
      date = timeZoneAdjustedMoment().minutes(calculatedMinute).format();
    } else {
      date = timeZoneAdjustedMoment(selected_time.end_time).format();
    }

    const handleDeliveryTimeChange = () => {
      if (isExpress) {
        setDeliveryTime({
          enabled: true,
          end_time: date,
          start_time: date,
          schedule_until: date,
        })
        return
      }

      if (arrayHasContent(operatingTimes)) {
        setDay(operatingTimes[0]);
        setTime(operatingTimes[0]?.options[0]);
        setDeliveryTime(operatingTimes[0]?.options[0])
      }
    }

    useEffect(() => {
      handleDeliveryTimeChange()
    }, [operatingTimes, isExpress])
  
    const setSelectDay = (day) => {
      if (day && day !== selected_day) {
        setDay(day)
        setSelectTime(day.options[0])
      }
    }
  
    const setSelectTime = (time) => {
      setDeliveryTime(time)
      setTime(time)
    }
  
    function scrollNext(ref) {
      ref.current.scrollBy({ left: 100, behavior: "smooth" });
    }
    function scrollBefore(ref) {
      ref.current.scrollBy({ left: -100, behavior: "smooth" });
    }

    const formatDeadline = (deadline) => {
      const now = new Date()
      const deadlineDate = new Date(deadline)
      const businessDays = calculateBusinessDays(now, deadlineDate)
      return businessDays
    }

  return (
    <Card className='mt-3 order-delivery-time' style={formError === 5 ? { border: "solid 1px red" } : {}}>
      {loading ? (
        <div style={{height: "15vh", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
          <CircularProgress color="primary" />
        </div>) : (
        <>
          {!postalMethodValues.length ? 
      (<>
        <Box
          display="flex"
          flexDirection="column">
          <Box pl={3} pt={2} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title-details">
            <h5>Horário de {deliveryMethod.mode === "drivethru" ? 'retirada' : 'entrega'}</h5>
          </Box>
          {deliveryMethod.preparation_time ?
            <Box px={3} pb={2}>
              <p className='type' style={{ ...domainColorStyle('color') }}>{deliveryMethod.display_name}</p>
              <p><b>{moment(date).format('dddd')}</b> Dia {`${moment(date).format('LL')} (${moment(date).format('L')})`}</p>
                <p>Seu pedido chegará as {timeZoneAdjustedMoment(date).format("HH:mm")}</p>
            </Box>
            :
            <Box pl={3}>
              <Box pt={1} mb={2} className="title-time" style={{ width: '100%' }}>
                <h5>Dia</h5>
              </Box>
              <Box className='box-days' pb={1} ref={days}>
                {operatingTimes.map((day) => (
                  <Button
                    key={day.header}
                    style={selected_day === day ? {} : { backgroundColor:`${getDomainMarketColor()}4d`, color: "#000" }}
                    variant='contained'
                    color="primary"
                    className='delivery-days'
                    onClick={() => setSelectDay(day)}>
                    {moment(day.options[0].start_time).format('DD/MM')} - {day.header}</Button>
                ))}
              </Box>
              <Box className='buttons-icon'>
                <IconButton onClick={() => scrollBefore(days)} className="icon-button">
                  <NavigateBefore fontSize='small' />
                </IconButton>
                <IconButton onClick={() => scrollNext(days)} className="icon-button">
                  <NavigateNext fontSize='small' />
                </IconButton>
              </Box>
              <Box pt={1} mb={2} className="title-time" style={{ width: '100%' }}>
                <h5>Horário</h5>
              </Box>
              <Box className='box-times' pb={1} ref={times}>
                {selected_day?.options && selected_day?.options.map((time) => (
                  <Button
                    key={time.start_time}
                    style={selected_time === time ? {} : { backgroundColor:`${getDomainMarketColor()}4d`, color: "#000" }}
                    color="primary"
                    variant='contained'
                    className='delivery-times'
                    onClick={() => setSelectTime(time)}>
                    {timeZoneAdjustedMoment(time.start_time).format("HH:mm")}h
                    e {timeZoneAdjustedMoment(time.end_time).format("HH:mm")}h</Button>
                ))}
              </Box>
              <Box className='buttons-icon'>
                <IconButton onClick={() => scrollBefore(times)} className="icon-button">
                  <NavigateBefore fontSize='small' />
                </IconButton>
                <IconButton onClick={() => scrollNext(times)} className="icon-button">
                  <NavigateNext fontSize='small' />
                </IconButton>
              </Box>
            </Box>
          }
        </Box>
      </>) : (
          <>
            <Box
              display="flex"
              flexDirection="column">
              <Box pl={3} pt={2} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title-details">
                <h5>Tipo de envio</h5>
              </Box>
            </Box>
            <Box className='radio-box-mail-methods'>
              <FormControl component="fieldset">
                <RadioGroup value={serviceCodeCorreios} onChange={handleChangeValueCorreios}>
                  {postalMethodValues.map(correio => (
                    <Box key={correio.id} className='box-form-control-mail-methods'>
                      <FormControlLabel
                        className='form-control-mail-methods-label'
                        value={correio.code}
                        control={<Radio size="small" color="primary" className='preferences-radio' />}
                        label={`${correio.name} - R$ ${correio.price}`} />
                      <small>
                        <span style={{ fontWeight: 700 }}>{formatDeadline(correio.deadline)} dias úteis</span>
                        <span>, após confirmação do pagamento</span>
                      </small>
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </>
      )}
      </>
    )}
    </Card>
  )}
export default OrderDeliveryTime;