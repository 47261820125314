import React, { Component } from "react"
import IntlCurrencyInput from "react-intl-currency-input"
import {
    InputGroupAddon,
    InputGroupText,
    Label,
    InputGroup,
    Popover,
    PopoverBody
} from "reactstrap"
import { domainColorStyle } from "config"
const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
}
class InputCurrency extends Component {
    constructor() {
        super()
        this.state = {}
    }
    componentDidMount() {
        this.setState({
            popoverReady: true
        })
    }
    render() {
        const { placeholder, name, inputMode, onChange, defaultValue, type, icon, disabled, className, error, required, value, autoFocus, showLabel } = this.props
        const { popoverReady } = this.state
        return (
            <>
                {
                    ((showLabel === undefined) || showLabel) && (<Label className="form-control-label text-warning font-weight-bold" for={name}>{placeholder}</Label>)
                }
                <InputGroup className="input-group-alternative mt-1">
                    {icon && (<InputGroupAddon addonType="prepend">
                        <InputGroupText className={disabled ? "bg-disabled" : ""}>
                            <i style={domainColorStyle("color")} className={icon} />
                        </InputGroupText>
                    </InputGroupAddon>)}

                    <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        inputMode={inputMode}
                        autoFocus={autoFocus || false}
                        id={name}
                        required={required}
                        className={(error ? "is-invalid" : "") + " form-control " + className}
                        disabled={disabled || false}
                        defaultValue={defaultValue}
                        value={value}
                        placeholder={placeholder}
                        type={type || "text"}
                        onChange={(e, value) => onChange({ target: { name: name, value: value } })}
                        name={name}
                    />
                    {
                        (popoverReady === true && name) && (
                            <Popover
                                placement="bottom"
                                isOpen={error !== undefined}
                                target={name}
                                className="popover-danger"
                            >
                                <PopoverBody>
                                    {error}
                                </PopoverBody>
                            </Popover>
                        )
                    }

                </InputGroup>
            </>
        )
    }
}


export default InputCurrency