import config from "config";
import { getBrandID } from "config";
import { addBadgeForOfferKind } from "helpers/models/brand";
import { store } from "App";
import { BRAND_CHANGED } from "ActionTypes";
import { initFirebase } from "../../firebase";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function getBrandInfo() {
  try {
    const brandID = getBrandID();
    const response = await oAuthRequest.get(`${config.mappURLV2}/brands/${brandID}`);
    const brand = addBadgeForOfferKind(response.data)
    initFirebase(brand);
    store.dispatch({
      type: BRAND_CHANGED,
      payload: brand,
    });
    return brand
  } catch (e) {
    return null
  }
}
