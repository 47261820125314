import {
  SET_ZONE_FOR_REQUESTED_CEP,
  SET_ZONE,
  SET_NEIGHBORHOOD,
  RESET_MARKET,
  SET_MARKET,
} from "ActionTypes";

export default function (state = [], action) {
  switch (action.type) {
    case SET_ZONE_FOR_REQUESTED_CEP:
      return action.payload.data.markets || {};
    case SET_NEIGHBORHOOD:
      return action.payload.markets || {};
    case SET_ZONE:
      // Se a zona foi resetada, resetamos os mercados, se não mantemos o estado
      return !action.zone ? {} : state;
    case SET_MARKET:
      return action.payload.data;
    case RESET_MARKET:
      return [];
    default:
      return state;
  }
}
