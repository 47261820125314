import React, { useState } from "react";

import { ModalBody, Modal, ModalHeader, Input, Button } from "reactstrap";
import LoadingContent from "components/LoadingContent";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { domainColorStyle } from "config";
import { maskPhone } from "helpers/util";

import "./ShareModal.scss";

function ShareModal({
  phone,
  isOpen,
  toggleModal,
  changePhone,
  share,
  userToShare,
  loading,
  shareLink,
  setShareLink,
}) {
  const [copied, setCopied] = useState(false);

  const closeModal = () => {
    setShareLink(null);
    changePhone("");
    toggleModal(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
    >
      <ModalHeader className="list-header" toggle={() => closeModal()}>
        {userToShare && !shareLink
          ? "Usuário Encontrado"
          : shareLink
          ? "Compartilhar link!"
          : "Buscar amigo"}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <LoadingContent isLoading={loading} />
        ) : userToShare && !shareLink ? (
          <h6>Deseja adcionar {userToShare.name} ?</h6>
        ) : shareLink ? (
          <div className="share-link">
            <span className="share-text">
              Copie o link abaixo e encaminhe para seus amigos!
            </span>
            <p>{shareLink}</p>
            <CopyToClipboard text={shareLink} onCopy={() => setCopied(true)}>
              <span className="copy-button" style={domainColorStyle("color")}>
                Copiar link
              </span>
            </CopyToClipboard>
            {copied && <span className="copied-span">Copiado</span>}
          </div>
        ) : (
          <>
            <span>Digita o celular do seu amigo</span>
            <Input
              value={maskPhone(phone)}
              style={{ marginTop: 10 }}
              onChange={({ target }) => changePhone(target.value)}
            />
          </>
        )}
        <div className="share-buttons">
          <Button outline onClick={() => closeModal()}>
            Cancelar
          </Button>
          {!shareLink && (
            <Button
              onClick={() => share()}
              outline
              style={domainColorStyle("color")}
            >
              Compartilhar
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ShareModal;
