import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { getDomainMarketColor } from "config";
import {
  getAllyHigherValueCaptureRequests,
  sendAllyHigherValueCaptureStatus,
} from "helpers/apiHelper";
import Dialog from "../../Dialog/Dialog";
import { useParams } from "react-router-dom"

import "./AdditionApprovalPopUp.scss";
import { useSelector } from "react-redux";

function AdditionApprovalPopUp() {
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [currIndex, setCurrIndex] = useState(0); 
  const currPendingApproval = pendingApprovals[currIndex];
  const userId = useSelector(({ userInfo }) => userInfo?.id)

  const { market_id } = useParams()

  async function getAllyPendingApprovals() {
    try {
      const response = await getAllyHigherValueCaptureRequests(market_id);
      setPendingApprovals(response); 
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (userId) {
      getAllyPendingApprovals();
    }
  }, [userId, market_id]);

  function handleApprovalStatus(status) {
    try {
      sendAllyHigherValueCaptureStatus(market_id, status);
      setDialogType(status);
      setShowDialog(true);
    } catch (error) {
      console.log(error);
    }
  }

  return currPendingApproval ? (
    <div className="addition-approval-popup-overlay">
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        type={dialogType}
        value={currPendingApproval.value_requested}
        orderNumber={currPendingApproval.value_requested}
        increaseIndex={() => setCurrIndex(currIndex + 1)}
      />
      <div
        className="addition-approval-popup-card"
        style={{ borderTop: `15px solid ${getDomainMarketColor()}` }}
      >
        <div className="addition-approval-popup-content">
          <h4>Aprovação de acréscimo</h4>
          <div className="addition-approval-popup-info">
            <b>Pedido Nº {currPendingApproval.order_number}</b>
            <p>Realize a aprovação para confirmar a alteração em seu pedido.</p>
          </div>
          <div className="addition-approval-popup-message">
            Aprovar acréscimo de R$ {currPendingApproval.value_requested} ao
            pedido
          </div>
        </div>
        <div className="addition-approval-popup-actions">
          <Button
            variant="outlined"
            style={{
              color: getDomainMarketColor(),
              border: `2px solid ${getDomainMarketColor()}`,
              marginRight: "10px",
            }}
            onClick={() => handleApprovalStatus("disapproved")}
          >
            Rejeitar
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: getDomainMarketColor(),
              color: "#fff",
            }}
            onClick={() => handleApprovalStatus("approved")}
          >
            Aprovar
          </Button>
        </div>
      </div>
    </div>
  ) : null;
}

export default AdditionApprovalPopUp;
