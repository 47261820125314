import { TOGGLE_SIDEBAR, OPEN_DELIVERY_METHOD, HAS_AUTO_COUPONS, SAVE_BRAND } from "ActionTypes";

export function toggleSidebar(value){
  return { type: TOGGLE_SIDEBAR, payload: value}
}
export function openDeliveryMethod(value){
  return { type: OPEN_DELIVERY_METHOD, payload: value}
}
export function setAutoCoupons(value){
  return { type: HAS_AUTO_COUPONS, payload: value}
}
export function saveBrand(value){
  return { type: SAVE_BRAND, payload: value}
}