import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Container, Grid } from "@material-ui/core";
import { domainColorStyle } from "config";
import { timeZoneAdjustedMoment } from "helpers/util";
import { clearCart } from "actions/cartActions";
import numberToCurrency from "helpers/currency";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import "./OrderFinalized.scss";
import CashbackCouponModal from "../CashbackCouponModal/CashbackCouponModal";
import { useParams } from "react-router";
import { calculateBusinessDays } from "helpers/util";
import { notification } from "helpers/util";

export function OrderFinalized({
  order,
  market,
  paymentType,
  deliveryTime,
  isExpress,
  statusOrder,
  postalData,
  cashbackCoupon,
  hasItemsWithRevenue 
}) {
  const { market_id } = useParams()
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const [openCashbackModal, setOpenCashbackModal] = useState(Boolean(cashbackCoupon)); 
  const history = useHistory();
  const dispatch = useDispatch();
  const backMarketPage = () => {
    history.push(`/loja/${market.id || market_id }`);
  }

  const formatDeadline = (deadline) => {
    const now = new Date()
    const deadlineDate = new Date(deadline)
    const businessDays = calculateBusinessDays(now, deadlineDate)
    return businessDays
  }

  const navigateToOrder = () => {
    history.push(`/order?orderId=${order?.id}`);
  }

  useEffect(() => {
    if (hasItemsWithRevenue) {
      notification("Lembrete importante: Não esqueça de levar sua receita e documento de indentificação com foto para retirada do seu pedido.", "success")
    }

    return () => {
      dispatch(clearCart());
    };
  }, [])

  return (
    <Container className='mt-5 order-finalized-page' >
      <Grid container spacing={2} flex="column" alignItems="center">
        <Grid item xs={12} sm={12} md={8} lg={12}>
          <Box display={"flex"} alignItems="center" flexDirection={"column"}>
            <h3 className='mt-5'>Olá {userInfo.name}, sua compra foi realizada com sucesso. </h3>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              {statusOrder === 'waiting' &&
                <Box className='box-status-order-finalized-page'>
                  <ScheduleIcon color="primary" fontSize='large' />
                  <div className='title-status-order'>
                    <h4>Pagamento em análise</h4>
                    <p>O pagamento está em processo de análise. Em breve, vai receber a notificação com o status do seu pedido.</p>
                  </div>
                </Box>}
              <Card className='mt-2 order-details' onClick={navigateToOrder}>
                <CheckCircleOutlineIcon color="primary" className='checked-icon' />
                <h4 style={{ ...domainColorStyle('color') }}>Pedido Nº {order.order_number}</h4>
                <p className='title'>Loja</p>
                <p>{market.name}</p>
                <p className='title'>Informações para entrega</p>
                {isExpress ? (
                  <p>
                    {timeZoneAdjustedMoment(deliveryTime).format("DD/MM/YYYY") +
                      " às " +
                      timeZoneAdjustedMoment(deliveryTime).format("HH:mm") +
                      " horas"}
                  </p>
                ) : (
                  <>
                  {!postalData ? (
                    <p>
                    {timeZoneAdjustedMoment(deliveryTime.start_time).format(
                      "DD/MM/YYYY"
                    ) +
                      " - " +
                      timeZoneAdjustedMoment(deliveryTime.start_time).format(
                        "HH:mm"
                      ) +
                      " às " +
                      timeZoneAdjustedMoment(deliveryTime.end_time).format(
                        "HH:mm"
                      ) +
                      " horas"}
                  </p>) : (
                    <p>
                      <span>{formatDeadline(postalData.deadline)} dias úteis, após confirmação do pagamento</span>
                    </p>
                  )}
                </>)}
                
                <p>{userInfo.address_attributes?.street}
                  {userInfo.address_attributes?.number ? `, ${userInfo.address_attributes?.number}` : ''}
                  {userInfo.address_attributes?.complement ? ` - ${userInfo.address_attributes?.complement}` : ''}</p>
                <p className='title'>Informações de Pagamento</p>
                <p>{paymentType.kind === 'online' ? 'Pagamento Online' : 'Pagamento na Entrega'}</p>
                <p>{paymentType.name}</p>
                <Box className='order-finalized-footer'>
                  Valor: {numberToCurrency(order.total_price)}
                </Box>
              </Card>
            </Grid>
            <span className='order-finalized-message'>Você pode acompanhar o andamento de seu pedido e avaliar suas compras acessando a tela de “Pedidos”</span>
            <Button onClick={() => backMarketPage()} variant='contained' color='primary'>Retornar a loja</Button>
          </Box>
        </Grid>

      </Grid>
      <CashbackCouponModal
        isOpen={openCashbackModal}
        setIsOpen={setOpenCashbackModal}
        couponCode={cashbackCoupon?.code}
      />
    </Container>
  )
}

export default OrderFinalized;