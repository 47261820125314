import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { domainColorStyle } from 'config';
import { timeZoneAdjustedMoment } from 'helpers/util';
import { useSelector } from 'react-redux';
import { getDomainMarketColor } from 'config';

import moment from 'moment';
import "./ChangeOrderTime.scss"
import { isExpress } from 'helpers/util';
import { getOperatingTimes } from 'helpers/apiHelper';
import { getMixesFromCart } from 'helpers/cartHelper';
import { notification } from 'helpers/util';
import { updateTimeOrder } from 'helpers/apiHelper';

export default function ChangeOrderTime({orderId, onClose}) {
  const [operatingTimes, setOperatingTimes] = useState([]);
  const [selectedTime, setTime] = useState({})
  const [selectTimeColor, setSelectTimeColor] = useState({})
  const zone = useSelector(({ zone }) => zone);
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod);
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const market = useSelector(({ markets }) => markets);
  let date = '';

  if (isExpress(deliveryMethod)) {
    const currentMinute = moment().minutes();
    const calculatedMinute = currentMinute + deliveryMethod.preparation_time;
    date = timeZoneAdjustedMoment().minutes(calculatedMinute).format();
  } else {
    date = timeZoneAdjustedMoment(selectedTime.end_time).format();
  }


  async function updateOperatingTimes() {
    const cartIDs = cart.map(item => item.id)
    await getOperatingTimes(
      market.id,
      zone.neighborhood_id,
      deliveryMethod.deliveryMethod.id, cartIDs,
      (error, data) => {
        if (error) {
          notification(
            "Ocorreram erros, verifique sua conexão com a internet e tente novamente!",
            "danger"
          );
        } else {
          setOperatingTimes(data.operating_times);
        }
      }
    );
  }

  const setSelectTime = (time) => {
    if (time?.enabled) { 
      setSelectTimeColor(time) 
    }
    setTime(time)
  }

  const handleChangeTime = async () => {
   if(selectedTime){
    try {
      const response = await updateTimeOrder(orderId, selectedTime)
      if(response){
        onClose()
        notification("Pedido alterado com sucesso!", "success")
      }
    } catch(error){
      console.log(error)
    }
   }
  }

  useEffect(() => {
    updateOperatingTimes();
  }, [])

  return (
    <Box>
      <div>
        <h5>Ao invés de cancelar, podemos alterar o horário do seu pedido</h5>
      </div>
      {operatingTimes.map((times) => (
            <div>
            <h4 style={{
              ...domainColorStyle("background"),
              width: '100%',
              textAlign: 'center'
            }}
              className='change-time-title-day'>
              {times.header}
            </h4>
            <Box className='box-button-change-time'>
              {times.options && times?.options.map((time) => (
                <Button
                  key={time.start_time}
                  color="primary"
                  className='available-times-request'
                  onClick={() => setSelectTime(time)}
                  style={selectTimeColor === time ? { backgroundColor: `${getDomainMarketColor()}4d`, color: "#000" } : {}}>
                  {timeZoneAdjustedMoment(time.start_time).format("HH:mm")}h
                  ás {timeZoneAdjustedMoment(time.end_time).format("HH:mm")}h</Button>
              ))}
            </Box>
          </div>
      ))}
      <div className='div-button-change-time'>
        <Button
          variant="contained"
          className='button-change-time'
          disabled={selectedTime !== selectTimeColor}
          onClick={handleChangeTime}
          >
          Alterar horário
        </Button>
      </div>
    </Box>
  )
}