import { ORDENATION_CHANGED } from "ActionTypes";
import { SEARCH_ACTIVE_CHANGED } from "ActionTypes";
import { SEARCH_TEXT_CHANGED } from "ActionTypes";

export function seachTextChanged(searchText) {
  return {
    type: SEARCH_TEXT_CHANGED,
    payload: searchText,
  };
}

export function searchActiveChanged(searchActive) {
  return {
    type: SEARCH_ACTIVE_CHANGED,
    payload: searchActive
  }
}

export function ordenationChanged(ordenation) {
  return {
    type: ORDENATION_CHANGED,
    payload: ordenation,
  };
}
