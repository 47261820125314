import React, { useEffect, useRef } from "react";

import { domainColorStyle } from "config";
import "../../assets/css/react-confirm-alert.css";

import "./Messages.scss";
import moment from "moment";

function Messages({ chatMessages }) {
  let messagesEnd = useRef(null);
  useEffect(() => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div style={{ overflow: "auto", height: "calc(100% - 4.1rem)" }}>
      {Object.keys(chatMessages).map((key, i) => {
        const message = chatMessages[key];
        return (
          <div>
            <div
              key={i}
              className="w-100 row"
              style={{
                margin: 0,
                justifyContent: message.is_from_market
                  ? "flex-start"
                  : "flex-end",
              }}
            >
              <div
                className="chat-message"
                style={
                  message.is_from_market
                    ? { textAlign: "left" }
                    : {
                        textAlign: "right",
                        ...domainColorStyle("background"),
                        color: "#fff",
                      }
                }
              >
                <div className="message-text">
                  <p
                    style={
                      !message.is_from_market
                        ? {
                            color: "#fff",
                            display: "initial",
                            fontWeight: "bold",
                          }
                        : { display: "initial", fontWeight: "bold" }
                    }
                  >
                    {message.content}
                  </p>
                  <span
                    className="message-time"
                    style={
                      !message.is_from_market
                        ? { color: "#fff", marginLeft: 5 }
                        : { marginRight: 5 }
                    }
                  >
                    {moment(message.created_at).format("DD/MM/YYYY HH:mm")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div style={{ float: "left", clear: "both" }} ref={messagesEnd}></div>
    </div>
  );
}

export default Messages;
