import React, { useState, useEffect } from "react";

import { ModalBody, Modal, ModalHeader, Button, Input } from "reactstrap";
import ShareModal from "components/ShareModal/ShareModal";
import AmountProduct from "components/AmountProduct";

import numberToCurrency from "helpers/currency";
import { domainColorStyle } from "config";
import { getCustomerByPhone } from "helpers/apiHelper";
import { unmask } from "helpers/util";
import { sendInvite, renameList, updateList } from "helpers/apis/listApi";
import { showNotification } from "helpers/util";
import "moment/locale/pt-br";

import "./ListDetails.scss";
import { timeZoneAdjustedMoment } from "helpers/util";
import { useSelector } from "react-redux";

function ListDetails({
  list = {},
  isOpen,
  toggleModal,
  addItemsCart,
  findLists,
}) {
  const [phoneModal, setPhoneModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [userToShare, setUserToShare] = useState("");
  const [loading, setLoading] = useState(false);
  const [editTitle, setEditTitle] = useState(null);
  const [shareLink, setShareLink] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [edited, setEdited] = useState(false);
  const userInfo = useSelector(({ userInfo }) => userInfo)
  useEffect(() => {
    setListItems(list.list_items);
  }, [list]);

  const shareList = async () => {
    setLoading(true);
    if (userToShare === "") {
      getCustomerByPhone(unmask(phone), (error, data) => {
        if (error) {
          setShareLink(window.location.href.split("/user").join(""));
          setLoading(false);
        } else {
          setUserToShare(data.customer);
          setLoading(false);
        }
      });
    } else {
      try {
        await sendInvite(list.id, phone);
        setLoading(false);
        showNotification(
          "top",
          "center",
          "Convite enviado com sucesso",
          "success"
        );
        setPhone("");
        setUserToShare("");
        setPhoneModal(false);
      } catch {
        showNotification("top", "center", "Erro ao enviar convite", "danger");
      }
    }
  };

  const updateListName = async () => {
    try {
      await renameList(list.id, editTitle, userInfo.id);
      showNotification(
        "top",
        "center",
        "Lista atualizada com sucesso.",
        "success"
      );
      setEditTitle(null);
      toggleModal({ ...list, name: editTitle });
      findLists();
    } catch (error) {
      console.log(error)
      showNotification("top", "center", "Erro ao renomear lista.", "danger");
    }
  };
  const addToList = (product) => {
    if (!edited) {
      setEdited(true);
    }
    const list = [...listItems];
    const index = list.findIndex((item) => item.product_id === product.product_id);
    list[index].amount += 1;
    setListItems(list);
  };

  const removeFromList = (product) => {
    if (!edited) {
      setEdited(true);
    }
    const list = [...listItems];
    const index = list.findIndex((item) => {
      return item.product_id === product.product_id;
    });
    if (list[index].amount === 1) {
      list.splice(index, 1);
    } else {
      list[index].amount -= 1;
    }
    setListItems(list);
  };

  const saveListUpdate = async () => {
    const to_remove = list.list_items.filter((a) => !listItems.includes(a));
    try {
      await updateList(
        list.id,
        list.name,
        listItems,
        { id: list.market_id },
        to_remove,
        userInfo.id
      );
      showNotification(
        "top",
        "center",
        "Lista de compras atualizada com sucesso!",
        "success"
      );
    } catch (error) {
      showNotification(
        "top",
        "center",
        "Tivemos um problema ao tentar atualizar a lista! Tente novamente ou entre em contato com o suporte.",
        "danger"
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal(null)}
      className="modal-dialog-centered"
    >
      <ModalHeader className="list-header" toggle={() => toggleModal(null)}>
        {editTitle ? (
          <>
            <Input
              className="input-title"
              value={editTitle}
              onChange={({ target }) => setEditTitle(target.value)}
              onKeyDown={({ key }) =>
                key === "Enter" ? updateListName() : null
              }
            />
            <div className="buttons-input">
              <span onClick={() => setEditTitle(null)} className="obs-input">
                Cancelar
              </span>
              <span
                onClick={() => updateListName()}
                className="obs-input"
                style={domainColorStyle("color")}
              >
                Salvar
              </span>
            </div>
          </>
        ) : (
          <>
            {list.name}
            <span
              className="edit-title"
              onClick={() => setEditTitle(list.name)}
            >
              <i className="fas fa-edit" />
              Renomear{" "}
            </span>
          </>
        )}
      </ModalHeader>
      <ModalBody>
        <span className="last-update-list">
          Atualizado em:{" "}
          {timeZoneAdjustedMoment(list.last_update.time).format("DD/MM/YYYY")}{" "}
          por {list.last_update.author}
        </span>
        <div className="list-items">
          <h6>Items</h6>
          {listItems.map((item) => (
            <div key={item.product_id}>
              <div className="item-description">
                <img
                  src={item.image}
                  alt={item.description}
                  style={{ opacity: item.is_unavailable ? 0.3 : 1 }}
                />
                <div className="item-details">
                  <div
                    className="d-flex justify-content-between align-items-center mb-1"
                    style={{ width: "100%" }}
                  >
                    <p>
                      <span>{item.amount}x</span>
                      {item.description}
                    </p>
                    <span>{numberToCurrency(item.price)}</span>
                  </div>
                  {list.open_edition && (
                    <AmountProduct
                      product={item}
                      addToList={addToList}
                      removeFromList={removeFromList}
                    />
                  )}
                  <span className="item-user">
                    Adicionado por: {item.client_name}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-100 d-flex justify-content-between">
          <Button
            className="share-button-list"
            style={domainColorStyle("color")}
            onClick={() => setPhoneModal(true)}
          >
            <i className="fas fa-share-alt"></i>
            Compartilhar
          </Button>
          {edited && (
            <Button
              className="share-button-list"
              style={domainColorStyle("color")}
              onClick={() => saveListUpdate()}
            >
              Salvar alterações
            </Button>
          )}
        </div>
        <div className="cart-button-list">
          <Button
            onClick={() => addItemsCart(listItems, list.market_id)}
            style={domainColorStyle("color")}
          >
            Preencher carrinho
          </Button>
        </div>
      </ModalBody>
      <ShareModal
        isOpen={phoneModal}
        userToShare={userToShare}
        shareLink={shareLink}
        setShareLink={setShareLink}
        loading={loading}
        phone={phone}
        changePhone={setPhone}
        share={shareList}
        toggleModal={setPhoneModal}
      />
    </Modal>
  );
}

export default ListDetails;
