import axios from "axios";
import config, { v4AuthHeader, getBrandID } from "config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

let customerId = localStorage.getItem("USER_ID")

export async function loginZoombox(cpf, senha) {
  const id = getBrandID();
  try {
    const user = await axios.post(
      `${config.zoombox}/login?brand_id=${id}`,
      { login: cpf, senha },
      {
        headers: v4AuthHeader,
      }
    );
    return user.data;
  } catch (error) {
    console.log("List error", error.response);
    throw error.response.data;
  }
}

export async function getFormOptions() {
  const id = getBrandID();
  try {
    const brand = await axios.get(
      `${config.zoombox}/form_options?brand_id=${id}`,
      {
        headers: v4AuthHeader,
      }
    );
    return brand.data;
  } catch (error) {
    console.log("Brand error");
  }
}

export async function doRegister(user) {
  const id = getBrandID();
  try {
    const brand = await axios.post(
      `${config.zoombox}/register?brand_id=${id}`,
      { cliente: user },
      {
        headers: v4AuthHeader,
      }
    );
    return brand.data;
  } catch (error) {
    throw error.response.data.messageError.message;
  }
}


export async function getOffers(market_id, page, deliveryMethodId, neighborhoodId, limit ) {
  const url = `${config.mappURLV2}/markets/${market_id}/items/club_offers`

  const params = {
    page: page,
    limit: limit,
    delivery_method_id: deliveryMethodId,
    neighborhood_id: neighborhoodId,
    customer_id: customerId

  }

  try {
    const response = await oAuthRequest.get(url, { params: params });
    return response.data;
  } catch (error) {
    console.log("Brand error");
    throw error;
  }
}
