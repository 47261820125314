import React from "react";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { getDomainMarketColor, getDomainLogo } from "config";
import "./Dialog.scss";

const marketColor = getDomainMarketColor();
const marketLogo = getDomainLogo();

function Dialog({ showDialog, setShowDialog, type, value, orderNumber, increaseIndex}) {
  if (!showDialog) {
    return null;
  }

  function handleClick() {
    increaseIndex && increaseIndex();
    setShowDialog(false);
  }  

  switch (type) {
    case "approved":
      return (
        <div className="dialog-overlay">
          <div
            className="dialog-card-variant"
            style={{ borderTop: `10px solid ${marketColor}` }}
          >
            <CheckCircleIcon style={{ color: marketColor }} />
            <h5>Acréscimo aprovado</h5>
            <small>
              O acréscimo de <strong>R$ {value}</strong> ao seu pedido{" "}
              <strong>
                Nº
                {orderNumber}
              </strong>{" "}
              foi aprovado.
            </small>
            <Button
              style={{ background: marketColor }}
              onClick={() => handleClick()}
            >
              OK
            </Button>
          </div>
        </div>
      );
    case "disapproved":
      return (
        <div className="dialog-overlay">
          <div
            className="dialog-card-variant"
            style={{ borderTop: `10px solid ${marketColor}` }}
          >
            <WarningIcon style={{color: marketColor}}/>
            <h5>Acréscimo rejeitado</h5>
            <small>
              O acréscimo de <strong>R$ {value}</strong> ao seu pedido{" "}
              <strong>
                Nº
                {orderNumber}
              </strong>{" "}
              foi rejeitado.
            </small>
            <Button
              style={{ background: marketColor }}
              onClick={() => handleClick()}
            >
              OK
            </Button>
          </div>
        </div>
      );
    default:
      return (
        <div className="dialog-overlay">
          <div
            className="dialog-card"
            style={{ borderTop: `10px solid ${marketColor}` }}
          >
            <img alt="main image" src={marketLogo} />
            <small>
              A reserva de valor trata-se de um valor extra no cartão de crédito para cobrir mudanças no preço final do pedido, especialmente com produtos por peso ou substituições. O valor cobrado será ajustado de acordo com total do pedido após a separação dos itens. O valor a ser debitado no cartão não excederá ao valor reservado.
            </small>
            <Button
              style={{ background: marketColor }}
              onClick={() => setShowDialog(false)}
            >
              OK
            </Button>
          </div>
        </div>
      );
  }
}

export default Dialog;
