import React, { useEffect, useState } from "react";
import { Button, Card } from "@material-ui/core";
import { getDomainMarketColor } from "config";
import { getAllyHigherValueCaptureRequests, sendAllyHigherValueCaptureStatus } from "helpers/apiHelper";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Dialog from "../../Dialog/Dialog";

import "./AdditionApprovalBox.scss";

function AdditionApprovalBox({ orderID }) {
  const market_id = localStorage.getItem("market_id");
  const [orderPendingApproval, setOrderPendingApproval] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);  

  async function getOrderPendingApprovals() {
    try {
      const response = await getAllyHigherValueCaptureRequests(
        market_id,
        orderID
      );
      setOrderPendingApproval(response);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOrderPendingApprovals();
  }, [orderID]);

  function handleApprovalStatus(status) {
    try {
      sendAllyHigherValueCaptureStatus(market_id, status);
      setDialogType(status);
      setShowDialog(true);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (orderPendingApproval?.status === "pending") {
      getOrderPendingApprovals();
    }
  }, [orderPendingApproval?.status]);

  switch (orderPendingApproval?.status) {
    case "pending":
      return (
        <Card className="addition-approval-box">
          <Dialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            type={dialogType}
            value={orderPendingApproval.value_requested}
            orderNumber={orderPendingApproval.order_number}
          />
          <div className="addition-approval-box-content">
            <h4>Aprovação de acréscimo</h4>
            <p>Realize a aprovação para confirmar a alteração em seu pedido.</p>
            <div className="addition-approval-box-message">
              Aprovar acréscimo de R$ {orderPendingApproval.value_requested} ao
              pedido
            </div>
          </div>
          <div className="addition-approval-box-actions">
            <Button
              onClick={() => handleApprovalStatus("disapproved")}
              variant="outlined"
              style={{
                color: getDomainMarketColor(),
                border: `2px solid ${getDomainMarketColor()}`,
                marginRight: "10px",
              }}
            >
              Rejeitar
            </Button>
            <Button
              onClick={() => handleApprovalStatus("approved")}
              variant="contained"
              style={{ backgroundColor: getDomainMarketColor(), color: "#fff" }}
            >
              Aprovar
            </Button>
          </div>
        </Card>
      );
    case "approved":
      return (
        <div className="addition-approval-box-approved">
          <CheckCircleIcon style={{ color: getDomainMarketColor() }} />
          <div>
            <strong>Acréscimo aprovado:</strong>
            <span>
              Limite total de acréscimo aprovado ao valor do pedido:{" "}
              <strong>R$ {orderPendingApproval.value_requested}</strong>
            </span>
          </div>
        </div>
      );
    case "disapproved":
      return (
        <div className="addition-approval-box-disapproved">
          <CancelIcon /> Acréscimo ao pedido não realizado
        </div>
      );
    default:
      return null;
  }
}

export default AdditionApprovalBox;
