import React from "react";

import { ModalBody, Modal, ModalHeader } from "reactstrap";
import LoadingContent from "components/LoadingContent";
import MapProducts from "components/MapProducts";

import campaign from "../../../assets/img/icons/campaign.png";
import "./BannerProductsModal.scss";
import { BANNER } from "OrderItemOrigins";

function BannerProductsModal({ isOpen, toggleModal, banner }) {
  const closeModal = () => {
    toggleModal(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      style={{ maxWidth: "85vw", minHeight: "70vh" }}
    >
      <ModalHeader className="list-header" toggle={() => closeModal()}>
        {banner ? banner.name : ""}
      </ModalHeader>
      <ModalBody
        style={{ minHeight: "50vh", maxHeight: "85vh", overflow: "scroll" }}
      >
        {banner && (
          <div>
            <div
              style={{ width: "100%", borderRadius: 10, position: "relative" }}
            >
              <img
                src={banner.image}
                alt={banner.name}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  objectFit: "contain",
                }}
              />
              {banner.is_publicity && (
                <p className="has-publicity">
                  <img alt="" src={campaign} /> Contém publicidade
                </p>
              )}
            </div>
            <div className="banner-products">
              {banner.products ? (
                banner.products.length <= 0 ? (
                  <span>Este banner não possui produtos ativos.</span>
                ) : (
                  banner.products.map((product, key) => (
                    <MapProducts
                      origin={BANNER}
                      key={key}
                      product={product.items}
                    />
                  ))
                )
              ) : (
                <LoadingContent isLoading={true} />
              )}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

export default React.memo(BannerProductsModal);
