import React from "react";
import { domainColorStyle } from "config";

import { Dialog, DialogContent } from "@material-ui/core";

function ModalDefault({ isOpen, toggleModal, size, className, children }) {
  return (
    <Dialog
      open={isOpen}
      onClose={toggleModal}
      fullWidth
      maxWidth={size || "md"}
      className={className || ""}
      style={{ borderRadius: "15px" }}
    >
      <div style={{ height: "10px", ...domainColorStyle("backgroundColor") }} />
      <DialogContent style={{ padding: "1rem" }}>{children}</DialogContent>
    </Dialog>
  );
}

export default ModalDefault;
