import React, { useState, useEffect } from "react";
import { domainColorStyle } from "config";
import { getItemsByPromotionId } from "helpers/apis/itemApi";
import { getDomainMarketId } from "config";
import { ToDecimal } from "helpers/currency";
import { applyProductFunctions } from "helpers/models/product";
import "./CardOfferTakeMore.scss";
function CardOfferTakeMore({ product, addToCart }) {
  const [productSecondary, setProductSecondary] = useState(null);

  useEffect(() => {
    const marketId = getDomainMarketId();
    getItemsByPromotionId(marketId, product.promotion_id, product.id).then(
      (items) => {
        items = applyProductFunctions(items);
        setProductSecondary(items[0]);
      }
    );
  }, [product.promotion_id, product.id]);

  function addItemsToCart() {
    for (let i = 0; i < product.promotion_discount_table[0].amount; i++) {
      addToCart(product);
    }
    addToCart(productSecondary);
  }
  return (
    productSecondary && (
      <div>
        <div>
          <div className="cardTakeMore">
            <div className="container-products">
              <div className="product">
                <img alt="Primeiro produto" src={product.image}></img>
                <div style={{ width: "120px" }}>
                  <div className="product-description">
                    {product.description}
                  </div>
                  <div
                    style={{
                      ...domainColorStyle("color"),
                      fontWeight: 800,
                      fontSize: 16,
                    }}
                  >
                    <strong>
                      {product.promotion_discount_table[0].amount} unidades
                    </strong>
                  </div>
                </div>
              </div>

              <h2>+</h2>

              <div className="product">
                <img alt="Segundo produto" src={productSecondary.image} />
                <div style={{ width: "120px" }}>
                  <div className="product-description">
                    {productSecondary.description}
                  </div>
                  <div
                    style={{
                      ...domainColorStyle("color"),
                      fontWeight: 800,
                      fontSize: 16,
                    }}
                  >
                    <strong>
                      por R${" "}
                      {ToDecimal(product.promotion_discount_table[0].weight)}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <button
              style={{
                ...domainColorStyle("backgroundColor"),
              }}
              onClick={() => {
                addItemsToCart();
              }}
            >
              <i className="fa fa-shopping-cart text-white" />
              {"  "}Adicionar produtos
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default CardOfferTakeMore;
