import { SET_DELIVERY_METHOD } from "ActionTypes";
import { applyDeliveryMethodFunctions } from "helpers/models/deliveryMethod";

const initialState = {
  deliveryMethod: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DELIVERY_METHOD:
      return {
        deliveryMethod: applyDeliveryMethodFunctions([action.deliveryMethod])[0],
      };
    default:
      return state;
  }
}
