import { Box, Button, Card, Container, Grid, Paper } from "@material-ui/core";
import React from "react";
import AddRemoveComponent from "new/components/AddRemove/AddRemove";
import numberToCurrency from "helpers/currency";
import { domainColorStyle } from "config";
import CancelIcon from '@material-ui/icons/Cancel';

const OrderItemsTable = ({
  orderItems = [],
  updateProductAmount = () => {},
  removeEntireProduct = () => {},
}) => {
  return (
    <Card className="mt-3 items-details">
      <Box display="flex" flexDirection="column" className="order-items-table">
        <Container component={Paper} className="table-items">
          <Box>
            <Box
              px={3}
              py={1}
              display={"flex"}
              justifyContent="space-between"
              className="table-head"
            >
              <p className="description" style={{ width: "30%" }}>
                Produto
              </p>
              <p
                className="description"
                style={{ width: "15%", textAlign: "center" }}
              >
                Preço
              </p>
              <p className="description" style={{ textAlign: "center" }}>
                Quantidade
              </p>
              <p
                className="description"
                style={{ width: "10%", textAlign: "center" }}
              >
                Total
              </p>
              <p className="description" style={{ width: "10%" }}></p>
            </Box>
            <div className="products">
              {orderItems.map((item) => (
                <Box
                  px={3}
                  py={1}
                  display={"flex"}
                  justifyContent="space-between"
                  flexWrap={"wrap"}
                  key={item}
                >
                  <div
                    className="table-cell description"
                    style={{ width: "30%" }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      className="product-description"
                    >
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <img
                          alt={item.description}
                          className="chart-product-image"
                          src={item.image}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={9}
                        lg={9}
                        className="product-decription"
                      >
                        <label>{item.description}</label>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="table-cell price">
                    {item.price < item.original_price ? (
                      <>
                        <label className="original-price">
                          De:<del>{numberToCurrency(item.original_price)}</del>
                        </label>
                        <label>Por: {numberToCurrency(item.price)}</label>
                      </>
                    ) : (
                      <label>{numberToCurrency(item.price)}</label>
                    )}
                  </div>
                  <div className="table-cell" style={{ padding: "0 10px" }}>
                    <AddRemoveComponent
                      product={item}
                      amount={item.amount}
                      remove={() => updateProductAmount(item.item_id)}
                      add={() => updateProductAmount(item.item_id, true)}
                    />
                  </div>
                  <div className="table-cell total-price">
                    {numberToCurrency(item.total_price)}
                  </div>
                  <div className="table-cell total-price">
                    <Button onClick={() => removeEntireProduct(item.item_id)}>
                      <CancelIcon style={{ ...domainColorStyle("color") }} />
                    </Button>
                  </div>
                </Box>
              ))}
            </div>
          </Box>
        </Container>
      </Box>
    </Card>
  );
};

export default OrderItemsTable;
