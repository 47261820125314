import { Box, Button, Grid, Typography } from "@material-ui/core";
import InputCustom from "components/InputCustom";
import LoadingContent from "components/Loading/Loading";
import Product from "components/NewProductCard/Product";
import { search } from "helpers/apis/itemApi";
import { applyProductFunctions } from "helpers/models/product";
import { extractItemsFromMixes } from "helpers/storeHelper";
import { notification } from "helpers/util";
import { arrayHasContent } from "helpers/util";
import { debounce } from "lodash";
import Modal from "new/components/Modal/Modal";
import React, { useEffect, useState } from "react";
import "./styles.scss"

const SearchProductsModal = ({
  isOpen = false,
  setIsOpen = () => {},
  orderNumber,
  marketId,
  updateProductAmount = () => {},
}) => {
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchProduct = debounce(async () => {
    setIsLoading(true);
    try {
      const response = await search(searchQuery, marketId, 1);
      const items = extractItemsFromMixes(response);
      setSearchProducts(applyProductFunctions(items));
    } catch (error) {
      console.log(error);
      notification("Erro ao pesquisar produtos", "error");
    } finally {
      setIsLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      handleSearchProduct();
    } else {
      setSearchProducts([]);
    }
  }, [searchQuery]);

  const handleConclude = () => {
    setIsOpen(false);
    setSearchQuery("");
    setSearchProducts([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={() => setIsOpen(false)}
      className="modal-add-product"
    >
      <Grid className="list-header">
        <h5>Pedido Nº {orderNumber}</h5>
      </Grid>
      <Grid className="modal-body-add-product">
        <Box className="search-bar-add-product">
          <InputCustom
            onChange={({ target }) => setSearchQuery(target.value)}
            showLabel={false}
            placeholder="Pesquise seus produtos aqui!"
            icon="fa fa-search"
          />
        </Box>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <Grid container spacing={1}>
            {arrayHasContent(searchProducts) ? (
              <Grid container item xs={12} spacing={3}>
                {searchProducts.map((item) => (
                  <Product
                    key={item.id}
                    currentCategory={item.category_id}
                    products={item}
                    product={item}
                    updateAmountProduct={updateProductAmount}
                  />
                ))}
              </Grid>
            ) : (
              <Typography variant="subtitle1">Nenhum produto encontrado.</Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Box p={3} className="button-modal-add-product">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConclude()}
        >
          Concluir
        </Button>
      </Box>
    </Modal>
  );
};

export default SearchProductsModal;
