import React from "react";
import Modal from "new/components/Modal/Modal";
import { useHistory } from "react-router";
import { getDomainMarketColor } from "config";
import "./CashbackCouponModal.scss";
import { clearCart } from "actions/cartActions";
import { useDispatch } from "react-redux";
import { Box, Button, Card, IconButton, Icon } from "@material-ui/core";
import { domainColorStyle } from "config";

const CashbackCouponModal = ({ isOpen, setIsOpen, couponCode }) => {
  const history = useHistory();
  const mainColor = getDomainMarketColor();
  const dispatch = useDispatch();

  const closeModal = () => setIsOpen(false);

  const navigateToCoupons = () => {
    dispatch(clearCart());
    history.push("/user/cupons");
  };

  return (
    <Modal isOpen={isOpen} toggleModal={closeModal}>
      <Box className="cashback-coupon-modal">
        <IconButton className="close-cashback-modal-btn" onClick={closeModal}>
          <Icon>close</Icon>
        </IconButton>
        <h4 className="cashback-modal-title" style={{ ...domainColorStyle("color") }}>
          Parabéns!
        </h4>
        <p>Seu pedido gerou um cupom para suas próximas compras.</p>
        <Card className="cashback-instruction">
          <Box
            className="cashback-coupon-code"
            sx={{
              backgroundColor: `${mainColor}2d`,
              color: mainColor,
            }}
          >
            {couponCode}
          </Box>
          <Box>
            <ol>
              <li>
                O cupom será ativado somente depois do pedido ser entregue.{" "}
              </li>
              <li>Esse código é único e intransferível.</li>
              <li>
                Você pode usar esse cupom ao fazer suas próximas compras em
                nosso ecommerce ou loja física.
              </li>
              <li>Para saber mais acesse a central de cupons. </li>
            </ol>
          </Box>
        </Card>
        <Button
          className="coupons-navigate-btn"
          variant="contained"
          color="primary"
          onClick={navigateToCoupons}
        >
          Ir para central de cupons
        </Button>
      </Box>
    </Modal>
  );
};

export default CashbackCouponModal;
