import { Box, Chip } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import "./SectionsMenu.scss";
import { useDispatch } from "react-redux";
import { changeSelectedCategory } from "actions/categoriesActions";
import { sendCategoryEvent } from "helpers/apis/impulseEventsApiHelper";

const SectionsMenu = ({ sections = [] }) => {
  const history = useHistory();
  const { market_id } = useParams();
  const dispatch = useDispatch()

  const navigateToCategory = (section) => {
    dispatch(changeSelectedCategory(section))
    sendCategoryEvent([[section]]);
    history.push(`/loja/${market_id}/categoria/${section.id}`);
  };

  return sections.length > 0 ? (
    <Box className="sections-menu">
      {sections.map((section) => (
        <Chip
          label={section.display_name}
          color="primary"
          onClick={() => navigateToCategory(section)}
        />
      ))}
    </Box>
  ) : null;
};

export default SectionsMenu;
