import React from "react"
import {
    Button,
    Container,
    Row,
    Form,
    Col
} from "reactstrap"
import { showNotification } from "../helpers/util"
import { setOrderId } from "actions/clearsaleActions"
import Input from "./InputCustom"
import LoadingContent from "./LoadingContent"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import loadsContent from "./loadsContent"
import { domainColorStyle } from "config";
import { cancelOrder } from "helpers/apiHelper"

class OrderCancelationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {}
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined
        this.setState({
            [name]: value,
            errors: errors
        })
    }

    onSubmit(event) {
        event.preventDefault()
        const { reason } = this.state
        let {  order_id: orderId,orderIdSelect } = this.props
        if(orderIdSelect){
            orderId = orderIdSelect;
        }
        this.props.toggleLoading()
        cancelOrder(orderId, reason, (error, data) => {
            this.props.toggleLoading()
            if (error) {
                showNotification("top", "center", "Ocorreram erros ao cancelar seu pedido, por favor tente novamente.", "danger")
            } else {
                this.props.setOrderId(null)
                showNotification("top", "center", "Seu pedido de cancelamento foi efetuado com sucesso.", "success")
                this.props.toggleModal()
                this.props.onFormSubmit()
            }
        })
    }

    render() {
        const { isLoading } = this.props
        return (
            <>
                <Form onSubmit={this.onSubmit}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Input
                                    required
                                    disabled={isLoading}
                                    error={this.state.errors.reason}
                                    placeholder="Qual o motivo do cancelamento?"
                                    name="reason"
                                    onChange={this.handleChange}
                                    value={this.state.reason}
                                />
                            </Col>
                        </Row>

                        <Row className="justify-content-center mt-3">
                            <Col xs={isLoading ? 12 : 5} md={isLoading ? 4 : 3} className="p-0">
                                <LoadingContent isLoading={isLoading}>
                                    <Button type="submit" className="btn-icon block btn-3" outline style={{
                                        ...domainColorStyle("color"),
                                        ...domainColorStyle("borderColor")
                                    }}>
                                        <span className="btn-inner--text">Confirmar</span>
                                    </Button>
                                </LoadingContent>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        order_id: state.clearsale.order_id,
        phone_number: state.userInfo.phone_number,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setOrderId
    }, dispatch)
}
OrderCancelationForm = loadsContent(OrderCancelationForm)
OrderCancelationForm = connect(mapStateToProps, mapDispatchToProps)(OrderCancelationForm)
export default OrderCancelationForm