import { Box, Typography } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import "./styles.scss";

const Warning = ({ show, ...props }) => {
  return Boolean(show) ? (
    <Box {...props?.sx} className="withhold-revenue-warning">
      <InfoIcon />
      <Typography variant="body2">
        Sua cesta possui medicamento que exige a retenção de receita física e de
        apresentação de documento de identificação original com foto para que a
        compra possa ser concluída durante a retirada em loja.
      </Typography>
    </Box>
  ) : null;
};

export default Warning;
