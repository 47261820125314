import config from "config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function getChat(player_id, name, phone_number, market_id) {
  try {
    const data = {
      player_id,
      name,
      phone_number,
      market_id,
    };
    const chat = await oAuthRequest.post(`${config.mappURLV2}/chats`, data);
    return chat.data;
  } catch (error) {
    console.log("Brand error");
  }
}
export async function getChatOrder(order_id) {
  try {
    const data = {
      order_id,
    };
    const chat = await oAuthRequest.post(
      `${config.mappURLV2}/chats/create_order_chat`,
      data
    );
    return chat.data;
  } catch (error) {
    console.log("Brand error");
  }
}

export async function sendNewMessage(chat_id, message) {
  try {
    const data = {
      text: message,
    };
    const chat = await oAuthRequest.post(
      `${config.mappURLV2}/chats/${chat_id}/send_message`,
      data
    );
    return chat.data;
  } catch (error) {
    console.log("Brand error");
  }
}
