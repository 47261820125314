import React, { useCallback, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import logoLinxMercadapp from "../../../assets/img/LogoLinxMercadapp.png"
import "animate.css";
import { maskCNPJ } from "helpers/util";
import { getDomainName } from "config";
import "./MainFooter.scss";

import AdaptiveFooter from "./components/AdaptiveFooter/AdaptiveFooter";
import { useDispatch, useSelector } from "react-redux";
import { getDomainMarketId } from "config";
import { getMarketById } from "helpers/apis/marketInfo";
import { useParams } from "react-router-dom";
import { setMarket } from "actions/setZoneForRequestedCep";
import { getDomainMarketColor } from "config";
import { isAlcoholRestricted } from "config";


function MainFooter() {
  const currentMarket = useSelector(({ markets }) => markets);
  const brandMarkets = useSelector(({ brand }) => brand.brandMarkets)
  const { market_id } = useParams()
  const dispatch = useDispatch()
  const domainColor = getDomainMarketColor()
  const hasAlcoholicItemsSales = !isAlcoholRestricted()

  const getCurrentMarket = useCallback(async () => {
    const marketId = market_id || getDomainMarketId() || brandMarkets[0].id;
    const response = await getMarketById(marketId);
    dispatch(setMarket(response))
  }, []);

  useEffect(() => {
    if (!currentMarket?.id) getCurrentMarket()
  }, []);

  return (
    <footer backgroundColor="#F8F8F8">
      <div className='footer-main'>
        <Box className="footer-stock-message" sx={{ backgroundColor: domainColor }}>
          Produtos disponíveis enquanto durar o estoque
        </Box>
        <AdaptiveFooter infoMarket={currentMarket}/>
      </div>
      <hr style={{margin: "1em 0"}}/>
      <Box className="storage-main-footer">
        <Box className="text-info-footer">
          {hasAlcoholicItemsSales && <span>PROIBIDA VENDA DE BEBIDA ALCOÓLICA A MENOR DE 18 ANOS (Lei 8.069/1990, art. 243)</span>}
          {currentMarket.corporate_name || getDomainName()}
          {currentMarket.cnpj && " | CNPJ: " + maskCNPJ(currentMarket.cnpj) + " | "} - Todos os direitos reservados.
        </Box>
        <Box className="logo-mercadapp-footer" >
          <span>Feito por</span> <img src={logoLinxMercadapp} alt="mercadapp" />
        </Box>
      </Box>
    </footer>
  )
}

export default MainFooter;