import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { getDomainMarketColor } from "config";
import numberToCurrency from "helpers/currency";

import "./Cupom.scss";
import { showNotification } from "helpers/util";
import { Button, Card, Icon, Box, Typography } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { timeZoneAdjustedMoment } from "helpers/util";

function Cupom({ cupom = {}, discounts = [], setShowModal, getApiDiscounts }) {
  const [showDetails, setShowDetails] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const mainColor = getDomainMarketColor();
  const checkoutOrigin = Boolean(getApiDiscounts);

  const handleCopy = () => {
    if (getApiDiscounts) {
      getApiDiscounts(cupom.code);
    } else {
      setIsCopied((prev) => !prev);
    }
    if (setShowModal) setShowModal(false);
  };

  const isApplied = discounts.find((item) => item.id === cupom.id);
  const isCouponSelected = checkoutOrigin ? isApplied : isCopied;

  const formatCurrency = (value) => {
    return numberToCurrency(parseFloat(value));
  };

  useEffect(() => {
    if (isCopied) {
      showNotification(
        "top",
        "center",
        `Código ${cupom.code} copiado com sucesso!`,
        "success"
      );
    }
  }, [isCopied]);

  const CouponDetails = ({ coupon }) => {
    return (
      <Box className="coupon-details">
        <b>Regras:</b>
        <ul>
          <li>
            Validade:{" "}
            {timeZoneAdjustedMoment(coupon.end_date).format("DD/MM/YYYY")}
          </li>
          {coupon.max_per_customer_unlimited ? (
            <li>Uso ilimitado</li>
          ) : (
            <li>
              Limite de uso: {coupon.max_per_customer}{" "}
              {coupon.max_per_customer > 1 ? "vezes" : "vez"} por usuário
            </li>
          )}
          <li>
            {coupon.is_cumulative
              ? "Cumulativo com outros cupons e descontos"
              : "Não cumulativo com outros cupons e descontos"}
          </li>
          <li>
            Habilitado para sua próxima compra no ecommerce ou loja física
          </li>
          <li>É permitido fazer apenas um resgate por pedido</li>
          <li>Código é único, pessoal e intransferível</li>
          <li>O bônus não poderá ser convertido em dinheiro</li>
          <li>Não será aplicado desconto sobre o valor de taxas e fretes</li>
          <li>
            Esse código é de uso único. Caso o valor total do bônus não for
            utilizado em seu pedido, o código será inativado e qualquer saldo
            restante será perdido
          </li>
        </ul>
      </Box>
    );
  };

  return (
    <Card className="coupon-card">
      <Box className="coupon-top-box" sx={{ color: mainColor }}>
        <Icon>confirmation_number</Icon>
        <span>{formatCurrency(cupom.discount_value)} de desconto</span>
      </Box>

      <Box className="coupon-infos">
        <b>{cupom.title}</b>
        <span>{cupom.text}</span>
      </Box>

      <CopyToClipboard text={cupom.code} onCopy={handleCopy}>
        <Button
          variant="outlined"
          color={isCouponSelected ? "primary" : ""}
          className="copy-code-btn"
        >
          {cupom.code}
          <Icon
            style={{ color: isCouponSelected ? mainColor : "#ccc" }}
            fontSize="small"
          >
            {isCouponSelected ? "check_circle" : "panorama_fish_eye"}
          </Icon>
        </Button>
      </CopyToClipboard>
      <Box className="coupon-min-purchase">
        <span>
          Válido para pedidos acima de{" "}
          {formatCurrency(cupom.min_purchase_value)}
        </span>
        <span>(sem considerar taxas e fretes)</span>
      </Box>
      {showDetails ? <CouponDetails coupon={cupom} /> : null}
      <Button
        className="show-details-btn"
        endIcon={
          <Icon>
            {showDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </Icon>
        }
        onClick={() => setShowDetails((prev) => !prev)}
        color="primary"
      >
        {showDetails ? "Ocultar" : "Ver detalhes"}
      </Button>
    </Card>
  );
}

export default Cupom;
