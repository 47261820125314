import React, { Component } from "react"
import { Container } from "reactstrap"
import { domainColorStyle } from "config"

export default class LoadingContent extends Component {
    render() {
        return this.props.isLoading ? (
            <Container className="py-5 text-center">
                <div className="spinner">
                    <div style={domainColorStyle("background")} className="bounce1"></div>
                    <div style={domainColorStyle("background")} className="bounce2"></div>
                    <div style={domainColorStyle("background")} className="bounce3"></div>
                </div>
                <h6>Carregando...</h6>
            </Container>
        ) : (
                this.props.children || null
            )
    }
}

