import firebase from "firebase/app";
import { getPhonePrefix } from "config";

export const getAuthMethods = (brand) => {
  const authMethodMap = {
    SMS: () => ({
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      fullLabel: 'Fazer login com telefone',
      recaptchaParameters: {
        type: "image",
        size: "invisible",
        badge: "bottomleft",
      },
      defaultCountry: "BR",
      defaultNationalNumber: `${getPhonePrefix()}`,
    }),
    Website: () => ({
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      fullLabel: 'Fazer login com e-mail',
    }),
    Facebook: () => ({
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Fazer login com Facebook',
    }),
    Google: () => ({
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Fazer login com Google',
    }),
    Apple: () => ({
      provider: new firebase.auth.OAuthProvider('apple.com').providerId,
      fullLabel: 'Fazer login com Apple',
    }),
  };

  return (brand.brand_authentication_methods || [])
    .filter(({ is_active }) => is_active)
    .map(({ name }) => authMethodMap[name]?.())
    .filter(Boolean);
};


export const getGoogleAuthenticationMethod = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    console.error(error);
  }
}

export const getAppleAuthenticationMethod = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    console.log(error);
  }
};

export const getEmailAuthenticationMethod = async (email, password) => {
  try {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    return response
  } catch (error) {
    console.log(error)
    return error
  }
};

export const createAccountWithEmailAndPassword = async (email, password, name) => {
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await userCredential.user.updateProfile({
      displayName: name
    })
    return userCredential.user;
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getPhoneAuthenticationMethod = async (phone, appVerifier) => {
  try {
    const response = await firebase.auth().signInWithPhoneNumber(phone, appVerifier)
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getPhoneAuthenticationCredential = async (appVerifier, code) => {
  try {
    const response = firebase.auth.PhoneAuthProvider.credential(appVerifier, code)
    const success = await firebase.auth().signInWithCredential(response)
    return success
  } catch (error) {
    console.log(error)
    return error
  }
}