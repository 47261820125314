import { SELECTED_SUBCATEGORY_CHANGED } from "ActionTypes";
import { SELECTED_FILTER_CHANGED } from "ActionTypes";
import { SUBCATEGORIES_CHANGED } from "ActionTypes";
import { CATEGORIES_CHANGED } from "ActionTypes";
import { SELECTED_CATEGORY_CHANGED } from "ActionTypes";

const initialState = {
  selectedCategory: undefined,
  selectedSubcategory: undefined,
  selectedFilter: undefined,
  categories: [],
  subcategories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_CHANGED:
      return Object.assign({}, state, {
        categories: action.payload,
      });
    case SUBCATEGORIES_CHANGED:
      return Object.assign({}, state, {
        subcategories: action.payload,
      });
    case SELECTED_CATEGORY_CHANGED:
      return {
        ...state,
        selectedCategory: action.payload,
        selectedSubcategory: undefined,
        subcategories: undefined,
      };
    case SELECTED_SUBCATEGORY_CHANGED:
      return { ...state, selectedSubcategory: action.payload };
    case SELECTED_FILTER_CHANGED:
      return { ...state, selectedFilter: action.payload };
    default:
      return state;
  }
}
