import React, { useEffect, useState } from "react";

import { ModalBody, Modal, ModalHeader } from "reactstrap";
import { Button } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";

import { notification } from "helpers/util";
import { sendDataMail } from "helpers/apis/oAuthApi";

import "./PrivacyOptions.scss";
import { domainColorStyle } from "config";
import { useSelector } from "react-redux";
import { getMarketAppInfos } from "helpers/apiHelper";

function PrivacyModal({ userId, isOpen, toggleModal }) {
  const [termsOfUse, setTermsOfUse] = useState("");
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const currentMarket = useSelector(({ markets }) => markets)
  const brand = useSelector(({ brand }) => brand.brand)

  useEffect(() => {
    getTermsOfUse(currentMarket.id || brand.main_market_id);
  }, [userId]);

  const getTermsOfUse = async (marketID) => {
    const { market_app_infos } = await getMarketAppInfos(marketID)
    const { text } = market_app_infos.find((info) => info.title === "Termos") || ""
    setTermsOfUse(text)
  };

  const getData = () => {
    confirmAlert({
      title: "Solicitar dados pessoais.",
      message:
        "Ao clicar em confirmar, você receberá um email contendo todas as informações que possuímos sobre você.",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            sendMail();
          },
        },
        { label: "Cancelar" },
      ],
    });
  };

  const sendMail = async () => {
    try {
      await sendDataMail(userId);
      confirmAlert({
        title: "Email enviado com sucesso",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    } catch {
      notification("Não foi possivel enviar seu email", "danger");
    }
  };

  const closeModal = () => {
    toggleModal();
    setShowTermsOfUse(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="modal-dialog-centered modal-privacy"
    >
      <ModalHeader
        className="list-header"
        toggle={closeModal}
      >
        Privacidade
      </ModalHeader>
      <ModalBody style={{ maxHeight: "70vh", overflow: "scroll" }}>
        {showTermsOfUse ? (
          <>
            <Button
              onClick={() => {
                setShowTermsOfUse(false);
              }}
              className="btn button-privacy"
              style={{ ...domainColorStyle("color"), marginTop: 0 }}
            >
              voltar
            </Button>
            <p>{termsOfUse}</p>
          </>
        ) : (
          <>
            {termsOfUse &&
              <Button
                onClick={() => setShowTermsOfUse(true)}
                style={domainColorStyle("backgroundColor")}
                className="btn btn-block"
              >
                Política de privacidade
              </Button>}
            <Button
              onClick={() => getData()}
              style={domainColorStyle("backgroundColor")}
              className="btn btn-block"
            >
              Solicitar dados para portabilidade
            </Button>
            <Button
              onClick={() =>
                window.open(
                  "https://conteudo.mercadapp.com/exercicio-de-direitos-dos-usuarios"
                )
              }
              style={domainColorStyle("backgroundColor")}
              className="btn btn-block"
            >
              Apagar dados pessoais
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default PrivacyModal;
