import { ToDecimal } from "helpers/currency";
import { domainColorStyle } from "config";
import React from "react";
import "./ProductCard.scss";

export default function ProductPrice({ price }) {
  return (
    <p className="current-price-product" style={domainColorStyle("color")}>
      <span>R$</span>
      {ToDecimal(price)}
    </p>
  );
}
