import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "./ArrowButton.scss";
import { getDomainMarketColor } from "config";

function ArrowButton({ direction, show, onClick }) {
  const marketColor = getDomainMarketColor()
  const style = {
    backgroundColor: marketColor,
    display: show ? "inline" : "none"
  }
  return (
    <button className={`${direction}-arrow`} style={style} onClick={onClick}>
      {direction === "back" ? (
        <ArrowBackIosIcon style={{ fontSize: "1rem", marginLeft: "5px" }} />
      ) : (
        <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
      )}
    </button>
  );
}

export default ArrowButton;
