import React from "react";
import {Container} from "reactstrap";
import {domainColorStyle} from "config";

export default function LoadingContent() {
  return (
    <Container style={{marginTop: 100}} className="py-5 text-center">
      <div className="spinner">
        <div style={domainColorStyle("background")} className="bounce1"></div>
        <div style={domainColorStyle("background")} className="bounce2"></div>
        <div style={domainColorStyle("background")} className="bounce3"></div>
      </div>
      <h6>Carregando...</h6>
    </Container>
  );
}
