import React, { Component } from "react";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
import { PopoverBody, Popover } from "reactstrap";
import InputMask from "react-input-mask";
import { domainColorStyle } from "config";
class InputMaskCustom extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      popoverReady: true,
    });
  }

  render() {
    const {
      placeholder,
      onFocus,
      style,
      autoComplete,
      inputMode,
      showLabel,
      pattern,
      value,
      name,
      onChange,
      required,
      defaultValue,
      type,
      icon,
      disabled,
      className,
      error,
      mask,
    } = this.props;
    const { popoverReady } = this.state;
    return (
      <>
        {(showLabel === undefined || showLabel) && (
          <Label
            style={domainColorStyle("color")}
            className="form-control-label font-weight-bold"
            for={name}
          >
            {placeholder}
          </Label>
        )}
        <InputGroup className="input-group-alternative mt-1" style={style}>
          {icon && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i style={domainColorStyle("color")} className={icon} />
              </InputGroupText>
            </InputGroupAddon>
          )}

          <Input
            inputMode={inputMode}
            id={name}
            onFocus={onFocus}
            autoComplete={autoComplete}
            pattern={pattern}
            className={(error ? "is-invalid" : "") + " " + className}
            disabled={disabled || false}
            defaultValue={defaultValue}
            value={value}
            mask={mask}
            placeholder={placeholder}
            type={type || "text"}
            onChange={onChange}
            name={name}
            required={required || false}
            tag={InputMask}
          />
          {popoverReady === true && (
            <Popover
              placement="bottom"
              isOpen={error !== undefined}
              target={name}
              className="popover-danger"
            >
              <PopoverBody>{error}</PopoverBody>
            </Popover>
          )}
        </InputGroup>
      </>
    );
  }
}

export default InputMaskCustom;
