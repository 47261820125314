import React from "react";
import { domainColorStyle, getDomainLogo } from "config";
import { ModalBody, Modal } from "reactstrap";
import "./AppNotices.scss";

function AppNotices({
  isOpen,
  toggleModal,
  notice = {},
  title,
  logo,
  isDocument,
  textButton = "Continuar",
  orderModals
}) {

  const zIndexValue = orderModals ? 1080 : 1050 
  
  return (
    <Modal
      zIndex={zIndexValue}
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered modal-app-notices"
      style={
        isDocument
          ? { borderRadius: "15px", maxWidth: "80%" }
          : { borderRadius: "15px" }
      }
    >
      <div
        style={{
          height: "12px",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          ...domainColorStyle("backgroundColor"),
        }}
      ></div>

      <ModalBody>
        {title ? (
          <div
            styles={{ ...domainColorStyle("color") }}
            className="app-notices-title"
          >
            {title}
          </div>
        ) : (
          <div />
        )}
        {logo ? (
          <div className="app-notices-logo">
            <img alt="Logo" src={getDomainLogo()} className="notices-logo-img" />
          </div>
        ) : (
          <div />
        )}
        {isDocument ? (
          <div
            style={{
              overflow: "scroll",
              maxHeight: "60vh",
              whiteSpace: "pre-wrap",
            }}
            dangerouslySetInnerHTML={{ __html: notice.description }}
          />
        ) : (
          <div className="app-notices-description" >
            {notice.description}
          </div>
        )}
        {notice.list && (
          <div style={{ marginBottom: "30px" }} >
            {notice.list.map((item, index) => (
              <div className="app-notices-list" >
                <div className="notices-list-index" >
                  {index + 1}
                </div>
                {item}
              </div>
            ))}
          </div>
        )}
        {notice.image_url ? (
          <div className="app-notices-image">
            <img
              className="notices-image"
              alt="Noticia"
              src={notice.image_url}
            />
          </div>
        ) : (
          <div />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="exitButton"
            style={{ ...domainColorStyle("backgroundColor") }}
            onClick={toggleModal}
          >
            {textButton}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AppNotices;
