import React, { useState } from 'react'
import { Box, Button, Card, Grid, Input, TextField } from '@material-ui/core';
import Modal from 'new/components/Modal/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Cupom from 'components/UserPage/Cupom/Cupom';
import { domainColorStyle } from 'config';

import './OrderCoupon.scss'
import { getDomainMarketColor } from 'config';
import { useSelector } from 'react-redux';
import { showNotification } from 'helpers/util';

function OrderCoupon({ discounts = [], coupons = [], getApiDiscounts }) {
  const [showModal, setShowModal] = useState(false)
  const coupon_discounts = discounts.filter((item) => item.coupon_code)
  const [couponCode, setCouponCode] = useState('');
  const userId = useSelector(({ userInfo }) => userInfo?.id) || localStorage.getItem("USER_ID")

  const handleApplyCoupon = async () => {
    if (userId) {
      getApiDiscounts(couponCode);
    } else {
      showNotification(
        "top",
        "center",
        "É necessário fazer login para aplicar o cupom.",
        "danger"
      );
    }
    setShowModal(false);
  };

  const btnStyle = {
    textTransform: "capitalize",
    color: couponCode.length === 0 ? "#bdbdbd" : "#fff",
    backgroundColor: couponCode.length === 0 ? "#f2f4f7" : getDomainMarketColor(),
    marginLeft: "1rem",
  };

  return (
    <Card className='order-coupon'>
      <Box display="flex" flexDirection="column">
        <Box px={3} pt={2} className="title-coupon">
          <h5>Cupom de desconto</h5>
        </Box>
        <Box display="flex" flexDirection="column" px={3} className='coupon-details'>
          {coupon_discounts.length <= 0 ? 
            <Box display="flex" flexDirection="column" py={2}>
              <span
                className='coupon-disponible'
                style={{ ...domainColorStyle('color') }}>
                {coupons.length > 0 &&
                `Você possui ${coupons.length} 
                  ${coupons.length === 1 ? 'Cupom disponível' :
                    'Cupons disponíveis'}`}
              </span>
              <label>
                Para adicionar um cupom ao seu pedido clique no botão abaixo
              </label>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setShowModal(true)}
                >Adicionar cupom</Button>
            </Box> : 
            <Box pb={2}>
              {coupon_discounts.map((discount) => (
                <div className='discount-applied' key={discount.coupon_code}>
                  <p className="cupom-title">
                    {discount.title}</p>
                  <div
                    className="cupom-code"
                    style={{ ...domainColorStyle('borderColor') }}
                  >
                    <p>
                      {discount.coupon_code}
                      <CheckCircleIcon style={{ ...domainColorStyle('color') }} fontSize="small" />
                    </p>
                  </div>
                </div>
              ))}
              {coupons.length > 0 && 
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setShowModal(true)}
                >Mais cupons</Button>
              }
            </Box>
          }
        </Box>
      </Box>
      <Modal
        isOpen={showModal}
        toggleModal={() => setShowModal(!showModal)}
        className="order-cupons-modal"
        size="md"
      >
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2}>
            {coupons.map((coupon) => (
              <Grid key={coupon.code} item md={6} lg={6} sm={6} xs={12}>
                <Cupom
                  setShowModal={setShowModal}
                  getApiDiscounts={getApiDiscounts}
                  cupom={coupon}
                  discounts={discounts} />
              </Grid>
            ))}
          </Grid>
          <Box display="flex" flexDirection="column" marginTop="1rem">
            <span style={{ fontSize: ".8rem" }}>
              Clique aqui para digitar seu cupom
            </span>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Código de cupom"
                InputLabelProps={{ shrink: true }}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                size="small"
              />
              <Button
                style={btnStyle}
                variant="contained"
                onClick={handleApplyCoupon}
                disabled={couponCode.length === 0}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
}

export default React.memo(OrderCoupon);