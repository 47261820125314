import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { ModalBody, Modal, ModalHeader } from "reactstrap";
import { withRouter } from "react-router-dom";
import Messages from "./Messages";

import { domainColorStyle } from "config";
import { getChatOrder } from "helpers/apis/chatApi";
import { sendNewMessage } from "helpers/apis/chatApi";
import { notification } from "helpers/util";

import "../../assets/css/react-confirm-alert.css";
import "./Chat.scss";
function ChatOrder({ isOpen = true, toggleModal, orderId, order = {} }) {

  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatId, setChatId] = useState(null);

  const getChatGeral = useCallback(async () => {
    if (orderId) {
      const chat = await getChatOrder(orderId);
      if (chat.chat_id) {
        const socket = new WebSocket(
          `wss://merconnect.mercadapp.com.br/websockets/chats/${chat.chat_id}`
        );
        socket.onmessage = (menssage) => {
          setChatMessages(JSON.parse(menssage.data));
          setChatId(chat.chat_id);
        };
      }
    }
  }, [orderId]);


  useEffect(() => {
    getChatGeral();
  }, [orderId]);

  const sendMessage = () => {
    try {
      sendNewMessage(chatId, newMessage);
      setNewMessage("");
    } catch {
      notification("Ocorreu um erro ao enviar sua mensagem.", "danger");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal(null)}
      className="modal-dialog-centered modal-order-chat"
    >
      <ModalHeader
        className="header-order-chat"
        style={domainColorStyle("background")}
        toggle={() => toggleModal(null)}
      >
        {order ? `Pedido N° ${order.order_number}` : ""}
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className="chat-order-messages chat-order-menu">
          <div
            className="text-center w-100"
            style={{ height: "calc(50vh - 3.1rem)" }}
          >
            <div style={{ overflow: "auto", height: "100%" }}>
              <Messages chatMessages={chatMessages} />
              <div className="input-order-chat" style={{ width: "100%" }}>
                <input
                  onKeyDown={({ key }) =>
                    key === "Enter" ? sendMessage() : null
                  }
                  value={newMessage}
                  onChange={({ target }) => setNewMessage(target.value)}
                />
                <button onClick={() => sendMessage()}>
                  <i
                    className=" far text-exclamation fa-paper-plane mr-1"
                    style={{ ...domainColorStyle("color"), fontSize: 25 }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentMarket: state.markets,
    deliveryMethod: state.deliveryMethod.deliveryMethod,
  };
}
export default withRouter(connect(mapStateToProps, {})(ChatOrder));
