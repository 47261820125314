import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Box, Container, IconButton } from "@material-ui/core";
import { Menu, NavigateBefore, NavigateNext } from '@material-ui/icons';

import { changeSelectedCategory } from 'actions/categoriesActions';
import { sendCategoryEvent } from 'helpers/apis/impulseEventsApiHelper';
import { domainColorStyle } from 'config';

import './MenuHeader.scss';

import { hideForYouCategory } from 'helpers/categoriesHelper';
import AppNotices from 'components/AppNotices/AppNotices';


function MenuHeader({ setSidebar, sidebar }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const menu = useRef(null)

  const categories = useSelector(({ categories }) => categories.categories);
  const [menuCategories, setMenuCategories] = useState([]);
  const [colorDomain, setColorDomain] = useState("")
  const [openBulkWarning, setOpenBulkWarning] = useState(false)
  const { category, market_id } = useParams();

  const selectedCategory = useSelector(({ categories }) => categories.selectedCategory) || {};

  const setCategoriesMenu = useCallback(() => {
    setMenuCategories(categories);
  }, [categories]);

  React.useEffect(() => {
    window.addEventListener("resize", setCategoriesMenu, false);
    setCategoriesMenu()
  }, [categories]);

  function scrollNext(ref) {
    ref.current.scrollBy({ left: 100, behavior: "smooth" });
  }
  function scrollBefore(ref) {
    ref.current.scrollBy({ left: -100, behavior: "smooth" });
  }

  const changeCategory = async (category) => {
    if (hideForYouCategory(category)) {
      return;
    }

    if (!category.id) {
      dispatch(changeSelectedCategory(undefined));
      history.push(`/loja/${market_id}`);
      return;
    }

    if (category.id !== selectedCategory?.id) {
      dispatch(changeSelectedCategory(category));
      sendCategoryEvent([[category]]);
      history.push(`/loja/${market_id}/categoria/${category.id}`);
      setOpenBulkWarning(category.bulk)
    }
  };

  useEffect(() => {
      setColorDomain(!selectedCategory.color ? domainColorStyle("background").background : selectedCategory.color)
    },[selectedCategory?.color])
    

  return (
    <Box className='menu-categories' style={{backgroundColor: `${colorDomain}`}}>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setSidebar(!sidebar)}
          className="menu-buttons"
        >
          <Menu />
          <span className='menu-span'> Todas as categorias </span>
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => history.push(`/loja/${market_id}/ofertas`)}
          className="menu-buttons"
        >
          <span>Ofertas</span>
        </IconButton>
        <IconButton onClick={() => scrollBefore(menu)} className="icon-button icon-button-categories">
          <NavigateBefore fontSize='small' />
        </IconButton>
        <Box className='menu-scroller' style={{ display: "flex", overflow: 'auto' }} ref={menu}>
          {menuCategories
            .filter(item => item.id !== "paravoce")
            .map((item) => (
              <IconButton
                key={item.id}
                color="inherit"
                aria-label="open drawer"
                onClick={() => changeCategory(item)}
                className="menu-buttons"
              >
                <span className={Number(category) === item.id ? 'menu-span' : ''}>{item.display_name}</span>
              </IconButton>
            ))}
        </Box>
        <IconButton onClick={() => scrollNext(menu)} className="icon-button icon-button-categories">
          <NavigateNext fontSize='small' />
        </IconButton>
      </Container>
      <AppNotices
        isOpen={openBulkWarning}
        toggleModal={() => setOpenBulkWarning(false)}
        notice={{
          description:
            "Essa seção contém produtos vendidos por peso. As quantidades pedidas nesse tipo de produto correspondem a um peso aproximado, o que pode acarretar em variações no total do pedido.",
        }}
        title={"Aviso"}
      />
    </Box>
  )
}

export default React.memo(MenuHeader);