import React, { useEffect, useRef } from "react";

import { domainColorStyle } from "config";
import "../../assets/css/react-confirm-alert.css";

import "./Chat.scss";

function Messages({ chatMessages }) {
  let messagesEnd = useRef(null);
  useEffect(() => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth" })
  });


  return (
    <div style={{ overflow: "auto", height: "calc(100% - 4.1rem)" }}>
      {chatMessages.map((message, i) => (
        <div>
          {((i > 0 && message.created_at_date !== chatMessages[i - 1].created_at_date) || i === 0) &&
            <p style={{ marginTop: 10 }}>{message.created_at_date}</p>}
          <div key={i} className="w-100 row" style={{ margin: 0, justifyContent: message.from_market ? "flex-start" : "flex-end" }}>
            <div className="chat-message"
              style={message.from_market ? { textAlign: "left" } :
                { textAlign: "right", ...domainColorStyle("background"), color: "#fff" }}>
              <span style={message.from_market ? domainColorStyle("color") : { color: "#fff" }}>{message.author}</span>
              <div className="message-text">
                <p style={!message.from_market ? { color: "#fff", display: "initial" } : { display: "initial" }}>{message.text}</p>
                <span className="message-time" style={!message.from_market ? { color: "#fff", marginLeft: 5 } : { marginRight: 5 }}>{message.created_at_time}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
      < div style={{ float: "left", clear: "both" }}
        ref={messagesEnd}>
      </div>
    </div>
  )
}

export default Messages
