import { Box, Button, Typography } from "@material-ui/core";
import ModalDefault from "new/components/Modal/Modal";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import DeliveryMethods from "components/Homepage/DeliveryMethods";
import { getListMarkets } from "helpers/apis/marketInfo";
import { notification } from "helpers/util";
import ListMarkets from "components/Homepage/ListMarkets";

const Dialog = ({
  isOpen = false,
  setIsOpen = () => {},
  onProgress = () => {},
}) => {
  const [openDrivethruModal, setOpenDrivethruModal] = useState(false);
  const [openDeliveryMethodsModal, setOpenDeliveryMethodsModal] = useState(false);
  const [marketList, setMarketList] = useState([])
  const closeModal = () => setIsOpen(false);

  const closeAllModals = () => {
    setOpenDeliveryMethodsModal(false);
    setOpenDrivethruModal(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const getMarketsList = async () => {
      try {
        const { data } = await getListMarkets()
        setMarketList(data)
      } catch (error) {
        notification("Erro ao buscar lojas da marca", "error")
        console.log(error)
      }
    }
    getMarketsList()
  }, [])

  return (
    <>
      <ModalDefault isOpen={isOpen} toggleModal={closeModal} size="sm">
        <Box className="withhold-revenue-dialog">
          <Typography color="primary" variant="h6">
            Atenção!
          </Typography>
          <Typography variant="body1">
            {" "}
            A compra deste produto depende de apresentação, avaliação e retenção
            de receita física. Ao adicionar este item à sua cesta, seu pedido só
            poderá ser retirado em loja.{" "}
          </Typography>

          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDrivethruModal(true)}
          >
            manter cesta e mudar forma de entrega
          </Button>
          <Button color="primary" onClick={closeModal}>
            Cancelar
          </Button>
        </Box>
      </ModalDefault>

      <ModalDefault
        isOpen={openDrivethruModal}
        toggleModal={() => setOpenDrivethruModal(false)}
        size="xs"
      >
        <Typography style={{ textAlign: "center" }} variant="h6">Escolha a loja para retirada</Typography>
        <ListMarkets
          setModalDeliveryMethods={(status) =>
            setOpenDeliveryMethodsModal(status)
          }
          stores={marketList}
          setShowModal={(status) => setOpenDrivethruModal(status)}
        />
      </ModalDefault>

      <ModalDefault
        isOpen={openDeliveryMethodsModal}
        toggleModal={() => setOpenDeliveryMethodsModal(false)}
        size="xs"
      >
        <Typography style={{ textAlign: "center" }} variant="h6">Escolha a forma de retirada</Typography>
        <DeliveryMethods
          onSelect={() => {
            onProgress();
            closeAllModals();
          }}
          modalDeliveryMethods={true}
          setModalDeliveryMethods={() => setOpenDeliveryMethodsModal(false)}
        />
      </ModalDefault>
    </>
  );
};

export default Dialog;
