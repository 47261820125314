import { filterItemsByFilterId } from "./mixesHelper";

export function order(a, b, ordenation) {
  switch (ordenation) {
    case "1":
      if (a.description === b.description) return 0;
      return a.description.trim() > b.description.trim() ? 1 : -1;
    case "2":
      if (a.description === b.description) return 0;
      return a.description.trim() < b.description.trim() ? 1 : -1;
    case "3":
      return b.currentPrice() - a.currentPrice();
    case "4":
      return a.currentPrice() - b.currentPrice();
    default:
      if (a.description === b.description) return 0;
      return a.description > b.description ? 1 : -1;
  }
}

export function sortProducts(products, ordenation) {
  const keys = ["is_highlight", "section_id", "description"];

  return products.sort((a, b) => {
    for (let key of keys) {
      if (a[key] === b[key]) continue;

      if (key === "is_highlight") {
        return a.is_highlight ? -1 : 1;
      }

      if (key === "description") {
        return order(a, b, ordenation);
      }

      return a[key] > b[key] ? 1 : -1;
    }

    return 0;
  });
}
export function getMixesOrdered(mixes, order, selFilter = {}) {
  const items = filterItemsByFilterId(mixes, selFilter?.id)
  return sortProducts(items, order);
}

export function extractItemsFromMixes(mixes) {
  if (!mixes) return [];
  return mixes.mixes.flatMap((mix) => mix.items);
}

export const dispatchItemsInBatches = (items = [], dispatchFunc = () => {}) => {
  if (!items || items.length === 0) {
    dispatchFunc([]);
    return;
  }

  const batchSize = 25;
  const initialBatchCount = 3;
  const initialBatchInterval = 250;
  const lastBatchInterval = 1000;
  const maxRemainingItemsLength = 400;
  let startIndex = 0;
  let itemsToDispatch = [];

  const dispatchInitialBatch = () => {
    const fastBatch = items.slice(startIndex, batchSize + startIndex);
    itemsToDispatch = [...itemsToDispatch, ...fastBatch];
    startIndex += batchSize;
    dispatchFunc(itemsToDispatch);

    if (startIndex < items.length && startIndex < initialBatchCount * batchSize) {
      setTimeout(dispatchInitialBatch, initialBatchInterval);
    } else {
      setTimeout(dispatchRemainingItems, lastBatchInterval);
    }
  };

  const dispatchRemainingItems = () => {
    const remainingItems = items.slice(startIndex);

    if (remainingItems.length > maxRemainingItemsLength) {
      const firstBatch = remainingItems.slice(0, maxRemainingItemsLength);
      const secondBatch = remainingItems.slice(maxRemainingItemsLength);

      itemsToDispatch = [...itemsToDispatch, ...firstBatch];
      dispatchFunc(itemsToDispatch);

      setTimeout(() => {
        itemsToDispatch = [...itemsToDispatch, ...secondBatch];
        dispatchFunc(itemsToDispatch);
      }, lastBatchInterval);
    } else {
      itemsToDispatch = [...itemsToDispatch, ...remainingItems];
      dispatchFunc(itemsToDispatch);
    }
  };

  dispatchInitialBatch();
};