import React from "react";
import InputCustom from "components/InputCustom";
import {Button} from "reactstrap";
import {domainColorStyle} from "config";
import LoadingContent from "../LoadingContent";

function NeedsFullAddress({
  setStreet,
  setNumber,
  street,
  number,
  reset,
  fullAddressPress,
  isLoading,
}) {
  return (
    <div>
      <InputCustom
        type="text"
        name="street"
        required
        placeholder="Qual a sua rua?"
        onChange={(e) => setStreet(e.target.value)}
        value={street}
      />

      <InputCustom
        type="text"
        name="number"
        required
        placeholder="Número"
        onChange={(e) => setNumber(e.target.value)}
        value={number}
      />
      {isLoading ? (
        <LoadingContent isLoading={isLoading} />
      ) : (
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <Button
            onClick={() => reset()}
            type="submit"
            className="btn-icon block btn-3"
            outline
            style={{
              margin: 10,
              ...domainColorStyle("color"),
              ...domainColorStyle("borderColor"),
            }}
          >
            <span className="btn-inner--text">Voltar</span>
          </Button>
          <Button
            onClick={() => fullAddressPress()}
            type="submit"
            className="btn-icon block btn-3"
            outline
            style={{
              margin: 10,
              ...domainColorStyle("color"),
              ...domainColorStyle("borderColor"),
            }}
          >
            <span className="btn-inner--text">Continuar</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default NeedsFullAddress;
