import React from "react";
import { Skeleton } from "@material-ui/lab";
import ProductSliderSkeleton from "new/components/ProductsSlider/ProductsSliderSkeleton";

function OffersPageSkeleton() {
  return (
    <>
      <ProductSliderSkeleton />
      <ProductSliderSkeleton />
      <Skeleton
        variant="rect"
        width={120}
        height={40}
        style={{
          margin: "1rem auto",
          borderRadius: "4px",
        }}
      />
    </>
  );
}

export default OffersPageSkeleton;
