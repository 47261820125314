import React, { useEffect, useCallback, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { domainColorStyle, domainSecondaryColorStyle, getMenu, getDomainLogo, getDomainMarketColor} from "config";
import numberToCurrency from "helpers/currency";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addToCart, removeFromCart, addPreferencesToCart, removePreferencesToCart } from "actions/cartActions";
import { openDeliveryMethod } from "actions/configsActions";
import { getItemByCodeBar, getItemBySlug } from "../../../helpers/apis/itemApi";
import { notification, humanReadableWeight, countItensCart } from "helpers/util";
import LoadingContent from "components/Loading/Loading";

import appExclusive from "assets/img/icons/appExclusive.png";
import offerExplosion from "assets/img/icons/offerExplosion.png";
import {ArrowBack, NavigateBefore, NavigateNext} from "@material-ui/icons";
import "./ProductModal.scss";

import { applyProductFunctions } from "helpers/models/product";
import { track, trackCustom } from "helpers/pixelHelper";
import {
  sendViewProductEvent,
  sendAddedToCartEvent,
} from "helpers/analyticHelper";
import { sendProductEvent } from "helpers/apis/impulseEventsApiHelper";
import ModalPreferences from "components/ModalPreferences/ModalPreferences";
import CardOfferTakeMore from "components/CardOfferTakeMore/CardOfferTakeMore";
import ModalLogin from "components/ModalLogin/ModalLogin";
import { Box, Grid, Button, Card, TextField, IconButton, Typography } from "@material-ui/core";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AddRemoveComponent from "new/components/AddRemove/AddRemove";
import Modal from "new/components/Modal/Modal";

import Product from "components/NewProductCard/Product";
import { getRelatedItems } from "helpers/apiHelper";
import { getMixesFromCart } from "helpers/cartHelper";
import { changeDocumentTitle } from "helpers/util";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { WithholdRevenueDialog } from "new/components/WithholdRevenue";

function ProductModal(props) {
  const { removeFromCart, amount, product, origin } = props;
  const history = useHistory();
  const location = useLocation();
  const deliveryMethodMaxItems = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod.max_items_amount);  
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const dispatch = useDispatch()
  const [customerPreferences, setCustomerPreferences] = useState("");
  const [offerBadge, setOfferBadge] = useState();
  const [highestPriorityOffer, setHighestPriorityOffer] = useState();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState([]);
  const [related, setRelated] = useState([]);
  const { market_id } = useParams();
  const [modalPreference, setModalPreference] = useState(false);
  const [preference, setPreference] = useState("");
  const [crm, setCrm] = useState({});
  const [modalLogin, setModalLogin] = useState(false);
  const [amountItemInEdit,] = useState(0);
  const [openPreferencesModal, setOpenPreferencesModal] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const brand = useSelector(({brand}) => brand.brand)
  const relateCarousel = useRef(null);
  const [showFwdButton, setShowFwdButton] = useState(true);  
  const [showBackButton, setShowBackButton] = useState(false);
  const [scrolledWidth, setScrolledWidth] = useState(0);
  const [showAlertStockLessQuantity, setShowAlertStockLessQuantity] = useState(false);
  const currentMarket= useSelector(({markets}) => markets)
  const totalItemsDeliveryMethod = countItensCart(cart)
  const deliveryMethod = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod)
  const neighborhoodId = useSelector(({ zone }) => zone.neighborhood_id);
  const isValidMenu = getMenu()
  const [openRevenueDialog, setOpenRevenueDialog] = useState(false)

  async function getRelatedItemsList(itemId, itemsQuantity) {
   const {related} = await getRelatedItems(market_id, itemId, itemsQuantity, deliveryMethod?.id, neighborhoodId)

      if(related !== null){
        setRelatedItems(applyProductFunctions(related));
      }
  }

  function handlePreferencesAndCart(_preference) {
    item.mix_item_preference = _preference
    item.customer_preferences = customerPreferences
    setModalPreference(false);
    setPreference(_preference);
    sendProductToCart(item);
  }

  function handlePreferencesCustomerAndCart(_preference){
    item.mix_item_preference = _preference
    item.customer_preferences = customerPreferences
    setModalPreference(false);
    setPreference(_preference);
    dispatch(addPreferencesToCart(item))
  }

  function removePreferencesCustomerAndCart(){
    item.customer_preferences = ""
    dispatch(removePreferencesToCart(item))
  }
  

  const get = useCallback(async () => {
    getBrand(async (receivedBrand) => {
      try {
        let item;
        product.cb
          ? (item = await getItemByCodeBar(product.cb, Number(market_id)))
          : (item = product);
          const response = item.slug 
            ? await getItemBySlug(item.slug, Number(market_id))
            : await getRelatedItems(Number(market_id), item.id, 0, deliveryMethod?.id)
          changeDocumentTitle(response.item)
          setCrm(receivedBrand.crm);
          trackCustom("ProductPageView", response.item);
        const functionsAppliedItem = {
          ...item,
          ...applyProductFunctions([response.item])[0],
        };
        sendProductEvent(functionsAppliedItem);
        setItem(functionsAppliedItem);
        setRelated(applyProductFunctions(response.related));

        sendViewProductEvent(functionsAppliedItem);
        
        const highestPriorityOffer = applyProductFunctions([
          response.item,
        ])[0].highestPriorityOffer();

        const offerBadge = receivedBrand
        ? receivedBrand.offerBadgeForKind(highestPriorityOffer.kind)
        : null;
        
        setOfferBadge(offerBadge);
        setHighestPriorityOffer(highestPriorityOffer);
        await getRelatedItemsList(response.item.id, 5);
      } catch (error) {
        history.replace(`/loja/${market_id}`);
      } finally {
        setLoading(false);
      }
    });
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, market_id, product]);
  
  async function getBrand(callBack) {
    if (brand) {
      callBack(brand);
    }
  }
  
  useEffect(() => {
    track("ViewContent");
    get();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  
  
  function addProductToCart(item, amount ) {
    if (item.item_preference) {
      if (amount === 0 || !preference) {
        setModalPreference(true);
        return;
      }
      
      item.preferences =
      preference !== null
      ? customerPreferences + " - " + preference
      : customerPreferences;
      sendProductToCart(item);
    } else {
      item.preferences = customerPreferences;
      sendProductToCart(item, amount);
      if (amount >= item.stock || amountItemInEdit >= item.stock) {
        notification("Quantidade máxima atingida para o item", "warning");
        return;
      }
    }
  }
  
  const handleAddProductToCart = (item, amount) => {
    if (deliveryMethodMaxItems && totalItemsDeliveryMethod >= deliveryMethodMaxItems) {
      notification(`Limite de produtos no carrinho atingido (${deliveryMethodMaxItems})`, 'warning')
      return
    }

    if (deliveryMethod?.mode === "delivery" && item?.withhold_revenue) {
      setOpenRevenueDialog(true)
      return
    }

    addProductToCart(item, amount)
  }
  
  const sendProductToCart = (product, quantity) => {
    if (
      crm?.restrict_to_crm_logged_users &&
      !localStorage.getItem("crmUserCpf") &&
      crm.company !== "propz"
    ) {
      setModalLogin(true);
      return;
    }
  
    const { openDeliveryMethod, addToCart } = props;
  
    if (!currentMarket.deliveryMode) {
      openDeliveryMethod(true);
      return;
    }
    addToCart(product, origin, quantity);
    track("AddToCart", product);
    sendAddedToCartEvent(product);
  };
  

  function isBuyingXProductTakeYProductPerXPrice() {
    return (
      item.promotion_kind === "buying_x_product_take_y_product_per_z_price"
    );
  }
  const promotionsLabels = isBuyingXProductTakeYProductPerXPrice()
    ? ["OFERTA", "LEVE +"]
    : item.promotion_label_array;

  const scrollBefore = (e) => {
    e.preventDefault();
    setScrolledWidth(
     relateCarousel.current.scrollLeft -= relateCarousel.current.offsetWidth
    )
  }

  const scrollNext = (e) => {
    e.preventDefault();
    setScrolledWidth(
      relateCarousel.current.scrollLeft += relateCarousel.current.offsetWidth
    )
  }

  const carousel = relateCarousel;
  const shouldShowButton = useCallback(() => {
    scrolledWidth > 0
      ? setShowBackButton(true)
      : setShowBackButton(false)

    if (carousel.current !== null) {
      const contentWidth = carousel.current?.scrollWidth
      const viewportWidth = carousel.current?.offsetWidth
      const maxScroll = contentWidth - viewportWidth

      scrolledWidth >= maxScroll
        ? setShowFwdButton(false)
        : setShowFwdButton(true)
    }
  }, [scrolledWidth]);

  useEffect(() => {
    function handleCarouselScroll() {
      setScrolledWidth(carousel.current.scrollLeft)
    }

    if (carousel.current) {
      carousel.current.addEventListener('scroll', handleCarouselScroll)
    }

    return () => {
      if (carousel.current) {
        carousel.current.removeEventListener('scroll', handleCarouselScroll)
      }
    },
    
    shouldShowButton()
  }, [scrolledWidth, relatedItems]);


    const stockLessQuantity = item.stock <= amount
    if(stockLessQuantity && !showAlertStockLessQuantity){
      setShowAlertStockLessQuantity(true)
      notification(`Produto indisponível (${item.description})`, "warning");
    }
    
  return (
    <>
      {loading ? (
        <LoadingContent isLoading={loading} />
      ) : (
        <div className="div-modal">
          <Grid xs={12} style={{ marginBottom: 16, marginTop: -25 }}>
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              style={{ borderRadius: 5 }}
              size="small"
              color="primary"
            >
              <ArrowBack fontSize="inherit" />
              Voltar
            </IconButton>
          </Grid>
          <Grid container spacing={2} xs={12}>
            <Card className="card-produtos">
              <Grid item xs={12}>
                <Box px={5} pt={2}>
                  <div className="div-info ">
                    <h1 className="div-info-title">{item.description}</h1>
                    <h2 className="div-info-subtitle">{item.aditional_info ? item.aditional_info : item.description}</h2>
                    {item.promotion_labels && (
                      <span className="div-info-bar-code">
                        {item.promotion_labels}
                      </span>
                    )}
                    <span className="div-info-bar-code">
                      {"Código de barras: " + item.bar_code}
                    </span>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} lg={6} md={6} sm={12}>
                <div className="div-image" style={{opacity: `${stockLessQuantity ? "50%" : 1}`}}>
                  {item.offers.length > 0 && !item.crmOffer() && (
                    <img
                      alt=""
                      className="offer"
                      src={
                        offerBadge
                          ? offerBadge.image_url
                          : highestPriorityOffer.kind === "app_exclusive"
                            ? appExclusive
                            : highestPriorityOffer.kind === "club_exclusive"
                              ? getDomainLogo()
                              : offerExplosion
                      }
                    />
                  )}
                  {item.has_free_delivery && (
                    <span
                      className="free-delivery"
                      style={item.is_offer ? { top: "35%" } : { top: "15%" }}
                    >
                      Frete Grátis
                    </span>
                  )}
                  <img className="image" src={item.image} alt="item" />
                  {item.is_publicity && (
                    <p
                      className="has-publicity product-publicity"
                      style={{
                        ...domainSecondaryColorStyle("backgroundColor"),
                        ...domainColorStyle("color"),
                      }}
                    >
                      {" "}
                      Patrocinado
                    </p>
                  )}
                  {item.amount_in_kilo && (
                    <span
                      className="amount-in-kilo-label"
                      style={{
                        ...domainColorStyle("background"),
                        position: "absolute",
                        bottom: 25,
                        right: 0,
                      }}
                    >
                      {humanReadableWeight(item.amount_in_kilo)}
                    </span>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} lg={6} md={6} sm={12}>
                <div className="div-info-price">
                  {promotionsLabels && (
                    <Box
                      className="discount-price"
                      style={{ backgroundColor: `${getDomainMarketColor()}4d` }}
                    >
                      <strong style={{ ...domainColorStyle("color") }}>
                        {promotionsLabels.map((p) => `${p} `)}
                      </strong>
                    </Box>
                  )}
                  <Box>
                    {item.currentPrice() < item.original_price && (
                      <p className="info-price-original">
                        De: <del>{numberToCurrency(item.original_price)}</del>
                      </p>
                    )}
                    <Box
                      display="flex"
                      flexDirection="column"
                      className="price-product-modal"
                    >
                      <div style={{ ...domainColorStyle("color") }}>
                        <p>Por:</p>
                        <span style={{ fontSize: 24, fontWeight: "bold" }}>
                          {numberToCurrency(item.currentPrice())}
                        </span>
                        <span>
                          {!item.kilogram_price
                            ? " Unidade"
                            : " a cada " +
                            humanReadableWeight(item.amount_in_kilo)}
                        </span>
                      </div>
                      {item.kilogram_price ?
                        <strong>
                          <i>{numberToCurrency(item.kilogram_price)}/Kg</i>
                        </strong> : <></>}
                    </Box>
                      {!isValidMenu &&
                        <Button
                          className="change-button"
                          color="primary"
                          onClick={() => setOpenPreferencesModal(true)}
                        >
                          <span>Adicionar preferências</span>
                        </Button>}
                  </Box>
                  <Box className="btn-add-cart ">
                      {!isValidMenu &&
                        <>
                          {amount <= 0 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className="button-cart"
                              startIcon={<ShoppingCart style={{ fontSize: 25 }} />}
                              onClick={() => handleAddProductToCart(item, amount)}
                            >
                              Adicionar ao carrinho
                            </Button>
                          ) : (
                            <AddRemoveComponent
                              product={item}
                              amount={amount}
                              remove={removeFromCart}
                              allowInput={true}
                              add={() => handleAddProductToCart(item)}
                            />
                          )}
                        </>}
                  </Box>
                </div>
              </Grid>
              {item?.withhold_revenue ? (
                <Box className="revenue-warning-box">
                  <WarningRoundedIcon />
                  <Box>
                    <Typography variant="subtitle2">
                      Retenção de receita
                    </Typography>
                    <Typography variant="body2">
                      A compra deste produto depende de apresentação, avaliação e retenção de receita física.
                      Ao adicionar este item à sua cesta, seu pedido só poderá ser retirado em loja.
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Card>
          </Grid>
          
          {item.aditional_info && 
            <Card className="card-info">
              <Grid item xs={12}>
                <Box px={5} pt={2}>
                  <Grid item sm={12}>
                    <p className="card-info-text">Informações do produto</p>
                    <p
                      style={{
                        overflow: "scroll",
                        maxHeight: "30vh",
                        whiteSpace: "pre-wrap",
                      }}
                      className="div-info-body"
                    >
                      {item.aditional_info}
                    </p>
                  </Grid>
                </Box>
              </Grid>
            </Card>
          }

          {relatedItems.length && related.length > 0 ? (
            <Card className="card-info">
              <Grid item xs={12}>
                <Box px={5} pt={2}>
                  <p className="card-info-text">Veja também</p>
                  <div className="px-1 px-lg-4 card-products-relate">

                    {showBackButton && 
                      <IconButton
                        className="products-relate-btn"
                        style={{ ...domainColorStyle("backgroundColor") }}
                        onClick={scrollBefore}>
                        <NavigateBefore className="btn-icon" />
                      </IconButton>
                    }

                    <div className="products-relate-carousel" ref={relateCarousel}>
                      {relatedItems.map((related_product) => (
                        <Product
                          currentCategory={related_product.category_id}
                          products={related}
                          product={related_product}
                        />
                      ))}
                    </div>

                    {showFwdButton && 
                      <IconButton
                        className="products-relate-btn"
                        style={{ ...domainColorStyle("backgroundColor")}}
                        onClick={scrollNext}>
                        <NavigateNext className="btn-icon"/>
                      </IconButton>
                    }

                  </div>
                </Box>
              </Grid>
            </Card>
          ) : null}

          <Grid lg={10} md={12}>
            {item.kind_for_promotion_calculation === "full_price_item" &&
              item.promotion_kind ===
              "buying_x_product_take_y_product_per_z_price" && (
                <Grid lg={6} md={12} className="col-div-info">
                  <CardOfferTakeMore
                    product={item}
                    addToCart={(product) => {
                      sendProductToCart(product);
                    }}
                  />
                </Grid>
              )}
          </Grid>
        </div>
      )}

      <Modal
        isOpen={openPreferencesModal}
        className="preferences-modal"
        toggleModal={() => setOpenPreferencesModal(!openPreferencesModal)}
      >
        <Grid className="preferences-modal-info">
          <h5>Preferências</h5>
          <TextField
            className="preferences-modal-info-text"
            multiline
            rows={5}
            margin="normal"
            placeholder="Deseja deixar algum recado sobre seu item?"
            variant="outlined"
            onChange={(e) => setCustomerPreferences(e.target.value)}
            value={customerPreferences}
          />

          <Box className="preferences-modal-info-button">
            <Button
              className="info-button"
              variant="outlined"
              color="primary"
              onClick={() => {
                setCustomerPreferences("");
                removePreferencesCustomerAndCart(item.preference)
                setOpenPreferencesModal(false);
              }}>
              Cancelar
            </Button>
            <Button
              className="info-button"
              variant="contained"
              color="primary"
              onClick={() => {
                handlePreferencesCustomerAndCart(item.preference)
                setOpenPreferencesModal(false)} 
              }>
              Confirmar
            </Button>
          </Box>
        </Grid>
      </Modal>

      <ModalPreferences
        isOpen={modalPreference}
        close={() => setModalPreference(false)}
        toggleModal={handlePreferencesAndCart}
        itemPreference={item.item_preference}
      />
      {crm && (
        <ModalLogin
          isOpen={modalLogin}
          toggleModal={() => setModalLogin(false)}
          crm={crm}
        />
      )}

      <WithholdRevenueDialog
        isOpen={openRevenueDialog}
        setIsOpen={setOpenRevenueDialog}
        onProgress={() => addProductToCart(item, amount)}
      />
    </>
  );
} 

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { addToCart, removeFromCart, openDeliveryMethod },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
