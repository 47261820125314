/* eslint-disable no-case-declarations */
import {
  SET_ZONE_FOR_REQUESTED_CEP,
  SET_ZONE,
  SET_NEIGHBORHOOD,
  RESET_MARKET,
} from "ActionTypes";
import {getZone, saveZone} from "helpers/localStorage";

const initialState = {
  cep: "",
  city: "",
  id: "",
  locality: "",
  neighborhood: "",
  neighborhood_id: "",
  neighborhoods: [],
  state: "",
  street: "",
};

export default function (zone = getZone() || initialState, action) {
  switch (action.type) {
    case SET_ZONE_FOR_REQUESTED_CEP:
      // Obs: note no exemplo acima que o cep é um json que representa a zona.
      // Dentro do "cep" temos o cep realmente.
      const cep = {
        ...action.payload.data.cep,
        neighborhoods: action.payload.data.neighborhoods,
      };
      if (!cep.neighborhoods) {
        delete cep.neighborhoods;
      }
      saveZone(cep);
      return cep;
    case SET_NEIGHBORHOOD:
      const newZone = Object.assign({}, zone, {
        neighborhood_id: action.payload.neighborhood_id,
        neighborhood: action.payload.neighborhood,
        street: action.payload.street,
      });
      saveZone(newZone);
      return newZone;
    case SET_ZONE:
      return action.zone;
    case RESET_MARKET:
      return [];
    default:
      return zone;
  }
}
