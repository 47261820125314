import _ from "lodash";

let crmData = [];

export function registerCRMDataListener(store) {
  store.subscribe(function () {
    crmData = store.getState().crm.crmData;
  });
}

export const applyProductFunctions = (items) => {
  if(!items) return [];
  return items.map((item) => {
    if (item.items) {
      item.items = applyProductFunctions(item.items);
    } else {
      item.highestPriorityOffer = () => {
        return (
          _.maxBy(item.offers, function (offer) {
            return offer.priority;
          }) || {}
        );
      };

      item.crmOffer = () => {
        const crmOffer = crmData[item.bar_code] || [];
        return crmOffer[0];
      };

      item.currentPrice = () => {
        const crmOffer = item.crmOffer();
        if (crmOffer) {
          let price = crmOffer.price;
          if (item.amount_in_kilo) {
            price *= item.amount_in_kilo;
          }
          return price;
        }
        const commonOffer = item.highestPriorityOffer();
        return commonOffer.price || item.price;
      };

      item.currentKilogramPrice = () => {
        if (item.kilogram_price > 0) {
          const crmOffer = item.crmOffer();
          if (crmOffer) {
            let price = crmOffer.price;
            return price;
          }
          const commonOffer = item.highestPriorityOffer() || {};
          return commonOffer.kilogram_price || item.kilogram_price;
        }
        return item.kilogram_price;
      };
    }

    return item;
  });
};
