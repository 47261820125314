import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

import ProductCard from "./ProductCard";
import ProductCardCRM from "./ProductCardCrm";


import { addToCart, removeFromCart } from "actions/cartActions";
import { openDeliveryMethod } from "actions/configsActions";
import { SEARCHING, HIGHLIGHT, CATEGORIES } from "OrderItemOrigins";
import { checkIsNewCategory, getSubcategorie } from "helpers/categoriesHelper";

function Product({
  currentCategory,
  product,
  products,
  index,
  cart,
  currentMarket,
  brand,
  mix,
  updateAmountProduct,
  sliderStyle
}) {
  const [mixProductIndex, setMixProductIndex] = useState(0)
  const categories = useSelector(({categories}) => categories.categories)
  const searchActive = useSelector(({ui}) => ui.searchActive)

  const origin = () => {
    if (currentCategory?.id === undefined && product.is_highlight) {
      return HIGHLIGHT;
    }
    return CATEGORIES;
  };

  const currentProduct = mix ? mix[mixProductIndex] : product

  return (
    <>
      {currentCategory.id === undefined &&
        (products.length > 1
          ? index < products.length - 1
          : index <= products.length - 1) &&
        !searchActive &&
        checkIsNewCategory(products, product, index) && (
          <p
            style={{
              width: "100%",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {product.is_highlight
              ? "Destaques"
              : getSubcategorie(categories, product.section_id, product.category_id)}
          </p>
        )}
      {currentCategory === "crm" ? (
        <ProductCardCRM
          key={product.id}
          brand={brand}
          product={product || {}}
          currentMarket={currentMarket}
          amount={(cart[product.id] || {}).amount || 0}
        />
      ) : (
        currentProduct && (
          <ProductCard
            origin={searchActive ? SEARCHING : origin()}
            key={currentProduct.id}
            product={currentProduct || {}}
            currentMarket={currentMarket}
            amount={(cart[currentProduct.id] || {}).amount || 0}
            mix={mix}
            mixProductIndex={mixProductIndex}
            setMixProductIndex={setMixProductIndex}
            updateAmountProduct={updateAmountProduct}
            sliderStyle={sliderStyle}
          />
        )
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    currentMarket: state.markets,
    crmData: state.crm.crmData,
  };
};
export default connect(mapStateToProps, {
  addToCart,
  removeFromCart,
  openDeliveryMethod,
})(React.memo(Product));
