import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const modally = (WrappedComponent) => {
  return function ModalWrapper({
    showModal,
    title,
    toggleModal,
    size,
    titleClassName,
    className,
    ...props
  }) {
    return (
      <Dialog
        open={showModal}
        onClose={toggleModal}
        fullWidth
        maxWidth={size || "sm"}
        className={className || ""}
        aria-labelledby="modal-title"
      >
        {title && (
          <DialogTitle id="modal-title" className={titleClassName || ""}>
            {title}
            {toggleModal && (
              <IconButton
                aria-label="Close"
                color="default"
                onClick={toggleModal}
                style={{ position: "absolute", right: "8px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent dividers>
          <WrappedComponent toggleModal={toggleModal} {...props} />
        </DialogContent>
      </Dialog>
    );
  };
};

export default modally;
