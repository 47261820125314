import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import './CardListingCheckout.scss';
import Modal from 'new/components/Modal/Modal';
import Cards from '../Cards/Cards';
import ModalAddNewCard from 'new/containers/checkout/components/ModalAddNewCard/ModalAddNewCard';

import { deletePaymentCard } from 'helpers/apiHelper';
import { notification } from 'helpers/util';
import { domainColorStyle } from 'config';
import { getListingCards } from 'helpers/apiHelper';
import { showNotification } from 'helpers/util';


export default function CardListingCheckout({cardPicker, isClose, setCvvValue, updatePaymentInfos }) {
  const [showModalAddCard, setShowModalAddCard] = useState(false)
  const [showModalConfirmCvv, setShowModalConfirmCvv] = useState(false)
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null)
  const [cvv, setCvv] = useState('')
  const userInfo = useSelector(({ userInfo }) => userInfo)

  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const marketId = useSelector(({ markets }) => markets.id)
  const customerId = useSelector(({ userInfo }) => userInfo.id)


  const listCards = async () => {
    const { cards } = await getListingCards(marketId, customerId, deliveryMethod.id)
    cards ? setCardList(cards) : setCardList([])
  }

  const deleteCardFromList = async (cardId) => {
    const response = await deletePaymentCard(cardId, customerId, marketId)
    if (!response.data) {
      setCardList((prevCardList) => prevCardList.filter((card) => card.id !== cardId))
      notification(`${response.message}`, "success")
    } else {
      notification("Erro interno, contate o suporte", "danger")
    }
  }

  useEffect(() => {
    listCards();
  }, [])


  const handleCardClick = (cardId) => {
    setSelectedCardId(cardId)
  }

  const handleConfirmClick = () => {
    const cvvConfirm = cvv.length === 3 || cvv.length === 4
    if(cvvConfirm){
      setCvvValue(cvv)
      setShowModalConfirmCvv(false)
      isClose();
    }else if(!cvvConfirm || !cvv){
      notification('O código CVV deve conter no maximo 4 digitos', 'danger')
    }
  }

  //defini se o componente vai mostrar o cartao selecionado
  const toggleCardSelected = cardPicker

  const checkUserAuthentication = () => {
    if (userInfo.id) {
      setShowModalAddCard(true)
    } else {
      showNotification(
        "top",
        "center",
        "É necessário fazer login para cadastrar um cartão.",
        "warning"
      );
    }
  }

  return (
    <>
      <Box style={{ margin: 10 }}>
        <Grid container>
          <Box className='header-card-listing-check'>
            <Box className='title-card-listing-check'>
              <Button 
                className="card-listing-btn" 
                style={domainColorStyle("color")}
                onClick={isClose}>
                  Fechar</Button>
              <h4 style={domainColorStyle("color")}>Forma de Pagamento</h4>
              <Typography>Selecione a forma que deseja pagar o seu pedido</Typography>
            </Box>
          </Box>
          <Grid container spacing={1} className='body-card-listing-check'>
            {cardList.map((card) => (
              <Grid item xs={12} sm={12} md={12} key={card.id}>
                <Cards
                  lastFourDigits={card.last_four_digits}
                  brandImg={card.brand}
                  cardId={card.id}
                  onDelete={deleteCardFromList}
                  onClick={handleCardClick}
                  selectedCardId={selectedCardId}
                  allCards={cardList}
                  toggleCardSelected={toggleCardSelected}
                />
              </Grid>
            ))}
            <Box className='button-add-cvv'>
              <IconButton
                className='button-new-card-checkout'
                style={domainColorStyle("color")}
                onClick={checkUserAuthentication}>
                <CreditCardIcon />
                <span>Adicionar novo cartão</span>
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModalConfirmCvv(true)}
                className='button-confirm-cvv'>
                Confirmar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
        isOpen={showModalAddCard}
        className="order-client-modal"
        toggleModal={() => setShowModalAddCard(!showModalAddCard)}>
        <ModalAddNewCard 
          setShowModal={setShowModalAddCard} 
          getNewCardList={listCards}
          updatePaymentInfos={updatePaymentInfos}/>
      </Modal>

      <Modal
        isOpen={showModalConfirmCvv}
        className="confirm-cvv-modal">
        <Box className='title-confirm-cvv'>
            <Button 
              className="btn-close-cvv" 
              style={domainColorStyle("color")}
              onClick={() => setShowModalConfirmCvv(false)}>
                Fechar</Button>
            <h4 style={domainColorStyle("color")}>Código de Segurança</h4>
            <Typography>Para confirmar sua compra, digite o código de segurança (CVV) escrito no seu cartão.</Typography>
          </Box>
          <Box className='input-cvv'>
            <TextField
              label="CVV"
              variant="outlined"
              value={cvv}
              onChange={(event) => setCvv(event.target.value)}
              type='number'
              required
              className='input-text-field'
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleConfirmClick}
              className='btn-input-cvv'>
              Confirmar
            </Button>
        </Box>
      </Modal>
    </>
  );
} 