import { Button } from "@material-ui/core";
import { getDomainMarketColor } from "config";
import React from "react";
import "./SeeMoreButton.scss";
import { getMenu } from "config";

function SeeMoreButton({ hasMoreCategories, showMoreCategories }) {
  const textButton = !getMenu ? "Ver mais ofertas" : "Ver Mais"
  const text = hasMoreCategories ? textButton : "Voltar ao topo";
  function handleClick() {
    hasMoreCategories
      ? showMoreCategories()
      : window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const style = hasMoreCategories
    ? {
      color: getDomainMarketColor(),
      border: `2px solid ${getDomainMarketColor()}`,
      background: "transparent",
      display: "flex",
      margin: "0 auto"
    }
    : {
      display: "flex",
      margin: "0 auto",
      color: "#fff",
      border: "none",
      background: getDomainMarketColor()
    };

  return (
    <Button
      className="see-more-btn"
      style={style}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}

export default SeeMoreButton;
