import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { NavLink } from "reactstrap";
import { useTheme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button, IconButton, Box, Container, Badge } from "@material-ui/core";
import { Person, ShoppingCart, Menu, Room, ArrowForwardIos } from "@material-ui/icons";
import petiLogo from '../../../assets/img/logo/petiLogoPrincipal.png'
import ReceiptIcon from '@material-ui/icons/Receipt';

import InputCustom from "components/InputCustom";
import MenuHeader from "../MenuHeader/MenuHeader";

import { domainColorStyle, getDomainLogo } from "config";
import { openCart } from "actions/cartActions";
import { seachTextChanged } from "actions/uiActions";

import { getMixesFromCart, getProductsTotal } from 'helpers/cartHelper';
import numberToCurrency from 'helpers/currency';
import { updateAbandonedOrder } from 'helpers/apiHelper';
import { setAbandonedCart } from 'actions/cartActions';
import { searchActiveChanged } from 'actions/uiActions';
import { getMarketById } from "helpers/apis/marketInfo";


function PrimaryHeader({
  automaticCoupons = [],
  mobileOpen,
  setSidebar,
  sidebar,
  setDrawerMobileOpen,
  address,
  textSearch,
  setTextSearch,
  infoMarket,
  changeAddress,
  checkPage,
  goToPage,
  cartOpen,
  isMenu
}) {

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const pendingAbandonedCart = useSelector(({ cart }) => cart.pendingAbandonedCart);
  const totalInCart = getProductsTotal(cart);
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const searchActive = useSelector(({ui}) => ui.searchActive)
  const { market_id } = useParams()
  const brand = useSelector(({ brand }) => brand.brand)
  const selectedCategory = useSelector(({ categories }) => categories.selectedCategory)
  const [nameMainMarket, setNameMainMarket] = useState("")

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };


  const cartAbandonmentData = useCallback(async () => {
    return setTimeout(async () => {
      if (pendingAbandonedCart) {
        await updateAbandonedOrder(cart);
        dispatch(setAbandonedCart(false))
      }
    }, 2000)
  }, [pendingAbandonedCart, cart, dispatch])

  useEffect(() => {
    dispatch(searchActiveChanged(textSearch.length >= 3));
  }, [textSearch]);

  useEffect(() => {
    if(!searchActive){
      dispatch(seachTextChanged(""))
    }
  }, [searchActive])

  useEffect(() => {
    setTextSearch("")
  }, [selectedCategory])

  useEffect(() => {
    const timeOut = cartAbandonmentData()
    return () => {
      clearTimeout(timeOut);
    };
  }, [pendingAbandonedCart])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToHome = () => {
    setTextSearch("")
    history.push(`/loja/${market_id || infoMarket?.id || brand?.main_market_id}`)
  }

  const isEAN = (value) => {
    return Boolean(Number(value) && value.length >= 8 && value.length <= 17)
  }

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    const newValue = isEAN(inputValue) ? inputValue.trim() : inputValue;
    dispatch(seachTextChanged(newValue));
    setTextSearch(newValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedValue = String(event.clipboardData.getData('Text')).trim();
    dispatch(seachTextChanged(pastedValue));
    setTextSearch(pastedValue);
  };

  const getNameMainMarket =  async (id) => {
    const response = await getMarketById(id)
    if(response.data){
      setNameMainMarket(response.data.name)
    }
  }

  useEffect(() => {
    getNameMainMarket(market_id)
  }, [])


 //criado para atender solici individual da mara Petí MAPP-10206
 const logoMarket = brand.id === 384 ? petiLogo :  getDomainLogo()

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "#fff", zIndex: 999 }}>
      <Toolbar className="toolbar-content">
        {(scrollPosition < 50 && automaticCoupons[0]) && !isMenu &&
          <p
            key={automaticCoupons[0].id}
            style={{ backgroundColor: theme.palette.primary.dark }}
            className="automatic-coupon">
            Aproveite o cupom de desconto e ganhe: {automaticCoupons[0].title}
          </p>
        }
        <Container style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Box style={{ width: "100%" }} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={() => setDrawerMobileOpen(!mobileOpen)}
              edge="start"
              style={{ marginLeft: 5 }}
              className={"menu-mobile-button"}
            >
              <Menu />
            </IconButton>
            
           <Link className='header-logo-image'>
            <img onClick={navigateToHome} alt={"logo"} src={logoMarket} />
            </Link>

            <Box className='hide-in-mobile-box' width={"85%"} display={"flex"} flexDirection={"column"}>
              <NavLink onClick={() => changeAddress()} style={{ width: "40%" }} className="link-header hide-in-mobile-search">
                <div className="address-new-header">
                  {!isMenu ?
                    (<>
                      <span className="textTitle" style={{ ...domainColorStyle("color") }}>
                        {address ? "Endereço de entrega:" : "Retirada em loja:"}
                      </span>
                      <span className="text" style={{ ...domainColorStyle("color") }}>
                        {address ? address : infoMarket.address || deliveryMethod.display_name}
                      </span>
                    </>)
                    : (
                      <>
                        <span className="textTitle" style={{ ...domainColorStyle("color") }}>
                          Loja:
                        </span>
                        <span className="text" style={{ ...domainColorStyle("color") }}>
                          {nameMainMarket}
                        </span>
                      </>
                    )
                  }
                </div>
              </NavLink>


              <Box display={"flex"} alignItems={"center"} justifyContent="space-between">
                {(!history.pathname?.includes("/produto/")) && (
                  <div style={{ width: "40%" }} className="hide-in-mobile-search">
                    <InputCustom
                      onChange={handleSearchChange}
                      showLabel={false}
                      placeholder="Pesquise seus produtos aqui!"
                      icon="fa fa-search"
                      value={textSearch}
                      onPaste={handlePaste}
                    />
                  </div>
                )
                }
                {!isMenu && 
                <div className="hide-in-mobile">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className='header-button'
                    onClick={() => goToPage("/user")}
                    startIcon={<Person />}
                  >
                    Minha Conta
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className='header-button'
                    onClick={() => goToPage("/user", { my_orders: true })}
                    startIcon={<ReceiptIcon />}
                  >
                    Pedidos
                  </Button>
                  <Badge badgeContent={cart.length || 0} color="primary" className='cart-badge' style={{ height: "100%" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className='header-button'
                      onClick={() => dispatch(openCart(!cartOpen))}
                      style={{ marginRight: 0 }}
                      startIcon={<ShoppingCart />}
                    >
                      {totalInCart > 0
                        ? `Total: ${numberToCurrency(totalInCart)}`
                        : "Carrinho"}

                    </Button>
                  </Badge>
                </div>}
              </Box>
            </Box>

           
            <Box className="menu-mobile">
              {!isMenu &&
                <>
                  <IconButton
                    color="primary"
                    edge="start"
                    onClick={() => goToPage("/user", { my_orders: true })}
                    style={{ marginRight: 5 }}
                    className="menu-mobile-button"
                  >
                    <Badge>
                      <Person />
                    </Badge>
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={() => dispatch(openCart(!cartOpen))}
                    edge="start"
                    className="menu-mobile-button"
                  >
                    <Badge badgeContent={cart.length || 0} color="primary" className='cart-badge'>
                      <ShoppingCart />
                    </Badge>
                  </IconButton>
                </>
              }
            </Box>


            <Box className="menu-mobile-button search-bar-mobile" style={{width: "98%"}}>
              {(checkPage() &&
                !history.pathname?.includes("/produto/")) && (
                  <InputCustom
                    onChange={(event) => {
                      dispatch(seachTextChanged(event.target.value))
                      setTextSearch(event.target.value);
                    }}
                    showLabel={false}
                    placeholder="Pesquise seus produtos aqui!"
                    icon="fa fa-search"
                    value={textSearch}
                  />
                )
              }
            </Box>

          </Box>
        </Container>
      </Toolbar>
      <Box className="menu-mobile-button search-bar-mobile">
        <NavLink onClick={() => changeAddress()} className="link-header__mobile">
          <div className="address-new-header address-new-header__mobile" style={{ ...domainColorStyle("backgroundColor") }}>
            <Container style={{ display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1 }}>
              {!isMenu ? (
                <>
                  <span className="textTitle">
                    <Room />
                    {address ? "Endereço de entrega:" : "Retirada em loja:"}
                  </span>
                  <span className="text">
                    {address ? address : infoMarket.address}
                  </span>
                  <ArrowForwardIos style={{ height: 12, color: "#fff" }} />

                </>
              ) : (
                <>
                  <span className="textTitle" style={{ fontWeight: 900, fontSize: 15 }}>
                    Loja:
                  </span>
                  <span className="text" >
                    {nameMainMarket}
                  </span>
                </>
              )}
            </Container>
          </div>
        </NavLink>
      </Box>
      <MenuHeader setSidebar={setSidebar} sidebar={sidebar} />
    </AppBar>
  )
}

export default PrimaryHeader;