import { SAVE_BRAND } from "ActionTypes";
import { TOGGLE_SIDEBAR, OPEN_DELIVERY_METHOD, HAS_AUTO_COUPONS } from "ActionTypes";

const initialState = {
  sideBarOpened: false,
  category: undefined,
  deliveryMethodOpen: false,
  hasAutoCoupons: false, 
  brand: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sideBarOpened: action.payload }
    case OPEN_DELIVERY_METHOD:
      return { ...state, deliveryMethodOpen: action.payload }
    case HAS_AUTO_COUPONS:
      return { ...state, hasAutoCoupons: action.payload }
    case SAVE_BRAND:
      return { ...state, brand: action.payload }
    default:
      return state;
  }
}
