import React, { useEffect, useState, useCallback } from "react";
import Messages from "components/ChatOrder/Messages";

import { domainColorStyle } from "config";
import { getChat } from "helpers/apis/chatApi";
import { sendNewMessage } from "helpers/apis/chatApi";
import { notification } from "helpers/util";

import "../../assets/css/react-confirm-alert.css";
import "./Chat.scss";
import getOneSignalPlayerId from "helpers/oneSignalHelper";

import { useSelector } from "react-redux";

import ButtonBackTop from "new/components/Button/ButtonBackTop/ButtonBackTop";
import UserLoginModal from "components/UserLoginModal/UserLoginModal";
import ForumIcon from '@material-ui/icons/Forum';
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { getDomainMarketColor } from "config";

const marketColor = getDomainMarketColor() 

function Chat({ market }) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatId, setChatId] = useState(null);
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const getChatGeral = useCallback(async () => {
      let player_id = await getOneSignalPlayerId();
      const chat = await getChat(player_id, userInfo.name, userInfo.phone_number, market.id);
      if (chat && chat.chat_id) {
        const socket = new WebSocket(
          `wss://merconnect.mercadapp.com.br/websockets/chats/${chat.chat_id}`
        );
        socket.onmessage = (menssage) => {
          setChatMessages(JSON.parse(menssage.data));
          setChatId(chat.chat_id);
        };
      }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.id) {
      getChatGeral();
    }
  }, [userInfo]);

  const sendMessage = () => {
    try {
      sendNewMessage(chatId, newMessage);
      setNewMessage("");
    } catch {
      notification("Ocorreu um erro ao enviar sua mensagem.", "danger");
    }
  };

  const handleChatButtonClick = () => {
    userInfo.id ? setIsChatOpen(true) : setIsAuthModalOpen(true);
  }

  return (
    <>
      {isChatOpen ? (
          <div className="floating-button-right chat-floating-button-right chat-menu shadow">
            <div className="text-center h-100 w-100">
              <div className="header-chat" style={domainColorStyle("background")}>
                <Typography variant="h6">Fale conosco</Typography>
                <IconButton onClick={() => setIsChatOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div
                style={{
                  marginTop: "3.1rem",
                  overflow: "auto",
                  height: "calc(100% - 3.1rem)",
                }}
              >
                <Messages chatMessages={chatMessages} />
                <div className="input-chat">
                  <input
                    onKeyDown={({ key }) => (key === "Enter" ? sendMessage() : null)}
                    value={newMessage}
                    onChange={({ target }) => setNewMessage(target.value)}
                  />
                  <button onClick={() => sendMessage()}>
                    <i
                      className="far text-exclamation fa-paper-plane mr-1"
                      style={{ ...domainColorStyle("color"), fontSize: 25 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
      ) : (
        <section className="floating-button-right chat-floating-button-right">
          <Typography variant="subtitle2" className="span-chat">
            Fale conosco
          </Typography>
          <Tooltip title="Fale Conosco" arrow>
            <IconButton
              style={{ backgroundColor: marketColor }}
              className="chat-icon-btn"
              color="primary"
              onClick={handleChatButtonClick}
            >
              <ForumIcon />
            </IconButton>
          </Tooltip>
          <ButtonBackTop />
        </section>
      )}
      {isAuthModalOpen && (
        <UserLoginModal showModal={isAuthModalOpen} toggleModal={setIsAuthModalOpen} />
      )}
    </>
  );  
}

export default React.memo(Chat);
