import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import { domainColorStyle } from 'config';
import { Box, Button, Card, Container, Grid, Paper } from '@material-ui/core';
import { getMixesFromCart } from 'helpers/cartHelper';
import { addToCart, removeFromCart, clearCart, removeProductFromCart } from 'actions/cartActions';
import { notification } from 'helpers/util';
import numberToCurrency from 'helpers/currency';
import AddRemoveComponent from 'new/components/AddRemove/AddRemove';
import CancelIcon from '@material-ui/icons/Cancel';

import './OrderItems.scss'
import { countItensCart } from 'helpers/util';
import { WithholdRevenueFlag } from 'new/components/WithholdRevenue';

export function OrderItems({ market, orderValidationMessage, getApiDiscounts, discounts }) {
  const dispatch = useDispatch()

  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const hasAutoCoupons = useSelector(({ settings }) => settings.hasAutoCoupons)
  const history = useHistory();
  const [checkCupons, setCheckCupons] = useState(false)
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod)
  const cartItemsCount = countItensCart(cart)

  const sendProductToCart = (product) => {
    const { amount, stock } = product;

    const stockIsOver = amount > stock
    const reachedMaxItemsQty = deliveryMethod.max_items_amount && cartItemsCount >= deliveryMethod.max_items_amount
    const reachedMaxDistinctItemsQty = deliveryMethod.max_distinct_items && cart.length > deliveryMethod.max_distinct_items
    
    switch (true) {
      case stockIsOver:
        notification("Quantidade máxima atingida para o item", "warning");
        break;
      case reachedMaxItemsQty:
        notification(`Limite de produtos no carrinho atingido (${deliveryMethod.max_items_amount})`, 'warning')
        break;
      case reachedMaxDistinctItemsQty:
        notification(`Limite de produtos distintos no carrinho atingido (${deliveryMethod.max_distinct_items})`, 'warning')
        break;
      default:
        dispatch(addToCart(product));
        if (hasAutoCoupons) {
          getApiDiscounts()
        } 
        setCheckCupons(true);
    }
  };

  const removeProductToCart = (product, removeAll) => {
    if (removeAll) {
      dispatch(removeProductFromCart(product));
    } else {
      dispatch(removeFromCart(product));
    }
    if (hasAutoCoupons) {
      getApiDiscounts()
    } 
    setCheckCupons(true);
  };

  const checkDiscounts = useCallback(
    (cart_here) => {
      const can_check = false;
      const coupon_discounts = discounts.filter((item) => item.coupon_code)
      if (coupon_discounts.length > 0) {
        getApiDiscounts(coupon_discounts[0]?.coupon_code, cart_here)
        setCheckCupons(can_check)
      }
    },
    [discounts, getApiDiscounts]
  )

  const handleValidationMessage = () => {
    const validationMessage = orderValidationMessage();
    if (validationMessage.length !== 0) {
      confirmAlert({
        title: validationMessage,
        buttons: [{ label: "Ok" }],
      });
    }
  }

  useEffect(() => {
    handleValidationMessage();
    if (checkCupons) {
      const cart_here = { ...cart }
      checkDiscounts(cart_here);
    }
  }, [cart, checkCupons])

  const clearCartClick = () => {
    confirmAlert({
      title: "Todos os produtos serão removidos do carrinho, deseja continuar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            dispatch(clearCart());
            history.push("/loja/" + market.id)
          },
        },
        { label: "Não" },
      ],
    });
  };

  return (
    <Card className='mt-3 items-details'>
      <Box
        display="flex"
        flexDirection="column"
        className='order-items-table'>
        <Container
          component={Paper}
          className='table-items'>
          <Box>
            <Box
              px={3}
              py={1}
              display={'flex'}
              justifyContent="space-between"
              className='table-head'>
              <p className="description" style={{ width: "30%" }}>Produto</p>
              <p className="description" style={{ width: "15%", textAlign: 'center' }}>Preço</p>
              <p className="description" style={{ textAlign: 'center' }}>Quantidade</p>
              <p className="description" style={{ width: "10%", textAlign: 'center' }}>Total</p>
              <p className="description" style={{ width: "10%" }}></p>
            </Box>
            <div className='products'>
              {cart.map((product) => (
                <Box
                  px={3}
                  py={1}
                  display={'flex'}
                  justifyContent="space-between"
                  flexWrap={'wrap'}
                  key={product.id}>
                  <div className='table-cell description' style={{ width: "30%" }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems='center'
                      className='product-description'>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <img alt="" className="chart-product-image" src={product.image} />
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} lg={9} className='product-decription'>
                        <label>{product.description}</label>
                        <WithholdRevenueFlag show={product?.withhold_revenue} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className='table-cell price'>
                    {product.price < product.original_price &&
                      <label className='original-price'>De: <del>{numberToCurrency(product.original_price)}</del></label>
                    }
                    <label>Por: {numberToCurrency(product.price)}</label>
                  </div>
                  <div className='table-cell' style={{ padding: '0 10px' }}>
                    <AddRemoveComponent
                      product={product}
                      amount={product.amount}
                      remove={() => removeProductToCart(product)}
                      add={() => sendProductToCart(product)} />
                  </div>
                  <div className='table-cell total-price'>{numberToCurrency(product.total_price)}</div>
                  <div className='table-cell total-price'>
                    <Button onClick={() => removeProductToCart(product, true)}>
                      <CancelIcon style={{ ...domainColorStyle('color') }} />
                    </Button>
                  </div>
                </Box>
              ))}
            </div>
          </Box>
        </Container>
        <div className='table-buttons'>
          <Button
            variant="outlined"
            color='primary'
            onClick={() => clearCartClick()}>Esvaziar</Button>
          <Button
            onClick={() =>
              history.push("/loja/" + market.id)
            }
            color='primary'
            variant="contained">
            Adicionar produtos</Button>
        </div>
      </Box>
    </Card>
  )
}

export default OrderItems;