import React, { useEffect } from 'react';
import { Box, Button, Card, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'new/components/Modal/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { formatarCEP } from 'helpers/util';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import './OrderDeliveryInfos.scss'
import InputForm from 'new/components/InputForm/InputForm';
import { userInfoChanged } from 'actions/userInfoActions';

export function OrderDeliveryInfos({ deliveryMethod = {}, market = {}, showAdrressModal, setShowAddressModal, formError }) {
  const zone = useSelector(({ zone }) => zone);
  const isValidStreet = !!zone.street
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const { complete_address } = market;
  const { address_attributes } = userInfo;
  const client_address = {
    cep: zone.cep,
    city: zone.city,
    state: zone.state,
    complement: address_attributes.complement,
    neighborhood: zone.neighborhood,
    neighborhood_id: zone.neighborhood_id,
    number: address_attributes.number,
    reference_spot: address_attributes.reference_spot,
    street: isValidStreet ? zone?.street : address_attributes.street
  }
  const dispatch = useDispatch();
  
  useEffect(() => {
    const zipCodeChanged = address_attributes?.cep !== zone?.cep
    if (deliveryMethod.mode === "delivery" && zipCodeChanged) {
      dispatch(userInfoChanged({ ...userInfo, address_attributes: client_address }));
    }
  }, [])

  const default_address = deliveryMethod.mode === "drivethru" ? complete_address : client_address;

  const delivery_infos_schema = Yup.object().shape({
    cep: Yup.string()
      .min(8, 'Insira um cep válido')
      .required('Campo obrigatório'),
    street: Yup.string()
      .min(4, 'O campo deve ter no mínimo 4 caracteres')
      .required('Campo obrigatório'),
    number: Yup.string()
      .max(5, 'Número deve ter no máximo 5 digitos')
      .required('Campo obrigatório'),
    reference_spot: Yup.string()
      .min(4, 'O campo deve ter no mínimo 4 caracteres')
      .max(128, 'O campo deve ter no máximo 128 caracteres')
      .required('Campo obrigatório'),
  });

  return (
    <>
      <Card className='mt-3 order-delivery-infos' style={formError === 4 ? { border: "solid 1px red" } : {}}>
        <Box
          display="flex"
          flexDirection="column">
          <Box pl={3} pr={2} pt={1} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title-details">
            <h5>Dados de {deliveryMethod.mode === "drivethru" ? 'Retirada' : 'Entrega'}</h5>
            {(deliveryMethod.mode !== "drivethru" || ((default_address.number && default_address.reference_spot) && deliveryMethod.mode === "drivethru")) &&
              <Button className='change-button' onClick={() => setShowAddressModal(true)} color="primary">Editar</Button>
            }
          </Box>
          {(deliveryMethod.mode === "drivethru" || (default_address.number && default_address.reference_spot)) ?
            <Box px={3} pb={2} className="delivery-infos" display="flex" flexWrap="wrap">
              <Grid item xs={12} md={6} lg={6}>
                <span>Rua</span>
                <p>{default_address.street}</p>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <span>CEP</span>
                <p>{formatarCEP(default_address.cep)}</p>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <span>Número</span>
                <p>{default_address.number}</p>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <span>Complemento</span>
                <p>{default_address.complement}</p>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <span>Bairro</span>
                <p>{default_address.neighborhood}</p>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <span>Cidade</span>
                <p>{default_address.city}</p>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <span>UF</span>
                <p>{default_address.state}</p>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <span>Ponto de referência</span>
                <p>{default_address.reference_spot || '-'}</p>
              </Grid>
            </Box>
            :
            <Box pb={2} display="flex" justifyContent="space-between">
              <AddressForm
                default_address={default_address}
                delivery_infos_schema={delivery_infos_schema}
                client={userInfo}
                setShowAddressModal={setShowAddressModal} />
            </Box>
          }
        </Box>
      </Card>

      <Modal
        isOpen={showAdrressModal}
        className="order-delivery-modal"
        toggleModal={() => setShowAddressModal(!showAdrressModal)}
      >
        <Box pl={3} pt={1} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title">
          <h5>Dados de Entrega</h5>
          <Button className='change-button' onClick={() => setShowAddressModal(false)}><CloseIcon /></Button>
        </Box>
        <AddressForm
          default_address={default_address}
          delivery_infos_schema={delivery_infos_schema}
          client={userInfo}
          setShowAddressModal={setShowAddressModal} />
      </Modal>
    </>
  )
}

const AddressForm = ({ default_address, delivery_infos_schema, client, setShowAddressModal }) => {
  const zone = useSelector(({ zone }) => zone);
  const isValidStreet = !!zone.street
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{
        cep: default_address.cep,
        street: default_address.street,
        number: default_address.number || "",
        complement: default_address.complement,
        reference_spot: default_address.reference_spot,
        neighborhood: default_address.neighborhood,
        city: default_address.city,
        state: default_address.state,
        neighborhood_id: default_address.neighborhood_id
      }}
      validationSchema={delivery_infos_schema}
      onSubmit={values => {
        dispatch(userInfoChanged({ ...client, address_attributes: values, terms_of_use_version: client.terms_of_use_version }))
        setShowAddressModal(false)
      }
      }
    >
      <Form noValidate>
        <Box px={3}>
          <Grid container className="order-delivery-inputs-form">
            <Grid item className='input-item read-only' xs={12}>
              <Field name="cep" label="CEP" total_width={true} disabled required component={InputForm} />
            </Grid>
            <Grid item className='input-item read-only' xs={12}>
              <Field name="street" label="Rua" total_width={true} disabled={isValidStreet} required component={InputForm} />
            </Grid>
            <Grid item className='input-item' xs={5}>
              <Field name="number" label="Número" total_width={true} required component={InputForm} />
            </Grid>
            <Grid item className='input-item' xs={6}>
              <Field name="complement" label="Complemento" total_width={true} component={InputForm} />
            </Grid>
            <Grid item className='input-item' xs={12}>
              <Field name="reference_spot" label="Ponto de referencia" total_width={true} required component={InputForm} />
            </Grid>

          </Grid>
        </Box>
        <Box mt={3} px={3} display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Button
            variant='outlined'
            onClick={() => setShowAddressModal(false)}
          >Cancelar</Button>
          <Button
            variant='contained'
            type='submit'
            color='primary'>Salvar</Button>
        </Box>
      </Form>
    </Formik >
  )
}

export default OrderDeliveryInfos;