import React from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProductSliderSkeleton from "new/components/ProductsSlider/ProductsSliderSkeleton";

function HomePageSkeleton() {
  const currentMarket = useSelector(({ markets }) => markets)
  const history = useHistory()
  const isValidStorePage = history.location.pathname !== `/loja/${currentMarket?.id}`
  return (
    <>
      {isValidStorePage &&
        <div style={{display: "flex", justifyContent: "flex-end"}} className="skeleton-store-page">
          <Skeleton width={125} height={40} />
        </div>}
      <ProductSliderSkeleton isValidStorePage={isValidStorePage}/>
      <ProductSliderSkeleton isValidStorePage={isValidStorePage}/>
    </>
  );
}

export default HomePageSkeleton;