import React, { useState, useEffect, useCallback, memo } from "react";
import { connect, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { domainColorStyle, getDomainLogo } from "config";
import InputMask from "components/InputMaskCustom";
import InputCustom from "components/InputCustom";
import { loginZoombox } from "helpers/apis/zoomboxApi";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { notification } from "helpers/util";
import { userInfoChanged } from "../../actions/userInfoActions";
import loadsContent from "../loadsContent";
import { parseQueryString } from "../../helpers/util";
import "./club.scss";
import { login as mercafacilLoginApi } from "helpers/apis/mercafacilApi";
import { login as izioLoginApi } from "helpers/apis/izioApi";
import { HomePageLayout } from "components/HomePageLayout";
import { unmask } from "../../helpers/util";

function Login({
  userInfoChanged,
  crmModule,
  ignorePageLayout = false,
  required = false,
  closeModal = () => {},
}) {
  const history = useHistory();
  const [crm, setCRM] = useState(crmModule || {});
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const brand = useSelector(({brand}) => brand.brand)

  const getMctUrlParams = useCallback(() => {
    const readUrl = parseQueryString(window.location);
    userInfoChanged({
      name: readUrl.mc_name,
      cpf: readUrl.mc_cpf,
      email: readUrl.mc_email,
      sex: readUrl.mc_sex,
      phone_number: readUrl.mc_phone_number,
      birthDate: readUrl.mc_birthdate,
    });
  }, [userInfoChanged]);

  useEffect(() => {
    const recoverBrand = async () => {
      setCRM({ ...crm, ...brand.crm });
    };
    if (Object.keys(crm).length === 0) {
      recoverBrand();
    }
    getMcCpf();
    getMctUrlParams();
  }, [crm]);

  function getMcCpf() {
    const readUrl = parseQueryString(window.location);
    if (readUrl.mc_cpf) {
      setUser(readUrl.mc_cpf);
      localStorage.setItem("crmUserCpf", readUrl.mc_cpf);
    }
  }

  const zoomboxLogin = async () => {
    const userLogged = await loginZoombox(unmask(user), password);
    localStorage.setItem("crmToken", userLogged.token);
    localStorage.setItem("crmUserCpf", userLogged.cpfcnpj);
    localStorage.setItem("crmUser", user);
    localStorage.setItem("crmUserName", userLogged.nome);
  };

  const mercafacilLogin = async (brand) => {
    const checkUser = await mercafacilLoginApi(unmask(user), brand);
    if (checkUser.loyalty) {
      localStorage.setItem("crmToken", crm.token);
      localStorage.setItem("crmUserCpf", user);
    } else {
      notification("Você não possui cadastro nesta loja.", "warning");
    }
  };

  const izioLogin = async (brand) => {
    const checkUser = await izioLoginApi(unmask(user), brand);
    if ((checkUser.payload || {}).cod_pessoa) {
      localStorage.setItem("crmToken", crm.token);
      localStorage.setItem("crmUserCpf", user);
    } else {
      notification("Você não possui cadastro nesta loja.", "warning");
    }
  };

  const doLogin = async () => {
    try {
      setLoading(true);
      switch (crm.company) {
        case "zoombox":
          await zoomboxLogin();
          break;
        case "mercafacil":
          await mercafacilLogin(brand);
          break;
        case "izio":
          await izioLogin(brand);
          break;
        default:
          localStorage.setItem("crmUserCpf", user);
          break;
      }
      required ? closeModal() : history.replace("/");
    } catch (error) {
      switch (crm.company) {
        case "zoombox":
          notification(error.messageError.message, "danger");
          break;
        default:
          notification("Você não possui cadastro nesta loja.", "warning");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const isZoombox = () => {
    return crm.company === "zoombox";
  };

  return (
    <HomePageLayout ignore={ignorePageLayout}>
      <div className="modal-body">
        <div className="logo">
          <img
            className="img mx-auto"
            src={getDomainLogo()}
            alt="Logo do mercado"
          />
        </div>
        {required && (
          <h5 style={{ color: "#595959", fontWeight: "bold" }}>
            Entre para continuar comprando
          </h5>
        )}
        <div style={{ width: "100%" }}>
          <strong style={domainColorStyle("color")}>CPF</strong>
        </div>
        <div className="login">
          <div className="form">
            <InputMask
              mask="999.999.999-99"
              inputMode="numeric"
              required
              placeholder="CPF (Apenas números)"
              name="cpf"
              showLabel={false}
              onChange={({ target }) => setUser(target.value)}
              value={user}
            />

            {isZoombox() && (
              <InputCustom
                showLabel={false}
                required
                placeholder="Senha"
                type="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
            )}
            <div className="buttons mt-4">
              <Button
                onClick={() => doLogin()}
                disabled={loading}
                style={
                  loading
                    ? { backgroundColor: "#ccc" }
                    : {
                        ...domainColorStyle("backgroundColor"),
                        color: "#fff",
                      }
                }
              >
                {loading ? <CircularProgress size={25} /> : "Acessar"}
              </Button>
              {crm.enable_registering && (
                <Button
                  outline
                  style={{
                    ...domainColorStyle("borderColor"),
                    ...domainColorStyle("color"),
                    marginTop: "10px",
                  }}
                  onClick={() => history.push("/clube/cadastro")}
                >
                  Cadastrar
                </Button>
              )}
              {!required && (
                <>
                  <div className="or">
                    <span>ou</span>
                  </div>
                  <Button
                    onClick={() => history.push("/?ignore_club_redirect=true")}
                    outline
                    disabled={loading}
                    style={
                      loading
                        ? { backgroundColor: "#ccc" }
                        : {
                            ...domainColorStyle("backgroundColor"),
                            color: "#fff",
                          }
                    }
                  >
                    {loading ? (
                      <CircularProgress size={25} />
                    ) : (
                      "Ir para a loja"
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="terms">
          <span>Ao utilizar o site voce estará concordando com o </span>
          <a
            href="https://www.linx.com.br/aviso-de-privacidade"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de privacidade do operador
          </a>
          .
        </div>
      </div>
    </HomePageLayout>
  );
}

const mapDispatchToProps = () => ({
  userInfoChanged,
});

export default loadsContent(connect(mapDispatchToProps, null)(memo(Login)));
