import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router";
import Carousel from "./Carousel";
import { Fade } from "react-reveal";
import { openUrl } from "helpers/util";
import { getBannerInfos } from "helpers/apis/marketInfo";
import BannerProductsModal from "../../../components/StorePage/BannerProductsModal/BannerProductsModal";
import { useStylesHeader } from "assets/useStyles";
import { applyProductFunctions } from "helpers/models/product";
import { Grid } from "@material-ui/core";
import { getBannerDeliveryMethod } from "helpers/apis/marketInfo";
import { bannersChanged } from "actions/marketStuffActions";
import "./Carousel.scss";

function CarouselView(props) {
  const { history } = props;

  const classesHeader = useStylesHeader();
  const [bannerSelected, setBannerSelected] = React.useState({});
  const [bannerProducts, setBannerProducts] = React.useState(false);
  const deliveryMethod = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod);
  const { market_id } = useParams();
  const validateProductScreen = history.location.pathname === `/loja/${market_id}`
  const banners = useSelector(({ marketStuff }) => marketStuff.banners);
  const dispatch = useDispatch()

  const categories = useSelector(({ categories }) => categories.categories)
  const currentCategory = useSelector(({categories}) => categories.selectedCategory?.id) || undefined
  const searchActive = useSelector(({ui}) => ui.searchActive)

  async function getBanners() {
    const {banners} = await getBannerDeliveryMethod(
      market_id,
      deliveryMethod.id
      );
    dispatch(bannersChanged(banners))
  }

  useEffect(() => {
    getBanners()
  }, [])

  async function changeBanner(banner) {
    if (banner.kind === "flipbook") {
      const { flipbook_id } = banners.find((item) => item.id === banner.id);
      history.push(`${market_id}/encartes/${flipbook_id}`);
    }
    if (banner.kind === "associated_products") {
      setBannerSelected({ ...banner, products: null });
      setBannerProducts(true);
      const productsBanner = await getBannerInfos(market_id || 3, banner.id, deliveryMethod.id);
      setBannerSelected({
        ...banner,
        products: applyProductFunctions(productsBanner.mixes),
      });
      setBannerProducts(true);
    }
    if (banner.link) {
      openUrl(banner.link);
    }
  }

  const carouselItens = (banners || []).map((banner) => {
    return {
      src: banner.image,
      altText: banner.name,
      caption: "",
      header: "",
      link: banner.link,
      kind: banner.kind,
      id: banner.id,
      is_publicity: banner.is_publicity,
      onClick: () => changeBanner(banner),
    };
  });

  if (
    (categories || []).length &&
    currentCategory === undefined &&
    !searchActive && validateProductScreen
  ) {
    return carouselItens.length > 2 ? (
      <div className={classesHeader.carouselMargin} style={{maxWidth: 1220, margin: "0 auto"}}>
        <Grid spacing={4} container className="carousel-banner-two">
          <Grid  item className="variable-banner">
            <Fade>
              <Carousel items={carouselItens} />
            </Fade>
          </Grid>
          <Grid
            item
            className="fixed-banners"
          >
            <img alt="banner" src={carouselItens[0].src} className="carousel-banner-img" />
            <img alt="banner" src={carouselItens[1].src} className="carousel-banner-img" />
          </Grid>
        </Grid>
        <BannerProductsModal
          isOpen={bannerProducts}
          toggleModal={(value) => setBannerProducts(value)}
          banner={bannerSelected}
        />
      </div>
    ) : carouselItens.length > 0 ? (   
      <div className={classesHeader.carouselMargin} style={{maxWidth: 1220, margin: "0 auto"}}>
        <Grid spacing={4} container className="carousel-banner-one">
          <Grid xs={12} item>
            <Fade>
              <Carousel items={carouselItens} />
            </Fade>
          </Grid>
        </Grid>
        <BannerProductsModal
          isOpen={bannerProducts}
          toggleModal={(value) => setBannerProducts(value)}
          banner={bannerSelected}
        />
      </div>
    ): <></>
  }

  return null;
}

export default (withRouter(CarouselView));
