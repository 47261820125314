import React from "react";
import clsx from "clsx";
import Header from "./SuperComponents/Header/index";
import { useStylesSidebar } from "assets/useStyles";

const LayoutWrapper = (Children) =>
  function AnimatedWrapper(props) {
    const [sideBar, setSidebar] = React.useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStylesSidebar();

    const setDrawerMobileOpen = (value) => {
      setSidebar(value);
      setMobileOpen(value);
    };
    return (
      <>
        <Header
          sidebar={sideBar}
          setSidebar={setSidebar}
          mobileOpen={mobileOpen}
          setDrawerMobileOpen={setDrawerMobileOpen}
        />
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: sideBar,
          })}
        >
          <Children {...props} />
        </div>
      </>
    );
  };

export default LayoutWrapper;
