import React, { useEffect, useState } from "react";
import Routes from "./router";
import firebase from "firebase/app";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { loadState, saveState } from "./stateLocalStorage";
import reducers from "./reducers/index";
import { getOneSignalId } from "config.js";
import AppNotices from "components/AppNotices/AppNotices";
import { getAppNotices } from "helpers/apis/oAuthApi";
import { devToolsEnhancer } from "redux-devtools-extension/logOnlyInProduction";
import { registerCRMDataListener } from "helpers/models/product";
import { registerLinxImpulseParametersListener } from "helpers/apis/impulseEventsApiHelper";
import { v4 as uuidv4 } from "uuid";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { getDomainMarketColor } from "config";
import ModalNotificationPix from "components/ModalNotificationPix/ModalNotificationPix";
import moment from "moment";
import { getBrandInfo } from "helpers/apis/brandApi";
import { getAppNoticesCep } from "helpers/apis/oAuthApi";


const persistedState = loadState();
// Cria store preparada para usar Redux Dev Tools do Google Chrome.
// - Extensão: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
// - NPM: https://github.com/zalmoxisus/redux-devtools-extension
export const store = createStore(reducers, persistedState, devToolsEnhancer());

// Mesmo que a store dê vários reloads, garantimos com o throttle que saveState() só será chamado uma vez a cada segundo
store.subscribe(() => {
  saveState(store.getState());
});

registerCRMDataListener(store);
registerLinxImpulseParametersListener(store);

window.OneSignal.push(async function () {
  await window.OneSignal.init({
    appId: getOneSignalId(),
    // safari_web_id: getOneSignalIdSafari(),
    subdomainName: "mercadapp.os.tc",
    notifyButton: {
      enable: false,
    },
    // allowLocalhostAsSecureOrigin: true,
  });
});



async function setSubscription() {
  const isPushNotificationsEnabled =
    await window.OneSignal.isPushNotificationsEnabled();
  if (!isPushNotificationsEnabled) {
    window.OneSignal.showNativePrompt();
    window.OneSignal.setSubscription(true);
    window.OneSignal.registerForPushNotifications();
  }
}
window.OneSignal.push(function () {
  if (!window.OneSignal.isPushNotificationsSupported()) {
    return;
  }
  setSubscription();
});

if (!localStorage.deviceId) {
  localStorage.deviceId = uuidv4();
}

function App() {
  const [firebaseConfig, setFirebaseConfig] = useState(false);
  const [pixNotice, setPixNotice] = useState(false);
  const [brand, setBrand] = useState(null);
  const [notices, setNotices] = useState(null)
  const [noticesCep, setNoticesCep] = useState(null)
  const [currIndex, setCurrIndex] = useState(0)
  const [currIndexCep, setCurrIndexCep] = useState(0)
  const [displayPriorityForWarning, setDisplayPriorityForWarning] = useState()
  const storageVersion = "2.1"
  
  useEffect(() => {
    async function fetchData() {
      const brand = await getBrandInfo();
      setBrand(brand);
      setFirebaseConfig(true);
    }
    try {
      fetchData();
      clearStorage();
      if (!pixNotice) {
        getNotificationPix();
      }
      if(!noticesCep){
        getNoticesCep()
      }
      if (!notices) {
        getNotices()
      }
    } catch (error) {
      console.log(error)
    }
  }, []);

  

  var socket;
  const getNotificationPix = async () => {
    const lastIdPixOrder = localStorage.getItem("last_id_pix_order");
    if (lastIdPixOrder) {
      socket = new WebSocket(
        `wss://merconnect.mercadapp.com.br/websockets/orders/${lastIdPixOrder}`
      );
      const lastPixExpiresAt = localStorage.getItem("last_pix_expires_at");
      const timeInSeconds = getRemainingTimeInSeconds(lastPixExpiresAt);
      if (timeInSeconds < 1) {
        localStorage.removeItem("last_pix_expires_at");
        localStorage.removeItem("last_id_pix_order");
        socket.close();
      } else {
        socket.onmessage = (notice) => {
          setPixNotice(notice.data);
          socket.close();
        };
      }
    }
  };

  function clearStorage() {
    const localStorageVersion = localStorage.getItem("storage_version")
    if (localStorageVersion && localStorageVersion !== storageVersion) {
      localStorage.clear();
      firebase.auth().signOut();
      localStorage.setItem("storage_version", storageVersion);
    }
  }

  function getRemainingTimeInSeconds(pixExpiresAt) {
    const currentTime = moment();
    const pixExpirationTime = moment(pixExpiresAt);
    var diff = moment(pixExpirationTime, "DD/MM/YYYY HH:mm:ss").diff(
      moment(currentTime, "DD/MM/YYYY HH:mm:ss")
    );
    return moment.duration(diff).asSeconds();
  }


  const getNoticesCep = async () => {
    const response = await getAppNoticesCep()
    setNoticesCep(response?.app_notices)
  }

  const getNotices = async () => {
    const response = await getAppNotices();
    setNotices(response?.app_notices)
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: getDomainMarketColor(),
        contrastText: "#fff"
      },
    },
    typography: {
      // eslint-disable-next-line quotes
      fontFamily: '"Lato", sans-serif',
    },
  });

  useEffect(() => {
    if (noticesCep) {
      if (noticesCep?.length === currIndexCep) {
        setDisplayPriorityForWarning(true)
      }
    } else if (!noticesCep && notices?.length) (
      setDisplayPriorityForWarning(true)
    )
  }, [currIndex, currIndexCep, notices, noticesCep])

  return (
    <ThemeProvider theme={theme}>
      {firebaseConfig && (
        <Provider store={store}>
          <Routes brand={brand} />
          {(noticesCep || []).map((noticeCep, index) => (
            <AppNotices
              isOpen={currIndexCep === index}
              toggleModal={() => setCurrIndexCep(index+1)}
              notice={noticeCep}
              logo
              orderModals
            />
          ))}  
          {displayPriorityForWarning && (notices || []).map((notice, index) => (
            <AppNotices
              isOpen={currIndex === index}
              toggleModal={() => setCurrIndex(index+1)}
              notice={notice}
              logo
            />
          ))}
          {pixNotice && (
            <ModalNotificationPix
              isOpen={pixNotice}
              toggleModal={() => setPixNotice(false)}
              notice={pixNotice}
            />
          )}
        </Provider>
      )}
    </ThemeProvider>
  );
}

export default App;
