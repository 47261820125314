import axios from "axios";

export async function login(CPF, brand) {
  try {
    if (brand.crm) {
      const response = await axios.post(
        `${brand.crm.base_url}/PessoaRest/api/Pessoa/VerificarPessoaExiste/token`,
        {
          cod_cpf: CPF,
        },
        {
          headers: { tokenAutenticacao: brand.crm.token },
        }
      );
      return response.data;
    }
  } catch (error) {
    console.log("Brand error");
  }
}

export async function createUser(userData, brand) {
  if (brand.crm) {
    const response = await axios.post(
      `${brand.crm.base_url}/PessoaRest/api/Pessoa/InserirPessoa/{tokenAutenticacao}`,
      userData,
      {
        headers: { tokenAutenticacao: brand.crm.token },
      }
    );
    return response.data;
  }
}
