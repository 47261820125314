import {
    SET_ORDER_ID,
    SET_ORDER_NUMBER,
    SET_ORDER_STATUS
} from "ActionTypes"

const INITIAL_STATE = {
    order_id: null,
    status: null,
    order_number: null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ORDER_ID:
            return Object.assign({}, state, {
                order_id: action.payload,
            })
        case SET_ORDER_NUMBER:
            return Object.assign({}, state, {
                order_number: action.payload,
            })
        case SET_ORDER_STATUS:
            return Object.assign({}, state, {
                status: action.payload,
            })
        default:
            return state
    }
}