import React from "react";
import "./ShowAllButton.scss";
import { useHistory, useParams } from "react-router";
import { getDomainMarketColor } from "config";
import { Button, useMediaQuery } from "@material-ui/core";
import { getMenu } from "config";
import { useDispatch } from "react-redux";
import { changeSelectedCategory } from "actions/categoriesActions";
import { showCommonCategoriesOnHome } from "config";

function ShowAllButton({section, setShowModal, showModal, showInCarousel }) {
  const { market_id } = useParams();
  const history = useHistory();
  const isMenuMode = getMenu()
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch()
  const homeWithCommonCategories = showCommonCategoriesOnHome()

  function handleClick() {
    const shouldNavigateToCategory = (isMenuMode && isSmallScreen) || (homeWithCommonCategories && section.id !== "destaques");
    if (shouldNavigateToCategory) {
      dispatch(changeSelectedCategory(section))
      history.push(`/loja/${market_id}/categoria/${section.id}`)
      return
    }

    if (section.display_name === "Ofertas") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      history.push(`/loja/${market_id}/ofertas`);
    } else {
      setShowModal(!showModal);
    }
  }
  
  const customClass = showInCarousel ? `show-all-carousel` : `show-all`

  return (
    <Button
      className={customClass}
      style={{ color: getDomainMarketColor() }}
      onClick={handleClick}
    >
      Ver todos
    </Button>
  );
}

export default ShowAllButton;
