import React, { useState, useEffect } from "react";
import { domainColorStyle } from "config";
import { Modal } from "reactstrap";
import { getItemsByPromotionId } from "helpers/apis/itemApi";
import { getDomainMarketId } from "config";
import { ToDecimal } from "helpers/currency";
import { applyProductFunctions } from "helpers/models/product";
import "./ModalOfferTakeMore.scss";
function ModalOfferTakeMore({ isOpen, toggleModal, product, addToCart }) {
  const [productSecondary, setProductSecondary] = useState(null);

  useEffect(() => {
    const marketId = getDomainMarketId();
    getItemsByPromotionId(marketId, product.promotion_id, product.id).then(
      (items) => {
        items = applyProductFunctions(items);
        if (items[0]) setProductSecondary(items[0]);
      }
    );
  }, [product.promotion_id, product.id]);

  function addItemsToCart() {
    for (let i = 1; i < product.promotion_discount_table[0].amount; i++) {
      addToCart(product);
    }
    addToCart(productSecondary);
  }
  return (
    productSecondary && (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className="modal-dialog-centered modalTakeMore"
      >
        <div
          className="modal-top"
          style={{
            ...domainColorStyle("backgroundColor"),
          }}
        />

        <div className="container">
          <div className="title">
            <strong>Oferta </strong>
            <strong style={{ ...domainColorStyle("color") }}>Leve +</strong>
          </div>
          <div style={{ color: "#686868 ", lineHeight: "20px" }}>
            Comprando <strong>{product.description}</strong> com
            <strong>
              {" "}
              {product.promotion_discount_table[0].amount} unid + R${" "}
              {ToDecimal(product.promotion_discount_table[0].weight)}
            </strong>
            <br /> leve um <strong>{productSecondary.description}</strong>
          </div>
          <div className="container-products">
            <div className="product">
              <img alt="Primeiro produto" src={product.image}></img>
              <div style={{ width: "120px" }}>
                <div className="product-description">{product.description}</div>
                <div
                  style={{
                    ...domainColorStyle("color"),
                    fontWeight: 800,
                    fontSize: 16,
                  }}
                >
                  {product.promotion_discount_table[0].amount} unidades
                </div>
              </div>
            </div>

            <h1>+</h1>

            <div className="product">
              <img alt="Segundo produto" src={productSecondary.image}></img>
              <div style={{ width: "120px" }}>
                <div className="product-description">
                  {productSecondary.description}
                </div>
                <div
                  style={{
                    ...domainColorStyle("color"),
                    fontWeight: 800,
                    fontSize: 16,
                  }}
                >
                  por R$ {ToDecimal(product.promotion_discount_table[0].weight)}
                </div>
              </div>
            </div>
          </div>
          <button
            style={{
              ...domainColorStyle("backgroundColor"),
            }}
            onClick={() => {
              addItemsToCart();
              toggleModal();
            }}
          >
            <i className="fa fa-shopping-cart text-white" />
            {"  "}Adicionar produtos
          </button>
        </div>
      </Modal>
    )
  );
}

export default ModalOfferTakeMore;
