import React from 'react';
import { FormControl, FormHelperText, Input, InputLabel, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';


const InputForm = ({ field, form: { errors, touched }, total_width, ...props }) => (
  <FormControl {...props} fullWidth={total_width} error={errors[field.name] && touched[field.name]}>
    {props.type === "date" ?
      <TextField
        id="date"
        label={props.label}
        type={props.type}
        required={props.required}
        {...field}
        InputLabelProps={{
          shrink: true,
        }}
      /> :
      <>
        <InputLabel {...props} htmlFor="standard-adornment-amount">{props.label}</InputLabel>
        {props.mask ?
        <TextMaskCustom {...field} errors={errors} readOnly={props.readOnly} touched={touched} mask={props.mask} />
        :
        <Input {...field} />}
      </>
    }
    {(errors[field.name] && touched[field.name]) && <FormHelperText>{errors[field.name]}</FormHelperText>}
  </FormControl>
);

function TextMaskCustom({ errors, touched, name, ...props }) {
  return (
    <InputMask {...props}>
      {() => <Input error={errors[name] && touched[name]} name={name} />}
    </InputMask>
  );
}

export default React.memo(InputForm);