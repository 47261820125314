import React, { useState, useEffect } from "react";
import { getItensOrder, existingOrderChange } from "helpers/apiHelper";
import { notification } from 'helpers/util';
import { Box, Button } from "@material-ui/core";

import "./OrderItemsEdit.scss"

import LoadingContent from "components/Loading/Loading";
import Modal from "new/components/Modal/Modal";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SearchProductsModal from "./SearchProductsModal";
import OrderItemsTable from "./OrderItemsTable";
import { arrayHasContent } from "helpers/util";

function OrderItemsEdit({ isOpen, closeModal, orderId, loadOrderItems, orderNumber }) {
  const [orderItems, setOrderItems] = useState([]);
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const marketId =  useSelector(({ markets }) => markets.id) || localStorage.getItem("market_id");
  const orderItemsWithAmount = orderItems.filter((orderItem) => Number(orderItem.amount) > 0)
  const totalAmountOfItems = orderItems.reduce((sum, item) => sum + parseFloat(item.amount), 0);

  const updateAmountProduct = (item_id, isIncrement, newItemData, newItemAmount) => {

    //responsavel por adicionar ou remover quantidades do produto
    const getItemAmount = (orderItem, itemId) => {
      if (orderItem.item_id !== itemId) return orderItem.amount;

      const orderItemNumber = parseFloat(orderItem.amount);

      return isIncrement ? `${orderItemNumber + 1}` : `${orderItemNumber - 1}`;
    };

    const existingItem = orderItems.find((orderItem) => orderItem.item_id === newItemData?.id);

    let orderItemsMap;

    if (newItemData) {
      if (existingItem) {
        orderItemsMap = orderItems.map((orderItem) => {
          if (orderItem.item_id === newItemData.id) {
            const newAmount = getItemAmount(orderItem, item_id);
            return {
              ...orderItem,
              amount: newAmount,
              total_price: parseFloat(newAmount) * parseFloat(orderItem.price),
            };
          }
          return orderItem;
        });
      } else {
        const newItemToAdd = {
          amount: newItemAmount,
          description: newItemData.description,
          image: newItemData.image,
          item_id: newItemData.id,
          original_price: newItemData.original_price,
          price: newItemData.price,
          total_price: parseFloat(newItemAmount) * parseFloat(newItemData.price),
          stock: newItemData.stock,
        };
        orderItemsMap = [...orderItems, newItemToAdd];
      }
    } else {
      orderItemsMap = orderItems.map(orderItem => {
        const newAmount = getItemAmount(orderItem, item_id)
        return {
          ...orderItem,
          amount: newAmount,
          total_price: parseFloat(newAmount) * parseFloat(orderItem.price)
        }
      })
    }

    totalAmountOfItems === 1
      ? notification("Seu pedido não pode estar vazio", "warning")
      : setOrderItems(orderItemsMap);
  }

  const removeEntireProduct = (item_id) => {
    if (orderItemsWithAmount.length === 1) {
      notification("Seu pedido não pode estar vazio", "warning")
      return
    }

    const updatedItems = orderItems.map(item => {
      if (item.item_id === item_id) {
        return { ...item, amount: 0 }
      }
      return item
    })

    setOrderItems(updatedItems)
  }


  const handleUpdateOrder = async () => {
    setIsLoading(true)
    try {
      const response = await existingOrderChange(orderId, orderItems);
      if (arrayHasContent(response?.data?.problems)) {
        response.data.problems.map(({ error_message }) => (
          notification(error_message, "error")
        ))
      } else {
        notification("Pedido atualizado com sucesso!", "success")
      }
    } catch (error) {
      console.log(error)
      notification("Ocorreu um erro ao editar o pedido", "error")
    } finally {
      setIsLoading(false)
      closeModal();
      loadOrderItems();
    }
  };

  useEffect(() => {
    async function fetchOrderItems() {
      setIsLoading(true)
      try {
        const { data } = await getItensOrder(orderId)
        setOrderItems(data)
      } catch (error) {
        console.log(error)
        notification("Erro ao buscar itens do pedido", "error")
      } finally {
        setIsLoading(false)
      }
    }
    fetchOrderItems()
  }, [orderId])

  return (
    <>
      <Modal isOpen={isOpen} toggleModal={closeModal}>
        <div className="modal-items-edit">
          <Row className="mb-2">
            <Col xs={12} md={1}>
              <Button
                style={{ textTransform: "capitalize" }}
                color="primary"
                startIcon={<ArrowBack />}
                onClick={closeModal}
              >
                Sair
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <LoadingContent />
          ) : (
            <OrderItemsTable
              orderItems={orderItemsWithAmount}
              updateProductAmount={updateAmountProduct}
              removeEntireProduct={removeEntireProduct}
            />
          )}
          <Box className="edit-order-buttons">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenModalAddProduct(true)}
            >
              Adicionar produtos
            </Button>
            <Button variant="contained" color="primary" onClick={handleUpdateOrder}>
              Salvar
            </Button>
          </Box>
        </div>
      </Modal>
      <SearchProductsModal
        isOpen={openModalAddProduct}
        setIsOpen={setOpenModalAddProduct}
        orderNumber={orderNumber}
        marketId={marketId}
        updateProductAmount={updateAmountProduct}
      />
    </>
  );
}

export default OrderItemsEdit;