import { Box, Button, Grid } from "@material-ui/core";
import React from "react";

export default function PaymentSectionsFooter({defaultPayment ,adjustableGridSize, images}) {
  const titleSectionPayment = defaultPayment.filter(title => title.kind === 'payment').map(title => title.title)

  return (
    <>
    <hr style={{ margin: "1em 0" }} />
    <Grid item xs={adjustableGridSize} className='support-footer-section-area footer-section-area-mobile'>
      <h6 style={{textTransform: "uppercase"}}>{titleSectionPayment.length ? titleSectionPayment : "PAGUE COM"}</h6>
      <Box className='footer-payment-method-information'>
        {images.map((image, index) => (
          <Box key={index} p={0.5}>
            <img src={image} alt="cartão" className="pay-image-footer" />
          </Box>
        ))}
        <Button href="/informacoes" className="button-info" color="primary">
          Ver Mais
        </Button>
      </Box>
    </Grid>
    </>
  )
}