import axios from "axios"
import { getRecaptchaSiteKey } from "config"

export const checkCaptcha = async (token) => {
  const recaptchaKeyCheck = getRecaptchaSiteKey()
  let recaptchaKeyIndex;
  switch (recaptchaKeyCheck) {
    case process.env.REACT_APP_RECAPTCHA_SITE_KEY1:
      recaptchaKeyIndex = 0;
      break;
    case process.env.REACT_APP_RECAPTCHA_SITE_KEY2:
      recaptchaKeyIndex = 1;
      break;
    default:
      recaptchaKeyIndex = 2;
  }
  const response = await axios.post("https://recaptcha-checker.merconnect.com.br/submit", {
    "g-recaptcha-response": token,
    "recaptcha-key-index": recaptchaKeyIndex
  })

  return response.data.success
}
