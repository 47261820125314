import React, { useState, useEffect, useCallback, memo } from "react";
import { Button } from "reactstrap";
import { showNotification, unmask, parseQueryString } from "../../helpers/util";
import { setZoneForRequestedCep } from "actions/setZoneForRequestedCep";
import InputMask from "../InputMaskCustom";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import loadsContent from "../loadsContent";
import { domainColorStyle, getDomainLogo, getDomainName } from "config";
import { userInfoChanged } from "../../actions/userInfoActions";
import { getZoneForCep } from "../../helpers/apis/marketInfo";
import Modal from "../Modal/modal";
import ListMarkets from "./ListMarkets";
import AppNotices from "components/AppNotices/AppNotices";
import {
  termsOfUse,
  privacyPolicy,
} from "components/ termsOfUse/recibomTermsOfUse";
import { Grid } from "@material-ui/core";
import "./home.scss";
function CEPForm(props) {
  const {
    isLoading,
    toggleLoading,
    setZoneForRequestedCep,
    userInfoChanged,
    setModalDeliveryMethods = () => { },
  } = props;
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const [hasDelivery, setHasDelivery] = useState(false);
  const [hasDriveThru, setHasDriveThru] = useState(false);

  const [deliveryMode, setDeliveryMode] = useState("delivery");

  const [showModal, setShowModal] = useState(false);

  const [cep, setCep] = useState(localStorage.lastCep || "");
  const [cpf] = useState("");
  const [markets, setMarkets] = useState("");

  const [thisZoneForCep, setZoneForCep] = useState(null);
  const [modalRecibomTermsOfUse, setModalRecibomTermsOfUse] = useState(false);
  const [modalRecibomPrivacyPolicy, setModalRecibomPrivacyPolicy] =
    useState(false);

  const brand = useSelector(({brand}) => brand.brand)

  const getMctUrlParams = useCallback(() => {
    const readUrl = parseQueryString(window.location);
    userInfoChanged({
      ...userInfo,
      name: readUrl.mc_name || userInfo.name,
      cpf: readUrl.mc_cpf || userInfo.cpf,
      email: readUrl.mc_email || userInfo.email,
      sex: readUrl.mc_sex || userInfo.sex,
      phone_number: readUrl.mc_phone_number || userInfo.phone_number,
      birthDate: readUrl.mc_birthdate || userInfo.birthDate || userInfo.birth_date,
    });
  }, [userInfoChanged, userInfo]);

  useEffect(() => {
    brandData();
    localStorage.removeItem("USERCPF");
    getMcCpf();
    getMctUrlParams();
  }, []);

  async function brandData() {
    setHasDelivery(brand.has_delivery);
    setHasDriveThru(brand.has_drivethru);
  }

  function getMcCpf() {
    const readUrl = parseQueryString(window.location);
    if (readUrl.mc_cpf) {
      localStorage.setItem("USERCPF", readUrl.mc_cpf);
    }
  }

  async function onSubmit() {
    if (cpf) {
      localStorage.setItem("USERCPF", unmask(cpf));
    }
    const unmaskedCep = unmask(cep);
    if (unmaskedCep.length < 8) {
      showNotification(
        "top",
        "center",
        "O CEP digitado é inválido, ou está incompleto.",
        "warning"
      );
      return;
    }
    try {
      toggleLoading();
      const zoneForCep = await getZoneForCep(unmaskedCep, undefined);
      toggleLoading();
      if (zoneForCep.status >= 200 && zoneForCep.status < 300) {
        if (zoneForCep.data.markets.length > 1) {
          setZoneForCep(zoneForCep);
          setMarkets(zoneForCep.data.markets);
          setShowModal(true);
        } else {
          if (zoneForCep.data.markets[0]) {
            localStorage.setItem("market_id", zoneForCep.data.markets[0].id);
          }
          setZoneForRequestedCep(zoneForCep, deliveryMode);
          setModalDeliveryMethods();
        }
      } else {
        showNotification(
          "top",
          "center",
          "Ainda não atendemos a sua área.",
          "warning"
        );
      }
    } catch (error) {
      showNotification(
        "top",
        "center",
        "Ainda não atendemos a sua área.",
        "warning"
      );
    }
  }

  function saveCpf() {
    if (cpf) {
      localStorage.setItem("USERCPF", unmask(cpf));
    }
  }

  function saveLastCep() {
    localStorage.lastCep = cep;
  }

  return (
    <div className="modal-body">
      <div className="logo">
        <img
          className="img mx-auto"
          src={getDomainLogo()}
          alt="Logo do mercado"
        />
      </div>
      <div className="login">
        <div className="form">
          <div style={{ width: "80%" }}>
            <strong style={domainColorStyle("color")}>CEP</strong>
            <InputMask
              style={{ border: "solid 1px #ccc" }}
              mask="99.999-999"
              disabled={isLoading}
              placeholder="CEP"
              showLabel={false}
              name="cep"
              onChange={(e) => setCep(e.target.value)}
              value={cep}
              data-cy="cep"
            />
          </div>
          <div className="buttons">
            <div style={{ display: "flex", width: "80%", height: "60%" }}>
              {hasDelivery && (
                <Button
                  onClick={() => {
                    saveCpf();
                    setDeliveryMode("delivery");
                    saveLastCep();
                    onSubmit();
                  }}
                  className="btn-icon block btn-3 normal-whitespace cep-button"
                  outline
                  style={{
                    ...domainColorStyle("backgroundColor"),
                  }}
                >
                  Receber em casa
                </Button>
              )}
              {hasDriveThru && (
                <Button
                  onClick={() => {
                    saveCpf();
                    setZoneForRequestedCep({ data: { cep: null } });
                    setDeliveryMode("drivethru");
                    setShowModal(true);
                  }}
                  className="btn-icon block btn-2 normal-whitespace cep-button"
                  outline
                  style={{
                    ...domainColorStyle("backgroundColor"),
                  }}
                >
                  Retirar na loja
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Grid item xs={10} sm={8} md={6} lg={10} className="terms">
        <p>
          <span>Ao utilizar o site voce estará concordando com a </span>
          {getDomainName() === "Recibom" ? (
            <>
              <span
                onClick={() => {
                  setModalRecibomPrivacyPolicy(true);
                }}
                style={{ color: "#f57f17", cursor: "pointer" }}
              >
                Política de privacidade do operador
              </span>
            </>
          ) : (
            <>
              <a
                href="https://www.linx.com.br/aviso-de-privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                Política de privacidade do operador
              </a>
            </>
          )}
          .
        </p>
      </Grid>
      <Modal
        WrappedComponent={ListMarkets}
        zoneForCep={thisZoneForCep}
        setZoneForRequestedCep={setZoneForRequestedCep}
        title="Lista de mercados"
        size="md"
        setModalDeliveryMethods={setModalDeliveryMethods}
        showModal={showModal}
        markets={markets}
        setShowModal={(status) => setShowModal(status)}
      />
      {modalRecibomTermsOfUse && (
        <AppNotices
          isOpen={modalRecibomTermsOfUse}
          toogleModal={() => setModalRecibomTermsOfUse(false)}
          title={termsOfUse.tittle}
          notice={{
            description: termsOfUse.content,
          }}
          isDocument
        />
      )}
      {modalRecibomPrivacyPolicy && (
        <AppNotices
          isOpen={modalRecibomPrivacyPolicy}
          toogleModal={() => setModalRecibomPrivacyPolicy(false)}
          title={privacyPolicy.tittle}
          notice={{
            description: privacyPolicy.content,
          }}
          isDocument
        />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setZoneForRequestedCep,
      userInfoChanged,
    },
    dispatch
  );
}

export default loadsContent(connect(null, mapDispatchToProps)(memo(CEPForm)));
