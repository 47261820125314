import React, { useState, useEffect, memo, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CEPForm from "../../../components/Homepage/CEPFormOld";
import Modal from "../../../components/Modal/modal";
import NeighborhoodsForm from "../../../components/Homepage/NeighborhoodsForm";
import DeliveryMethods from "../../../components/Homepage/DeliveryMethods";
import PrimaryHeader from "new/components/PrimaryHeader/PrimaryHeader";

import { notification } from "helpers/util";
import { openDeliveryMethod } from "actions/configsActions";

import "./index.scss";
import { getAutomaticCupons } from "helpers/apiHelper";
import { setAutoCoupons } from "actions/configsActions";
import { useParams } from "react-router"
import { getMenu } from "config";
import ListMarketsMenu from "components/Homepage/ListMarketsMenu";

function Header({
  setSidebar,
  sidebar,
  mobileOpen,
  setDrawerMobileOpen
}) {

  const [address, setAddress] = useState([]);
  const [modalDeliveryMethods, setModalDeliveryMethods] = useState();
  const [textSearch, setTextSearch] = useState("");
  const [automaticCoupons, setAutoCupons] = useState([]);
  const [showModalListMarkets, setShowModalListMarkets] = useState(false)
  const { market_id } = useParams()
  const [personType, setPersonType] = useState(null)

  const cartOpen = useSelector(({ cart }) => cart.openCart)
  const currentMarket = useSelector(({ markets }) => markets)
  const deliveryMethodOpen = useSelector(({ settings }) => settings.deliveryMethodOpen)
  const zone = useSelector(({ zone }) => zone)
  const userInfo = useSelector(({ userInfo }) => userInfo)

  const history = useHistory();
  const dispatch = useDispatch();

  const coalescedZone = zone || {};
  const zoneEmpty = Object.keys(coalescedZone).length === 0;
  const isValidMenu = getMenu()

  const getAddress = useCallback(() => {
    const zoneEmpty = Object.keys(zone).length === 0;
    if (!currentMarket.deliveryMode) {
      setAddress("Selecione um método de entrega");
    } else if (zoneEmpty) {
      setAddress();
    } else {
      const { street, neighborhood, locality } = zone;
      const LabelAddress = `${street ? street : ""} - ${neighborhood} - ${locality}`;
      setAddress(LabelAddress);
    }
  }, [currentMarket, zone]);


  useEffect(() => {
    getAddress();
  }, [currentMarket, zone]);


  const getCoupons = useCallback(async () => {
    try {
      const response = await getAutomaticCupons(userInfo.id, currentMarket.id || market_id)
      if (response) {
        setAutoCupons(response.discount_coupons)
        if (response.discount_coupons.length > 0) {
          dispatch(setAutoCoupons(true))
        } else {
          dispatch(setAutoCoupons(false))
        }
      }
    } catch {
      console.log("error")
    }
  }, [currentMarket, userInfo, dispatch]);

  useEffect(() => {
    getCoupons()
  }, [currentMarket, userInfo])




  function changeAddress() {
    if (!isValidMenu) {
      if (history.location.pathname.includes(`/loja/${market_id ||currentMarket.id}`)) {
        dispatch(openDeliveryMethod(true));
      } else {
        notification(
          "Para alterar o endereço volte para a página de produtos!",
          "warning"
        );
      }
    }else{
      setShowModalListMarkets(true)
    }
  }

  function checkPage() {
    if (!currentMarket.id && history.location.pathname.includes("/loja/")) {
      return true;
    }
    return history.location.pathname.includes(`/loja/${currentMarket.id}`) &&
      !history.location.pathname.includes(`/loja/${currentMarket.id}/`)
  }

  function goToPage(link, params) {
    history.push({
      pathname: link,
      state: params
    })
  }

  return (
    <>
      <PrimaryHeader
        automaticCoupons={automaticCoupons}
        mobileOpen={mobileOpen}
        setDrawerMobileOpen={setDrawerMobileOpen}
        address={address}
        textSearch={textSearch}
        setTextSearch={setTextSearch}
        infoMarket={currentMarket}
        changeAddress={changeAddress}
        checkPage={checkPage}
        goToPage={goToPage}
        cartOpen={cartOpen}
        sidebar={sidebar}
        setSidebar={setSidebar}
        isMenu={isValidMenu}
      />
      <Modal
        WrappedComponent={CEPForm}
        title="Selecione um método de entrega para prosseguir!"
        size="xs"

        toggleModal={() => dispatch(openDeliveryMethod(false))}
        showModal={
          deliveryMethodOpen || (deliveryMethodOpen && !currentMarket.id)
        }
        setModalDeliveryMethods={(status) => setModalDeliveryMethods(status)}
        setPersonType={setPersonType}
        personType={personType}
      />
      <Modal
        WrappedComponent={NeighborhoodsForm}
        title="Selecione seu bairro!"
        showModal={
          deliveryMethodOpen &&
          !zoneEmpty &&
          coalescedZone.neighborhoods.length > 0
        }
      />

      <Modal
        WrappedComponent={DeliveryMethods}
        title={currentMarket.deliveryMode === "delivery" ? "Forma de entrega" : "Local de Retirada"}
        size="sm"
        modalDeliveryMethods={true}
        setModalDeliveryMethods={() => setModalDeliveryMethods(false)}
        showModal={modalDeliveryMethods && currentMarket.id ? true : false}
        personType={personType}
      />

      <Modal 
        WrappedComponent={ListMarketsMenu}
        title="Escolha uma loja"
        size="sm"
        showModal={showModalListMarkets}
        setShowModalListMarkets={() => setShowModalListMarkets(false)}
        toggleModal={() => setShowModalListMarkets(false)}
      />
    </>
  );
}

export default withRouter(memo(Header));
