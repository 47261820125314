import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { getDomainMarketColor} from "config";
import AdditionalValueInput from "./AdditionalValueInput/AdditionalValueInput";

import "./AcceptanceBox.scss";
import { useDispatch } from "react-redux";
import { awareFromHigherValueCapture } from "actions/cartActions";
import { higherValueLimitChanged } from "actions/cartActions";
import Dialog from "../Dialog/Dialog";

const marketColor = getDomainMarketColor();

function HigherValueCaptureCheckbox({
  percentage = 0,
  value = 0,
  userAccepts = true,
}) {
  const [isChecked, setIsChecked] = useState(userAccepts);
  const [showInfo, setShowInfo] = useState(false);
  const additionalValue = ((percentage * value) / 100).toFixed(2);
  const dispatch = useDispatch()

  function handleCheckboxChange(e) {
    setIsChecked(e.target.checked);
  }

  useEffect(() => {
    if(isChecked){
      dispatch(awareFromHigherValueCapture(isChecked))
    } else {
      dispatch(awareFromHigherValueCapture(undefined))
      dispatch(higherValueLimitChanged(undefined))
    }
  }, [isChecked])

  return (
    <>
      <Box className="acceptance-box-container">
        <Box className="acceptance-box-top">
          <h6 style={{ color: marketColor }}>Reserva de valor</h6>
          <IconButton onClick={() => setShowInfo(true)}>
            <InfoOutlinedIcon
              style={{
                color: marketColor,
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        </Box>
        <Box className="acceptance-box-mid">
          <p>
            Se houver produtos vendidos por peso ou hover substituições, o preço
            do pedido pode sofrer variações. Por isso, será feito uma reserva de
            valor maior no cartão para cobrir essas variações. A cobrança no
            cartão será do valor ajustado do pedido após separação.
          </p>
          <FormControlLabel
            onChange={handleCheckboxChange}
            control={
              <Checkbox
                color="primary"
                size="small"
                checked={isChecked} />}
            label="Estou ciente" 
          />
        </Box>
        {isChecked ? (
          <Box className="acceptance-box-bottom">
            <Box>
              <p>Limite total de acréscimo para reserva de valor:</p>
            </Box>
            <AdditionalValueInput defaultValue={additionalValue} />
          </Box>
        ) : null}
      </Box>
      <Dialog showDialog={showInfo} setShowDialog={setShowInfo} />
    </>
  );
}

export default HigherValueCaptureCheckbox;
