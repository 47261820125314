import forge from "node-forge";
export function getEncryptedValue(pem, value) {
  // load public key from PEM-formatted string
  var publicKey = forge.pki.publicKeyFromPem(pem);

  // convert string to UTF-8 encoded bytes
  var buffer = forge.util.createBuffer(value, "utf8");
  var bytes = buffer.getBytes();

  // encrypt data with a public key using RSAES PKCS#1 v1.5
  var encrypted = publicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");

  // base64-encode encrypted data to send to server
  return forge.util.encode64(encrypted);
}

export function getDecryptedValue(pem, value) {
  // load public key from PEM-formatted string
  var privateKey = forge.pki.privateKeyFromPem(pem);

  // encrypt data with a public key using RSAES PKCS#1 v1.5
  var decrypted = privateKey.decrypt(forge.util.decode64(value), "RSAES-PKCS1-V1_5");

  // base64-encode encrypted data to send to server
  return decrypted;
}
