import React, { useEffect, useState } from "react";
import { formatValue } from "helpers/orderhelper";
import "./OrderProducts.scss";
import { Box, Button, Typography } from "@material-ui/core";
import { getItensOrder } from "helpers/apiHelper";
import { getMixesFromCart } from "helpers/cartHelper";
import LoadingContent from "components/LoadingContent";
import { displayProductQty } from "helpers/orderhelper";
import OrderItemsEdit from "new/containers/checkout/components/OrderItemsEdit/OrderItemsEdit";

const OrderProducts = ({ order }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const getOrderProducts = async (orderId) => {
    setLoading(true);
    try {
      const { data } = await getItensOrder(orderId);
      const items = getMixesFromCart(data);
      setProducts(items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderProducts(order.id);
  }, []);

  return (
    <Box className="order-products-list">
      <div className="order-products-list-top">
        <Typography variant="h6">Itens da Compra</Typography>
        {order.can_edit && order.tax_calculation_method !== 'correios' ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenEditModal(true)}
          >
            Editar
          </Button>
        ) : null}
      </div>
      {loading ? (
        <LoadingContent isLoading={loading} />
      ) : (
        <>
          {products.length > 0 &&
            products?.map((product, index) => (
              <Box className="order-product" key={index}>
                <Box className="order-product-info">
                  <b>{displayProductQty(product)}</b>
                  <img src={product.image} alt={product.description} />
                  <span>{product.description}</span>
                </Box>
                <span>{formatValue(product.price)}</span>
              </Box>
            ))}
          <OrderItemsEdit
            isOpen={openEditModal}
            closeModal={() => setOpenEditModal(false)}
            orderId={order.id}
            loadOrderItems={() => getOrderProducts(order.id)}
            orderNumber={order.order_number}
          />
        </>
      )}
    </Box>
  );
};

export default OrderProducts;
