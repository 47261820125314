import { SET_CRM, CLEAR_CRM, GET_CRM_DATA } from "ActionTypes";
import _ from "lodash";
const initialState = {
  crmData: {
    product_discounts: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CRM:
      return {
        crmData: _.groupBy(action.crmData, function (offer) {
          return offer.ean;
        }),
      };
    case CLEAR_CRM:
      return {
        crmData: initialState,
      };
    case GET_CRM_DATA:
      return state;
    default:
      return state;
  }
}
