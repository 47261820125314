import React, { Component } from "react"
import {
    Input,
    InputGroupAddon,
    InputGroupText,
    Label,
    InputGroup,
    Popover,
    PopoverBody
} from "reactstrap"
import { domainColorStyle } from "config"
class InputCustom extends Component {
    constructor() {
        super()
        this.state = {}
    }
    componentDidMount() {
        this.setState({
            popoverReady: true
        })
    }
    render() {
        const { placeholder, onFocus, autoComplete, name, inputMode, onChange,
            defaultValue, type, icon, disabled, pattern,
            className, error, required, options, value, autoFocus, showLabel } = this.props
        const optionsWithPlaceholder = (options || [])
        const { popoverReady } = this.state
        if (type === "checkbox") {
            return (
                <div className="custom-control custom-checkbox mb-3">
                    <input
                        id={name}
                        required={required}
                        className={"custom-control-input " + (error ? "is-invalid" : "") + " " + className}
                        disabled={disabled || false}
                        defaultValue={defaultValue}
                        checked={value}
                        onClick={onChange}
                        name={name}
                        type="checkbox"
                        {...this.props}
                    />
                    <label className="custom-control-label" style={{ whiteSpace: "pre-line" }} htmlFor={name}>
                        <div style={value ? domainColorStyle("background") : { background: "white" }} className="custom-label-checkbox"></div>
                        {placeholder}
                    </label>
                </div>
            )
        }
        return (
            <>
                {
                    ((showLabel === undefined) || showLabel) && (
                        <Label
                            style={domainColorStyle("color")}
                            className="form-control-label font-weight-bold"
                            for={name}>{placeholder}</Label>
                    )
                }
                <InputGroup className="input-group-alternative mt-1">
                    {icon && (<InputGroupAddon addonType="prepend">
                        <InputGroupText className={disabled ? "bg-disabled" : ""}>
                            <i style={domainColorStyle("color")} className={icon} />
                        </InputGroupText>
                    </InputGroupAddon>)}
                    {type === "select" ?
                        (<Input
                            inputMode={inputMode}
                            autoFocus={autoFocus || false}
                            onFocus={onFocus}
                            pattern={pattern}
                            id={name}
                            required={required}
                            className={(error ? "is-invalid" : "") + " " + className}
                            disabled={disabled || false}
                            defaultValue={defaultValue}
                            value={value}
                            placeholder={placeholder}
                            type={type || "text"}
                            onChange={onChange}
                            name={name}
                            {...this.props}
                        >
                            {type === "select" ? (<option value={null}>{placeholder}</option>) : null}
                            {type === "select" ? optionsWithPlaceholder
                                .map((option, key) => <option key={key} value={option.value}>{option.label}</option>) : null}
                        </Input>) : (
                            <Input
                                inputMode={inputMode}
                                autoFocus={autoFocus || false}
                                id={name}
                                onFocus={onFocus}
                                autoComplete={autoComplete}
                                required={required}
                                className={(error ? "is-invalid" : "") + " " + className}
                                disabled={disabled || false}
                                defaultValue={defaultValue}
                                value={value}
                                placeholder={placeholder}
                                type={type || "text"}
                                onChange={onChange}
                                name={name}
                                {...this.props}
                            ></Input>
                        )}
                    {
                        (popoverReady === true && name) && (
                            <Popover
                                placement="bottom"
                                isOpen={error !== undefined}
                                target={name}
                                className="popover-danger"
                            >
                                <PopoverBody>
                                    {error}
                                </PopoverBody>
                            </Popover>
                        )
                    }

                </InputGroup>
            </>
        )
    }
}

export default InputCustom