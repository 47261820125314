import React from "react";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { getDomainMarketColor } from "config";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { showNotification } from "helpers/util";
import { domainColorStyle } from "config";
import "./PixInfo.scss";
import { Box, Paper } from "@material-ui/core";

const PixInfo = ({ pix, minutes, seconds, totalTime }) => {
  const handleCopyCode = () => {
    navigator.clipboard.writeText(pix.pix_qr_code);
    showNotification("top", "center", "Código copiado!", "success", true);
  };

  return (
    <Box className="pix-info">
      <Box className="pix-code">
        <img src={pix.pix_qr_code_url} alt="pix code" />
        <Box className="pix-code-box">
          <span className="pix-code-tip">
            Escaneie o QR Code ou copie o código de pagamento
          </span>
          <Box className="pix-code-copy">
            <span className="pix-code-text">{pix.pix_qr_code}</span>
            <FilterNoneIcon onClick={() => handleCopyCode()} />
          </Box>
        </Box>
      </Box>

      <Box className="pix-timer">
        <div className="timer">
          <CircularProgressbarWithChildren
            value={minutes * 60 + seconds}
            maxValue={totalTime * 60}
            styles={buildStyles({
              rotation: 0.75,
              strokeLinecap: "butt",
              pathTransitionDuration: 0.5,
              pathColor: getDomainMarketColor(),
              trailColor: "#d6d6d6",
            })}
          >
            <strong>
              {("00" + minutes).slice(-2)}:{("00" + seconds).slice(-2)}
            </strong>
          </CircularProgressbarWithChildren>
        </div>
        <Box className="timer-info">
          <strong>Código válido por {totalTime} minutos </strong>
          <span>Realize o pagamento para finalizar o pedido.</span>
        </Box>
      </Box>

      <Paper className="pix-help-box" style={{ ...domainColorStyle("color") }}>
        <p>Como pagar usando Pix?</p>
        <ul>
          <li>
            <b>1.</b>
            <span>
              Abra o aplicativo ou banco de sua preferência. Selecione a opção
              pagar com “Pix copia e cola”, ou código QR.
            </span>
          </li>
          <li>
            <b>2.</b>
            <span>
              Copie e cole o código acima ou escaneie o código QR com a câmera
              do seu celular. Verifique todas as informações e confirme o
              pagamento.
            </span>
          </li>
          <li>
            <b>3.</b>
            <span>
              Pronto! Em instantes, seu pagamento será confirmado em nosso site.
            </span>
          </li>
        </ul>
      </Paper>
      <Box className="pix-linx-info">
        <span>Este pagamento está sendo processado por</span>
        <span style={{ ...domainColorStyle("color") }}>
          LINX TELECOMUNICAÇÕES LTDA
        </span>
      </Box>
    </Box>
  );
};

export default PixInfo;
