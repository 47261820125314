
import config from "config";
import { getBrandID } from "../../config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";
import { store } from "App";

function getUserIdAPICallUsingRedux() {
  const reduxState = store.getState()
  const customerId = reduxState.userInfo.id
  return customerId
}

export async function getZoneForCep(cep, neighborhoodId = "") {
  const brandID = getBrandID();
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/by_neighborhood?cep=${cep}&neighborhood_id=${neighborhoodId}&brand_id=${brandID}`
    );
    if (response.data.market) {
      localStorage.market_id = response.data.market.id;
    }
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAppInfos() {
  try {
    const response = await oAuthRequest.get(`${config.mappURLV2}/app_infos`);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getDeliveryMethods(
  delivery,
  cep,
  neighborhoodId = "",
  marketId,
  personType
) {
  const url =`${config.mappURLV2}/markets/${marketId}/delivery_methods`
  const params = {
    mode: delivery,
    cep: cep,
    neighborhood_id: neighborhoodId,
    person_type: personType
  }
  try {
    const response = await oAuthRequest.get(url, {params: params});
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getListMarkets(exclusiveMarketId = null) {
  const brandID = getBrandID();
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/drivethru_markets?brand_id=${brandID}${exclusiveMarketId ? `&exclusive_market_id=${exclusiveMarketId}` : ""
      }`
    );

    return response;
  } catch (error) {
    return error.response;
  }
}
export async function getListAllMarkets(brandId = null) {
  const url = `${config.mappURLV2}/markets`
  const params = { brand_id : brandId || getBrandID() }

  try {
    const response = await oAuthRequest.get(url, { params });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getRatingMarketById(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/ratings/visible`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getMarketById(id) {
  try {
    const response = await oAuthRequest.get(`${config.mappURLV2}/markets/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getMissingItemsOption(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/missing_items_options`
    );

    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getTaxFullAddress(
  id,
  deliveryMethodsId,
  cep,
  neighborhoodId,
  street,
  number
) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/delivery_methods/${deliveryMethodsId}/full_address_tax/?cep=${cep}&neighborhood_id=${neighborhoodId}&street=${street}&number=${number}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getBannerInfos(id, banner_id, delivery_method_id) {
  const userCPF = localStorage.getItem("crmUserCpf");
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/banners/${banner_id}?delivery_method_id=${delivery_method_id}&${userCPF ? `cpf=${userCPF}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getBannerDeliveryMethod(id, delivery_method_id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/banners?delivery_method_id=${delivery_method_id}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getAllTimes(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/times?delivery_type=drivethru`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllPaymentTypes(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/payment_types`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAuthFlag(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/mercadapp_authentication_flags/chosen?market_id=${id}`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getCustomBags(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/custom_bags`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}


export async function getMarketOffers(marketId, deliveryMethod, neighborhoodId, onlyOffers, limit) {
  const crm_token = localStorage.getItem("crmToken");
  const userCPF = localStorage.getItem("crmUserCpf");
  const deviceId = localStorage.getItem("deviceId");

  const url = `${config.mappURLV2}/markets/${marketId}/items/offers`
  const user = getUserIdAPICallUsingRedux()

  const params = {
    originator: 2,
    limit: limit,
    visitor_identifier: deviceId,
    cpf: userCPF,
    delivery_method_id: deliveryMethod,
    neighborhoodId: neighborhoodId,
    only_offers: onlyOffers,
    customer_id: user
  }

  try {
    const response = await oAuthRequest.get(url, { params: params });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response;
  }
}

export async function getMarketBanners(id) {
  const userCPF = localStorage.getItem("crmUserCpf");
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/banners?${userCPF ? `cpf=${userCPF}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getMarketCategories(id, deliveryMethod) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/brand_categories?delivery_method_id=${deliveryMethod}`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getMarketCategorieSubcategories(id, section, deliveryMethod) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${id}/brand_categories/${section}/subcategories?delivery_method_id=${deliveryMethod}`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getMarketItemsBySection(marketID, section, deliveryMethodID, limit, neighborhoodId) {
  const url = `${config.mappURLV2}/markets/${marketID}/items`
  const userCPF = localStorage.getItem("crmUserCpf");
  const user = getUserIdAPICallUsingRedux()
  const params = {
    section_id: section,
    cpf: userCPF,
    limit: limit,
    delivery_method_id: deliveryMethodID,
    neighborhood_id: neighborhoodId,
    customer_id: user

  }
  
  try {
    const response = await oAuthRequest.get(url, { params: params });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getMarketItemsByCategory(marketID, categoryId, deliveryMethodId, neighborhoodId) {
  const url = `${config.mappURLV2}/markets/${marketID}/items`
  const userCPF = localStorage.getItem("crmUserCpf");
  const user = getUserIdAPICallUsingRedux()
  const params = {
    category_id: categoryId,
    cpf: userCPF,
    delivery_method_id: deliveryMethodId,
    neighborhood_id: neighborhoodId,
    customer_id: user
  }
  
  try {
    const response = await oAuthRequest.get(url, { params: params });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function getCorreiosShippingMethods(marketId, cart, cep){
  const url = `${config.mappURLV3}/markets/${marketId}/correios_estimate`
  const params = {
    cart: cart,
    destiny_cep: cep
  }
  try{
    const response = await oAuthRequest.get(url, {params: params})
    return response
  } catch(error){
    throw error
  }
}
