import axios from "axios";

export async function login(CPF, brand) {
  try {
    if (brand.crm) {
      const response = await axios.get(
        `${brand.crm.base_url}/v1/loyalty/${CPF}`,
        {
          headers: { Authorization: `Bearer ${brand.crm.token}` },
        }
      );
      return response.data;
    }
  } catch (error) {
    console.log("Brand error");
  }
}

export async function createUser(userData, brand) {
  if (brand.crm) {
    const response = await axios.post(
      `${brand.crm.base_url}/v1/user`,
      { user: userData },
      {
        headers: { Authorization: `Bearer ${brand.crm.token}` },
      }
    );
    return response.data;
  }
}
