import React from "react";
import { Modal, ModalHeader } from "reactstrap";

import Zoom from 'react-img-zoom'

import "./FlipbookView.scss";
import { domainColorStyle } from "config";

export default function FlipbookView({
  showModal,
  toggleModal,
  size,
  flipbook = {},
  className,
}) {
  return (
    flipbook &&
    <Modal
      className={"modal-dialog-centered flipbook-modal" + (className || "")}
      size={size || "lg"}
      isOpen={showModal}
      toggle={() => {
        if (toggleModal) toggleModal(null);
      }}
    >
      <ModalHeader
        className="flip-header"
        style={domainColorStyle("color")}
        toggle={() => toggleModal(null)}>
        {flipbook.name}
      </ModalHeader>
      <div className="modal-body p-2">
        {flipbook.images_urls.map((src, index) => (
          <Zoom
            img={src}
            zoomScale={2}
            width={600}
            height={600}
          />
        ))}
      </div>
    </Modal>
  );
}
