import React from "react";

import { Button, Card, Col, Row, Container } from "reactstrap";
import { domainColorStyle } from "config";


function AmountProduct({
  removeFromList,
  addToList,
  product,
}) {

  return (
    <Card className="px-1 circle-corners">
      <Container fluid>
        <Row>
          <Col xs={2} sm={3} md={3} lg={3} className="p-0">
            <Button
              onClick={() => removeFromList(product)}
              size="sm"
              style={domainColorStyle("color")}
              className=" bg-white  border-0 shadow-none"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-delete" />
              </span>
            </Button>
          </Col>
          <Col
            className="text-center my-auto"
            xs={8}
            sm={6}
            md={6}
            lg={6}
          >
            <strong
              style={domainColorStyle("color")}
              className="text-sm"
            >
              {(product.amount + "").padStart(2, "0")}
            </strong>
          </Col>
          <Col xs={2} sm={3} lg={3} md={3} className="p-0">
            <Button
              style={domainColorStyle("color")}
              onClick={() => {
                addToList(product);
                // this.forceUpdate();
              }}
              size="sm"
              className="bg-white float-left border-0 shadow-none"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default AmountProduct;
