/* eslint-disable quotes */
import React, { useState } from "react";
import { domainColorStyle } from "config";
import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

function ModalPreferences({
  isOpen,
  toggleModal,
  close,
  itemPreference = { question: "", options: [""] },
}) {
  const [preference, setPreference] = useState("");

  useEffect(() => {
    setPreference("");
  }, [isOpen]);

  const getQuestion = () => {
    return itemPreference ? itemPreference.question : "";
  };

  const getOptions = () => {
    return itemPreference ? itemPreference.options : [""];
  };

  const onSubmit = () => {
    preference !== "" ? toggleModal(preference) : close();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <div
        style={{
          height: "10px",
          ...domainColorStyle("backgroundColor"),
        }}
      />
      <DialogTitle>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          {getQuestion()}
        </h3>
      </DialogTitle>
      <DialogContent>
        <form name="form">
          {getOptions().map((item) => {
            return (
              <div key={item}>
                <input
                  type="radio"
                  id={item}
                  value={item}
                  key={item}
                  name="preference"
                  onChange={(event) => setPreference(event.target.value)}
                  style={{ height: "1.3rem", width: "1.3rem", margin: ".4rem" }}
                />
                <label style={{ fontSize: "1.3rem" }} for={item}>
                  {item}
                </label>
              </div>
            );
          })}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            ...domainColorStyle("backgroundColor"),
            color: "#fff",
            height: "3rem",
            width: "40%",
            margin: "0 auto"
          }}
          onClick={onSubmit}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalPreferences;
