import {
  CATEGORIES_CHANGED,
  BANNERS_CHANGED,
  CURRENT_MIXES_CHANGED,
} from "ActionTypes";
import { applyProductFunctions } from "helpers/models/product";

const INITIAL_STATE = {
  categories: [],
  // subcategories: [],
  offers: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CATEGORIES_CHANGED:
      return Object.assign({}, state, {
        categories: action.payload,
      });
    case BANNERS_CHANGED:
      return Object.assign({}, state, {
        banners: action.payload,
      });
    default:
      return state;
  }
}
