import axios from "axios";
import config from "config";

export async function createReview(review, market) {
  try {
    const brand = await axios.post(`${config.mappURLV2}/markets/${market}/ratings`, { rating: review }, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return brand.data;
  } catch (error) {
    console.log("List error", error.response);
    return error.response.data
  }
}
