import { Box, Button } from "@material-ui/core";
import { getDomainLogo } from "config";
import ModalDefault from "new/components/Modal/Modal";
import React from "react";
import "./styles.scss";

const marketLogo = getDomainLogo();

const LoginFailureModal = ({
  isOpen,
  setIsOpen = () => {},
  onConfirm = () => {},
  errorMessage= ""
}) => {
  const closeModal = () => {
    setIsOpen(false);
    onConfirm();
  };

  return (
    <ModalDefault isOpen={isOpen} toggleModal={closeModal} size="xs">
      <Box className="login-failure-modal-box">
        <img src={marketLogo} alt="marketLogo" />
        {errorMessage ? <span className="login-error-message">{errorMessage}</span> : null}
        <div>
          <span>Não foi possível efetuar o login.</span>
          <span>Tente novamente ou entre em contato com o suporte.</span>
        </div>
        <Button onClick={closeModal} variant="contained" color="primary">
          OK
        </Button>
      </Box>
    </ModalDefault>
  );
};

export default LoginFailureModal;
