import moment from "moment";
import numberToCurrency from "./currency";
import { humanReadableWeight } from "./util";

export const formatValue = (value) => {
  return numberToCurrency(Number(value));
};

export const getRemainingTimeInSeconds = (pixExpiresAt) => {
  const current_time = moment();
  const pix_expiration_time = moment(pixExpiresAt);
  var diff = moment(pix_expiration_time, "DD/MM/YYYY HH:mm:ss").diff(
    moment(current_time, "DD/MM/YYYY HH:mm:ss")
  );
  return moment.duration(diff).asSeconds();
};

export const getDeliveryTime = (order) => {
  return (
    moment(order.deliver_end_time).format("DD/MM/YYYY") +
    " | " +
    moment(order.deliver_start_time).format("HH:mm") +
    " às " +
    moment(order.deliver_end_time).format("HH:mm")
  );
};

export const getTotalPrice = (order) => {
  return (
    Number(order.subtotal_with_additions) +
    Number(order.delivery_tax) +
    Number(order.custom_bag_value) +
    Number(order.service_tax) -
    Number(order.total_discounts)
  );
};

export const displayProductQty = (item) => {
  if (item.unit  === "sold_in_kilogram") {
    return humanReadableWeight(item.amount * item.amount_in_kilo);
  } else {
    return parseInt(item.amount) + " unid ";
  }
}
