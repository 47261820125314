import _ from "lodash"
import { SET_ZONE_FOR_REQUESTED_CEP, SET_NEIGHBORHOOD } from "ActionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case SET_ZONE_FOR_REQUESTED_CEP:
      return _.mapKeys(action.payload.data.businesses, "id")
    case SET_NEIGHBORHOOD:
      return _.mapKeys(action.payload.businesses, "id")
    default:
      return state
  }
}
