import React, { Component } from "react";
import { connect } from "react-redux";
import {
  categoriesChanged,
  bannersChanged,
} from "actions/marketStuffActions";
import { clearCart } from "actions/cartActions";
import { setCRM, clearCRM } from "actions/CRMActions";
import loadsContent from "components/loadsContent";
import HomePageSkeleton from "new/containers/Home/HomePageSkeleton";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  setZoneForRequestedCep,
  setNeighborhood,
} from "actions/setZoneForRequestedCep";
import { hasAnotherMarketMix } from "helpers/cartHelper";
import ProductsView from "../components/StorePage/ProductsView/ProductsView";
import { setMarket } from "actions/setZoneForRequestedCep";
import { openDeliveryMethod } from "actions/configsActions";
import { changeSelectedCategory } from "actions/categoriesActions";
import { search } from "helpers/apis/itemApi";
import { notification } from "helpers/util";
import { getMarketById } from "helpers/apis/marketInfo";
import { getOffers } from "helpers/apis/zoomboxApi";

import { applyProductFunctions } from "helpers/models/product";
import { sendSearchEvent } from "helpers/apis/impulseEventsApiHelper";
import { sendEmptySearchEvent } from "helpers/apis/impulseEventsApiHelper";
import CRMPage from "new/containers/CRMPage/CRMPage";
import ProductView from "./ProductView/ProductView";
import { updateBrandCustomer } from "helpers/apiHelper";
import { userInfoChanged } from "actions/userInfoActions";
import NotificationCenterWarningModal from "components/NotificationCenterWarningModal/NotificationCenterWarningModal";
import { getMixesOrdered } from "helpers/storeHelper";
import { extractItemsFromMixes } from "helpers/storeHelper";
import debounce from "lodash/debounce";
import { subcategoryProductsChanged, filterProductsChanged } from "actions/productsActions";
import { searchProductsChanged } from "actions/productsActions";
import { dispatchItemsInBatches } from "helpers/storeHelper";
import ShortcutBtn from "new/components/RedirectMenu/ShortcutBtn/ShortcutBtn";
import { Box } from "@material-ui/core";
import { clubOffersChanged } from "actions/productsActions";
import { getForYouProducts } from "helpers/apiHelper";
import { forYouProductsChanged } from "actions/productsActions";
import { getMarketItemsByCategory } from "helpers/apis/marketInfo";
import { arrayHasContent } from "helpers/util";

class StorePage extends Component {
  constructor(props) {
    super(props);
    this.notificationCentralWarningSeen =
      this.notificationCentralWarningSeen.bind(this);
    this.openNotificationCenterModal =
      this.openNotificationCenterModal.bind(this);
    this.toggleSearchLoading = this.toggleSearchLoading.bind(this);
    this.currentMarket = props.markets;
    this.state = {
      notificationModalOpen: false,
      isSearchLoading: false,
      searchData: [],
      showFilterMedicineKind: false,
      searchPage: 1,
      isFetchingMore: false,
      clubPage: 1,
      isFetchingClub: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const marketID = Number(this.props.match.params.market_id);
    const { searchText = "", searchActive } = this.props;
    if (searchActive && nextProps.searchText !== searchText) {
      this.searchProduct(nextProps.searchText, marketID);
    }
  }

  async componentWillMount() {
    window.removeEventListener('scroll', this.handleScroll);
    const marketID = Number(this.props.match.params.market_id);
    const { markets, setMarket, openDeliveryMethod } = this.props;
    openDeliveryMethod(false);

    // Check if localStorage.market_id is not set
    if (!localStorage.market_id) {
      localStorage.market_id = marketID;
    }

    if (
      (marketID && marketID !== markets.id) ||
      (marketID && !markets && !markets.deliveryMethod)
    ) {
      const response = await getMarketById(Number(marketID));
      setMarket(response);
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const category = this.props.match.params.category;
    const marketID = this.props.match.params.market_id;
    const { searchActive, searchText, selectedSubcategory } = this.props

    if (
      hasAnotherMarketMix(this.props.cart, this.props.match.params.market_id)
    ) {
      this.props.clearCart();
    }

    if (searchActive) {
      this.searchProduct(searchText, marketID)
    } else if (selectedSubcategory) {
      this.fetchSubcategoryProducts(marketID);
    } else if (category) {
      this.handleCategoryItems(marketID, category)
    }
    this.openNotificationCenterModal();
  }

  async componentDidUpdate(props) {
    const marketID = this.props.match.params.market_id;

    const {
      searchActive,
      searchText,
      selectedSubcategory,
      selectedFilter,
      ordenation,
      subcategoryProductsChanged,
      subcategoryProducts,
      filterProductsChanged,
      selectedCategory
    } = this.props;

    if (this.currentMarket?.id !== Number(this.props.match.params.market_id)) {
      if (this.currentMarket.id !== props.markets.id) {
        this.currentMarket = props.markets;
        if (hasAnotherMarketMix(this.props.cart, props.markets.id)) {
          this.props.clearCart();
        }
        window.location.reload();
      }
    } else if (props.markets && props.markets.id !== Number(marketID)) {
      if (props.markets.id) {
        this.props.history.push(`/loja/${props.markets.id}`);
      }
    }

    if (selectedCategory && selectedCategory.id !== props.selectedCategory.id) {
      this.handleCategoryItems(marketID, selectedCategory.id)
    }
    
    if (ordenation && ordenation !== props.ordenation) {
      const mixesOrdered = getMixesOrdered(subcategoryProducts, ordenation, selectedFilter);
      selectedFilter ? filterProductsChanged(mixesOrdered) : subcategoryProductsChanged(mixesOrdered)
    }

    if (selectedSubcategory && selectedSubcategory?.id !== props.selectedSubcategory?.id) {
      this.fetchSubcategoryProducts(marketID);
    }
    
    if (searchActive !== props.searchActive) {
      this.searchProduct(searchText, marketID);
    }
  }

  fetchSubcategoryProducts = async (marketID) => {
    this.toggleSearchLoading();
    const { selectedSubcategory, deliveryMethod, zone, subcategoryProductsChanged } = this.props;
    try {
      const response =  await getMarketItemsByCategory(marketID, selectedSubcategory?.id, deliveryMethod.deliveryMethod?.id, zone?.neighborhood_id);
      const items = extractItemsFromMixes(response)
      dispatchItemsInBatches(items, subcategoryProductsChanged);
    } catch (error) {
      console.log(error);
      notification(
        "Ocorreram erros, verifique sua conexão com a internet e tente novamente!",
        "danger"
      );
    } finally { 
      this.toggleSearchLoading();
      window.scrollTo(0, 0);
    }
  };

  handleCategoryItems = async (marketId, categoryId) => {
    window.scrollTo(0, 0);
    if (categoryId === "clube") {
      const { clubPage } = this.state
      this.getClubeOffers(marketId, clubPage)
    } else if (categoryId === "paravoce") {
      this.fetchForYouProducts(marketId);
    }
  }

  getClubeOffers = async (marketID, clubPage) => {
    this.toggleSearchLoading();
    const { changeSelectedCategory, clubOffers, clubOffersChanged, deliveryMethod, zone } = this.props;

    try {
      const response = await getOffers(marketID, clubPage, deliveryMethod.deliveryMethod?.id, zone?.neighborhood_id);
      if (clubPage === 1) {
        const items = extractItemsFromMixes(response)
        clubOffersChanged(applyProductFunctions(items))
      } else {
        this.appendNewResults(response, clubOffers, clubOffersChanged)
      }
    } catch (error) {
      console.log(error)
      notification("Você precisa fazer login no clube de descontos novamente!", "warning");
      localStorage.removeItem("crmUserCpf");
    } finally {
      this.toggleSearchLoading();
      this.setState({ isFetchingClub: false });
      changeSelectedCategory({ id: "clube" });
    }
  };

  fetchForYouProducts = async (marketId) => {
    this.toggleSearchLoading();
    const { changeSelectedCategory, forYouProductsChanged, client_attributes, zone } = this.props
    changeSelectedCategory({ id: "paravoce" })
    try {
      const response = await getForYouProducts(marketId, client_attributes.id, null, zone.neighborhood_id)
      const items = extractItemsFromMixes(response)
      forYouProductsChanged(applyProductFunctions(items))
    } catch (error) {
      notification("Erro ao buscar produtos dessa categoria", "error");
      console.log(error)
    } finally {
      this.toggleSearchLoading();
    }
  }

  searchProduct = debounce(async (searchText, marketID, page = 1) => {
    this.toggleSearchLoading();
    const { searchProducts, searchProductsChanged } = this.props;
    try {
      const results = await search(searchText, marketID, page);
      window.history.pushState({}, "Search", this.props.match.url + "?search=" + searchText);

      if (!arrayHasContent(results.mixes)) {
        this.setState({ isFetchingMore: false });
        window.removeEventListener('scroll', this.handleScroll);
        return
      }
      
      if (page === 1) {
        this.handleSearchResults(results, searchText);
        window.scrollTo(0, 100);
      } else {
        this.appendNewResults(results, searchProducts, searchProductsChanged);
      }
    } catch (error) {
      console.log(error);
      notification("Ocorreram erros, verifique sua conexão com a internet", "error");
    } finally {
      this.toggleSearchLoading();
      this.setState({ isFetchingMore: false });
    }
  }, 500);

  appendNewResults = (newResults = [], currentItems = [], dispatchFunction = () => {}) => {
    const newItems = extractItemsFromMixes(newResults);
    const updatedItems = [...currentItems, ...newItems];
    dispatchFunction(updatedItems);
  };

  handleSearchResults(searchData, searchText) {
    const { searchProductsChanged } = this.props;
    const searchItems = extractItemsFromMixes(searchData);

    if (searchData.medicine_kinds && searchData.medicine_kinds.length > 0) {
      this.setState({
        searchData: searchData,
        showFilterMedicineKind: true
        })
    }

    if (searchItems?.length > 0) {
      sendSearchEvent(searchText, searchItems);
    } else {
      sendEmptySearchEvent(searchText, searchItems);
    }

    searchProductsChanged(searchItems);
  }

  handleMedicineItems = async (medicineId, medicineLabel) => {
    const { searchProductsChanged } = this.props;
    const searchItems = extractItemsFromMixes(this.state.searchData);
    let filterMedicine;

    switch(medicineId){
      case "reference":
        filterMedicine = searchItems.filter(medicine => medicine.id === "reference")
        searchProductsChanged(filterMedicine);
        break;
      case "similar":
        filterMedicine = searchItems.filter(medicine => medicine.id === "similar")
        searchProductsChanged(filterMedicine);
        break;
      case "generics":
        filterMedicine = searchItems.filter(medicine => medicine.id === "generics")
        searchProductsChanged(filterMedicine);
        break;
      default:
    }
  }

  toggleSearchLoading() {
    this.setState(
      (state) => ({
        isSearchLoading: !state.isSearchLoading,
      }),
    );
  }

  notificationCentralWarningSeen() {
    return this.props.client_attributes?.brand_customer
      ?.notification_central_warning_seen;
  }

  openNotificationCenterModal() {
    const { client_attributes, userInfoChanged, brand } = this.props;
    if (!!client_attributes.id && !this.notificationCentralWarningSeen()) {
      this.setState({
        notificationModalOpen: true,
      });
      updateBrandCustomer(client_attributes, brand.id);
      userInfoChanged({
        ...client_attributes,
        brand_customer: {
          ...client_attributes.brand_customer,
          notification_central_warning_seen: true,
        },
      });
    }
  }

  handleScroll = () => {
    const marketID = this.props.match.params.market_id;
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 125) {
      const { isFetchingMore, searchPage, isFetchingClub, clubPage } = this.state;
      const { searchText, searchActive, isSearchLoading, selectedCategory } = this.props;
      if (searchActive && !isSearchLoading && !isFetchingMore) {
        this.setState({ isFetchingMore: true, searchPage: searchPage + 1 }, () => {
          this.searchProduct(searchText, marketID, searchPage + 1);
        });
      } else if (selectedCategory?.id === "clube" && !isFetchingClub && !searchActive) {
        this.setState({ isFetchingClub: true, clubPage: clubPage + 1 }, () => {
          this.getClubeOffers(marketID, clubPage + 1);
        });
      }
    }
  };

  render() {
    const { selectedCategory = {}, hasAutoCoupons, searchActive } = this.props;
    const { product_cb, product_slug } = this.props.match.params;
    const userCPF = localStorage.getItem("crmUserCpf");
    const { isSearchLoading } = this.state;

    return isSearchLoading ? (
      <div className="main-content mobile-home-padding">
         <HomePageSkeleton/>
      </div>
    ) : this.props.selectedCategory?.id === "clube" && !userCPF ? (
      <CRMPage showModal={!userCPF} />
    ) : (
      <section className="section" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div>
          <div
            className={`${
              !selectedCategory.id ? "main-content" : "main-content with-banner"
            }`}
            style={{
              backgroundColor: "#f8f8f8",
              marginTop: hasAutoCoupons ? 0 : -40,
            }}
          >
          {this.state.showFilterMedicineKind && searchActive &&
            <Box className="redirect-menu" style={{ position: "relative", top: "3em" }}>
              {this.state.searchData?.medicine_kinds?.map((item) => (
                <ShortcutBtn
                  key={item.id}
                  label={item.label}
                  onClick={() => this.handleMedicineItems(item?.id, item?.label)}
                />
              ))}
            </Box>}
            {(product_cb || product_slug) && !searchActive ? (
              <ProductView />
            ) : (
              <ProductsView isLoading={isSearchLoading} />
            )}
            <NotificationCenterWarningModal
              isOpen={false}
              toggleModal={() => {
                this.setState({
                  notificationModalOpen: false,
                });
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    zone: state.zone,
    markets: state.markets,
    client_attributes: state.userInfo,
    offers: state.marketStuff.offers,
    categories: state.marketStuff.categories,
    cart: state.cart.cart,
    discount_crm: state.crm.crmData,
    deliveryMethod: state.deliveryMethod,
    selectedCategory: state.categories.selectedCategory,
    subcategories: state.categories.subcategories,
    selectedSubcategory: state.categories.selectedSubcategory,
    selectedFilter: state.categories.selectedFilter,
    hasAutoCoupons: state.settings.hasAutoCoupons,
    searchText: state.ui.searchText,
    searchActive: state.ui.searchActive,
    ordenation: state.ui.ordenation,
    brand: state.brand.brand,
    searchProducts: state.products.searchProducts,
    subcategoryProducts: state.products.subcategoryProducts,
    forYouProducts: state.products.forYouProducts,
    clubOffers: state.products.clubOffers,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userInfoChanged,
      subcategoryProductsChanged,
      forYouProductsChanged,
      clubOffersChanged,
      filterProductsChanged,
      searchProductsChanged,
      categoriesChanged,
      bannersChanged,
      setNeighborhood,
      clearCart,
      setZoneForRequestedCep,
      setCRM,
      setMarket,
      openDeliveryMethod,
      changeSelectedCategory,
      clearCRM,
    },
    dispatch
  );
}
StorePage = connect(mapStateToProps, mapDispatchToProps)(StorePage);
StorePage = withRouter(StorePage);
export default loadsContent(React.memo(StorePage));
