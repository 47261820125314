import { SELECTED_CATEGORY_CHANGED, SELECTED_SUBCATEGORY_CHANGED, SELECTED_FILTER_CHANGED } from "ActionTypes";

export function changeSelectedCategory(category){
  return { type: SELECTED_CATEGORY_CHANGED, payload: category}
}

export function changeSelectedSubcategory(subcategory){
  return { type: SELECTED_SUBCATEGORY_CHANGED, payload: subcategory}
}

export function changeSelectedFilter(filter) {
  return { type: SELECTED_FILTER_CHANGED, payload: filter}
}