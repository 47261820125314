import React from "react";
import { connect } from "react-redux";
import clsx from 'clsx';
import { Drawer, Hidden } from "@material-ui/core";
import SideBarList from "./SideBarList";

import { useStylesSidebar } from "assets/useStyles";

function SideBar({ sideBar, setSidebar, mobileOpen, setMobileOpen }) {
  const classes = useStylesSidebar();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setSidebar(!mobileOpen);
  };

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={sideBar && mobileOpen}
          onClose={() => handleDrawerToggle()}
          classes={{
            paper: classes.drawerPaper,
          }}
          className={clsx(classes.content, {
            [classes.contentShift]: sideBar,
          })}
        >
          <div className={classes.toolbar} />
          <SideBarList />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
           className={clsx(classes.content,classes.drawer, {
            [classes.contentShift]: sideBar,
          })}
          variant="persistent"
          anchor="left"
          open={sideBar}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SideBarList />
        </Drawer>
      </Hidden>
    </nav>
  );
}

function mapStateToProps({ marketStuff, markets }) {
  return {
    categories: marketStuff.categories,
    currentMarket: markets,
  };
}

export default connect(mapStateToProps, null)(SideBar);
