import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { domainColorStyle } from "config";
import React from "react";

export default function modal({
  WrappedComponent,
  showModal,
  title,
  toggleModal,
  size,
  className,
  ...props
}) {
  return (
    <Dialog
      open={showModal}
      onClose={toggleModal}
      fullWidth
      maxWidth={size || "sm"}
      className={className || ""}
    >
      <div style={{ height: "10px", ...domainColorStyle("backgroundColor") }} />
      {title && (
        <>
          <DialogTitle style={{ margin: "0 auto", textAlign: "center" }}>
            {title || ""}
          </DialogTitle>
          {toggleModal && (
            <IconButton
              aria-label="Close"
              color="default"
              onClick={toggleModal}
              style={{ position: "absolute", right: "8px", top: "8px" }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Divider />
        </>
      )}
      <DialogContent style={{ padding: "1rem" }}>
        <WrappedComponent toggleModal={toggleModal} {...props} />
      </DialogContent>
    </Dialog>
  );
}
