import { USER_INFO_CHANGED, CLEAR_USER_INFO } from "ActionTypes";
const INITIAL_STATE = {
  cpf: "",
  phone_number: "",
  name: "",
  email: "",
  address_attributes: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_INFO_CHANGED:
      return Object.assign({}, state, {
        ...action.payload,
      });
    case CLEAR_USER_INFO:
      return {
        cpf: "",
        phone_number: "",
        name: "",
        email: "",
        address_attributes: {},
      };
    default:
      break;
  }

  return state;
}
