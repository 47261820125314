import React, { useState } from "react";
import { connect } from "react-redux";

import { ModalBody, Modal, ModalHeader, Button, Input } from "reactstrap";
import FeedbackStars from "components/FeedbackStars/FeedbackStars";

import { createReview } from "helpers/apis/reviewApi";
import { showNotification } from "helpers/util";
import { domainColorStyle } from "config";

import "./Feedback.scss";

function Feedback({ order_number, isOpen, toggleModal, markets }) {
  const [evaluations, setEvaluations] = useState({});

  const sendEvaluation = async () => {
    if (
      evaluations.r_items_variety &&
      evaluations.r_items_quality &&
      evaluations.r_delivery_time
    ) {
      try {
        await createReview({ ...evaluations, order_number }, markets.id);
        showNotification(
          "top",
          "center",
          "Avaliação enviada com sucesso!",
          "success"
        );
        toggleModal(null);
        setEvaluations({});
      } catch (error) {
        showNotification(
          "top",
          "center",
          "Erro ao fazer a avaliação, entre em contato ou tente novamente",
          "danger"
        );
      }
    } else {
      showNotification(
        "top",
        "center",
        "Por favor, avalie todas as categorias.",
        "danger"
      );
    }
  };

  const doEvaluation = (value, key) => {
    setEvaluations({ ...evaluations, [key]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal(null)}
      className="modal-dialog-centered modal-feedback"
    >
      <ModalHeader className="feedback-header" toggle={() => toggleModal(null)}>
        Avalie seu pedido
      </ModalHeader>
      <ModalBody>
        <p className="subtitle">
          Seu pedido foi entregue! Agora você pode avaliar sua compra!
        </p>
        <div className="feedback-type">
          <p>Variedade de produtos</p>
          <FeedbackStars
            evaluation={evaluations.r_items_variety}
            changeEvaluation={(value) => doEvaluation(value, "r_items_variety")}
          />
        </div>
        <div className="feedback-type">
          <p>Qualidade dos produtos</p>
          <FeedbackStars
            evaluation={evaluations.r_items_quality}
            changeEvaluation={(value) => doEvaluation(value, "r_items_quality")}
          />
        </div>
        <div className="feedback-type">
          <p>Pontualidade na entrega</p>
          <FeedbackStars
            evaluation={evaluations.r_delivery_time}
            changeEvaluation={(value) => doEvaluation(value, "r_delivery_time")}
          />
        </div>

        <Input
          type="textarea"
          value={evaluations.review}
          onChange={({ target }) => doEvaluation(target.value, "review")}
        />

        <div className="send-feedback">
          <Button onClick={() => toggleModal(null)}>Cancelar</Button>
          <Button
            onClick={() => sendEvaluation()}
            style={domainColorStyle("color")}
          >
            Enviar avaliação
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

function mapStateToProps({ markets }) {
  return {
    markets,
  };
}

export default connect(mapStateToProps, null)(Feedback);
