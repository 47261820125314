import React, { useState } from "react";
import { Box, Button, Grid, Icon } from "@material-ui/core";
import { humanReadableWeight } from "helpers/util";
import { notification } from "helpers/util";
import Input from "reactstrap/lib/Input";

import "./AddRemove.scss";
import { getDomainMarketColor } from "config";
function AddRemoveComponent({ remove = () => {}, add = () => {}, product, amount, allowInput }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputAmount, setInputAmount] = useState(0);
  const isSoldInKilogram = product?.unit === "sold_in_kilogram" || Boolean(product?.sold_in_kilogram) 

  const AmountButton = ({ type }) => {
    const handleClick = () => {
      if (type === "add") {
        add(product, amount);
        return
      }
      
      if (type === "remove") {
        remove(product);
        return
      }
    };

    return (
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          className="icon-button"
        >
          <span className="btn-inner--icon">
            <Icon>{type}</Icon>
          </span>
        </Button>
      </Grid>
    )
  }

  const handleAmountChange = ({ target }) => {
    let newAmount = target.value;

    if (!newAmount) {
      newAmount = -1;
    }

    if (newAmount <= 0) {
      add(product, 1);
      setInputAmount(0);
      remove(product);
      setIsEditing(false);
      return;
    }

    if (newAmount >= product.stock) {
      setInputAmount(parseInt(inputAmount / 10));
      notification("Quantidade máxima atingida para o item", "warning");
      return;
    }

    add(product, parseInt(newAmount));
    setInputAmount(newAmount);
  };

  const handleFocus = (value) => {
    setInputAmount(value.toString());
    setIsEditing(true);
  }

  const getInputValue = (amount) => {
    return isEditing ? inputAmount : amount
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="add-remove-component"
    >
      <AmountButton type="remove" />
      <Grid
        item
        className="text-center text-sm amount"
        xs={8}
        sm={8}
        md={8}
        lg={6}
      >
        {isSoldInKilogram ? (
          humanReadableWeight(amount * product.amount_in_kilo)
        ) : allowInput ? (
          <div className="input-wrapper">
            <Input
              style={{ color: getDomainMarketColor() }}
              type="number"
              onFocus={() => handleFocus(amount)}
              value={getInputValue(amount)}
              onChange={(e) => handleAmountChange(e)}
            />
          </div>
        ) : (
          parseInt(product.amount)
        )}
      </Grid>
      <AmountButton type="add" />
    </Box>
  );
}

export default React.memo(AddRemoveComponent);
