import React, { useState, useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { showNotification } from "helpers/util";
import firebase from "firebase/app";
import { updateStyled } from "../../../helpers/util";

import { Modal, Input, Button, ModalBody, ModalFooter } from "reactstrap";
import { StyledFirebaseAuth } from "react-firebaseui";
import { createList, getLists, updateList } from "helpers/apis/listApi";
import { getUiConfig } from "enums/uiConfig";
import { UserModal } from "new/components/UserPage/UserModal/UserModal";

function NewList({
  showModal,
  title,
  toggleModal,
  size,
  titleClassName,
  className,
  cart,
  currentMarket,
  ...props
}) {
  const [createMode, setCreateMode] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [lists, setLists] = useState([]);
  const [signedIn, setSignedIn] = useState(false)
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState({})
  const [uiConfig, setUiConfig] = useState(null);
  const userInfo = useSelector(({ userInfo }) => userInfo)

  const authObserver = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setFirebaseRecoveredInfo({
          name: user?.displayName,
          phone_number: user?.phoneNumber?.split("+55").join(""),
          email: user?.email,
        })
        setSignedIn(!!user);
      }
    });
  };

  useEffect(() => {
    authObserver()
  }, [userInfo])

  async function getAuthConfig() {
    const response = await getUiConfig();
    setUiConfig(response);
  }

  useEffect(() => {
    getAuthConfig()
  }, [])

  const fetchLists = useCallback(
    async (update) => {
      if ((lists && lists.length === 0) || update) {
        const lists = await getLists(currentMarket, userInfo.id);
        setLists(lists.market_lists);
      }
    },
    [currentMarket, lists, userInfo.id]
  );

  useEffect(() => {
    fetchLists()
  }, [])

  const createNewList = async (name) => {
    const data = await createList(name, cart, currentMarket, userInfo.id);
    if (!data.message) {
      showNotification("top", "center", "Sua lista foi criada!", "success");
      fetchLists(true);
      setCreateMode(false);
      setError(null);
    } else {
      setError(data.message);
    }
  };

  const updateNewList = async (id, name) => {
    const data = await updateList(id, name, cart, currentMarket, null, userInfo.id);
    if (!data.message) {
      setError(null);
      showNotification("top", "center", "Sua lista foi atualizada!", "success");
      toggleModal();
    } else {
      setError(data.message);
    }
  };

  return (
    <>
      {!userInfo.id ? (
        <>
          <Modal
            className={"modal-dialog-centered " + (className || "")}
            size={size || "lg"}
            isOpen={uiConfig && !userInfo.id}
            toggle={() => {
              if (toggleModal) toggleModal(false);
            }}
          >
            <div className="modal-header">
                <h3 className="modal-title">
                  Listas
                </h3>
            </div>
            <StyledFirebaseAuth
              className="section t-5"
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
            {updateStyled()}
            <p style={{textAlign: "center", fontSize: "larger", fontWeight: 500}}>
              É necessário fazer login para salvar sua lista
            </p>
          </Modal>
          {signedIn && <UserModal isOpen={true} firebaseRecoveredInfo={firebaseRecoveredInfo} />}
        </>)
        : (
          <Modal
            className={"modal-dialog-centered " + (className || "")}
            size={size || "lg"}
            isOpen={showModal}
            toggle={() => {
              if (toggleModal) toggleModal(false);
            }}
          >
            <div className="modal-header">
              {title && (
                <h3
                  className={"modal-title " + (titleClassName ? titleClassName : "")}
                >
                  {title}
                </h3>
              )}
            </div>
            <ModalBody>
              {!createMode ? (
                !lists || lists.length === 0 ? (
                  <p>Você não possui listas salvas</p>
                ) : (
                  lists.map((item) => (
                    <Button
                      key={item.id}
                      onClick={() => updateNewList(item.id, item.name)}
                      style={{
                        boxShadow: "none",
                        borderBottom: "solid 1px #ccc",
                      }}
                      className="btn-block text-left bg-transparent"
                    >
                      {item.name}
                    </Button>
                  ))
                )
              ) : (
                <div>
                  <p>Dê um nome para sua lista:</p>
                  <Input
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                  {error && (
                    <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
                  )}

                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                className="text-black text-center"
                onClick={() => toggleModal(false)}
              >
                <span className="btn-inner--text"> Cancelar </span>
              </Button>
              <Button
                className="text-black text-center"
                onClick={() => createMode ? createNewList(name) : setCreateMode(true)}
              >
                <span className="btn-inner--text"> Criar Lista </span>
              </Button>
            </ModalFooter>
          </Modal>
        )
      }
    </>

  );
}
const mapStateToProps = ({ cart, userInfo }) => ({
  cart: cart.cart,
  userInfo: userInfo,
});

export default connect(mapStateToProps, null)(NewList);
