import { Box, Grid, useMediaQuery } from "@material-ui/core";
import Product from "components/NewProductCard/Product";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ProductsSlider.scss"
import Modal from "../SectionProductsModal/SectionProductsModal"
import ShowAllButton from "./ShowAllButton/ShowAllButton";
import ArrowButton from "./ArrowButton/ArrowButton";
import { groupProductsByMixId } from "helpers/mixesHelper";
import { removeRepeatedMixes } from "helpers/mixesHelper";

function ProductsSlider({ section, items }) {
  const carousel = useRef(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showFwdButton, setShowFwdButton] = useState(true);
  const [scrolledWidth, setScrolledWidth] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const showButtonInCarousel = !showFwdButton;
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const customPadding = { paddingRight: (showButtonInCarousel) ? `${isSmallScreen ? "1rem" : "2rem"}` : "0" }
  const breakpoints = {
    small: useMediaQuery('(max-width: 600px)'),
    medium: useMediaQuery('(min-width: 601px) and (max-width: 959px)'),
    large: useMediaQuery('(min-width: 960px) and (max-width: 1279px)'),
  };
  const numItems = breakpoints.small ? 2 : breakpoints.medium ? 3 : breakpoints.large ? 4 : 5;
  const cardWidth = `calc((100% - ${20 * (numItems - 1)}px) / ${numItems})`;

  const handleFwdClick = (e) => {
    e.preventDefault();
    setScrolledWidth(
      (carousel.current.scrollLeft += carousel.current.offsetWidth)
    );
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    setScrolledWidth(
      (carousel.current.scrollLeft -= carousel.current.offsetWidth)
    );
  };

  const shouldShowButton = useCallback(() => {
    setShowBackButton(scrolledWidth > 0 ? true : false);

    if (carousel.current) {
      const totalWidth =
        carousel.current.offsetWidth +
        parseInt(window.getComputedStyle(carousel.current).paddingRight, 10);
      setShowFwdButton(
        scrolledWidth > carousel.current.scrollWidth - totalWidth - 1
          ? false
          : true
      );
    }
  }, [scrolledWidth, carousel]);

  useEffect(() => {
    shouldShowButton();
  }, [shouldShowButton]);

  useEffect(() => {
    if (carousel.current) {
      carousel.current.addEventListener("scroll", shouldShowButton);
      return () => carousel.current.removeEventListener("scroll", shouldShowButton);
    }
  }, [shouldShowButton, carousel]);

  return items.length ? (
    <Grid item xs={12}>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        section={section}
      />
      <Box className="products-slider-box" px={5} pt={2} style={{ position: "relative" }}>
        <div className="title">
          <h5>{section?.display_name}</h5>
          {(!showButtonInCarousel) && (
            <ShowAllButton
              section={section}
              setShowModal={setShowModal}
              showModal={showModal}
              showInCarousel={showButtonInCarousel}
            />
          )}
        </div>
          <ArrowButton
            direction={"back"}
            show={showBackButton}
            onClick={handleBackClick}
          />
          <ArrowButton
            direction={"fwd"}
            show={showFwdButton}
            onClick={handleFwdClick}
          />
          <div
            className="carousel"
            ref={carousel}
            style={customPadding}
          >
            {removeRepeatedMixes(items).map((item, key) => (
              <Product
                key={key}
                currentCategory={item.section_id}
                products={items}
                product={item}
                mix={groupProductsByMixId(item, items)}
                sliderStyle={{ minWidth: cardWidth }}
              />
            ))}
            {(showButtonInCarousel) && (
              <ShowAllButton
                section={section}
                setShowModal={setShowModal}
                showModal={showModal}
                showInCarousel={showButtonInCarousel}
              />
            )}
          </div>
      </Box>
    </Grid>
  ) : null;
}
  

export default ProductsSlider;
