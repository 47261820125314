import { Button, useMediaQuery } from "@material-ui/core";
import React, { cloneElement } from "react";
import { getDomainMarketColor } from "config";

const ShortcutBtn = ({ label, icon, onClick }) => {
  const mainColor = getDomainMarketColor();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Button
      size={isMobile ? "small" : "large"}
      startIcon={icon ? cloneElement(icon, { style: { color: mainColor } }) : null}
      onClick={onClick}
      variant="contained"
      style={{ backgroundColor: `${mainColor}2d` }}
      className="button-shortcut"
    >
      {label}
    </Button>
  );
};

export default ShortcutBtn;
