import React, { useEffect, useState } from "react";
import Cupom from "components/UserPage/Cupom/Cupom";
import { getCupons } from "helpers/apis/cuponsApi";
import { useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { arrayHasContent } from "helpers/util";

function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const currentMarket = useSelector(({ markets }) => markets);
  const brand = useSelector(({ brand }) => brand.brand)
  const marketId = currentMarket.id || brand.main_market_id
  const userInfo = useSelector(({userInfo}) => userInfo)

  useEffect(() => {
    const getMarketCupons = async () => {
      try {
        const { discount_coupons } = await getCupons(marketId, userInfo.cpf);
        setCoupons(discount_coupons);
      } catch (error) {
        throw error;
      }
    };
    getMarketCupons();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {arrayHasContent(coupons) ? (
        coupons.map((coupon) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Cupom cupom={coupon} />
          </Grid>
        ))
      ) : (
        <Box>Sem cupons disponíveis</Box>
      )}
    </Grid>
  );
}

export default Coupons;
