import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import CarouselView from "components/StorePage/Carousel/CarouselView";
import ProductsSlider from "../../components/ProductsSlider/ProductsSlider";
import HomePageSkeleton from "./HomePageSkeleton";
import AdditionApprovalPopUp from "new/components/HigherValueCapture/AdditionApproval/PopUp/AdditionApprovalPopUp";

import { getOffers } from "helpers/apis/zoomboxApi";
import { getMarketOffers } from "helpers/apis/marketInfo";
import { getForYouProducts, getPublicityProducts, getHighlightedProducts } from "helpers/apiHelper";
import { extractItemsFromMixes } from "helpers/storeHelper";
import { applyProductFunctions } from "helpers/models/product";
import {
  currentOffersChanged,
  highlightedProductsChanged,
  publicityProductsChanged,
  forYouProductsChanged,
  clubOffersChanged
} from "actions/productsActions";

import StorePage from "containers/StorePage";
import RedirectMenu from "new/components/RedirectMenu/RedirectMenu";
import { getMenu } from "config";
import { autoRegisterCustomerOnCRM } from "helpers/registerCRMIdever";

function HomePage() {
  const { market_id } = useParams();
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("USER_ID");
  const userCPF = localStorage.getItem("crmUserCpf");
  const [isLoading, setIsLoading] = useState(false);
  const products = useSelector(({ products }) => products);
  const brand = useSelector(({brand}) => brand.brand)
  const userInfo = useSelector(({userInfo}) => userInfo)
  const neighborhoodId = useSelector(({zone}) => zone.neighborhood_id)
  const deliveryMethodId = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod?.id);
  const currentOffers = products.currentOffers;
  const limit = 20
  const searchActive = useSelector(({ui}) => ui.searchActive)
  const isValidMenu = getMenu()

  const homeSections = [
    {
      display_name: "Patrocinados",
      params: { market_id, deliveryMethodId, neighborhoodId },
      fetchFunc: (...params) => getPublicityProducts(...params),
      dispatchFunc: publicityProductsChanged,
      items: products.publicityProducts,
    },
    {
      display_name: "Destaques",
      params: { market_id, deliveryMethodId, neighborhoodId },
      fetchFunc: (...params) => getHighlightedProducts(...params),
      dispatchFunc: highlightedProductsChanged,
      items: products.highlightedProducts,
    },
    {
      display_name: "Para Você",
      params: { market_id, user_id, deliveryMethodId, neighborhoodId },
      fetchFunc: user_id
        ? (...params) => getForYouProducts(...params)
        : () => {},
      dispatchFunc: forYouProductsChanged,
      items: products.forYouProducts,
    },
    {
      display_name: "Clube",
      params: { market_id, clubPage: 1, deliveryMethodId, neighborhoodId},
      fetchFunc: userCPF ? (...params) => getOffers(...params) : () => {},
      dispatchFunc: clubOffersChanged,
      items: products.clubOffers,
    },
    {
      display_name: "Ofertas",
      params: { market_id, deliveryMethodId, neighborhoodId, onlyOffers: true},
      fetchFunc: (...params) => getMarketOffers(...params),
      dispatchFunc: currentOffersChanged,
      items: currentOffers,
    },
  ];

  async function getHomeProducts() {
    setIsLoading(true);
    try {
      const fetchPromises = homeSections.map(async (section) => {
        const response = await section.fetchFunc(
          ...Object.values(section.params),
          limit
        );
        const items = extractItemsFromMixes(response)
        dispatch(section.dispatchFunc(items))
      })
      await Promise.all(fetchPromises)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  function clearCurrentOffersState() {
    if (currentOffers.length > 0) {
      dispatch(currentOffersChanged([]));
    }
  }

  //condição para CRM idever
  const registerCustomerOnCRM = async (brandId, customerId, customerCpf) => {
    const response = await autoRegisterCustomerOnCRM(brandId, customerId)
    if (response.status === 200 || response.status === 201) {
      localStorage.setItem("crmUserCpf", customerCpf);
    }
  }


useEffect(() => {
  const isvalidCpf = !!userInfo.cpf
  if (!userCPF && isvalidCpf) {
    registerCustomerOnCRM(brand?.id, userInfo?.id, userInfo?.cpf)
  }
}, [userInfo?.id])

  useEffect(() => {
    clearCurrentOffersState()
  }, [])
  
  useEffect(() => {    
    getHomeProducts()
  }, [market_id, user_id, userCPF])

  return (
    <>
      {searchActive ? (
        <StorePage />
      ) : (
        <div className="main-content mobile-home-padding">
          {isLoading ? (
            <HomePageSkeleton />
          ) : (
            <>
              <AdditionApprovalPopUp />
              <CarouselView />
              {!isValidMenu && <RedirectMenu />}
              {homeSections.map((section, index) => (
                <ProductsSlider
                  key={index}
                  section={section}
                  items={applyProductFunctions(section.items)}
                />
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
  
}

export default withRouter(HomePage);
