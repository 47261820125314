import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import Product from "components/NewProductCard/Product";
import CloseIcon from "@material-ui/icons/Close";

import "./SectionProductsModal.scss"
import { getSectionOffers } from "helpers/apiHelper";
import { useParams } from "react-router";
import { extractItemsFromMixes } from "helpers/storeHelper";
import { applyProductFunctions } from "helpers/models/product";
import LoadingContent from "components/Loading/Loading";
import { useSelector } from "react-redux";
import { getMenu } from "config";
import { getMarketItemsBySection } from "helpers/apis/marketInfo";
import { showCommonCategoriesOnHome } from "config";

function SectionProductsModal({ showModal, setShowModal, section }) {
  const isMenuMode = getMenu();
  const [modalProducts, setModalProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { market_id } = useParams();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const deliveryMethodId = useSelector(
    ({ deliveryMethod }) => deliveryMethod.deliveryMethod?.id
  );
  const homeWithCommonCategories = showCommonCategoriesOnHome()

  const modalWidth = {
    maxWidth: isSmallScreen ? "100%" : "80%",
    maxHeight: "80vh",
    padding: isSmallScreen ? 0 : undefined,
  };

  function isHomeSection(section) {
    const homeSections = ["Patrocinados", "Destaques", "Para Você", "Clube"];

    return homeSections.includes(section.display_name);
  }

  async function getAllSectionOffers() {
    setIsLoading(true);
    try {
      const response = isHomeSection(section)
        ? await section.fetchFunc(...Object.values(section.params))
        : await getSectionOffers(market_id, section.id, null, deliveryMethodId);

      const items = extractItemsFromMixes(response);
      setModalProducts(applyProductFunctions(items));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getAllSectionProducts() {
    setIsLoading(true);
    try {
      if (section.id === "destaques") {
        setModalProducts(section.items);
      } else {
        const categoryItems = await getMarketItemsBySection(
          market_id,
          section.id,
          deliveryMethodId,
        );
        const items = extractItemsFromMixes(categoryItems)
        setModalProducts(applyProductFunctions(items));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (showModal) {
      isMenuMode || homeWithCommonCategories ? getAllSectionProducts() : getAllSectionOffers();
    }
  }, [showModal, section, market_id]);

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: modalWidth,
      }}
    >
      <div className="products-modal-header">
        <DialogTitle>
          <b>{section.display_name}</b>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowModal(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>
      <DialogContent style={{ padding: 0 }}>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <div className="products-modal-body">
            {modalProducts.map((product, key) => (
              <Product
                key={key}
                currentCategory={product.section_id}
                products={modalProducts}
                product={product}
                className="product"
              />
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SectionProductsModal;