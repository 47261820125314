import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router";

import ProductsSlider from "new/components/ProductsSlider/ProductsSlider";
import { extractItemsFromMixes } from "helpers/storeHelper";
import OffersPageSkeleton from "./OffersPageSkeleton";
import SeeMoreButton from "./components/SeeMoreButton/SeeMoreButton";
import { getSectionOffers } from "helpers/apiHelper";
import { filterItemsByCategory } from "helpers/categoriesHelper";
import { domainColorStyle } from "config";
import { currentOffersChanged } from "actions/productsActions";
import StorePage from "containers/StorePage";

function OffersPage() {
  const { market_id } = useParams();
  const dispatch = useDispatch();
  const [numDisplayedCategories, setNumDisplayedCategories] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const categories = useSelector(({ categories }) => categories.categories);
  const currentOffers = useSelector(({ products }) => products.currentOffers);
  const searchActive = useSelector(({ ui }) => ui.searchActive)
  const deliveryMethodId = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod?.id)

  // cria um array contendo apenas as categorias que têm ofertas ativas a partir do array de categorias
  const categoriesWithOffers =
    categories.filter((category) => {
      // a categoria é filtrada se houver ao menos uma oferta cujo section_id seja igual ao id da categoria atual
      return currentOffers.some((offer) => offer.section_id === category.id);
    }) || [];

  const hasMoreCategories =
    // verifica se o número de categorias mostradas é menor do que o número de categorias no array
    numDisplayedCategories < categoriesWithOffers.length &&
    // verifica se o index da última categoria mostrada é menor que o index da última categoria do array
    numDisplayedCategories - 1 < categoriesWithOffers.length - 1;  

  async function getOffersFromCategories(categories, limit) {
    setIsLoading(true);
    try {
      const promises = categories.map(async (category) => {
        const response = await getSectionOffers(market_id, category.id, limit, deliveryMethodId);
        return extractItemsFromMixes(response);
      });
      const results = await Promise.all(promises);
      const items = results.flat();
      dispatch(currentOffersChanged(items));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOffersFromCategories(categories, 20);
  }, [categories, market_id]);

  function showMoreCategories() {
    setNumDisplayedCategories(numDisplayedCategories + 5);
  }

  return (
    <>
    {searchActive ? (
      <StorePage />
    ) : (
    <div className="main-content mobile-home-padding">
      {isLoading ? (
        <OffersPageSkeleton />
      ) : (
          categoriesWithOffers.length ? (
            <>
              {categoriesWithOffers
                .slice(0, numDisplayedCategories)
                .map((category) => (
                  <ProductsSlider
                    key={category.id}
                    section={category}
                    items={filterItemsByCategory(currentOffers, category)}
                  />
                ))}
                <SeeMoreButton
                  hasMoreCategories={hasMoreCategories}
                  showMoreCategories={showMoreCategories}
                />
            </>
          ) : (
            <div className="text-center sad-div col-12 mt-5">
              <i
                style={domainColorStyle("color")}
                className="fas fa-heart-broken sad-class"
              />
              <h3 className="w-100">Sem ofertas disponíveis no momento</h3>
            </div>
          )
          )}
      </div>
    )}
    </>
  );
}

export default withRouter(OffersPage);
