// modules
import React, { useState, useEffect, useCallback } from "react";
import {
  getAllTimes,
  getAllPaymentTypes,
  getRatingMarketById,
  getMarketById,
} from "../../helpers/apis/marketInfo";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { getDomainLogo, domainColorStyle, getDomainMarketId } from "config";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { TextField, MenuItem } from "@material-ui/core";
import "./InfoStore.scss";
import { Rating } from "@material-ui/lab";
import { maskPhone } from "helpers/util";
import { useHistory } from "react-router-dom";
import { timeZoneAdjustedMoment } from "helpers/util";
import { maskCNPJ } from "helpers/util";
import { useSelector } from "react-redux";

function InfoStore() {
  const history = useHistory();
  const [marketsList, setMarketsList] = useState([]);
  const [ratingsMarket, setRatingsMarket] = useState([]);
  const [times, setTimes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const currentMarket = useSelector(({markets}) => markets)
  const [valueInfoMarket, setValueInfoMarket] = useState({})
  const brand = useSelector(({brand}) => brand.brand)
  const {color} = domainColorStyle("color")
  const brandMarkets = useSelector(({ brand }) => brand.brandMarkets)

  const getMarketList = useCallback(async () => {
    const id = getDomainMarketId() || brandMarkets[0].id;
    setMarketsList(brandMarkets);
    getRatingsMarket(id);
    getTimes(id);
    getPaymentTypes(id);
  }, [brand]);

  useEffect(() => {
    getMarketList();
    getInfoMarket(currentMarket.id)
  }, []);

  const changeMarket = (id) => {
    getInfoMarket(id)
    getRatingsMarket(id);
    getTimes(id);
    getPaymentTypes(id);
    getRatingsMarket(id);
  };

  const getInfoMarket = async (id) => {
    const response = await getMarketById(id);
    setValueInfoMarket(response.data)
  }

  const getRatingsMarket = async (id) => {
    const response = await getRatingMarketById(id);
    setRatingsMarket(response.data.visible_ratings);
  };

  const getTimes = async (id) => {
    const response = await getAllTimes(id);
    setTimes(response.data.operating_times);
  };
  const getPaymentTypes = async (id) => {
    const response = await getAllPaymentTypes(id);
    setPaymentTypes(response.data.payment_types);
  };

  const [activeTab, setActiveTab] = useState(0);
  const sections = ["Informações", "Avaliações"];
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderDrivethruTimes = useCallback(() => {
    return brand.has_drivethru ? (<div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
      }}
    >
      <div className="info-store-title">
        <div style={{ marginBottom: "-5px" }}>
          Retirada em loja
        </div>
        <i
          style={{
            fontWeight: "400",
            fontSize: 12,
          }}
        >
          Próximos horários
        </i>
      </div>
      {times.map((time) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ fontWeight: "600", marginRight: "3px" }}>
            {time.header}:
          </div>
          {time.options.map(
            (option) =>
              timeZoneAdjustedMoment(option.start_time).format(
                " HH:mm"
              ) +
              " ás " +
              timeZoneAdjustedMoment(option.end_time).format(
                "HH:mm"
              ) +
              ". "
          )}
        </div>
      ))}
    </div>) : null
  }, [brand, times])

  const renderDeliveryTimes = useCallback(() => {
    return brand.has_delivery ? (<div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
      }}
    >
      <div className="info-store-title">
        <div style={{ marginBottom: "-5px" }}>
          Entrega
        </div>
        <i
          style={{
            fontWeight: "400",
            fontSize: 12,
          }}
        >
          Próximos horários
        </i>
      </div>
      {times.map((time) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ fontWeight: "600", marginRight: "3px" }}>
            {time.header}:
          </div>
          {time.options.map(
            (option) =>
              timeZoneAdjustedMoment(option.start_time).format(
                " HH:mm"
              ) +
              " ás " +
              timeZoneAdjustedMoment(option.end_time).format(
                "HH:mm"
              ) +
              ". "
          )}
        </div>
      ))}
    </div>) : null
  }, [brand, times])

  return (
    <div>
      <Container className="mt-5">
        <Row className="mt-8">
          <Col xs={12} md={1}>
            <IconButton
              style={{
                ...domainColorStyle("color"),
                fontWeight: "bold",
                borderRadius: "4px",
              }}
              size="small"
              onClick={() => {
                history.goBack();
              }}
              color="primary"
            >
              <ArrowBack style={domainColorStyle("fill")} fontSize="inherit" />
              Voltar
            </IconButton>
          </Col>
        </Row>
        {getDomainLogo() && (
          <Row className="text-center">
            <img
              className="cep-image img mx-auto"
              src={getDomainLogo()}
              alt="Logo do mercado"
            />
          </Row>
        )}
        <TextField
          style={{ flex: 1, display: "flex", background: "#fff" }}
          label="Loja"
          value={
            valueInfoMarket.id
              ? valueInfoMarket.id
              : marketsList[0]
                ? marketsList[0].id
                : ""
          }
          select
        >
          {marketsList.map(({ id, name }) => (
            <MenuItem
              key={id}
              onClick={() => {
                changeMarket(id);
              }}
              value={id}
            >
              {name}
            </MenuItem>
          ))}
        </TextField>

        <div style={{ marginTop: 20 }}>
          <div>{currentMarket.description}</div>
          <div style={{ display: "flex", marginTop: 20, marginBottom: 20 }}>
            <div
              style={{
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="title">Contato</div>
              <div>{maskPhone(currentMarket.phone_number)}</div>
              <div>{maskPhone(currentMarket.second_phone_number)}</div>
              <div>{currentMarket.email}</div>

            </div>
            {currentMarket.cnpj ? (
              <div style={{ width: "50%" }}>
              <div className="info-store-title">Dados empresariais</div>
              <div>CNPJ: {maskCNPJ(currentMarket.cnpj)}</div>
              <div>Razão Social: {currentMarket.corporate_name}</div>
             </div>
            ) : (
              <></>
            )}
          </div>
          <Nav
            tabs
            style={{ justifyContent: "space-around", marginBottom: "30px" }}
          >
            {sections.map((item, index) => (
              <NavItem
                style={{ width: "50%", justifyContent: "center" }}
                key={index}
              >
                <NavLink
                  className={
                    activeTab === index 
                      ? "nav-link-info-store active" 
                      : "nav-link-info-store"
                  }
                  style={{ "--bottom-dynamic-color": `${color}`}}
                  onClick={() => {
                    toggle(index);
                  }}>
                  {item}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <div>
                {renderDeliveryTimes()}
                {renderDrivethruTimes()}

                <div className="container-payment-types">
                  <div className="info-store-title">Formas de pagamento</div>
                  {!!paymentTypes.filter((payment) => payment.kind === "online").length && (
                    <>
                      <div style={{ ...domainColorStyle("background") }} className="info-payment-types">
                        <span className="payment-types-title">
                          Online
                        </span>
                      </div>
                      {paymentTypes.map((payment) => (
                        <>
                          {payment.kind === "online" ? (
                            <div className="payment-type-method">
                              <div className="containerPaymentMethodImage">
                                <img
                                  alt="cartão"
                                  className="paymentMethodImage"
                                  src={payment.image}
                                />
                              </div>
                              <span>{payment.name}</span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}

                  {!!paymentTypes.filter((payment) => payment.kind === "credit").length && (
                    <>
                      <div style={{ ...domainColorStyle("background") }} className="info-payment-types">
                        <span className="payment-types-title">
                          Crédito
                        </span>
                      </div>
                      {paymentTypes.map((payment) => (
                        <>
                          {payment.kind === "credit" ? (
                            <div className="payment-type-method">
                              <div className="containerPaymentMethodImage">
                                <img
                                  alt="cartão"
                                  className="paymentMethodImage"
                                  src={payment.image}
                                />
                              </div>
                              <span>{payment.name}</span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}

                  {!!paymentTypes.filter((payment) => payment.kind === "debit").length !== 0 && (
                    <>
                      <div style={{ ...domainColorStyle("background") }} className="info-payment-types">
                        <span className="payment-types-title">
                          Débito
                        </span>
                      </div>
                      {paymentTypes.map((payment) => (
                        <>
                          {payment.kind === "debit" ? (
                            <div className="payment-type-method">
                              <div className="containerPaymentMethodImage">
                                <img
                                  alt="cartão"
                                  className="paymentMethodImage"
                                  src={payment.image}
                                />
                              </div>
                              <span>{payment.name}</span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}

                  {!!paymentTypes.filter((payment) => payment.kind === "food").length && (
                    <>
                      <div style={{ ...domainColorStyle("background") }} className="info-payment-types">
                        <span className="payment-types-title">
                          Vale
                        </span>
                      </div>
                      {paymentTypes.map((payment) => (
                        <>
                          {payment.kind === "food" ? (
                            <div className="payment-type-method">
                              <div className="containerPaymentMethodImage">
                                <img
                                  alt="cartão"
                                  className="paymentMethodImage"
                                  src={payment.image}
                                />
                              </div>
                              <span>{payment.name}</span>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}

                </div>
              </div>
            </TabPane>
            <TabPane tabId={1}>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 10,
                  }}
                >
                  <div className="info-store-title">Avaliações: </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ marginRight: 20 }}>
                      Pontualidade na entrega:
                    </span>
                    <Rating
                      precision={0.1}
                      name="Pontualidade na entrega"
                      value={parseFloat(currentMarket.rating_delivery_time)}
                      readOnly
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ marginRight: 20 }}>
                      Qualidade dos Produtos:{" "}
                    </span>
                    <Rating
                      precision={0.1}
                      name="Qualidade dos Produtos"
                      value={parseFloat(currentMarket.rating_items_quality)}
                      readOnly
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ marginRight: 20 }}>
                      Variedade de Produtos:
                    </span>
                    <Rating
                      precision={0.1}
                      name="Variedade de Produtos"
                      value={parseFloat(currentMarket.rating_items_variety)}
                      readOnly
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 32,
                  }}
                >
                  <div className="info-store-title">Opinião dos nossos clientes :</div>
                  {ratingsMarket.map((rating) => (
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {rating.review}
                      </div>
                      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
                    </div>
                  ))}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  );
}

export default InfoStore;
