import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselCaption,
} from "reactstrap";

import { Swipeable } from "react-swipeable";
import campaign from "../../../assets/img/icons/campaign.png";
import "./Carousel.scss"
class CustomCarousels extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.carouselRef = React.createRef();
  }
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { items } = this.props;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { items } = this.props;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { items } = this.props;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          className="custom-carousel h-100"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img
            className={"d-block shadow bannerStore"}
            style={{ width: "100%", height: "340px", objectFit: "fill", borderRadius: 10 }}
            onClick={() => item.onClick(item)}
            src={item.src}
            alt={item.altText || ""}
          />
          <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
          />
          {item.is_publicity &&
            <p className="has-publicity"><img alt="" src={campaign} /> Contém publicidade</p>
          }
        </CarouselItem>
      );
    });

    return (
      <Swipeable onSwipedLeft={this.next} onSwipedRight={this.previous}
        className="carousel-swipe"
      >
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          slide={false}
          pause={"hover"}
          previous={this.previous}
          className="h-100"
          interval={4000}
        >
          {slides}
          {items.length > 1 &&
            <>
              <CarouselControl
                direction="prev"
                directionText="Anterior"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Próximo"
                onClickHandler={this.next}
              />
            </>}
        </Carousel>
      </Swipeable>
    );
  }
}

export default CustomCarousels;
