import React from "react"
import { Box, Card, FormControlLabel, Radio, RadioGroup, Checkbox, TextField, CircularProgress } from "@material-ui/core";

import "./OrderPreferences.scss"
import { getDomainMarketColor } from "config";

function OrderPreferences({
  setRequestBags,
  request_custom_bags,
  setMissingItems,
  missing_items = {},
  custom_bags,
  total_cart_amount,
  preferences,
  setPreferences,
  postalMethodValues,
  loading
}) {
  const isValidInfoCorreio = !postalMethodValues.length
  const mainColor = getDomainMarketColor();
  const seletedCustomBags = ({ target }) => {
    const bag = custom_bags.custom_bags.find((item) => String(item.id) === target.value);
    const custom_bag = {
      name: bag.name,
      custom_bag_id: bag.id,
      custom_bag_amount: Math.round(total_cart_amount / bag.items_capacity),
      custom_bag_value: parseFloat(
        Math.round(total_cart_amount / bag.items_capacity) * bag.value
      ).toFixed(2),
    };
    if (request_custom_bags.custom_bag_id !== bag.id) {
      setRequestBags(custom_bag)
    } else {
      setRequestBags({})
    }
  }

  return (
    <Card className='mt-3 order-preferences'>
      {loading ? (
        <div style={{height: "15vh", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
        <CircularProgress color="primary" />
      </div>
      ) : (
         <Box pb={2} display="flex" flexDirection="column">
         <Box px={2} pt={2} className="title-preferences">
           <h5>Preferências do pedido</h5>
         </Box>
         <Box display="flex" flexDirection="column" px={3} pt={1} className='values-preferences'>
           {isValidInfoCorreio && <span className='option-title'>Se houver produtos em falta</span>}
           <RadioGroup
             name="missing-items"
             value={missing_items.selected_option}
             onChange={({ target }) => {
               setMissingItems({ ...missing_items, selected_option: target.value })
             }}
           >
             {missing_items.options.map(({ id, description }) => (
               <FormControlLabel
                 key={id}
                 className={missing_items.selected_option === description ? "checked" : ""}
                 value={description}
                 control={<Radio size="small" color="primary" className='preferences-radio' />}
                 label={description} />
             ))}
           </RadioGroup>
           {isValidInfoCorreio && 
           <>
           <span className='option-title'>Quero receber minhas compras em</span>
           {custom_bags.custom_bags.map((option) => (
             <FormControlLabel
               key={option.id}
               value={option.id}
               onChange={(event) => seletedCustomBags(event)}
               control={
                 <Checkbox
                   size="small"
                   color="primary"
                   className='preferences-radio'
                   checked={request_custom_bags.custom_bag_id === option.id}
                 />}
               label={option.name} />
           ))}
           </>}
           <span className='option-title'>Quer deixar algum recado sobre seu pedido? (opcional)</span>
           <TextField
             value={preferences}
             onChange={({ target }) => setPreferences(target.value)}
             multiline
             rows={4}
             variant="outlined"
           />
           {!isValidInfoCorreio && 
             <span 
               style={{ backgroundColor: `${mainColor}2d`, padding: "5%", borderRadius: "3%", marginTop: "1rem"}}>
               Se houver produtos em falta serão removidos do pedido e o valor dos itens serão estornados.
             </span>
           }
         </Box>
       </Box>
      )}
    </Card>
  )
}

export default React.memo(OrderPreferences);