import { USER_INFO_CHANGED, CLEAR_USER_INFO } from "ActionTypes"

export function userInfoChanged(userInfo) {
  localStorage.loggedCpf = userInfo.cpf
  return {
    type: USER_INFO_CHANGED,
    payload: userInfo
  }
}

export function clearUserInfo() {
  delete localStorage.loggedCpf
  return {
    type: CLEAR_USER_INFO,
  };
}