import React from "react";
import { Box, Grid, Link } from "@material-ui/core";
import { Twitter } from '@material-ui/icons';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

export default function SocialSectionsFooter({sections, adjustableGridSize}) {

  const iconMap = {
    "facebook": <FacebookIcon color='primary' />,
    "instagram": <InstagramIcon color='primary' />,
    "twitter": <Twitter color='primary' />,
    "youtube": <YouTubeIcon color='primary' />
  }

  return (
    sections
      .filter(section => section.kind === 'social')
      .map((sectionSocial, index) => (
        <>
          <hr style={{margin: "1em 0"}}/>
          <Grid
            item
            key={index}
            xs={adjustableGridSize}
            className='support-footer-section-area footer-section-area-mobile'>
            <h6 style={{ textTransform: 'uppercase' }}>{sectionSocial.title}</h6>
            <Box className='footer-social-media-area'>
              {sectionSocial.content?.map((socialType, index) => (
                <div key={index} >
                  <Link
                    underline='none'
                    href={socialType.url} target="_blank">
                    {iconMap[socialType.social_type]}
                    <span style={{ color: 'black', fontSize: 12 }}>{socialType.message}</span>
                  </Link>
                </div>
              ))}
            </Box>
          </Grid>
        </>
      ))
  )
}