import React from 'react';
import CartProductCard from 'components/CartProductCard';

function ItemList({ cart = {} }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
      {cart.map((mix, key) => (
        <CartProductCard mix={mix} key={key} />
      ))}
    </div>
  )
}

export default ItemList;