import React from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./ProductsSlider.scss"

function ProductSliderSkeleton({ isValidStorePage }) {
  const breakpoints = {
    small: useMediaQuery('(max-width: 600px)'),
    medium: useMediaQuery('(min-width: 601px) and (max-width: 959px)'),
    large: useMediaQuery('(min-width: 960px) and (max-width: 1279px)'),
  };
  const numItems = breakpoints.small ? 2 : breakpoints.medium ? 3 : breakpoints.large ? 4 : 5;

  const cardWidth = `calc((100% - ${20 * (numItems - 1)}px) / ${numItems})`;

  return (
    <Grid item xs={12}>
      <Box px={5} pt={2} style={{ position: "relative" }}>
        {!isValidStorePage &&
          <Box className="title" mb={2}>
            <Skeleton
              variant="rect"
              width={breakpoints.small ? 150 : 200}
              height={30}
            />
            <Skeleton
              variant="rect"
              width={breakpoints.small ? 75 : 100}
              height={30}
            />
          </Box>}
        <Box display="flex" position="relative">
          <Box className="carousel" style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
            {[...Array(numItems)].map((_, i) => (
              <Box key={i} width={cardWidth}>
                <Skeleton variant="rect" width="100%" height={240} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default ProductSliderSkeleton;
