
import React from "react";
import { Button, Container, Paper } from "@material-ui/core";
import "./PageNotFound.scss"
import { useHistory } from "react-router-dom";
import { domainColorStyle } from "config";
import { getDomainLogo } from "config";



export default function PageNotFound() {
  const history = useHistory();

  return (
    <Container maxWidth="lg" style={{ width: "40%", marginTop: "20vh" }}>
      <Paper className="paper-page-not-found">
        <div >
          <img
            className="cep-image img mx-auto"
            src={getDomainLogo()}
            alt="Logo do mercado"
          />
        </div>
        <div style={{ fontSize: "5rem" }}>404</div>
        <div style={{ fontSize: "1.2rem", textAlign: "center" }}>
          Parece que a página que você está procurando não existe.
        </div>
        <hr style={{ ...domainColorStyle("background"), width: "85%"}} />
        <div>
          <p style={{fontWeight: 400}}>
            Se você acha que há algo errado, reporte o problema.
          </p>
        </div>
        <Button
          color="primary"
          variant="contained"
          className="button-back-home"
          onClick={() => history.push('/')}
        >
          Voltar
        </Button>
      </Paper>
    </Container>
  )
};


