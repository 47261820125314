import React from "react";
import { Grid } from "@material-ui/core";
import { domainColorStyle } from "config";

export function HomePageLayout({ children, ignore = false }) {
  return ignore ? (
    children
  ) : (
    <div>
      <div className="modal-home-screen">
        <div
          className="background"
          style={domainColorStyle("backgroundColor")}
        ></div>
        <Grid item xs={11} sm={8} md={7} lg={4}>
          <div className="modal-body p-0">{children}</div>
        </Grid>
      </div>
    </div>
  );
}
