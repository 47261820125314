import firebase from "firebase";
import "firebase/auth";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let isFirebaseBrandConfig = false;
let useLegacyChat = true;

export function isFirebaseBrand() {
  return isFirebaseBrandConfig;
}

export function getUseLegacyChat() {
  return useLegacyChat;
}

export function initFirebase(brandConfig) {
  if (brandConfig.firebase_access_json) {
    useLegacyChat = false
  }
  const config = brandConfig.firebase_config_json;
  if (!firebase.apps.length) {
    if (config) {
      firebase.initializeApp(JSON.parse(config));
      isFirebaseBrandConfig = true;
    } else {
      firebase.initializeApp(firebaseConfig);
    }
    firebase.auth().languageCode = "pt-br";
    firebase.analytics();
  }
}
