import _ from "lodash";
import { getCartItemPreferences } from "./util";

export function hasAnotherMarketMix(cart, anotherMarketId) {
  return Object.keys(cart).some((cartKey) => {
    return cart[cartKey].market_id !== parseInt(anotherMarketId);
  });
}
export function getMixesFromCart(cart) {
  return Object.keys(cart).map((mixId) => {
    return cart[mixId];
  });
}

export function getMixesForAdults(cart) {
  return Object.keys(cart).filter(
    (mixId) => cart[mixId].adult_restricted || cart[mixId].category_id === 4261
  );
}

export function getProductsTotal(mixesMapped) {
  return (
    Math.round(
      (mixesMapped.reduce((sum, mix) => sum + mix.total_price, 0) +
        Number.EPSILON) *
      100
    ) / 100
  ).toFixed(2);
}

export function hasFreeDelivery(mixesMapped) {
  return mixesMapped.some((mix) => {
    return mix.has_free_delivery;
  });
}

export function getCartTotal(
  mixesMapped,
  currentMarket,
  driveThru = false,
  discounts = 0,
  custom_bag_value = 0,
  currentDeliveryMethod,
  correioDeliveryTax
) {
  const productsPrice = getProductsTotal(mixesMapped);
  const freeDelivery = hasFreeDelivery(mixesMapped);
  var deliveryTax = !correioDeliveryTax ? currentDeliveryMethod.tax : parseInt(correioDeliveryTax?.price);
  if (driveThru || freeDelivery) {
    deliveryTax = 0;
  }
  if (productsPrice <= 0) {
    return 0;
  }
  return (
    parseFloat(productsPrice) +
    deliveryTax +
    currentMarket.service_tax +
    custom_bag_value -
    discounts
  );
}
/*
 * Função utilizada para aplicar o total_price dos itens do carrinho (Útil para preços promocionais)
 * cartItem - Item que está sendo adicionado ou removido no carrinho
 * cart - O próprio carrinho (necessário para verificação de promoção com vários itens)
 */

function getTotalPrice(cart, cartItem, receivedAmount) {
  // raise 'Unit price must be Float'     unless @unit_price.is_a? Numeric
  // raise 'Discount table must be Hash'  unless @discount_table.nil? || @discount_table.is_a?(Array)
  const {
    promotion_id,
    promotion_discount_table,
    kind_for_promotion_calculation,
  } = cartItem;

  const fullPriceItem =
    Object.keys(cart)
      .map((productId) => {
        return cart[productId];
      })
      .find((product) => {
        return (
          product.promotion_id === promotion_id &&
          product.kind_for_promotion_calculation === "full_price_item"
        );
      }) || {};

  const fullPriceItemAmount = fullPriceItem.amount;
  const amount =
    receivedAmount === undefined ? cartItem.amount : receivedAmount;
  const unitPrice = cartItem.currentPrice();

  if (amount <= 0 || !promotion_discount_table) {
    return unitPrice * amount;
  }

  const filteredDiscountTableRows = promotion_discount_table.filter(
    (dicountTableRow) => {
      return dicountTableRow.amount <= (fullPriceItemAmount || amount) || dicountTableRow.is_regressive === true;
      // o "ou" foi colocado pra identificar o cenário da promo "until_n_units_pay_x_per_unit"
      // em que o valor promocional já deve ser aplicado nas primeiras unidades adicionadas
    }
  );

  const maxRow = _.maxBy(filteredDiscountTableRows, (dicountTableRow) => {
    return dicountTableRow.amount;
  });

  if (!maxRow || kind_for_promotion_calculation === "full_price_item") {
    return unitPrice * amount;
  }

  const maxRowAmount = maxRow.amount;
  const maxRowWeight = maxRow.weight;
  const isProgressive = maxRow.is_progressive;
  const isRegressive = maxRow.is_regressive;

  if (isProgressive) {
    return maxRowWeight * amount;
  }

  if (isRegressive) {
    const itemsWithPromotionPrice = Math.min(amount, maxRowAmount)
    const itemsWithOriginalPrice = amount - itemsWithPromotionPrice

    const promotionTotalPrice = itemsWithPromotionPrice * maxRowWeight;
    const originalTotalPrice = itemsWithOriginalPrice * unitPrice;

    return promotionTotalPrice + originalTotalPrice
  }

  const isExclusiveToNthItem = maxRow.exclusive_to_nth_item;

  if (isExclusiveToNthItem) {
    const amountLeft = Math.max(amount - 1, 0)
    return (maxRowWeight * unitPrice) + amountLeft * unitPrice;
  }

  if (kind_for_promotion_calculation === "promotional_price_item") {
    const numberOfPacks = Math.min(
      Math.trunc((fullPriceItemAmount || 0) / maxRowAmount),
      amount
    );

    const amountLeft = Math.max(amount - numberOfPacks, 0);
    const totalPrice = maxRowWeight * numberOfPacks + amountLeft * unitPrice;
    return totalPrice;
  }

  const numberOfPacks = Math.trunc(amount / maxRowAmount);
  const promotionalTotalAmount = numberOfPacks * maxRowAmount * maxRowWeight;
  const promotionalTotalPrice = unitPrice * promotionalTotalAmount;
  const amountLeft = amount % maxRowAmount;
  return promotionalTotalPrice + getTotalPrice(cart, cartItem, amountLeft);
}

export function applyTotalPrice(cart, cartItem) {
  const samePromotionIdItem = [
    cartItem,
    ...Object.keys(cart)
      .filter((productId) => {
        return productId + "" !== cartItem.id + "";
      })
      .map((productId) => {
        return cart[productId];
      }),
  ].filter((product) => {
    return product.promotion_id === cartItem.promotion_id;
  });

  return samePromotionIdItem.map((product) => {
    return { ...product, total_price: getTotalPrice(cart, product) };
  });
}
export function orderRequestFormattedItems(mixes) {
  return mixes.map((mix) => {
    const usedCrm = mix.crmOffer() !== null && mix.crmOffer() !== undefined
    const highestPriorityOffer = mix.highestPriorityOffer() || {}
    const offerId = usedCrm ? null : highestPriorityOffer.id
    const brandOfferId = highestPriorityOffer.model === "BrandOffer" ? highestPriorityOffer.id : null   
    return {
      amount: mix.amount,
      item_id: mix.id,
      origin: mix.origin,
      price: mix.currentPrice(),
      total_price: mix.total_price,
      used_crm: usedCrm,
      preferences: getCartItemPreferences(mix),
      offer_id: offerId,
      order_item_offer_kind: highestPriorityOffer.kind,
      brand_offer_id: brandOfferId,
      product_description: mix.description
    };
  });
}

export function discountsFormattedCart(cart) {
  return getMixesFromCart(cart).map((mix) => {
    return {
      item_id: mix.id,
      total_price: mix.total_price,
    };
  });
}
