import { getAuthMethods } from "helpers/activeAuthMethods";

export function getUiConfig(brand) {
  return {
    signInFlow: "popup",
    signInOptions: getAuthMethods(brand),
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };
}