import { Button, Card } from "@material-ui/core";
import React, { useState } from "react";

import "./Warning.scss";
import { getDomainMarketColor } from "config";

function Warning({ products, message }) {
  const [currIndex, setCurrIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(true);
  const currProduct = products?.[currIndex]

  if (message && showWarning) {
    return (
      <div className="warning-overlay" >
        <Card
          className="warning-card"
          style={{ borderTop: `15px solid ${getDomainMarketColor()}`, width: "85%" }}
        >
          <h5 style={{ color: getDomainMarketColor() }}>Aviso</h5>
          <p>{message}</p>
          <Button
            style={{ background: getDomainMarketColor(), width: "20%"}}
            onClick={() => setShowWarning(false)}
          >
            OK
          </Button>
        </Card>
      </div>
    );
  }

  if (currProduct) {
    return (
      <div className="warning-overlay">
        <Card
          className="warning-card"
          style={{ borderTop: `15px solid ${getDomainMarketColor()}` }}
        >
          <h5 style={{ color: getDomainMarketColor() }}>Aviso</h5>
          <p>
            O produto <b>{currProduct.description}</b> é sob encomenda, portanto
            você poderá agendar o pedido para{" "}
            <b>{currProduct.preparation_time_in_hours} horas</b> a partir de
            agora.
          </p>
          <Button
            style={{ background: getDomainMarketColor() }}
            onClick={() => setCurrIndex(currIndex + 1)}
          >
            OK
          </Button>
        </Card>
      </div>
    );
  }

  return null;
}

export default Warning;
