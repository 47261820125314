import { Box, Card, Grid, IconButton } from "@material-ui/core";
import Product from "components/NewProductCard/Product";
import { getRelatedItems } from "helpers/apiHelper";
import { applyProductFunctions } from "helpers/models/product";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import "./OrderRelatedItems.scss";
import { random, debounce } from "lodash";
import { getDomainMarketColor } from "config";
import { useSelector } from "react-redux";
import LoadingContent from "components/Loading/Loading";


const OrderRelatedItems = ({ cart = [] }) => {
  const [relatedItems, setRelatedItems] = useState([]);
  const carousel = useRef(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showFwdButton, setShowFwdButton] = useState(true);
  const [scrolledWidth, setScrolledWidth] = useState(0);
  const deliveryMethod = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod)
  const [loading, setLoading] = useState(true);
  const marketId = useSelector(({ markets }) => markets.id);
  

  const randomizeRelatedItems = useRef(
    debounce(async (cart) => {
      setLoading(true);
      let idList = cart.map((item) => item.id);
      const { related } = await getRelatedItems(marketId, idList[random(0, idList.length - 1)], 10, deliveryMethod.id);
      setRelatedItems(applyProductFunctions(related));
      setLoading(false);
    }, 200)
  ).current;


  useEffect(() => {
    randomizeRelatedItems(cart);
  }, []);

  const handleRightClick = (e) => {
    e.preventDefault();
      setScrolledWidth(
        carousel.current.scrollLeft += carousel.current.offsetWidth
      );
  };

  const handleLeftClick = (e) => {
    e.preventDefault();
      setScrolledWidth(
        carousel.current.scrollLeft -= carousel.current.offsetWidth
      );
  };

  const shouldShowButton = useCallback(() => {
    scrolledWidth > 0 
      ? setShowBackButton(true)
      : setShowBackButton(false)

    
    if(carousel.current !== null){
      const contentWidth = carousel.current?.scrollWidth;
      const viewportWidth = carousel.current?.offsetWidth;
      const maxScroll = contentWidth - viewportWidth;
      
      scrolledWidth >= maxScroll 
        ? setShowFwdButton(false)
        : setShowFwdButton(true)
    }
  }, [scrolledWidth]);

  useEffect(() => {
    function handleCarouselScroll() {
      setScrolledWidth(carousel.current.scrollLeft);
    }
  
    if (carousel.current) {
      carousel.current.addEventListener('scroll', handleCarouselScroll);
    }
  
    return () => {
      if (carousel.current) {
        carousel.current.removeEventListener('scroll', handleCarouselScroll);
      }
    },

    shouldShowButton()
  }, [scrolledWidth, relatedItems]);

  return (
    <Card>
      <Grid item xs={12} className="container-related-items">
        <Box px={5} pt={2} style={{ position: "relative" }}>
          <div>
            <h5 style={{ color: getDomainMarketColor()}} className="window-shop-title">
              Algo a mais?
            </h5>
            <p className="window-shop-text">
              Confira nossas recomendações personalizadas para você.
            </p>
          </div>
          {loading ? (
            <LoadingContent />
          ) : (
            <>
              <div className="carousel-arrow-button">
                {showBackButton && (
                  <IconButton
                    className="button-arrow"
                    style={{ backgroundColor: getDomainMarketColor() }}
                    onClick={handleLeftClick}
                    direction={"back"}>
                    <NavigateBeforeIcon className="arrow-back-icon" />
                  </IconButton>
                )}

                <div className="carousel" ref={carousel}>
                  {relatedItems.map((related_item) => (
                    <Product
                      currentCategory={related_item.category_id}
                      products={relatedItems}
                      product={related_item}
                    />
                  ))}
                </div>

                {showFwdButton && (
                  <IconButton
                    className="button-arrow"
                    style={{ backgroundColor: getDomainMarketColor() }}
                    onClick={handleRightClick}>
                    <NavigateNextIcon className="arrow-next-icon" />
                  </IconButton>
                )}
              </div>
            </>)}
        </Box>
      </Grid>
    </Card>
  );
};

export default OrderRelatedItems;
