import React from "react";
import "./OrderStatus.scss";
import { Box } from "@material-ui/core";

const OrderStatus = ({ status, minutes, seconds, correio }) => {
  const timeExpired = status === "waiting" && minutes === 0 && seconds === 0;
  const orderCanceled = status === "canceled" || status === "cancel_requested";

  if (timeExpired || orderCanceled) {
    return (
      <Box className="order-status canceled">
        <span>Cancelado</span>
      </Box>
    );
  }

  if (status === "received") {
    return (
      <Box className="order-status received">
        <span>Recebido</span>
      </Box>
    );
  }
  
  if (status === "in_delivery" && correio?.tax_calculation_method === "correios"){
    return(
      <Box className="order-status waiting" style={{width: '10%'}}>
        <span>Em transito</span>
     </Box>
    )
  }

  return (
    <Box className="order-status waiting">
      <span>Aguardando Pagamento</span>
    </Box>
  );
};

export default OrderStatus;
