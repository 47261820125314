import { SUBCATEGORIES_CHANGED } from "ActionTypes"
import { BANNERS_CHANGED } from "ActionTypes"
import { CATEGORIES_CHANGED } from "ActionTypes"

export function bannersChanged(banners) {
    return {
        type: BANNERS_CHANGED,
        payload: banners
    }
}
export function categoriesChanged(categories) {
    return {
        type: CATEGORIES_CHANGED,
        payload: categories
    }
}

export function subcategoriesChanged(subcategories) {
    return {
        type: SUBCATEGORIES_CHANGED,
        payload: subcategories
    }
}