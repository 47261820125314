import { BRAND_CHANGED, BRAND_MARKETS_CHANGED } from "ActionTypes";
const INITIAL_STATE = {
  brand: {},
  brandMarkets: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case BRAND_CHANGED:
      return Object.assign({}, state, {
        brand: action.payload,
      });
    case BRAND_MARKETS_CHANGED:
      return Object.assign({}, state, {
        brandMarkets: action.payload,
      });
    default:
      return state;
  }
}
