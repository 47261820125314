import {
  SET_ZONE_FOR_REQUESTED_CEP,
  SET_NEIGHBORHOOD,
  RESET_MARKET,
  SET_MARKET,
} from "ActionTypes";

export function setZoneForRequestedCep(response, deliveryMode) {
  return {
    type: SET_ZONE_FOR_REQUESTED_CEP,
    payload: {
      ...response,
      data: {
        ...response.data,
        markets: response.data.markets
          ? {
              ...response.data.markets[0],
              deliveryMode: deliveryMode,
            }
          : {
              ...response.data.markets,
              deliveryMode: deliveryMode,
            },
      },
    },
  };
}

export function setMarket(response, deliveryMode) {
  return {
    type: SET_MARKET,
    payload: {
      ...response,
      data: { ...response.data, deliveryMode: deliveryMode },
    },
  };
}

export function setNeighborhood(neighborhoodData) {
  return {
    type: SET_NEIGHBORHOOD,
    payload: {
      ...neighborhoodData,
      markets: neighborhoodData.markets,
    },
  };
}

export function resetMarket() {
  return {
    type: RESET_MARKET,
    payload: [],
  };
}
