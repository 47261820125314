import { Box, Typography } from "@material-ui/core";
import React from "react";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import "./styles.scss"

const Flag = ({ show }) => {
  return Boolean(show) ? (
    <Box className="withhold-revenue-flag">
      <WarningRoundedIcon />
      <Typography variant="caption">Retenção de receita</Typography>
    </Box>
  ) : null;
};

export default Flag;
