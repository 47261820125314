import React, { useState, memo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { useParams } from "react-router"

import { NavLink } from "reactstrap";
import { AppBar, Toolbar, Button, IconButton, Hidden, Container } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import CEPForm from "../../../components/Homepage/CEPFormOld";
import Modal from "../../../components/Modal/modal";
import NeighborhoodsForm from "../../../components/Homepage/NeighborhoodsForm";
import DeliveryMethods from "../../../components/Homepage/DeliveryMethods";

import { setCRM } from "actions/CRMActions";
import { useStylesSidebar, useStylesHeader } from "../../../assets/useStyles";
import {
  setZoneForRequestedCep,
  setNeighborhood,
  resetMarket,
} from "actions/setZoneForRequestedCep";
import { domainColorStyle, getDomainLogo, getMenu} from "config";

import "./index.scss";
import { openDeliveryMethod } from "actions/configsActions";
import { notification } from "helpers/util";

function Header(props) {
  const {
    zone,
    sidebar,
    mobileOpen,
    currentMarket,
    deliveryMethodOpen,
    openDeliveryMethod,
  } = props;
  const classes = useStylesSidebar();
  const classesHeader = useStylesHeader();

  const history = useHistory();

  const [modalDeliveryMethods, setModalDeliveryMethods] = useState();

  const coalescedZone = zone || {};
  const zoneEmpty = Object.keys(coalescedZone).length === 0;
  const isValidMenu = getMenu()
  const { market_id } = useParams()

  function appBarClasses() {
    if (history.location.pathname.includes("/encartes/")) {
      return "navbar-fixed";
    }

    return history.location.pathname.includes("/loja/")
      ? `navbar-fixed ${clsx(classes.appBar, {
        [classes.appBarShift]: sidebar,
      })}`
      : "navbar-fixed";
  }

  return (
    <AppBar
      position="fixed"
      className={appBarClasses}
      style={{ ...domainColorStyle("background"), alignItems: "center" }}
    >
      <Toolbar className="toolbar-content" style={{display: "flex", justifyContent: "center"}}>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            {history.location.pathname.includes(`/loja/${currentMarket.id}`) &&
              !history.location.pathname.includes(`/loja/${currentMarket.id}/`) ? (
              <>
                <Hidden xsDown implementation="css">
                  <div className={classesHeader.iconHeader}>
                    <Link
                      to={'/'}
                      className={classesHeader.logo}
                    >
                      <img
                        alt={"logo"}
                        style={{ height: 50, objectFit: "contain" }}
                        src={getDomainLogo()}
                      />
                    </Link>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => props.setSidebar(!sidebar)}
                      className={clsx(classes.menuButton)}
                    >
                      <Menu />
                    </IconButton>
                  </div>
                </Hidden>
                <Hidden smUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => props.setDrawerMobileOpen(!mobileOpen)}
                    edge="start"
                    style={{ marginLeft: 5 }}
                    className={clsx(classes.menuButton)}
                  >
                    <Menu />
                  </IconButton>
                </Hidden>
              </>
            ) : (
              <Link
                to={'/'}
                className={classesHeader.logo}
                style={{ justifyContent: "flex-start", width: 50 }}
              >
                <img
                  alt="logo"
                  style={{ height: 50, objectFit: "contain" }}
                  src={getDomainLogo()}
                />
              </Link>
            )}

            <NavLink
              style={{ display: " flex", justifyContent: "flex-start" }}
              onClick={() => {
                if (history.pathname.includes(`/loja/${market_id || currentMarket.id}`)) {
                  openDeliveryMethod(true);
                } else {
                  notification(
                    "Para alterar o endereço volte para a página de produtos!",
                    "warning"
                  );
                }
              }}
              className="link-header"
            />
          </div>

          {!isValidMenu &&
            <Link
              className={classesHeader.userButtonNormal + " link-header"}
              style={{ color: "#fff", lineHeight: 1.2 }}
              to="/user"
            >
              <i
                style={{ marginRight: 5, alignSelf: "center" }}
                className="fa text-white fa-user"
              />
              <span className="text">
                {localStorage.getItem("USERNAME") ? (
                  <span className="text">{`Bem vindo, ${localStorage.getItem(
                    "USERNAME"
                  )}`}</span>
                ) : (
                  <Button color="inherit">Login</Button>
                )}
              </span>
            </Link>}
          <Link
            className={classesHeader.userButtonMobile + " link-header"}
            style={{ color: "#fff", lineHeight: 1.2 }}
            to="/user"
          >
            <i
              style={{ marginRight: 5, alignSelf: "center" }}
              className="fa text-white fa-user"
            />
          </Link>
        </Container>
      </Toolbar>
      <Modal
        WrappedComponent={CEPForm}
        title="Selecione um método de entrega para prosseguir!"
        size="md"
        toggleModal={() => openDeliveryMethod(false)}
        showModal={
          deliveryMethodOpen || (deliveryMethodOpen && !currentMarket.id)
        }
        setModalDeliveryMethods={() => setModalDeliveryMethods(true)}
      />
      <Modal
        WrappedComponent={NeighborhoodsForm}
        title="Selecione seu bairro!"
        showModal={
          deliveryMethodOpen &&
          !zoneEmpty &&
          coalescedZone.neighborhoods.length > 0
        }
      />

      <Modal
        WrappedComponent={DeliveryMethods}
        title="Selecione sua forma de entrega"
        size="sm"
        modalDeliveryMethods={true}
        setModalDeliveryMethods={() => setModalDeliveryMethods(false)}
        showModal={modalDeliveryMethods && currentMarket.id ? true : false}
      />
    </AppBar>
  );
}

function mapStateToProps({ zone, markets, settings }) {
  return {
    zone,
    currentMarket: markets,
    deliveryMethodOpen: settings.deliveryMethodOpen,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setZoneForRequestedCep,
      setCRM,
      setNeighborhood,
      resetMarket,
      openDeliveryMethod,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(memo(Header)));
