import {
    SET_ORDER_ID,
    SET_ORDER_STATUS,
    SET_ORDER_NUMBER
} from "ActionTypes"

export function setOrderId(orderID) {
    return {
        type: SET_ORDER_ID,
        payload: orderID
    }
}

export function setOrderStatus(status) {
    return {
        type: SET_ORDER_STATUS,
        payload: status
    }
}

export function setOrderNumber(orderNumber) {
    return {
        type: SET_ORDER_NUMBER,
        payload: orderNumber
    }
}