import React, { useRef, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OrderStatus from '../OrderStatus/OrderStatus';
import OrderProducts from '../OrderProducts/OrderProducts';
import { formatarCEP, showNotification } from 'helpers/util';
import { formatValue } from 'helpers/orderhelper';
import OrderPostalModalStepper from './OrderPostalModalStepper';
import moment from 'moment';
import './OrderPostal.scss'

export default function OrderPostal({order, correio}) {
  const finalDeliveryDate = moment(correio?.deliver_end_time).format('DD/MM/YYYY')
  const trackingCodeRef = useRef(null)
  const [copiedText, setCopiedText] = useState('');
  const [showModalStepper, setShowModalStepper] = useState(false)


  const handleCopyCode = () => {
    const trackingCode = trackingCodeRef.current.innerText;
    navigator.clipboard.writeText(trackingCode).then(() => {
      setCopiedText(trackingCode);
      if (trackingCode) {
        showNotification(
          "top",
          "center",
          `Código ${copiedText} copiado com sucesso!`,
          "success"
        )}
    }).catch(err => {
      console.error('Erro ao copiar o código: ', err);
      alert('Erro ao copiar o código. Por favor, tente novamente.');
    });
  };

  return (
    <Grid container style={{ padding: '1em', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <OrderStatus status={correio?.status} correio={correio}/>
        <Typography fontWeigth="bold" variant="h6">Pedido Nº {correio?.order_number}</Typography>
      </Box>
      <Card className='card-order-correio'>
        <CardContent className='card-content-order-postal'>
          <Typography color='primary' style={{ fontWeight: 600 }}>Receber em {finalDeliveryDate}</Typography>
          <small>Vendido por {order.market_name}</small>
          <Box className='postal-column-information-box' >
            <small>Código de Rastreio:</small>
            {correio?.delivery_tracking_code?.length ? (
              <>
              <Box>
                <strong ref={trackingCodeRef}>{correio?.delivery_tracking_code}</strong>
                <Tooltip title="Copiar texto">
                  <IconButton
                    size="small"
                    color='primary'
                    variant='contained'
                    onClick={handleCopyCode}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
                </Box>
                <Button
                  size="small"
                  color='primary'
                  variant='contained'
                  style={{ textTransform: 'capitalize' }}
                  onClick={() => setShowModalStepper(true)}>
                  Rastrear entrega
                </Button>
              </>)
              : (<strong>Aguarde o código de rastreio</strong>)}
          </Box>
        </CardContent>

        <CardContent className='card-content-order-postal'>
          <Typography color='primary' style={{ fontWeight: 600 }}>Dados de entrega:</Typography>
          <small>Enviado por Correios</small>
          <Box className='postal-column-information-box'>
            <small>{`${correio?.address?.street}, ${correio?.address?.number}`}</small>
            <small>Bairro, {correio?.address?.neighborhood}</small>
            <small>Ponto de referência: {correio?.address?.reference_spot}</small>
            <small>CEP: {formatarCEP(correio?.address?.cep)}</small>
          </Box>
        </CardContent>

        <CardContent className='card-content-order-postal'>
          <Typography color='primary' style={{ fontWeight: 600 }}>Dados de pagamento:</Typography>
          <small>{correio?.payment_type?.name}</small>
          <Box className='postal-column-information-box'>
            <small>Total em itens: {formatValue(correio?.subtotal_with_additions)}</small>
            <small>Taxa de entrega: {formatValue(correio?.original_delivery_tax)}</small>
            <small>Taxa de serviço: {formatValue(correio?.service_tax)}</small>
            <small>Descontos: {formatValue(correio?.total_discounts)}</small>
            <small style={{ marginTop: 10, fontWeight: 700 }}>Valor total: {formatValue(correio?.total_price)} </small>
          </Box>

        </CardContent>
      </Card>
      <OrderProducts order={order} />

      <OrderPostalModalStepper 
        open={showModalStepper} 
        close={() => setShowModalStepper(false)}
        order={order}/>
    </Grid>
  )
}
