import { applyDeliveryMethodFunctions } from "helpers/models/deliveryMethod";
import { applyProductFunctions } from "helpers/models/product";

export function loadState() {
  try {
    const serializedState = localStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }
    const oldState = JSON.parse(serializedState);
    oldState.deliveryMethod.deliveryMethod = applyDeliveryMethodFunctions([
      oldState.deliveryMethod.deliveryMethod,
    ])[0];
    Object.keys(oldState.cart.cart).forEach((itemId) => {
      oldState.cart.cart[itemId] = applyProductFunctions([
        oldState.cart.cart[itemId],
      ])[0];
    });

    return oldState;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // nothing...
  }
}
