import React, { useEffect, useState } from "react";
import Modal from "new/components/Modal/Modal";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Box, CircularProgress, IconButton, Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './OrderPostal.scss';
import { getOrderCorreiosTracking } from "helpers/apiHelper";
import moment from "moment";
import { timeZoneAdjustedMoment } from "helpers/util";


export default function OrderPostalModalStepper({ open, close, order }) {
  const [activeStep, setActiveStep] = useState(0);
  const [correiosTracking, setCorreiosTracking] = useState(null);


  const getCorreiosTracking = async (orderId) => {
    try {
      const response = await getOrderCorreiosTracking(orderId);
      setCorreiosTracking(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados da ordem:', error);
    }
  };

  useEffect(() => {
    if (order?.id) {
      getCorreiosTracking(order.id);
    }
  }, [order]);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };


  return (
    <Modal
      isOpen={open}
      toggleModal={close}
      className='modal-track-order'>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color='primary'>Acompanhe seu pedido</Typography>
          <Button
            style={{ textTransform: 'none' }}
            color="primary"
            onClick={close}>
            Cancelar
          </Button>
        </Box>
        {!order ? (
          <div style={{ height: '20vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color='primary' />
          </div>
        ) : (
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className="stepper-correios-tracking">
            {correiosTracking?.map((correio, index) => (
              <Step key={index} className="step-correios-tracking">
                <Box style={{display: 'flex', alignItems: 'flex-end', marginBottom: 5}}>
                  <small className="correio-trancking-date-hr">
                    {moment(correio?.dtHrCriado).format('DD/MM')}<br />
                    {timeZoneAdjustedMoment(correio?.dtHrCriado).format("HH:mm")}
                  </small>
                  <StepLabel StepIconComponent={() => (
                    <IconButton onClick={() => handleStepClick(index)} style={{ cursor: 'pointer' }}>
                      {activeStep === index ? (
                        <CheckCircleIcon color='primary' />
                      ) : (<FiberManualRecordIcon color='grey' />)}
                    </IconButton>)}
                    className='step-label-component-correio'>
                    {correio?.descricao}
                  </StepLabel>
                </Box>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
    </Modal >
  );
}
