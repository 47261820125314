import React, { Component } from "react";
import numberToCurrency from "helpers/currency";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addToCart,
  removeFromCart,
  clearCart,
  addPreferencesToCart,
} from "../actions/cartActions";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { domainColorStyle } from "config";
import { openDeliveryMethod } from "actions/configsActions";
import { notification } from "helpers/util";
import Input from "./InputCustom";
import { track } from "helpers/pixelHelper";
import { humanReadableWeight, countItensCart, getCartItemPreferences } from "helpers/util";
import { sendAddedToCartEvent } from "helpers/analyticHelper";
import { getMixesFromCart } from "helpers/cartHelper";
import { WithholdRevenueFlag } from "new/components/WithholdRevenue";

class CartProductCard extends Component {
  render() {
    const deliveryMethodMaxItems = this.props.deliveryMethod.deliveryMethod.max_items_amount
    const totalCart =  getMixesFromCart(this.props.cart);
    const totalItemsDeliveryMethod = countItensCart(totalCart)
    const { mix, removeFromCart, addPreferencesToCart } = this.props;
    const { description, image, amount, stock, total_price,  amount_in_kilo: amountInKilo } = mix;   
    const sendProductToCart = (product) => {
      const { openDeliveryMethod, currentMarket, addToCart } = this.props;
      if (currentMarket.deliveryMode) {
        addToCart(product);
        track("AddToCart", product);
        sendAddedToCartEvent(product);
      } else {
        openDeliveryMethod(true);
      }
    };
    
    const notificationButton = () => {
      if(deliveryMethodMaxItems && totalItemsDeliveryMethod >= deliveryMethodMaxItems){
          notification(`Limite de produtos no carrinho atingido (${deliveryMethodMaxItems})`, 'warning')
          return
        }
      amount < stock
        ? sendProductToCart(mix)
        : notification("Quantidade máxima atingida para o item","warning");
    }

    return (
      <Card style={{ width: "100%" }}>
        <CardBody>
          <Row>
            <Col className="py-4 py-sm-0" xs={4} sm={4} md={4} lg={4}>
              <img alt="" className="chart-product-image" src={image} />
            </Col>
            <Col xs={7} sm={8} md={8} lg={8}>
              <Row className="p-3">
                <strong
                  style={domainColorStyle("color")}
                  className=" text-sm w-100"
                >
                  {description}
                </strong>
                <br />
                <span
                  style={domainColorStyle("color")}
                  className=" w-100 text-sm"
                >
                  {numberToCurrency(total_price)}
                </span>
                <WithholdRevenueFlag show={mix?.withhold_revenue} />
              </Row>
              <Row className="bottom-shadow">
                <Col
                  className="text-center"
                  xs={{ size: 2, offset: 1 }}
                  sm={{ offset: 0, size: 2 }}
                  md={{ offset: 2, size: 2 }}
                  lg={{ offset: 2, size: 2 }}
                >
                  <Button
                    onClick={() => {
                      removeFromCart(mix);
                    }}
                    size="sm"
                    className="bg-white  border-0  btn-icon shadow-none"
                    style={domainColorStyle("color")}
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-delete" />
                    </span>
                  </Button>
                </Col>
                <Col className="text-center" xs={6} sm={8} md={4} lg={4}>
                  <strong className="text-sm" style={domainColorStyle("color")}>
                    {amountInKilo ? humanReadableWeight(amount * amountInKilo) : amount}
                  </strong>
                </Col>
                <Col className="text-center" xs={2} sm={2} md={2} lg={2}>
                  <Button
                    onClick={() => notificationButton()}
                    size="sm"
                    style={domainColorStyle("color")}
                    className="bg-white border-0 shadow-none btn-icon float-right"
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Row
              className="div-info-input"
              style={{ width: "100%", margin: 0, marginTop: 10 }}
            >
              <Input
                showLabel={false}
                icon="fa fa-shopping-basket"
                placeholder="Deseja deixar algum recado sobre seu item?"
                type="textarea"
                name="item_preference"
                onChange={(e) =>
                  addPreferencesToCart({
                    ...mix,
                    item_preference: e.target.value,
                  })
                }
                value={getCartItemPreferences(mix)}
              />
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToCart,
      removeFromCart,
      clearCart,
      openDeliveryMethod,
      addPreferencesToCart,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentMarket: state.markets,
    discount_crm: state.crm.crmData,
    deliveryMethod: state.deliveryMethod
  };
}
CartProductCard = connect(mapStateToProps, mapDispatchToProps)(CartProductCard);
export default CartProductCard;
