import { getOneSignalId } from "config";

export default async function getOneSignalPlayerId() {
  let player_id;
  const hasOneSignal = getOneSignalId();
  const notification_supported =
    await window.OneSignal.isPushNotificationsSupported();
  if (hasOneSignal && notification_supported) {
    //player_id = await window.OneSignal.getUserId();
  }
  return player_id;
}
