// modules
import React, { useState, useEffect, useCallback } from "react";
import { getDomainMarketColor } from "config";
import { useHistory } from "react-router-dom";
import Modal from "new/components/Modal/Modal";

import "./CRMPage.scss";
import LoadingContent from "components/Loading/Loading";
import { useSelector } from "react-redux";

function CRMPage({ showModal }) {
  const cpfUser = localStorage.getItem("crmUserCpf")
  const [color, setColor] = useState("000000");
  const [showOpenCRMModal, setShowOpenCRMModal] = useState(showModal);
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const brand = useSelector(({ brand }) => brand.brand)


  const checkUserLogged = useCallback((e) => {
    if (e.data.length === 11) {
      localStorage.setItem("crmUserCpf", e.data);
      window.removeEventListener("message", checkUserLogged)
      window.location.reload();
    }
  }, [])

  const listener = useCallback(() => {
    window.addEventListener("message", checkUserLogged)
  }, [checkUserLogged])

  const getBrandData = useCallback(async function () {
    try {
      const brand_color = getDomainMarketColor()
      setColor(brand_color.replace("#", ""))
      listener()
      if (!showOpenCRMModal) {
        setShowOpenCRMModal(brand.crm.is_initial_screen)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

  }, [showOpenCRMModal, listener])

  useEffect(() => {
    getBrandData();
  }, []);

  const buttonCloseCRMModal = () => {
    setShowOpenCRMModal(!showOpenCRMModal)
    history.push("/")
  }
  const bodyCRM = (
    <div>
      {isLoading
        ? <LoadingContent />
        : (
          <>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => buttonCloseCRMModal()}>
              <span aria-hidden={true} className="span-crm-modal-button-close">
                X
              </span>
            </button>
            <iframe
              title="Clube"
              className="modal-body"
              target="_top"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              src={`https://mercacrm.mercadapp.com.br/logins?brand_id=${brand?.id}&brand_color=${color}`}></iframe>
          </>
        )}
    </div>
  )

  return (
    <Modal
      isOpen={!cpfUser && showOpenCRMModal}
      toggleModal={() => (setShowOpenCRMModal(!showOpenCRMModal), history.push("/"))}
      className="crm-modal">
      {bodyCRM}
    </Modal>
  );
}

export default CRMPage;
