import React, { Component } from "react"
import { insertClearsaleScriptOnDom } from "helpers/util"

export default function withClearsaleFingerPrint(WrappedComponent) {
    const ComponentWithClearsaleFingerprintFunction = class extends Component {
        getClearsaleFingerPrint(appCode, sessionIdReceiverId) {
            insertClearsaleScriptOnDom(window, window.document, "script", "https://device.clearsale.com.br/p/fp.js", "csdp")
            window.csdp("app", appCode)
            window.csdp("outputsessionid", sessionIdReceiverId)
        }
        render() {
            return <WrappedComponent getClearsaleFingerPrint={this.getClearsaleFingerPrint} {...this.props} />
        }
    }

    return ComponentWithClearsaleFingerprintFunction
}