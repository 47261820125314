import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Form,
  Col,
} from "reactstrap";
import {
  setZoneForRequestedCep,
  setNeighborhood,
} from "actions/setZoneForRequestedCep";
import InputCustom from "../InputCustom";
import LoadingContent from "../LoadingContent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import loadsContent from "../loadsContent";
import { showNotification, unmask } from "helpers/util";
import { domainColorStyle } from "config";
import { getZoneForCep } from "../../helpers/apis/marketInfo";
import { setDeliveryMethod } from "actions/setDeliveryMethod";
import { openDeliveryMethod } from "actions/configsActions";

class NeighborhoodsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getNeighborhoodById = this.getNeighborhoodById.bind(this);
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    let value = event.target.value;
    errors[name] = undefined;
    this.setState({
      [name]: value,
      errors: errors,
    });
  }
  getNeighborhoodById(id) {
    return this.props.neighborhoods.filter(
      (neighborhood) => neighborhood.id === parseInt(id)
    )[0];
  }

  async onSubmit(event) {
    // event.preventDefault();
    const { neighborhoodId, street } = this.state;
    const { cep, openDeliveryMethod } = this.props;
    const unmaskedCep = unmask(cep);
    try {
      this.props.toggleLoading();
      const zoneForCep = await getZoneForCep(unmaskedCep, neighborhoodId);
      this.props.toggleLoading();

      if (!zoneForCep.data.market && !zoneForCep.data.markets) {
        this.props.setZoneForRequestedCep({ data: { cep: null } });
      } else {
        const neighborhood = this.getNeighborhoodById(neighborhoodId);
        const market_id = zoneForCep.data.market
          ? zoneForCep.data.market.id
          : zoneForCep.data.markets[0].id;
        localStorage.setItem("market_id", market_id);
        this.props.setNeighborhood({
          neighborhood_id: neighborhoodId,
          neighborhood: neighborhood.name,
          street: street,
          markets: zoneForCep.data.market
            ? {
                ...zoneForCep.data.market,
                deliveryMode: "delivery",
              }
            : {
                ...zoneForCep.data.markets[0],
                deliveryMode: "delivery",
              },
        });
        // setDeliveryMethod();
        openDeliveryMethod(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    const { isLoading, neighborhoods } = this.props;
    if ((neighborhoods || []).length === 0) {
      showNotification("top", "center", "CEP Não encontrado", "warning");
      this.props.setZoneForRequestedCep({ data: { cep: null } });
    }
    return (
      <>
        <Container fluid>
          <Form>
            <Row>
              <div className="col">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <InputCustom
                          value={(this.state.neighborhoodId || {}).id}
                          onChange={this.handleChange}
                          type="select"
                          name="neighborhoodId"
                          required
                          placeholder="Selecione seu bairro"
                          options={(neighborhoods || []).map((neighborhood) => {
                            return {
                              value: neighborhood.id,
                              label: neighborhood.name,
                            };
                          })}
                        />
                      </Col>
                      <Col md={12}>
                        <InputCustom
                          value={this.state.street}
                          onChange={this.handleChange}
                          type="text"
                          name="street"
                          required
                          placeholder="Qual a sua rua?"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="justify-content-center">
                      <Col md={isLoading ? 4 : 2}>
                        <LoadingContent isLoading={isLoading}>
                          <Button
                            type="button"
                            onClick={() => this.onSubmit()}
                            className="btn-icon block btn-3"
                            outline
                            style={{
                              ...domainColorStyle("color"),
                              ...domainColorStyle("borderColor"),
                            }}
                          >
                            <span className="btn-inner--text">Confirmar</span>
                          </Button>
                        </LoadingContent>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    neighborhoods: state.zone.neighborhoods,
    cep: state.zone.cep,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setZoneForRequestedCep,
      setNeighborhood,
      setDeliveryMethod,
      openDeliveryMethod,
    },
    dispatch
  );
}
NeighborhoodsForm = loadsContent(NeighborhoodsForm);
NeighborhoodsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(NeighborhoodsForm);
export default NeighborhoodsForm;
