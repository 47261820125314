import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { Button, Card, CardBody } from "reactstrap";
import ListDetails from "../ListDetails/ListDetails";
import { applyProductFunctions } from "helpers/models/product";
import { bindActionCreators } from "redux";
import { getList, deleteList, leaveList } from "helpers/apis/listApi";
import { getItemsByIds } from "helpers/apis/itemApi";
import { domainColorStyle } from "config";
import { addToCartList, removeFromCart } from "actions/cartActions";
import { confirmAlert } from "react-confirm-alert";

import "./ShoppingLists.scss";
import { timeZoneAdjustedMoment } from "helpers/util";
import { useHistory } from "react-router-dom"
import { getLists } from "helpers/apis/listApi";
import { MARKET_LIST } from "OrderItemOrigins";

function ShoppingLists({ addToCartList }) {
  const history = useHistory()
  const [myLists, setMyLists] = useState([])
  const currentMarket = useSelector(({ markets }) => markets)
  const [showList, setShowList] = useState(null);
  const userInfo = useSelector(({ userInfo }) => userInfo)

  const showListDetails = async (list) => {
    if (list) {
      const list_selected = await getList(list.id, userInfo.id);
      setShowList({ ...list_selected, ...list });
    } else {
      setShowList(null);
    }
  };

  const findLists = async (marketId, userId) => {
    const { market_lists } = await getLists(marketId, userId)
    setMyLists(market_lists)
  }

  useEffect(() => {
    findLists(currentMarket.id, userInfo.id)
  }, [])

  const removeList = async (list) => {
    const list_selected = await getList(list.id, userInfo.id);
    confirmAlert({
      title: list_selected.is_shared
        ? `Você deseja realmente deixar a lista ${list.name}?`
        : `Você deseja realmente remover a lista ${list.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            if (list_selected.is_shared) {
              await leaveList(list.id, userInfo.id);
            } else {
              await deleteList(list.id, userInfo.id);
            }
            await findLists(currentMarket.id, userInfo.id);
          },
        },
        { label: "Não" },
      ],
    });
  };

  const addItemsCart = async (listItems, market_id) => {
    const itemIds = listItems.map((item) => item.product_id);
    const items = await getItemsByIds(itemIds, market_id);
    const allItems = items.mixes
      .flatMap((mix) => {
        const itensToReturn = mix.items.map((item) => {
          const listItem =
            listItems.find((listItem) => listItem.product_id === item.id) || {};
          return { ...item, amount: listItem.amount, origin: MARKET_LIST };
        });
        return itensToReturn;
      })
      .filter((item) => item.amount);
    await addToCartList(applyProductFunctions(allItems));
    history.push(`/loja/${currentMarket.id}`);
  };

  if ((myLists || []).length === 0) {
    return (
      <Card style={{ margin: 10 }}>
        <CardBody>
          <h6 className="font-weight-bold" style={domainColorStyle("color")}>
            Listas de compra
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="w-100 text-dark description-font">
              Você ainda não salvou nenhuma lista de compras
            </span>
          </div>
        </CardBody>
      </Card>
    );
  }
  return (
    <div style={{ margin: 10, border: "none" }}>
      <div>
        <h6 className="font-weight-bold" style={domainColorStyle("color")}>
          Listas de compra
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {(myLists || []).map((list) => {
            return (
              <div className="list-button" key={list.id}>
                <Button
                  style={{}}
                  key={list.id}
                  onClick={() => {
                    showListDetails(list);
                  }}
                >
                  <div className="list-description">
                    <i
                      className="fas fa-list"
                      style={domainColorStyle("color")}
                    />
                    <span className="">{list.name}</span>
                    <span className="last-update">
                      Ultima atualização em:{" "}
                      {timeZoneAdjustedMoment(list.last_updated).format("ll")}
                    </span>
                  </div>
                </Button>
                <div className="delete" onClick={() => removeList(list)}>
                  <i className="fas fa-times" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showList && (
        <ListDetails
          isOpen={!!showList}
          toggleModal={showListDetails}
          list={showList}
          addItemsCart={addItemsCart}
          findLists={()  => findLists(currentMarket.id, userInfo.id)}
        />
      )}
    </div>
  );
}

function mapStateToProps({ cart, markets, crm }) {
  return {
    cart: cart.cart,
    discount_crm: crm.crmData,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addToCartList, removeFromCart }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingLists);
