import { SET_SELECTED_CARD } from "ActionTypes";
const INITIAL_STATE = {
  paymentCard: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SELECTED_CARD:
      return Object.assign({}, state, {
        paymentCard: action.payload,
      });
    default:
      return state;
  }
}