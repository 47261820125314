import axios from "axios";
import config from "config";
import firebase from "firebase/app";
import { oAuthData, root, getBrandID } from "../../config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";
import { getFirebaseIdToken } from "helpers/util";

export async function getAccessToken(cpf) {
  let params = oAuthData
  const brandId = getBrandID()
  const firebaseToken = await getFirebaseIdToken()

  if (cpf || firebaseToken) {
    params = {
      brand_id: brandId,
      cpf: cpf,
      grant_type: "assertion",
      assertion: firebaseToken,
    };
  }
 
  try {
    const response = await axios.post(`${root()}/oauth/token`, params, {});
    if (response.data.access_token) {
      var encryptedAccessToken = window.aes.encrypt(
        response.data.access_token,
        "mcSiteToken123"
      );
      var encryptedRefreshToken = window.aes.encrypt(
        response.data.refresh_token,
        "mcSiteToken123"
      );
      localStorage.setItem("accessToken", encryptedAccessToken);
      localStorage.setItem("refreshToken", encryptedRefreshToken);
    }
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function refreshToken() {
  const refreshToken = window.aes.decrypt(localStorage.getItem("refreshToken"), "mcSiteToken123").toString(
    window.CryptoJS.enc.Utf8
  )
  let params = {
    ...oAuthData,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  }

  try {
    const response = await axios.post(`${root()}/oauth/token`, params, {});
    if (response.data.access_token) {
      var encryptedAccessToken = window.aes.encrypt(
        response.data.access_token,
        "mcSiteToken123"
      );
      var encryptedRefreshToken = window.aes.encrypt(
        response.data.refresh_token,
        "mcSiteToken123"
      );
      localStorage.setItem("accessToken", encryptedAccessToken);
      localStorage.setItem("refreshToken", encryptedRefreshToken);
    }

    return response.data;
  } catch (error) {


    return error.response;
  }
}
export async function sendDataMail(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/customers/${id}/send_data_mail/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAppNotices() {
  const marketID = localStorage.getItem("market_id") ? localStorage.getItem("market_id") : localStorage.getItem("marketIdCurrent")
  const customerID = localStorage.getItem("USER_ID");

  const urlParams = customerID
    ? `market_id=${marketID}&customer_id=${customerID}&app_screen=offers_screen`
    : `market_id=${marketID}&app_screen=offers_screen`;

  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/app_notices/active?${urlParams}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getAppNoticesCep() {
  const customerId = localStorage.getItem("USER_ID");
  const brandId = getBrandID();
  const params = {
    brand_id: brandId,
    customer_id: customerId,
    app_screen: 'ceps_screen'
  };

  const url = `${config.mappURLV2}/app_notices/active?`;
  try {
    const response = await oAuthRequest.get(url, {params: params})
    return response.data
  } catch (error) {
    return error.response
  }
}

const firebaseAuthErrors = {
  FINAL_ATTEMPT_ERRORS: [
    "brand_not_found",
    "invalid_auth_provider",
    "email_mismatch",
    "phone_mismatch",
    "firebase_access_json_not_found",
    "invalid_grant"
  ],
  DISPLAYED_ERRORS: ["email_mismatch", "phone_mismatch"],
  HIDDEN_ERRORS: [
    "customer_not_found",
    "firebase_uid_not_found",
    "brand_not_found"
  ]
}

export const handleTokenErrors = (errorData = {}, callback = () => {}) => {
  const { error, error_description } = errorData || {};
  const { FINAL_ATTEMPT_ERRORS, DISPLAYED_ERRORS, HIDDEN_ERRORS } = firebaseAuthErrors

  const displayError = FINAL_ATTEMPT_ERRORS.includes(error) && !HIDDEN_ERRORS.includes(error);
  
  if (displayError) {
    const errorMessage = DISPLAYED_ERRORS.includes(error)
      ? error_description
      : "Ocorreu um erro ao realizar o login.";
      
    callback(errorMessage);
  }
};
