import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Button } from "reactstrap";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDomainLogo, domainColorStyle } from "config";
import { getListAllMarkets } from "helpers/apis/marketInfo";
import { getMarketById } from "helpers/apis/marketInfo";
import LoadingContent from "components/Loading/Loading";

export default function ListMarketsMenu({ setShowModalListMarkets }) {
  const [allMarkets, setAllMarkets] = useState([])
  const [detailsStore, setDetailsStore] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const brandId = useSelector(({ brand }) => brand.brand.id)
  const history = useHistory()


  const getAllMarkets = async () => {
    try {
      const response = await getListAllMarkets()
      if (response.data) {
        setAllMarkets(response.data)
      }
    } catch (error) {
      console.error("Error markets:", error)
    }
  }

  const getStoreDetails = async (id) => {
    try {
      const response = await getMarketById(id);
      if (response.data) {
        setDetailsStore((prevDetails) => prevDetails.concat(response.data))
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error store details:", error);
    }
  };

  useEffect(() => {
    getAllMarkets()
  }, [brandId])

  useEffect(() => {
    if (allMarkets.length > 0) {
      allMarkets.forEach((item) => {
        getStoreDetails(item.id)
      });
    }
  }, [allMarkets])

  const goToNewStore = (marketId) => {
    setShowModalListMarkets()
    history.push(`/loja/${marketId}`);
  }  
  
  return (
    <Grid container>
      {isLoading ?
        (<LoadingContent />) : (
          <Grid item x={12}>
            {getDomainLogo() && (
              <Box className="text-center">
                <img
                  className="cep-image img mx-auto"
                  src={getDomainLogo()}
                  alt="Logo do mercado"
                />
              </Box>
            )}
            <div style={{ display: 'flex', flexDirection: "column" }}>
              {detailsStore?.map((item, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => goToNewStore(item.id)}
                    style={{
                      margin: 10,
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                      textTransform: "capitalize",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        ...domainColorStyle("color"),
                      }}
                    >
                      {item.name}
                    </span>

                    {item.complete_address ?
                    (<h6 style={{ fontSize: 12 }}>
                      {item.complete_address?.street}, 
                      {item.complete_address?.number}, 
                      {item.complete_address?.neighborhood}, 
                      {item.complete_address?.city} - {item.complete_address?.state}
                    </h6>) : (
                    <p style={{fontSize: "80%", fontWeight: 500}}>Sem endereço</p>)}
                  </Button>
                )
              })}
            </div>
          </Grid>
        )
      }
    </Grid>
  )
}