import React from "react"
import { CircularProgress, Dialog, DialogContent, Typography } from "@material-ui/core"
import "./SimpleDialogs.scss"

export default function SimpleDialogs({ isOpen, simpleParagraph }) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="custom-loader-dialog"
      className="dialog-simple">
      <DialogContent className="dialog-content-simple">
        <CircularProgress color="primary" />
        <Typography variant="body1" style={{ marginTop: '16px' }}>
          {simpleParagraph}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}