import { makeStyles } from "@material-ui/core/styles";
import { domainColorStyle } from "config";

const drawerWidth = 240;
const cartWidth = 30;

export const useStylesSidebar = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawerTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    ...domainColorStyle("color"),
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "10px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    minHeight: "40px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerContainer: {
    overflow: "auto",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    toolbar: theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerCart: {
    width: "85vw",
    flexShrink: 0,
    paddingBottom: 20,
    [theme.breakpoints.up("sm")]: {
      width: `${cartWidth}vw`,
      flexShrink: 0,
    },
  },
  appBar: {
    background: "white",
    [theme.breakpoints.up("sm")]: {
      left: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    zIndex: 1001,
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    zIndex: "998 !important",
    top: 'inherit',
    paddingTop: "220px",
    [theme.breakpoints.down("md")]: {
      paddingTop: 200,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0
    }
  },

  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginLeft: 0,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
export const useStylesHeader = makeStyles((theme) => ({
  userButtonMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  userButtonNormal: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  iconHeader: {
    display: "flex",
    minHeight: 64,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "240px",
    marginRight: 20
    // borderRight: "solid 1px #f2f2f270",
  },
  bannerStore: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  carouselMargin: {
    margin: "10px 2%",
    marginBottom: 0,
    [theme.breakpoints.up("xs")]: {
      paddingTop: "20px",
    },
    [theme.breakpoints.up("sm")]:{
      paddingTop: "65px",
    },
    [theme.breakpoints.up("md", "lg")]:{
      paddingTop: "10px",
    },
  },
}));
