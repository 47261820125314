import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect, useParams, withRouter } from "react-router-dom";
import ProductModal from "components/StorePage/Products/ProductModal";
import { categoriesChanged } from "actions/marketStuffActions";
import { getMarketStuff } from "helpers/apiHelper";
import { notification } from "helpers/util";
import { CATEGORIES } from "OrderItemOrigins";
import { parseQueryString } from "helpers/util";
import "./ProductView.scss";

function ProductView(props) {
  const { cart, currentMarket, categoriesChanged } = props;
  const { product_cb, product_slug, ...params } = useParams();
  const hasHyphen = product_slug?.includes("-")
  let product;
  if (product_cb) {
    product = { cb: Number(product_cb) };
  } else if (hasHyphen) {
    product = { slug: product_slug };
  } else {
    product = { id: product_slug };
  }
  const queryParams = parseQueryString(window.location);
  const getCartAmount = (product) => {
    const matchingProduct = Object.values(cart).find(item => item.slug === product.slug || item.id === product.id);
    return (matchingProduct || {}).amount || 0;
  };

  const fetchMarketStuff = useCallback(
    (marketID, section) => {
      getMarketStuff(marketID, section, (marketStuff, error) => {
        if (error) {
          notification(
            "Ocorreram erros, verifique sua conexão com a internet e tente novamente!",
            "danger"
          );
        } else {
          if (!section && marketStuff.categories?.length > 0) {
            categoriesChanged(marketStuff.categories);
          }
        }
      });
    },
    [categoriesChanged]
  );

  useEffect(() => {
    fetchMarketStuff(Number(params.market_id));
  }, [params]);
  if (
    parseInt(params.market_id, 10) !== currentMarket.id &&
    currentMarket.id !== undefined
  ) {
    return <Redirect to={`/loja/${currentMarket.id}`} />;
  }
  return (
    product_cb || product_slug ?
      <section className="section" style={{ paddingTop: 0 }}>
        <div>
          <div className={"sad-div col-12 mt-5"} style={{ paddingLeft: "25px", paddingRight: "25px" }}>
            <ProductModal
              origin={queryParams.origin || CATEGORIES}
              product={product}
              amount={getCartAmount(product)}
            />
          </div>
        </div>
      </section>
      : <></>
  );
}

function mapStateToProps({ cart, markets }) {
  return {
    cart: cart.cart,
    currentMarket: markets,
  };
}
export default connect(mapStateToProps, { categoriesChanged })(
  withRouter(ProductView)
);
