import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid } from '@material-ui/core';
import { timeZoneAdjustedMoment } from 'helpers/util';

import * as Yup from 'yup';
import moment from 'moment';
import { validate } from 'cpf-check';
import InputForm from 'new/components/InputForm/InputForm';
import { getEncryptedValue } from 'helpers/encryptionHelper';
import { TEST_MODE } from 'config';
import { useSelector } from 'react-redux';
import { creatCardInfo } from 'helpers/apiHelper';
import { notification } from 'helpers/util';




function ModalAddNewCard({ setShowModal, getNewCardList, updatePaymentInfos }) {
  const [paymentInfos, setPaymentInfos] = useState({})
  const customerInfo = useSelector(({ userInfo }) => userInfo)
  const addressAttributes = useSelector(({ userInfo }) => userInfo.address_attributes)
  const completeAddress = useSelector(({ markets }) => markets.complete_address);
  const marketId = localStorage.getItem("market_id")
  const cpfData = customerInfo.cpf?.length > 11 ? customerInfo?.responsible_document : customerInfo?.cpf


  function formatDate(date) {
    return moment(`01/${date}`).isValid();
  }


  async function savePaymentInfos(values) {
    if(updatePaymentInfos){
      updatePaymentInfos(values)
    }
    let removeCardNumberMask = values.card_number.replace(/\s/g,'')
    const pubKey = process.env.REACT_APP_MPAY_PUB_KEY;
    const body = {
      address: {
        number: parseInt(values.number),
        state: addressAttributes.state ? addressAttributes.state : values.state,
        street: values.street,
        cep: parseInt(values.cep),
        city: addressAttributes.city ? addressAttributes.city : values.city,
        neighborhood: addressAttributes.neighborhood ? addressAttributes?.neighborhood : values.neighborhood
      },
      payment: {
        card_expiration_date: getEncryptedValue(pubKey, values.card_expiration_date),
        card_number: getEncryptedValue(pubKey, removeCardNumberMask),
        cvv: getEncryptedValue(pubKey, values.cvv),
        client_name: getEncryptedValue(pubKey, values.card_holder_name),
        card_holder_name: getEncryptedValue(pubKey, values.card_holder_name),
      },
      company: "stone",
      market_id: marketId,
      customer_id: customerInfo.id,
      phone: customerInfo.phone_number,
      test_mode: TEST_MODE
    }
    const response = await creatCardInfo(body)

    if (!response.data?.message) {
      const newCard = response.data
      getNewCardList(newCard)
      newListCard(body)
      notification("Cartão adicionado com sucesso!", "success")
    } else {
      notification(`${response.data?.message} Tente novamente`, "warning");
    }
    setShowModal(false)
  }

  const newListCard = async (body) => {
    await getNewCardList(body)
  }

  const card_infos_schema = Yup.object().shape({
    card_holder_name: Yup.string()
      .min(5)
      .required('Campo obrigatório'),
    card_number: Yup.string()
      .min(14, 'O campo deve ter pelo menos 15 caracteres')
      .required('Campo obrigatório'),
    card_expiration_date: Yup.string()
      .required('Campo obrigatório')
      .test("card_expiration_date", "Data de expiração deve ser uma data válida.", formatDate),
    cvv: Yup.string()
      .required('Campo obrigatório'),
    card_holder_birth_date: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data de nascimento do titular do cartão deve ser uma data válida'),
    card_holder_cpf: Yup.string()
      .required('Campo obrigatório')
      .test("card_holder_cpf", "Cpf deve ser válido", validate),
    cep: Yup.string()
      .min(8, 'Insira um CEP válido')
      .max(8, 'O CEP deve ter exatamente 8 caracteres'),
    state: Yup.string()
      .max(2, 'Apenas a sigla do estado'),
    street: Yup.string()
      .min(4, 'O campo deve ter meno menos 4 caracteres'),
    number: Yup.string(),
    reference_spot: Yup.string()
      .min(4, 'O campo deve ter meno menos 4 caracteres')
  });


  return (
    <Box className="card-data">
      <Box pl={3} pr={2} pt={1} mb={2} display="flex" alignItems="center" justifyContent="space-between" className="title-card-data">
        <h5>Dados do Cartão</h5>
        <Button className='change-button' color='primary'
          onClick={() => setShowModal(false)}
        >Cancelar</Button>
      </Box>
      <Formik
        initialValues={{
          card_holder_name: paymentInfos.card_holder_name || "",
          card_number: paymentInfos.card_number || "",
          card_expiration_date: paymentInfos.card_expiration_date || "",
          cvv: paymentInfos.cvv || "",
          card_holder_cpf: cpfData || "",
          card_holder_birth_date: timeZoneAdjustedMoment(
            customerInfo?.birthDate,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD") || "",
          cep: addressAttributes?.cep || "",
          state: addressAttributes.state ? addressAttributes.state : completeAddress.state,
          city: addressAttributes.city ? addressAttributes.city : completeAddress.city,
          street: addressAttributes.street ? addressAttributes.street : completeAddress.street,
          number: addressAttributes?.number || "",
          reference_spot: addressAttributes?.reference_spot || ""
        }}
        validationSchema={card_infos_schema}
        onSubmit={values => {
          savePaymentInfos(values)
        }}
      >
        {() => (
          <Form noValidate style={{ width: "100%" }}>
            <Box px={3}>
              <Grid container className="order-payment-inputs-form">
                <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                  <h5>Preencha os dados do seu cartão para o pagamento</h5>
                  <Grid item className='input-item' xs={12}>
                    <Field name="card_holder_name" label="Nome impresso no cartão *" type="text" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="card_number" mask="9999 9999 9999 9999" label="Número do cartão" type="text" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="card_expiration_date" label="Data de expiração" mask="99/9999" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="cvv" label="CVV" type="number" total_width={true} required component={InputForm} />
                  </Grid>

                </Grid>
                <Grid item xs={12} md={6} className="card-first-infos">
                  <h5>Informações da cobrança</h5>
                  <Grid item className='input-item' xs={12}>
                    <Field name="card_holder_cpf" label="CPF do titular do cartão" type="text" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="card_holder_birth_date" label="Data de nascimento do titular do cartão" type="date" total_width={true} required component={InputForm} />
                  </Grid>
                </Grid>

                <Box display={'flex'} flexWrap={'wrap'}>
                  <h5>Preencha os dados do seu cartão para o pagamento</h5>
                  <Grid item className='input-item' xs={12}>
                    <Field name="cep" label="CEP" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="street" label="Rua" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={6}>
                    <Field name="number" label="Número" total_width={true} required component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={5}>
                    <Field name="complement" label="Complemento" total_width={true} component={InputForm} />
                  </Grid>
                  <Grid item className='input-item' xs={12}>
                    <Field name="reference_spot" label="Ponto de referencia" total_width={true} required component={InputForm} />
                  </Grid>
                  {!addressAttributes.state &&
                    <>
                      <Grid item className='input-item' xs={12}>
                        <Field name="state" label="ESTADO (UF)" total_width={true} required component={InputForm} />
                      </Grid>
                      <Grid item className='input-item' xs={12}>
                        <Field name="city" label="Cidade" total_width={true} required component={InputForm} />
                      </Grid>
                    </>}
                </Box>
              </Grid>
              <Box mt={3} className="modal-client-footer">
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'>Confirmar</Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ModalAddNewCard;