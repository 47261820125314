import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { domainColorStyle } from "config";
import { checkAge } from "helpers/util";

import "./birthday.scss";
export default function BirthdayModal({
  showModal,
  toggleModal,
  size,
  className,
  toggleSubcategory,
  user,
  showAgeInput,
  setState,
  isCheckout = false
}) {
  const savedBirthdate = localStorage.getItem("userBirthdate")
  const history = useHistory();
  const [isUserLogged, setUserLogged] = useState(false);
  const [birthDate, setBirthDate] = useState(savedBirthdate || "");
  useEffect(() => {
    const phone = localStorage.getItem("USERPHONE");
    if (phone) {
      if (user && user.age > 18) {
        toggleModal();
      }
      if (user.name) {
        setUserLogged(true)
      }
    }
  }, [user])

  function save() {

    const date = new Date(birthDate)
    const age = checkAge(date)
    if (age >= 18) {
      toggleModal()
    } else if (age < 18 && age >= 15) {
      setState({ ...user, age }, false)
    } else if (age < 15) {
      setState({ ...user, age }, false)
    }
    localStorage.setItem("userBirthdate", date)
  }

  return (
    <Modal
      className={"modal-dialog-centered " + (className || "")}
      size={size || "md"}
      isOpen={user.age && user.age < 15 ? true : (user.age && showModal)}
      toggle={() => {
        if (toggleModal && (user.age && user.age >= 15)) toggleModal();
      }}
    >
      <div className="modal-body modal-birthday p-2">
        {(!user.name && showAgeInput) &&
          <p>É necessário que informar sua data de nascimento para ter acesso a essa categoria.</p>
        }
        {(user.age && user.age >= 15) &&
          <div className="form-birthdate" style={{ justifyContent: "center" }}>
            {isCheckout ?
              <p>Há produto(s) no seu carrinho destinado(s) apenas para maiores de 18 anos.</p>
              :
              <p>Categoria destinada apenas para usuários maiores de 18 anos.</p>
            }
            {(isUserLogged || isCheckout) &&
              <label>
                De acordo com o seu cadastro, você possui {user.age} anos e não está permitido a acessar esta categoria de produtos.
                Se você acredita que isso é um erro no seu cadastro, corrija os dados do seu perfil e tente novamente.
              </label>}
            <Button
              style={
                { ...domainColorStyle("background"), color: "#fff", width: 100, margin: 0 }}
              onClick={() => {
                if (isUserLogged) {
                  toggleSubcategory(0, 0, false)
                  history.push("/user")
                } else if (user.age < 18 && user.age >= 15) {
                  toggleSubcategory(0, 0, false)
                  setState({}, true)
                  if (isCheckout) {
                    history.goBack();
                  }
                } else {
                  toggleSubcategory(0, 0, false)
                  setState({}, true)
                }
                localStorage.setItem("userBirthdate", birthDate)
              }}>Ok</Button>
          </div>
        }
        {(user.age && user.age < 15) &&
          <div className="form-birthdate" style={{ justifyContent: "center" }}>
            <p>O uso do app é permitido apenas para usuários maiores de 15 anos. </p>
            {isUserLogged &&
              <label>
                De acordo com o seu cadastro, você possui {user.age} anos e não está permitido a usar o aplicativo.
                Se você acredita que isso é um erro no seu cadastro, corrija os dados do seu perfil e tente novamente.
              </label>}
            <Button
              style={
                { ...domainColorStyle("background"), color: "#fff", width: 100, margin: 0 }}
              onClick={() => {
                if (isUserLogged) {
                  toggleSubcategory(0, 0, false)
                  history.push("/user")
                } else if (user.age < 15 && isCheckout) {
                  history.goBack();
                } else {
                  toggleSubcategory(0, 0, false)
                  setState({}, true)
                }
                localStorage.setItem("userBirthdate", birthDate)
              }}>Ok</Button>
          </div>
        }
        {showAgeInput &&
          <div className="form-birthdate">
            <TextField
              id="date"
              label="Data de nascimento"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                setBirthDate(target.value)
              }}
              value={birthDate}
            />
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "auto" }}>
              <Button
                style={
                  { color: "#fff", backgroundColor: "#df1111", width: 100, marginLeft: 0 }}
                onClick={() => {
                  toggleModal()
                  toggleSubcategory(0, 0, false)
                }}>Cancelar</Button>
              <Button
                style={
                  { ...domainColorStyle("background"), color: "#fff", width: 100 }}
                onClick={() => save()}>Salvar</Button>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
}
