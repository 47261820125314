import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Avatar, Box, Button, Typography } from '@material-ui/core';

import { imgAllCards } from '../imgcardlisting/imgAllCards';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import './Cards.scss';
import Modal from 'new/components/Modal/Modal';
import { domainColorStyle } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCard } from 'actions/paymentActions';


function Cards({lastFourDigits, brandImg, cardId, onDelete, onClick, selectedCardId, allCards, toggleCardSelected}) {
  const [confirmeDeleteCardModal, setConfirmeDeleteCardModal] = useState(false)
  const lastUsedPaymentCard = useSelector(({ payment }) => payment?.paymentCard?.id)
  const dispatch = useDispatch()

  const deleteCard= async () => {
    await onDelete(cardId)
    setConfirmeDeleteCardModal(false)
  }

  const clickSelected = () => {
    onClick(cardId)
  }
  
  //filtro responsavel por selecionar somente 1 cartão
  const selectedCard = allCards.find((card) => card.id === selectedCardId)
 
  //informações do cartao selecionado
  useEffect(() => {
    if(selectedCard){
      dispatch(setSelectedCard(selectedCard))
    }
  },[selectedCard])

  const isSelected = (cardId === selectedCardId || cardId === lastUsedPaymentCard) && toggleCardSelected

  let cardBrandImage;
  switch (brandImg) {
    case 'Visa':
      cardBrandImage = imgAllCards.visa;
      break;
    case "Mastercard":
      cardBrandImage = imgAllCards.mastercard;
      break;
    case 'Maestro':
      cardBrandImage = imgAllCards.maestro;
      break;
    case 'Elo':
      cardBrandImage = imgAllCards.elo;
      break;
    case 'Amex':
      cardBrandImage = imgAllCards.amex;
      break;
    default:
      cardBrandImage = null;
  }

  return (
    <>
      <Card 
        className="card-listing-content" 
        onClick={clickSelected}
        style={isSelected ? {border: "3px solid", ...domainColorStyle("borderColor")} : {}}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" >
              {cardBrandImage ? (
                <img src={cardBrandImage} alt="cartao" className='imag-card' />
              ) : (
                <CreditCardIcon />
              )}
            </Avatar>
          }
          action={
            <IconButton 
              className="card-icon-btn"
              onClick={() => setConfirmeDeleteCardModal(true)}>
              <DeleteIcon />
            </IconButton>
          }
          title="Cartão de Crédito"
          subheader={`**** **** **** ${lastFourDigits.slice(-4)}`}
        />
      </Card>
      <Modal
        isOpen={confirmeDeleteCardModal}
        toggleModal={() => setConfirmeDeleteCardModal(!confirmeDeleteCardModal)}
        className="modal-delete-card"
      >
          <h5>Confirmar exclusão</h5>
          <Typography className='delete-card-text'>
            Tem certeza que deseja excluir o cartão?
          </Typography>
          <Box className="delete-card-button">
            <Button onClick={() => setConfirmeDeleteCardModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={deleteCard} color="primary">
              Excluir
            </Button>
          </Box>
      </Modal>
    </>
  );
}

export default Cards;