import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { withRouter, useHistory } from "react-router-dom";
import { domainColorStyle } from "config";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import LoadingContent from "components/LoadingContent";
import "./Order.scss";
import { getOrders, getOrderStatus, getOrderCorreio } from "helpers/apiHelper";
import { parseQueryString } from "helpers/util";
import AdditionApprovalBox from "new/components/HigherValueCapture/AdditionApproval/Box/AdditionApprovalBox";
import { getRemainingTimeInSeconds } from "helpers/orderhelper";
import OrderInfos from "./OrderInfos/OrderInfos";
import OrderProducts from "./OrderProducts/OrderProducts";
import OrderPreview from "./OrderPreview/OrderPreview";
import { useSelector } from "react-redux";
import OrderPostal from "./OrderPostal/OrderPostal";

const Order = () => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [orderCorreio, setOrderCorreio] = useState(null)
  const [pix, setPix] = useState(null);
  const [pixStatus, setPixStatus] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [pause, setPause] = useState(false);
  const userId = useSelector(({ userInfo }) => userInfo.id) || localStorage.getItem("USER_ID");
  const history = useHistory();
  const queryParams = parseQueryString(window.location);
  const orderId = parseInt(queryParams.orderId);

  useEffect(() => {
    const findOrder = async (userId, orderId) => {
      const [ordersResponse, correioResponse] = await Promise.all([
        getOrders(userId),
        getOrderCorreio(orderId)
      ]);

      const order = ordersResponse?.data.find((order) => order.id === orderId);

      if (!order) {
        findOrder(userId, orderId);
      }

      setOrderCorreio(correioResponse?.data)
      setOrder(order);

      if (order.online_buy_pix) {
        setPix(order.online_buy_pix);
        setPixStatus(order.status.status);
        configPix(order);
      }

      setLoading(false);
    };

    findOrder(userId, orderId);
  }, []);

  useEffect(() => {
    const interval = handleTimer();
    return () => clearInterval(interval);
  }, [seconds, minutes]);

  const configPix = (order) => {
    const pix = order.online_buy_pix;
    let timeInSeconds = getRemainingTimeInSeconds(pix.pix_expires_at);
    timeInSeconds < 1 && (timeInSeconds = 0);
    setMinutes(Math.floor(timeInSeconds / 60));
    setSeconds(Math.floor(timeInSeconds % 60));
    setTotalTime(Math.floor(pix.pix_expires_in / 60));
  };

  function handleTimer() {
    let interval;
    if (!pause) {
      interval = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            setPause(true);
            clearInterval(interval);
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }

        if (pixStatus === "waiting") {
          pixPaymentCheck();
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return interval;
  }

  function pixPaymentCheck() {
    getOrderStatus(orderId, (error, data) => {
      if (data.order.status === "received") {
        setPause(true);
        setPixStatus(data.order.status);
      }
    });
  }

  if (loading) {
    return (
      <div style={{ paddingTop: 100 }}>
        <LoadingContent isLoading={loading} />
      </div>
    );
  }

  return (
    <section className="section user-page">
      <Container className="mt-5">
        <Row className="mb-2">
          <Col xs={12} md={1}>
            <IconButton
              style={{ ...domainColorStyle("color"), borderRadius: 5 }}
              size="small"
              color="primary"
              onClick={() => history.goBack()}
            >
              <ArrowBack style={domainColorStyle("fill")} fontSize="inherit" />
              Voltar
            </IconButton>
          </Col>
        </Row>
        {order ? (
          <>
            {orderCorreio.tax_calculation_method === 'correios' ? (
              <OrderPostal
                order={order}
                correio={orderCorreio}/>
            ) : (
              <Row className="mb-2">
                <Col xs={12} md={7}>
                  <div className="order">
                    <OrderInfos order={order} minutes={minutes} seconds={seconds} />
                    <OrderProducts order={order} />
                    <AdditionApprovalBox orderID={orderId} />
                  </div>
                </Col>
                <Col xs={12} md={5}>
                  <OrderPreview
                    order={order}
                    pix={pix}
                    pixStatus={pixStatus}
                    minutes={minutes}
                    seconds={seconds}
                    totalTime={totalTime}
                  />
                </Col>
              </Row>)}
          </>) : null}
      </Container>
    </section>
  );
};

export default withRouter(Order);
