import visaCardImg from '../imgcardlisting/visa.png';
import eloImg from '../imgcardlisting/elo.png';
import masterCardImg from '../imgcardlisting/mastercard.jpg';
import amexImg from '../imgcardlisting/amex.png';
import maestroCardImg from '../imgcardlisting/maestro.png';

export const imgAllCards = {
  visa: visaCardImg,
  elo: eloImg,
  mastercard: masterCardImg,
  amex: amexImg,
  maestro: maestroCardImg,
}