import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { domainColorStyle } from "config";
import { notification } from "helpers/util";
import Input from "reactstrap/lib/Input";
import "./AddOrRemove.scss";
import { displayAmountInKilo } from "helpers/util";

function AddOrRemove({
  amount,
  product,
  onAddClick,
  onRemoveClick,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputAmount, setInputAmount] = useState(0);

  const AmountButton = ({ type }) => {
    const handleClick = () => {
      type === "add" ? onAddClick(product) : onRemoveClick(product);
      setIsEditing(false);
    };
    return (
      <Button
        style={domainColorStyle("color")}
        className="amount-button"
        onClick={handleClick}
      >
        {type === "add" ? "+" : "-"}
      </Button>
    );
  };

  const handleAmountChange = ({ target }) => {
    let newAmount = target.value;

    if (!newAmount) {
      newAmount = -1;
    }

    if (newAmount <= 0) {
      onAddClick(product, 1);
      setInputAmount(0);
      onRemoveClick(product);
      setIsEditing(false);
      return;
    }

    if (newAmount >= product.stock) {
      setInputAmount(parseInt(inputAmount / 10));
      notification("Quantidade máxima atingida para o item", "warning");
      return;
    }

    onAddClick(product, newAmount);
    setInputAmount(newAmount);
  };

  const handleFocus = (value) => {
    setInputAmount(value.toString());
    setIsEditing(true);
  };

  const getInputValue = (amount) => {
    return isEditing ? inputAmount : amount;
  };

  return (
    <div style={domainColorStyle("color")} className="amount-area">
      <AmountButton type="remove" />
      <div className="input-wrapper">
        {product.amount_in_kilo ? (
          <span>{displayAmountInKilo(amount, product.amount_in_kilo)}</span>
        ) : (
          <Input
            type="number"
            onFocus={() => handleFocus(amount)}
            value={getInputValue(amount)}
            onChange={(e) => handleAmountChange(e)}
          />
        )}
      </div>
      <AmountButton type="add" />
    </div>
  );
}

export default AddOrRemove;
