import React from "react";
import { domainColorStyle } from "config";
import Product from "components/NewProductCard/Product";
import { Container, Row } from "reactstrap";
import OffersContainer from "./OffersContainer";
import { useSelector } from "react-redux";
import { groupProductsByMixId } from "helpers/mixesHelper";
import { removeRepeatedMixes } from "helpers/mixesHelper";
import { arrayHasContent } from "helpers/util";

const CommonSectionContainer = ({ products, currentCategory, brand }) => {
  return (
    <div
      className="px-1 px-lg-4"
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {products &&
        removeRepeatedMixes(products).map((product, key) => (
          <Product
            currentCategory={currentCategory}
            products={products}
            product={product}
            key={key}
            brand={brand}
            index={key}
            mix={groupProductsByMixId(product, products)}
          />
        ))}
    </div>
  )
}
function ContainerProducts({ products, currentCategory, isLoading }) {
  const searchActive = useSelector(({ ui }) => ui.searchActive)
  const brand = useSelector(({ brand }) => brand.brand)
  const hasProducts = arrayHasContent(products) && !isLoading

  return (
      <Container fluid className={`${searchActive && "mt-5"}`}>
        <Row>
        {!hasProducts ? (
            <div className="text-center sad-div col-12 mt-5">
              <i
                style={domainColorStyle("color")}
                className="fas fa-heart-broken sad-class"
              />

              <h3 className="w-100">
                Infelizmente não encontramos o produto que você procura
              </h3>
            </div>
          ) : currentCategory.id == null && !searchActive ? (

            <OffersContainer
              products={products}
              brand={brand}
              currentCategory={currentCategory}/>
          ) : (
            <CommonSectionContainer currentCategory={currentCategory}
              products={products}
              brand={brand} />
          )
          }
        </Row>
      </Container>
  );
}

export default ContainerProducts;
