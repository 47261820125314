import React from "react";
import { domainColorStyle } from "config";
import { ModalBody, Modal } from "reactstrap";
import Login from "components/Club/Login";
import "./modalLogin.scss";

function ModalLogin({ isOpen, toggleModal, crm }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      style={{ borderRadius: "50px" }}
    >
      <div
        style={{
          height: "12px",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          ...domainColorStyle("backgroundColor"),
        }}
      />
      <ModalBody style={{ padding: "0px" }}>
        <div className="modal-body-login p-0">
          <Login
            ignorePageLayout={true}
            crmModule={crm}
            required
            closeModal={toggleModal}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalLogin;
