import config from "config";
import { makeRequestCreator } from "./util";
import { v4AuthHeader, getBrandID } from "config";

import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";
import { oAuthHeader } from "config";
import { store } from "App";


function getUserIdAPICallUsingRedux() {
  const reduxState = store.getState()
  const customerId = reduxState.userInfo.id
  return customerId
}

export function createLog(logMessage, orderId, callBack) {
  const url = `${config.mappURLV2}/order_logs`;
  const params = {
    order_log: {
      order_id: orderId,
      message: logMessage,
    },
  };
  oAuthRequest
    .post(url, params)
    .then(function (response) {
      if (callBack) callBack(null, response.data);
    })
    .catch(function (error) {
      if (callBack) callBack(error);
    });
}

export async function getDiscounts(
  phoneNumber,
  cpf,
  subtotal,
  marketId,
  freeOfDeliveryTax,
  neighborhoodId,
  coupon_code,
  orderItems,
  callBack
) {
  const url = `${config.mappURLV3}/discount_manager/discounts`;

  let params = {
    neighborhood_id: neighborhoodId,
    cpf: cpf,
    is_free_of_delivery_tax: freeOfDeliveryTax,
    order_subtotal: parseFloat(subtotal),
    market_id: marketId,
    coupon_code: coupon_code,
    order_items: orderItems,
  };

  oAuthRequest.post(url, params)
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export async function updateAbandonedOrder(cart) {
  const user = getUserIdAPICallUsingRedux()
  const brandID = getBrandID();
  const url = `${config.mappURLV2}/brands/${brandID}/last_carts?customer_id=${user}`;
  const params = {
    "last_cart_items_attributes":
      cart.map((cartItem) => { return { item_id: cartItem.id, market_id: cartItem.market_id } })
  }

  try {
    const response = await oAuthRequest.post(url, params)
    return response.data
  } catch (error) {
    return error.response;
  }
}

export async function updateTimeOrder (orderId, newDeliveryTime){
  const url = `${config.mappURLV3}/orders/${orderId}/update_delivery_schedule_time`
  const params = {
    "new_deliver_start_time": newDeliveryTime.start_time,
    "new_deliver_end_time": newDeliveryTime.end_time
  }
  const response = await oAuthRequest.post(url, params)
  return response
}

export async function getDiscounts2(params) {
  const url = `${config.mappURLV3}/discount_manager/discounts`;
  try {
    const response = await oAuthRequest.post(url, params)
    return response.data;
  } catch (error) {
    return error.response;
  }
}
export async function existingOrderChange(orderId, orderItems) {
  const url = `${config.mappURLV2}/orders/${orderId}/update_order_items`;
  const params = {
     "order":{
      "order_items_attributes": orderItems.map((item) => ({ 
        price: parseFloat(item.price),
        amount: parseFloat(item.amount),
        origin: item.origin,
        item_id: item.item_id,
        offer_id: item.offer_id || null,
        used_crm: item.used_crm,
        preferences: item.preferences || "",
        description: item.description,
        original_amount: parseFloat(item.original_amount),
        total_price: parseFloat(item.total_price)
      }))
    }
  }
  try {
    const response = await oAuthRequest.post(url, params, { headers: {"Content-Type": "application/json"} })
    return response.data
  } catch (error) {
    console.log(error)
    return error.response
  }
}


export function customerLastOrder(phoneNumber, marketId, callBack) {
  const url = `${config.mappURLV2}/orders/last?phone_number=${phoneNumber}&market_id=${marketId}`;
  oAuthRequest
    .get(url)
    .then(function (response) {
      callBack(null, response.data.last_order);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export function getOrderStatus(orderId, callBack) {
  const url = config.mappURLV2 + `/orders/${orderId}/status`;
  oAuthRequest.get(url)
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}


export async function getRelatedItems(marketId, itemId, itemsQuantity, deliveryMethod, neighborhoodId) {
  const url = `${config.mappURLV2}/markets/${marketId}/items/${itemId}`
  const user = getUserIdAPICallUsingRedux()
  const params = {
    related_items_quantity: itemsQuantity,
    delivery_method_id: deliveryMethod,
    neighborhood_id: neighborhoodId,
    customer_id: user
  };
  try {
    const response = await oAuthRequest.get(url, {params: params})
    return response.data
  } catch (error) {
    console.log(error)
  }
}


export function getCustomerByPhone(phoneNumber, callBack) {
  const brandID = getBrandID();
  const url = `${config.mappURLV2}/customers/by_phone?q=${phoneNumber}&brand_id=${brandID}`;
  oAuthRequest.get(url)
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}


export const getCustomer = async () => {
  const url = `${config.mappURLV3}/customers/me`;
  return await oAuthRequest.get(url)
}

export async function getCpf() {
  const user_id = localStorage.getItem("USER_ID");
  const url = `${config.mappURLV2}/customers/${user_id}`;

  if (user_id) {
    try {
      const response = await oAuthRequest.get(url);
      return response.data.customer.cpf;
    } catch (error) {
      return error.response;
    }
  }
}

export const createCustomer = async (params, brandId) => {
  const url = `${config.mappURLV3}/customers`;
  return await oAuthRequest.post(url, { customer: params, brand_id: brandId });
}

export const updateCustomer = async (params, brandId) => {
  const url = `${config.mappURLV3}/customers`;
  return await oAuthRequest.put(url, { customer: params, brand_id: brandId })
}

export function cancelOrder(orderId, reason, callBack) {
  const url = `${config.mappURLV3}/orders/${orderId}/cancel`;
  oAuthRequest.post(
    url,
    {
      cancellation_reason: reason,
    }
  )
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export const getMarketAppInfos = async (marketId) => {
  const url = `${config.mappURLV2}/markets/${marketId}/market_app_infos`;

  try {
    const response = await oAuthRequest.get(url)
    return response.data
  } catch (error) {
    throw error;
  }
}

const searchGet = makeRequestCreator();
export function search(searchText, market, callBack) {
  const userCPF = localStorage.getItem("crmUserCpf");
  let url = `${config.mappURLV2
    }/markets/${market}/items/search?query=${searchText}&${userCPF ? `cpf=${userCPF}` : ""
    }`;

  return searchGet(url, callBack);
}
// Todo remove search to use seachNext
export async function seachNext(searchText, market) {
  const userCPF = localStorage.getItem("crmUserCpf");
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/markets/${market}/items/search?query=${searchText}&${userCPF ? `cpf=${userCPF}` : ""
      }`
    );
    return response;
  } catch (error) {
    console.log("search error");
    throw error;
  }
}

export function checkForCartChanges(orderItems, deliveryMethodId, market, callBack) {
  const userCPF = localStorage.getItem("crmUserCpf");
  const url = `${config.mappURLV2}/markets/${market}/orders/check?${userCPF ? `cpf=${userCPF}` : ""
    }`;
  oAuthRequest.post(
    url,
    {
      order: {
        order_items_attributes: orderItems,
        delivery_method_id: deliveryMethodId,
      },
    }
  )
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export function saveCustomer(params, callBack) {
  const url = `${config.mappURLV2}/customers/save`;
  oAuthRequest.post(
    url,
    {
      customer: params,
    }
  )
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export function payWithMerconnectPay(params, callBack) {
  const url = `${config.merconnectPayURLV1}/authorizations/authorize`;
  oAuthRequest.post(url, params, {
    headers: {
      ...v4AuthHeader,
      Accept: "application/json",
    },
  })
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export function getMarketStuff(market, section, completion) {
  const userCPF = localStorage.getItem("crmUserCpf");
  const url = `${config.mappURLV2}/markets/${market}${section
    ? `/items/section/${section}&${userCPF ? `cpf=${userCPF}` : ""}`
    : ""
    }`;
  return oAuthRequest.get(url)
    .then((response) => {
      completion({
        mixes: response.data.mixes,
        banners: response.data.banners,
        categories: response.data.categories,
      });
    })
    .catch((error) => {
      completion(error);
    });
}

export function createOrder(marketId, params, callBack) {
  const url = `${config.mappURLV3}/markets/${marketId}/orders/create`;
  oAuthRequest.post(url, params)
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      console.log(error);
      callBack(error);
    });
}

export function getOperatingTimes(
  marketId,
  neighborhoodId,
  deliveryMethodId,
  itemIDs,
  callBack
) {
  const url = `${config.mappURLV2}/markets/${marketId}/times`;
  const params = {
    delivery_method_id: deliveryMethodId,
    item_ids: itemIDs,
    neighborhood_id: neighborhoodId,
  };

  oAuthRequest
    .get(url, { params: params })
    .then(function (response) {
      callBack(null, response.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export function getPaymentTypes(marketId, deliveryType) {
  const response = oAuthRequest.get(
    `${config.mappURLV2}/markets/${marketId}/payment_types?delivery_method_id=${deliveryType}`
  );
  return response;
}

export function getPaymentTypeGroups(marketId, deliveryType) {
  const response = oAuthRequest.get(
    `${config.mappURLV2}/markets/${marketId}/payment_type_groups?delivery_method_id=${deliveryType}`
  );
  return response;
}

export async function getCRM(CPF, brand) {
  try {
    if (brand.crm && brand.crm.provides_offers) {
      const response = await oAuthRequest.get(
        `${brand.crm.base_url}/v1/user-sales/${CPF}`,
        {
          headers: { Authorization: `Bearer ${brand.crm.token}` },
        }
      );
      return response;
    }
  } catch (error) {
    console.log("Brand error");
  }
}

export async function login(CPF, brand) {
  try {
    if (brand.crm) {
      const response = await oAuthRequest.get(
        `${brand.crm.base_url}/v1/loyalty/${CPF}`,
        {
          headers: { Authorization: `Bearer ${brand.crm.token}` },
        }
      );
      return response.data;
    }
  } catch (error) {
    console.log("Brand error");
  }
}

export async function getOrders(id) {
  const brandID = getBrandID();
  const response = await oAuthRequest.get(
    `${config.mappURLV2}/orders`,
    {
      params: {
        customer_id: id,
        brand_id: brandID
      }
    }
  );
  return response;
}

export async function getOrderCorreio(orderId) {
  //não substitui totalmente por conta da resposta do enpoint não conter algumas
  //informações que a getOrders tras
  const url = `${config.mappURLV3}/orders/${orderId}`
  try {
    const response = await oAuthRequest.get(url)
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function getOrderCorreiosTracking(orderId) {
  const url = `${config.mappURLV3}/orders/${orderId}/correios_tracking`
  try {
    const response = await oAuthRequest.get(url)
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function getItensOrder(id) {
  const response = await oAuthRequest.get(
    `${config.mappURLV2}/orders/${id}/order_items`
  );
  return response;
}

export async function getAutomaticCupons(customerID, marketID) {
  const url = `${config.mappURLV2}/discount_coupons/automatic`;
  
  const params = {
    customer_id: customerID,
    market_id: marketID
  }

  try {
    const response = await oAuthRequest.get(url, { params: params })
    return response.data;
  } catch (error) {
    return error.response;
  }
}
export async function updateBrandCustomer(user, brandID) {
  const url = `${config.mappURLV2}/brand_customers?customer_id=${user?.id}&brand_id=${brandID}`
  try {
    const brand = await oAuthRequest.put(url, {
      notification_central_warning_seen: true
    },
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
    return brand
  } catch (error) {
    return error.response;
  }
}

export async function getBrandNotificationAcceptances(userId) {
  const brandID = getBrandID();
  const brand = await oAuthRequest.get(`${config.mappURLV2}/brands/${brandID}/brand_notification_acceptances?customer_id=${userId}`);
  return brand.data
}

export async function updateBrandNotificationAcceptances(customer_id = "", acceptances) {
  const brandID = getBrandID();
  const brand = await oAuthRequest.put(`${config.mappURLV2}/brands/${brandID}/brand_notification_acceptances/save?customer_id=${customer_id}`,
    acceptances);
  return brand.data
}

export async function createPagaLeveCheckout(orderId) {
  const url = `${config.mappURLV3}/paga_leve/checkouts`

  const params = {
    order_id: orderId,
    success_deeplink: window.location.href,
    cancel_deeplink: window.location.href
  }

  try {
    const response = await oAuthRequest.post(url, params )
    return response.data
  } catch (error) {
    throw error
  }
}


export async function authorizePagaLeveCheckout(orderId, value) {
  const url = `${config.mappURLV3}/paga_leve/payments/authorize_checkout`

  const params = {
    order_id: orderId,
    value: value
  }

  try {
    const response = await oAuthRequest.patch(url, params)
    return response.data
  } catch (error) {
    throw error
  }
}
export async function getForYouProducts(marketID, customerID, deliveryMethodId, neighborhoodId, limit) {
  const url = `${config.mappURLV2}/markets/${marketID}/items/top_most_purchased_items`;

  const params = {
    customer_id: customerID,
    limit: limit,
    delivery_method_id: deliveryMethodId,
    neighborhood_id: neighborhoodId
  };

  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSectionOffers(marketID, sectionID, limit, deliveryMethodId, neighborhoodId) {
  const url = `${config.mappURLV2}/markets/${marketID}/items/offers`;
  const user = getUserIdAPICallUsingRedux()
  const params = {
    limit: limit,
    section_id: sectionID,
    delivery_method_id: deliveryMethodId,
    neighborhood_id: neighborhoodId,
    customer_id: user
  };

  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function sendAllyHigherValueCaptureStatus(marketID, status) {
  const url = `${config.mappURLV3}/markets/${marketID}/order_capture_requests/order_capture_request_id`;
  
  const params = {
    status: status
  }
  
  try {
    const response = await oAuthRequest.patch(url, params);
    return response.data;
  } catch (error) {
    throw error;
  }
}
    
export async function getAllyHigherValueCaptureRequests(marketID, orderID) {
  const url = `${config.mappURLV3}/markets/${marketID}/order_capture_requests`;

  const params = {
    order_id: orderID,
  };

  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPublicityProducts(marketID, deliveryMethodId, neighborhoodId, limit) {
  const url = `${config.mappURLV2}/markets/${marketID}/items/publicity_items`;
  const user = getUserIdAPICallUsingRedux()

  const params = {
    delivery_method_id: deliveryMethodId,
    limit: limit,
    neighborhood_id: neighborhoodId,
    customer_id: user
  };

  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getHighlightedProducts(marketID, deliveryMethodId, neighborhoodId, limit) {
  const url = `${config.mappURLV2}/markets/${marketID}/items/highlighted_items`;
  const user = getUserIdAPICallUsingRedux()

  const params = {
    delivery_method_id: deliveryMethodId,
    limit: limit,
    neighborhood_id: neighborhoodId,
    customer_id: user
  };


  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getFreeDeliveryProducts(
  marketID,
  deliveryMethodID,
  customerID,
  neighborhoodId
) {
  const url = `${config.mappURLV2}/markets/${marketID}/items/free_delivery_items`;

  const params = {
    delivery_method_id: deliveryMethodID,
    customer_id: customerID,
    neighborhood_id: neighborhoodId
  };

  try {
    const response = await oAuthRequest.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getListingCards(market, customerId, deliveryMethodId) {
  const marketId = market ? market : localStorage.getItem("market_id")
  const url = `${config.mappURLV2}/cards`
  const params = {
    market_id: marketId,
    customer_id: customerId,
    delivery_method_id: deliveryMethodId
  }
  const headers = { headers: oAuthHeader() }
  try {
    const response = await oAuthRequest.get(url, { params, headers })
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function creatCardInfo(params) {
  const url = `${config.merconnectPayURLV1}/stone/cards`;
  try{
    const response = await oAuthRequest.post(url, params, {headers: { ...v4AuthHeader, Accept: "application/json"}})
    return response.data
  }catch(error){
    console.log(error)
    return error.response
  }
}

export async function deletePaymentCard(cardId, customerId, marketId){
  const url = `${config.mappURLV2}/cards/${cardId}`
  const params = {
    market_id: marketId,
    customer_id: customerId
  }
  const headers = { headers: oAuthHeader() }
  try {
    const response = await oAuthRequest.delete(url, {params,headers })
    return response.data;
  }catch(error){
    return error.response;
  }
}

export async function getFooterSectionInformation(brandId){
  const url = `${config.mappURLV3}/brands/${brandId}/footer_sections`
  try {
    const response = await oAuthRequest.get(url)
    return response.data;
  } catch (error) {
    return error.response;
  }
}
