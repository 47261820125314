import React, { useEffect, useState } from 'react';
import {  useSelector } from "react-redux";

import { Card, CardBody } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { domainColorStyle } from 'config';
import numberToCurrency from 'helpers/currency';

import "./FriendCampaign.scss";
import { friendCampaigns } from 'helpers/apis/cuponsApi';

function FriendCampaign({ removeSection }) {
  const [friendCampaign, setFriendCampaign] = useState({})
  const marketId = useSelector(({ markets }) => markets.id)
  const userId = useSelector(({ userInfo }) => userInfo.id)

  const getFriendCampaigns = async (marketId, userId) => {
    try {
      const { friend_campaign } = await friendCampaigns(marketId, userId);
      setFriendCampaign(friend_campaign);
    } catch (error) {
      removeSection("Código Amigo")
    }
  };

  useEffect(() => {
    getFriendCampaigns(marketId, userId)
  }, [])

  return (
    <div style={{ margin: 10, border: "none" }}>
      <div>
        <Card>
          <CardBody className="friend-campaign">
            <div className="sale-container">
              <p className="sale-header">Seu saldo é de:</p>
              <h6 className="sale" style={domainColorStyle("color")}>{numberToCurrency(parseFloat(friendCampaign.credit))}</h6>
            </div>
            <span className="share-code">Compartilhe seu código:</span>
            <div className="friend-code">
              <p>{friendCampaign.friend_code}</p>
              <CopyToClipboard text={friendCampaign.friend_code}>
                <span className="copy-button" style={domainColorStyle("color")}>Copiar código</span>
              </CopyToClipboard>
            </div>

            <span>Quando seu código é utilizado por um amigo na primeira compra, você ganha {numberToCurrency(parseFloat(friendCampaign.credit_to_be_paid_to_owner_of_the_friend_code))} de
            crédito para uso nas suas futuras compras e seu amigo ganha {numberToCurrency(parseFloat(friendCampaign.amount_of_credit_to_be_paid_to_the_new_customer))} de desconto na sua primeira compra.
            Seu código poderá ser usado por até {friendCampaign.limit_of_use_of_the_friend_code} e será válido até {friendCampaign.end_date_to_use_friend_code}.
            Seu crédito será válido até {friendCampaign.end_date_to_use_the_credit}. Após esta data ele será automaticamente zerado.
            Não atendendo estes requisitos seu código será invalidado.</span>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default FriendCampaign;